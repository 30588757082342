import React, { useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Container,
  useMediaQuery,
} from "@mui/material";
import Cancel from "../../assets/images/Cancel.png";
import LefthtArrowIcon from "../../assets/images/LeftArrow.png";
import { useLocation, useNavigate } from "react-router-dom";
import { PlanType } from "../../helper/planConstant";

interface LocationState {
  planType?: PlanType;
}

const PaymentCancel: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { planType } = location.state as LocationState;

  const handleRetry = () => {
    navigate(`/payment-gateway/${planType}`, { state: { planType } });
  };
  
  return (
    <div>
      <Container
        maxWidth="sm"
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          container
          spacing={2}
          direction="column"
          alignItems="center"
          borderRadius="22px"
          bgcolor="#FFFFFF"
          margin={0}
          justifyContent="center"
          style={{ padding: 30 }}
        >
          <Grid>
            <Typography
              variant="subtitle1"
              color="#273848"
              fontWeight={700}
              fontSize="24.94px"
            >
              Payment Failed!
            </Typography>
          </Grid>
          <Grid container spacing={2} justifyContent="center">
            <Grid
              style={{ padding: 30 }}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <img src={Cancel} width="40%" />
            </Grid>
          </Grid>
          <Grid display="flex" justifyContent="center">
            <Button
              type="submit"
              fullWidth
              style={{
                width: "auto",
                color: "#FA5252",
                fontSize: "18px",
                fontWeight: "500",
                letterSpacing: "0px",
                textTransform: "none",
              }}
              onClick={handleRetry}
            >
              <img src={LefthtArrowIcon} width="20px" height="20px" />
              <span style={{ marginLeft: "5px" }}>Retry Payment</span>{" "}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default PaymentCancel;
