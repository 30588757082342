import React from "react";
import { PopupModal, useCalendlyEventListener } from "react-calendly";
import { useNavigate } from "react-router-dom";



const Calendy: React.FC<{
  onEventScheduled: (data: { name: string; email: string; preparationDetails: string }) => void;
  userId: number;
}> = ({ onEventScheduled, userId }) => {
  const navigate = useNavigate();

  const handleModalClose = () => {
    navigate('/candidate-leads-dashboard', { state: { userId } });
  };


  const handleEventScheduled = (eventData: any) => {
    navigate('/interview-proccess', { state: { userId } });
    // Log event data for now (modify this as needed)
    console.log("Event scheduled data:", eventData);

    // Accessing user details from eventData if available
    const name = eventData?.payload?.invitee?.name || "N/A";
    const email = eventData?.payload?.invitee?.email || "N/A";
    const preparationDetails = eventData?.payload?.questions_and_answers?.find(
      (q: any) => q.question.toLowerCase().includes("preparation")
    )?.answer || "None";

    // Log extracted data
    console.log("Name:", name);
    console.log("Email:", email);
    console.log("Preparation Details:", preparationDetails);

    // Call onEventScheduled callback to pass the data
    onEventScheduled({ name, email, preparationDetails });
  };

  // Use the hook to listen for the scheduled event
  useCalendlyEventListener({
    onEventScheduled: handleEventScheduled,
  });

  return (
    <div className="App">
      <PopupModal
        url="https://calendly.com/tusharcogtix/30min"
        rootElement={document.getElementById("root") as HTMLElement}
        pageSettings={{
          backgroundColor: 'transparent',
          hideEventTypeDetails: false,
          hideLandingPageDetails: false,
          primaryColor: '00a2ff',
          textColor: '4d5055',
        }}
        onModalClose={handleModalClose}
        open={true}
      />
    </div>
  );
};

export default Calendy;