import { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Typography,
  IconButton,
  InputBase,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import plusIcon from "../../assets/images/plus.svg";
import EditIcon from "../../assets/images/EditIcon.svg";
import DeleteIcon from "../../assets/images/deleteIcon.svg";
import ChartIcon from "../../assets/images/JobIcon/bar-chart.svg";
import "../../styles/dashboard.css";
import Header from "../../layout/Header";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import { deleteRequest, postRequest } from "../../api/apiServices";
import { deleteJob, jobsList } from "../../api/apiList";
import Loading from "../../utils/Loading";
import PaginationComponent from "../../utils/PaginationComponent";
import debounce from "lodash/debounce";
import ShimmerEffect from "../../utils/ShimmerEffect";
import Toast from "../../utils/Toast";
import Loader from "../../utils/Loading";

const drawerWidth = 100;

interface HiringManager {
  id: number;
  name: string;
  email: string;
  // Add other properties as needed
}

interface Jobs {
  id: number;
  jobTitle: string;
  hiringManager: HiringManager;
  workingLocation: string;
  reference_number: string;
}

const JobListing = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Adjust breakpoint if necessary
  const navigate = useNavigate();

  const [job, setJob] = useState<Jobs[]>([]);
  const [selectedJob, setSelectedJob] = useState<number | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("");
  const [loader, setLoader] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);

  // State for confirmation dialog
  const [openDialog, setOpenDialog] = useState(false);
  const [jobToDelete, setJobToDelete] = useState<number | null>(null);

  // Pagination state
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); // limit
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const isTabs = useMediaQuery("(max-width:1200px)");
  // Pagination handlers
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleUpdate = (id: number) => {
    // setSelectedJob(id)
    navigate(`/jobs/update-job/${id}`);
  };

  const viewAnalysis = (jobTitle: string) => {
    navigate("/analytics", { state: { jobTitle } });
  };

  const handleDelete = async () => {
    if (jobToDelete !== null) {
      const respose = await deleteRequest(deleteJob(jobToDelete));
      Toast(respose?.data?.statusMessage || "Job deleted successfully!");
      jobList(page + 1, rowsPerPage, searchTerm);
      setOpenDialog(false);
      setJobToDelete(null);
    }
  };

  const handleDeleteClick = (id: number) => {
    setJobToDelete(id);
    setOpenDialog(true);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    setSearchLoading(true);
    debouncedSearch(value);
  };

  const handleFilterChange = (event: SelectChangeEvent<string>) => {
    setFilter(event.target.value as string);
  };

  const handleClick = () => {
    navigate("/jobs/create-jobprofile");
  };

  //debounced search functionality
  const debouncedSearch = useCallback(
    debounce((searchValue: string) => {
      jobList(1, rowsPerPage, searchValue);
      setSearchLoading(false);
    }, 500),
    []
  );

  const jobList = async (page: number, limit: number, search: string) => {
    // if (searchTerm) setLoader(true);
    setLoader(true);
    const response = await postRequest(jobsList, {
      page,
      limit,
      search,
      searchColumns: ["jobTitle", "workingLocation", "reference_number"],
    });
    if (response?.data?.success) {
      setJob(response?.data?.data?.jobs);
      setCount(response?.data?.data?.count);
      setPage(response?.data?.data?.page - 1); // Convert 1-based index to 0-based index
      setHasNextPage(response?.data?.data?.hasNextPage);
      setHasPreviousPage(response?.data?.data?.hasPreviousPage);
    }
    setLoader(false);
  };

  useEffect(() => {
    if (!searchLoading) {
      // Fetch data only if not in search loading state
      jobList(page + 1, rowsPerPage, searchTerm);
    }
  }, [page, rowsPerPage]);

  const inputStyle = {
    backgroundColor: "#ffffff",
    padding: "6px 12px",
    borderRadius: "8px",
    boxShadow: "0 1px 4px rgba(0,0,0,0.1)",
    height: "40px",
  };

  return loader ? (
    <Loading />
  ) : (
    <>
      {/* <Header pageName="Jobs" isShow={true} /> */}

      <Box
        sx={{
          // width: `calc(100% - ${drawerWidth}px)`,
          // ml: `${drawerWidth}px`,
          mt: isTabs ? "" : "50px",
          backgroundColor: "#f0f0f0",
          // padding: "20px",
          borderRadius: "8px",
        }}
      >
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          justifyContent={{ xs: "flex-start", md: "space-between" }}
          alignItems={{ xs: "flex-start", md: "center" }}
          mb={2}
        >
          <Container style={{ padding: "0px" }}>
            <button
              type="submit"
              className="user-scale"
              // variant="contained"
              style={{
                backgroundColor: "#84E1CC",
                boxShadow: "none",
                borderRadius: "10px",
                fontSize: "16px",
                padding: "8px 27px",
                textTransform: "none",
                border: "1px solid #D6D6D7",
                height: "40px",
                color: "white",
                fontWeight: 600,
              }}
              onClick={handleClick}
            >
              Add New &nbsp;
              <img src={plusIcon} alt="Add new job" />
            </button>
          </Container>
          <Box
            display="flex"
            flexDirection={{ xs: isTabs ? "row" : "column", md: "row" }}
            alignItems={{ xs: "center", md: "center" }}
            mt={{ xs: 2, md: 0 }}
          >
            <Box
              className="user-box"
              sx={{
                display: "flex",
                alignItems: "center",
                width: isMobile ? "auto" : "300px", // Set width dynamically based on screen size
                ...inputStyle,
                marginRight: "16px",
              }}
            >
              <SearchIcon />
              <InputBase
                placeholder="Search here..."
                value={searchTerm}
                onChange={handleSearchChange}
                sx={{ ml: 1, flex: 1, fontSize: "16px" }}
              />
            </Box>

            <FormControl
              className="user"
              sx={{
                minWidth: 120,
                ...inputStyle,
                boxShadow: "none",
                margin: { xs: "0", sm: "16px 0" },
              }}
            >
              <InputLabel sx={{ color: "inherit", marginTop: "-6px" }}>
                Filter
              </InputLabel>
              <Select
                value={filter}
                onChange={handleFilterChange}
                label="Filter"
                sx={{
                  height: "40px",
                  marginTop: "-4px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Remove the outline
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Remove the outline on hover
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Remove the outline when focused
                  },
                  "& .MuiSelect-select": {
                    padding: "8px 12px", // Adjust padding if necessary
                  },
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="location">Location</MenuItem>
                <MenuItem value="hrname">Hiring Manager</MenuItem>
                <MenuItem value="created">Created Date</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>

        <Divider />
        <div>
          <Box mt={3} className="table-container">
            <Box className="table-wrapper" sx={{ backgroundColor: "#f0f0f0" }}>
              <Box display="flex" padding="16px 0" textAlign="center">
                <Box width="5%" display="flex" justifyContent="center">
                  <Typography
                    fontSize="16px"
                    fontWeight={500}
                    color="#8C8C8C"
                    letterSpacing="normal"
                  >
                    Sr.
                  </Typography>
                </Box>
                <Box width="20%" display="flex" justifyContent="center">
                  <Typography
                    fontSize="16px"
                    fontWeight={500}
                    color="#8C8C8C"
                    letterSpacing="normal"
                  >
                    Job Title
                  </Typography>
                </Box>
                <Box width="20%" display="flex" justifyContent="center">
                  <Typography
                    fontSize="16px"
                    fontWeight={500}
                    color="#8C8C8C"
                    letterSpacing="normal"
                  >
                    Hiring Manager
                  </Typography>
                </Box>
                <Box width="20%" display="flex" justifyContent="center">
                  <Typography
                    fontSize="16px"
                    fontWeight={500}
                    color="#8C8C8C"
                    letterSpacing="normal"
                  >
                    Location
                  </Typography>
                </Box>
                <Box width="20%" display="flex" justifyContent="center">
                  <Typography
                    fontSize="16px"
                    fontWeight={500}
                    color="#8C8C8C"
                    letterSpacing="normal"
                  >
                    Reference Number
                  </Typography>
                </Box>
                <Box width="15%" display="flex" justifyContent="center">
                  <Typography
                    fontSize="16px"
                    fontWeight={500}
                    color="#8C8C8C"
                    letterSpacing="normal"
                  >
                    Edit / Delete
                  </Typography>
                </Box>
              </Box>
              {job && job.length > 0 ? (
                job.map((jobItem, index) => (
                  <Box
                    className="row-shadow"
                    display="flex"
                    key={jobItem.id}
                    alignItems="center"
                    textAlign="center"
                    bgcolor="#ffffff"
                    borderRadius="4px"
                    my={1}
                    py={1}
                  >
                    {searchLoading ? (
                      <>
                        {Array.from({ length: 6 }, (_, index) => {
                          return (
                            <Box
                              key={index}
                              width={index === 0 || index === 5 ? "10%" : "20%"}
                              display="flex"
                              justifyContent="center"
                            >
                              <ShimmerEffect />
                            </Box>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        <Box width="5%" display="flex" justifyContent="center">
                          <Typography
                            fontSize="16px"
                            fontWeight={500}
                            color="#273848"
                          >
                            {index + 1 + page * rowsPerPage}
                          </Typography>
                        </Box>
                        <Box width="20%" display="flex" justifyContent="center">
                          <Typography
                            fontSize="16px"
                            fontWeight={500}
                            color="#273848"
                          >
                            {jobItem?.jobTitle}
                          </Typography>
                        </Box>
                        <Box width="20%" display="flex" justifyContent="center">
                          <Typography
                            fontSize="16px"
                            fontWeight={500}
                            color="#273848"
                          >
                            {jobItem?.hiringManager?.name}
                          </Typography>
                        </Box>
                        <Box width="20%" display="flex" justifyContent="center">
                          <Typography
                            fontSize="16px"
                            fontWeight={500}
                            color="#273848"
                          >
                            {jobItem?.workingLocation.split(",")[0]}
                          </Typography>
                        </Box>
                        <Box width="20%" display="flex" justifyContent="center">
                          <Typography
                            fontSize="16px"
                            fontWeight={500}
                            color="#273848"
                          >
                            {jobItem?.reference_number}
                          </Typography>
                        </Box>
                        <Box width="15%" display="flex" justifyContent="center">
                          <IconButton
                            onClick={() => viewAnalysis(jobItem?.jobTitle)}
                          >
                            <img
                              src={ChartIcon}
                              alt="Chart-Icon"
                              style={{
                                width: "24px",
                                height: "24px",
                                color: "#273848",
                              }}
                              title="View Analysis"
                            />
                          </IconButton>
                          <IconButton onClick={() => handleUpdate(jobItem?.id)}>
                            <img
                              src={EditIcon}
                              alt="Edit"
                              style={{
                                width: "24px",
                                height: "24px",
                                color: "#273848",
                              }}
                              title="Edit"
                            />
                          </IconButton>
                          <IconButton
                            onClick={() => handleDeleteClick(jobItem?.id)}
                          >
                            <img
                              src={DeleteIcon}
                              alt="Delete"
                              style={{
                                width: "24px",
                                height: "24px",
                                color: "red",
                              }}
                              title="Delete"
                            />
                          </IconButton>
                        </Box>
                      </>
                    )}
                  </Box>
                ))
              ) : searchLoading ? (
                <ShimmerEffect />
              ) : (
                <Typography variant="body1" align="center">
                  {loader ? <Loader /> : <p>No records yet. </p>}
                  {/* <p>No data found..</p> */}
                </Typography>
              )}
            </Box>
          </Box>
          <PaginationComponent
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            count={count}
            hasNextPage={hasNextPage}
            hasPreviousPage={hasPreviousPage}
          />
        </div>
      </Box>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Please Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this job?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleDelete} color="warning">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default JobListing;
