import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";
import { TopCompany } from "../../utils/AnalytivalDataType";
import { useMediaQuery } from "@mui/material";

// Register the required components for chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

interface TopCompaniesChartProps {
  data: TopCompany[];
}

const TopCompaniesChart: React.FC<TopCompaniesChartProps> = ({ data }) => {

  const isMobile = useMediaQuery("(max-width:1200px)");

  // Prepare chart data
  const chartData = {
    labels: data.map(({ canonical_name }) => canonical_name), // Companies' names
    datasets: [
      {
        label: "Top Companies Hiring in Netherlands",
        data: data.map(({ count }) => count), // Revenue count for each company
        backgroundColor: "#56C609", // Color of the bars
        borderColor: "#3e8e41", // Border color of bars (optional)
        borderWidth: 1,
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    indexAxis: "y" as const, // Make the chart horizontal by setting indexAxis to 'y'
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem: any) => {
            return `Revenue: ${tooltipItem.raw}`; // Customize tooltip text
          },
        },
      },
      legend: {
        position: "top" as const, // Position of the legend
      },
    },
    scales: {
      x: {
        beginAtZero: true, // Ensure the x-axis starts from 0
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Bar data={chartData} options={options} width={ isMobile ? 300 : 600} height={ isMobile ? 300 : 400} />
    </div>
  );
};

export default TopCompaniesChart;