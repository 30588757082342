import React from "react";
import '../../styles/privacypolicy.css';
import { Link } from 'react-router-dom';

interface TableData {
    name: string;
    isEssential: string;
    type: string;
    party: string;
    session: string;
    time: string;
}

const data: TableData[] = [
    { name: '_ga', isEssential: 'Essential ', type: 'Analytical ', party: 'Third party ', session: 'Persistent', time: '5 days' },
    { name: '_ga', isEssential: 'Essential ', type: 'Analytical ', party: 'First party ', session: 'Persistent ', time: '2 Years' },
    { name: '_gid', isEssential: 'Essential ', type: 'Analytical ', party: 'First party ', session: 'Session ', time: 'When browser closed' },
    { name: '_gat', isEssential: 'Essential ', type: 'Analytical ', party: 'First party ', session: 'Session ', time: 'When browser closed' },
];

const Provacy_Policy: React.FC = () => {
    return (
        <div className='privacy-policy pt-2'>
            <h1 className="centered-heading">PRIVACY POLICY </h1>
            <p className='mt-5'>
                This Privacy Policy sets out how we, <span><Link to="https://www.sprooze.com/" className='link'>www.sprooze.com</Link></span>, collect, store and use information about you when you use or interact with our website, <span><Link to="https://www.sprooze.com/" className='link'>www.sprooze.com</Link></span> (our website) and where we otherwise obtain or collect information about you.
            </p>

            <p className='pt-2' style={{ fontWeight: 600 }}>This Privacy Policy is effective from 9th October, 2024.</p>

            <ol>
                <li><h2 className="heading-three">OUR DETAILS: </h2></li>
                <ul className='mt-2'>
                    <li><p>sprooze, (we, our and us) operates the website <span><Link to="https://www.costaxis.com/" className='link'>https://www.costaxis.com/.</Link></span></p></li>
                    <li><p>sprooze, is a software recruitment marketing solution based in Netherland. Our mailing Address is Lavendelplein 133, 5643 EH Eindhoven, Netherlands. </p></li>
                    <li><p>Our email address is <Link to="mailto:info@sprooze.com" className='link'>info@sprooze.com.</Link></p></li>
                    <li><p><span><Link to="https://www.costaxis.com/" className='link'>https://www.costaxis.com/</Link></span> is compliant with the 2018 E.U. GDPR. </p></li>
                    <li><p><span style={{ fontWeight: '700' }}>Data controller:</span> <span><Link to="https://www.costaxis.com/" className='link'>https://www.costaxis.com/</Link></span> </p></li>
                </ul>

                <li><h2 className="heading-three">WEB SERVER LOG INFORMATION:  </h2></li>
                <ul className="mt-2">
                    <li><p>We use a server to host our website. Our website server automatically logs the IP address you use to access our website as well as other information about your visit such as the pages accessed, information requested, the date and time of the request, the source of your access to our website, and your browser version and operating system.  </p></li>
                </ul>

                <li><h2 className="heading-three">USE OF WEBSITE SERVER LOG INFORMATION FOR IT SECURITY PURPOSES:</h2></li>
                <ul className="mt-2">
                    <li><p>We do not access log data from our website server collect and store server logs to ensure network and IT security and so that the server and website remain uncompromised. This includes analysing log files to help identify and prevent unauthorised access to our network, the distribution of malicious code, denial of services attacks and other cyber-attacks, by detecting unusual or suspicious activity. </p></li>
                </ul>

                <li><h2 className="heading-three">USE OF WEBSITE SERVER LOG INFORMATION TO ANALYSE WEBSITE USE AND IMPROVE OUR WEBSITE:</h2></li>
                <ul className="mt-2">
                    <li><p>We use the information collected by our website server logs to analyse how our website users interact with our website and its features. For example, we analyse the number of visits and unique visitors we receive, the time and date of the visit, the location of the visit and the operating system and browser used.</p></li>
                    <li><p>You can reject some or all of the cookies we use on or via our website by changing your browser settings, but doing so can impair your ability to use our website or some or all of its features. For further information about cookies, including how to change your browser settings, please see our cookies policy. </p></li>
                </ul>

                <li><h2 className="heading-three">INFORMATION WE COLLECT WHEN YOU CONTACT US:</h2></li>
                <ul className="mt-2">
                    <li><p>We collect and use information from individuals who contact us in accordance with this section.</p>
                        <ul>
                            <li style={{ listStyle: 'circle' }}> <span style={{ fontWeight: '700' }}>Email: </span> When you send an email to the email address displayed on our website we collect your email address and any other information you provide in that email. </li>
                        </ul>
                    </li>
                    <li><p>TRANSFER AND STORAGE OF YOUR INFORMATION:</p>
                        <ul>
                            <li style={{ listStyle: 'circle' }}><p><span style={{ fontWeight: '700' }}>Contact form: </span>When you contact us using our contact form, we collect name, email address, and IP address. We also collect any other information you provide to us when you complete the contact form including any optional information, such as phone number or company name. If you do not provide the mandatory information required by our contact form, you will not be able to submit the contact form and we will not receive your enquiry. </p></li>
                            <li style={{ listStyle: 'circle' }}><p><span style={{ fontWeight: '700' }}>Post: </span> If you contact us by post, we will collect any information you provide to us in any postal communications you send us. </p></li>
                        </ul>
                    </li>
                </ul>


                <li><h2 className="heading-three">INFORMATION WE COLLECT WHEN YOU INTERACT WITH OUR WEBSITE: </h2></li>
                <ul className="mt-2">
                    <li><p>We collect and use information from individuals who interact with particular features of our website in accordance with this section.</p>
                        <ul>
                            <li style={{ listStyle: 'circle' }}> <span style={{ fontWeight: '700' }}>E-Newsletter: </span> When you sign up for our e-newsletter on our website or opt to receive news, offers from us by entering the name and email address and clicking subscribe or ticking a box at checkout indicating that you would like to receive your e-newsletter, we collect name and email address.  </li>
                        </ul>
                    </li>
                    <li><p>TRANSFER AND STORAGE OF YOUR INFORMATION:</p>
                        <ul>
                            <li style={{ listStyle: 'circle' }}> <span style={{ fontWeight: '700' }}>Registering on our website:  </span> When you register and create an account on our website, we collect the following information: name, email address, contact and any other information you provide to us when you complete the registration form, including any optional information, such as phone number or company name. If you do not provide the mandatory information required by the registration form, you will not be able to register or create an account on our website.   </li>
                            <li style={{ listStyle: 'circle' }}> <span style={{ fontWeight: '700' }}>Processing your payment:   </span> After you place an order on our website you will need to make payment for the goods or services you have ordered. In order to process your payment we use a third party payment processor, your payment will be processed by the third party payment processor you choose to process your payment.
                                <ul>
                                    <li>The third party payment processor choose to process your payment collects, uses and processes your information, including payment information, in accordance with their privacy policies. You can access their privacy policies.  </li>
                                </ul>
                            </li>
                            <li style={{ listStyle: 'circle' }}> <span style={{ fontWeight: '700' }}>Marketing communications: </span> At checkout you will have the option of receiving marketing communications from us.  </li>
                            <li style={{ listStyle: 'circle' }}> <span style={{ fontWeight: '700' }}>THIRD PARTY SERVICES: </span> n addition to receiving information about our services, you can opt in to receiving marketing communications from us in relation third party and services by email.  </li>
                        </ul>
                    </li>
                </ul>

                <li><h2 className="heading-three">INFORMATION COLLECTED OR OBTAINED FROM THIRD PARTIES:  </h2></li>
                <ul className="mt-2">
                    <li><p>This section sets out how we obtain or collect information about you from third parties. Information received from third parties: </p></li>
                    <li><p>Generally, we do receive information about you from third parties. It is also possible that third parties with whom we have had no prior contact may provide us with information about you.</p></li>
                    <li><p>Information we obtain from third parties will generally be your name and contact details, but will include any additional information about you which they provide to us. </p></li>
                </ul>

                <li><h2 className="heading-three">WHERE WE RECEIVE INFORMATION ABOUT YOU IN ERROR:  </h2></li>
                <ul className="mt-2">
                    <li><p>If we receive information about you from a third party in error and/or we do not have a legal basis for processing that information, we will delete your information. </p></li>
                </ul>

                <li><h2 className="heading-three">DISCLOSURE AND ADDITIONAL USES OF YOUR INFORMATION:  </h2></li>
                <ul className="mt-2">
                    <li><p>This section sets out the circumstances in which will disclose information about you to third parties and any additional purposes for which we use your information.</p></li>
                    <li><p>Disclosure of your information to service providers:</p>
                        <ul>
                            <li style={{ listStyle: 'circle' }}>We use third parties to provide us with services which are necessary to run our business or to assist us with running our business. </li>
                            <li style={{ listStyle: 'circle' }}>Your information will be shared with the service providers where necessary to provide you with the service you have requested, whether that is accessing our website, or ordering goods and services from us. </li>
                            <li style={{ listStyle: 'circle' }}>We do not display the identities of all of our service providers publicly by name for security and competitive reasons. If you would like further information about the identities of our service providers, however, please contact us, directly via our contact form or by email and we will provide you with such information where you have a legitimate reason for requesting it. </li>
                        </ul>
                    </li>
                    <li><p>Disclosure of Your Information to Other Third Parties: </p>
                        <ul>
                            <li style={{ listStyle: 'circle' }}>We disclose your information to other third parties in specific circumstances, as set out below. Providing information to third parties when they collects information through our use of Analytics on our website. They uses this information, including IP addresses and information from cookies, for a number of purposes, such as improving Analytics service.  </li>
                        </ul>
                    </li>
                </ul>

                <li><h2 className="heading-three">TRANSFER AND STORAGE OF YOUR INFORMATION: </h2></li>
                <ul className="mt-2">
                    <li><p>Sharing your information with third parties, which are either related to or associated with the running of our business, where it is necessary for us to do so. These third parties include our accountants, advisors, affiliates, business partners, independent contractors. Further information on each of these third parties is set out below. </p>
                        <ul>
                            <li style={{ listStyle: 'circle' }}>Accountants </li>
                            <li style={{ listStyle: 'circle' }}>Advisors </li>
                            <li style={{ listStyle: 'circle' }}>Affiliates </li>
                            <li style={{ listStyle: 'circle' }}>Business partners </li>
                            <li style={{ listStyle: 'circle' }}>ndependent contractors </li>
                        </ul>
                    </li>
                </ul>

                <li><h2 className="heading-three">DISCLOSURE AND ADDITIONAL USES OF YOUR INFORMATION FOR LEGAL REASONS:  </h2></li>
                <ul className="mt-2">
                    <li><p> Indicating possible criminal acts or threats to public security to a competent authority:  </p>
                        <ul>
                            <li style={{ listStyle: 'circle' }}>If we suspect that criminal or potential criminal conduct has been occurred, we will in certain circumstances need to contact an appropriate authority, such as the police. This could be the case, for instance, if we suspect that we fraud or a cybercrime has been committed or if we receive threats or malicious communications towards us or third parties. </li>
                            <li style={{ listStyle: 'circle' }}>We will generally only need to process your information for this purpose if you were involved or affected by such an incident in some way. </li>
                        </ul>
                    </li>
                </ul>

                <li><h2 className="heading-three">HOW LONG WE RETAIN YOUR INFORMATION:  </h2></li>
                <ul className="mt-2">
                    <li><p>This section sets out how long we retain your information. We have set out specific retention periods where possible. Where that has not been possible, we have set out the criteria we use to determine the retention period. </p></li>
                    <li>
                        <p><span style={{ fontWeight: '700' }}>Retention periods:</span> Server log information: we retain information on our server logs for one year. </p>
                    </li>
                    <li><p><span style={{ fontWeight: '700' }}>Order information: </span> when you place an order for goods and services, we retain that information for six years following the end of the financial year in which you place your order, in accordance with our legal obligation to keep records for tax purposes.  </p></li>
                    <p><span style={{ fontWeight: '700' }}>E-Newsletter: </span> we retain the information you used to sign up for our e-newsletter for as long as you remain subscribed or if we decide to cancel our e-newsletter service, whichever comes earlier.  </p>
                </ul>

                <li><h2 className="heading-three">CRITERIA FOR DETERMINING RETENTION PERIODS: </h2></li>
                <ul className="mt-2">
                    <li><p>In any other circumstances, we will retain your information for no longer than necessary, taking into account the following: </p>
                        <ul>
                            <li style={{ listStyle: 'circle' }}>The purpose and use of your information both now and in the future such as whether it is necessary to continue to store that information in order to continue to perform our obligations under a contract with you or to contact you in the future;
                                <ul>
                                    <li style={{ listStyle: 'lower-alpha' }}>Whether we have any legal obligation to continue to process your information;  </li>
                                    <li style={{ listStyle: 'lower-alpha' }}>Whether we have any legal basis to continue to process your information; </li>
                                    <li style={{ listStyle: 'lower-alpha' }}>How valuable your information is; </li>
                                    <li style={{ listStyle: 'lower-alpha' }}>any relevant agreed industry practices on how long information should be retained; </li>
                                    <li style={{ listStyle: 'lower-alpha' }}>the levels of risk, cost and liability involved with us continuing to hold the information;  </li>
                                    <li style={{ listStyle: 'lower-alpha' }}>how hard it is to ensure that the information can be kept up to date and accurate; and </li>
                                    <li style={{ listStyle: 'lower-alpha' }}>Any relevant surrounding circumstances. </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>

                <li><h2 className="heading-three">HOW WE SECURE YOUR INFORMATION: </h2></li>
                <ul className="mt-2">
                    <li><p>We take appropriate technical and organisational measures to secure your information and to protect it against unauthorised or unlawful use and accidental loss or destruction, including: Only sharing and providing access to your information to the minimum extent necessary, subject to confidentiality restrictions where appropriate, and on an anonymised basis wherever possible;</p>
                        <ul>
                            <li style={{ listStyle: 'circle' }}>Using secure servers to store your information; </li>
                            <li style={{ listStyle: 'circle' }}>verifying the identity of any individual who requests access to information prior to granting them access to information; </li>
                        </ul>
                    </li>
                </ul>

                <li><h2 className="heading-three">TRANSMISSION OF INFORMATION TO US BY EMAIL: </h2></li>
                <ul className="mt-2">
                    <li><p>Transmission of information over the internet is not entirely secure, and if you submit any information to us over the internet, you do so entirely at your own risk. </p></li>
                    <li><p>We cannot be responsible for any costs, expenses, loss of profits, harm to reputation, damages, liabilities or any other form of loss or damage suffered by you as a result of your decision to transmit information to us by such means. </p></li>
                </ul>

                <li><h2 className="heading-three">RIGHTS IN RELATION TO YOUR INFORMATION: </h2></li>
                <ul className="mt-2">
                    <li><p>Subject to certain limitations on certain rights, you have the following rights in relation to your information, which you can exercise by sending an email to <Link to="mailto:info@sprooze.com" className='link'>info@sprooze.com:</Link> To request access to your information and information related to our use and processing of your information; </p>
                        <ul>
                            <li style={{ listStyle: 'circle' }}>To request the correction or deletion of your information; </li>
                            <li style={{ listStyle: 'circle' }}>To request that we restrict our use of your information; </li>
                            <li style={{ listStyle: 'circle' }}>To receive information which you have provided to us in a structured, commonly used and machine-readable format (e.g. a CSV file) and the right to have that information transferred to another data controller;  </li>
                            <li style={{ listStyle: 'circle' }}>To object to the processing of your information for certain purposes; and</li>
                            <li style={{ listStyle: 'circle' }}>To withdraw your consent to our use of your information at any time where we rely on your consent to use or process that information. Please note that if you withdraw your consent, this will not affect the lawfulness of our use and processing of your information on the basis of your consent before the point in time when you withdraw your consent.</li>
                            <li style={{ listStyle: 'circle' }}>The right not to be subject to a decision based solely on automated processing, including profiling which produces legal affects concerning you or similarly significantly affects you. </li>
                        </ul>
                    </li>
                </ul>

                <li><h2 className="heading-three">VERIFYING YOUR IDENTITY WHERE YOU REQUEST ACCESS TO YOUR INFORMATION:  </h2></li>
                <ul className="mt-2">
                    <li><p>Where you request access to your information, we are required by law to use all reasonable measures to verify your identity before doing so.  </p></li>
                    <li><p>These measures are designed to protect your information and to reduce the risk of identity fraud, identity theft or general unauthorised access to your information. </p></li>
                </ul>

                <li><h2 className="heading-three">HOW WE VERIFY IDENTITY: </h2></li>
                <ul className="mt-2">
                    <li><p>Where we possess appropriate information about you on file, we will attempt to verify your identity using that information.  </p></li>
                    <li><p>If it is not possible to identity you from such information, or if we have insufficient information about you, we may require original or certified copies of certain documentation in order to be able to verify your identity before we are able to provide you with access to your information. </p></li>
                    <li><p>We will be able to confirm the precise information we require to verify your identity in your specific circumstances if and when you make such a request. </p></li>
                </ul>

                <li><h2 className="heading-three">RIGHT TO OBJECT TO THE PROCESSING OF YOUR INFORMATION FOR CERTAIN PURPOSES: </h2></li>
                <ul className="mt-2">
                    <li><p>You have the following rights in relation to your information, which you may exercise in the same way as you may exercise by sending an email to <Link to="mailto:info@sprooze.com" className='link'>info@sprooze.com:</Link></p>
                        <ul>
                            <li style={{ listStyle: 'circle' }}>To object to us using or processing your information where we use or process it in order to carry out a task in the public interest or for our legitimate interests, including ‘profiling’ based on any of these purposes; and </li>
                            <li style={{ listStyle: 'circle' }}>To object to us using or processing your information for direct marketing purposes. You may also exercise your right to object to us using or processing your information for direct marketing purposes by: </li>
                            <li style={{ listStyle: 'circle' }}>Clicking the unsubscribe link contained at the bottom of any marketing email we send to you and following the instructions which appear in your browser following your clicking on that link;  </li>
                            <li style={{ listStyle: 'circle' }}>Sending an email to <Link to="mailto:info@sprooze.com" className='link'>info@sprooze.com,</Link> asking that we stop sending you marketing communications or by including the words “OPT OUT”. </li>
                        </ul>
                    </li>
                    <li><p>For more information on how to object to our use of information collected from cookies and similar technologies, please see our cookies policy, which is available on cookie policy page.</p></li>
                </ul>

                <li><h2 className="heading-three">COOKIES: </h2></li>
                <ul className="mt-2">
                    <li><p>Cookies are small data files sent by a website’s server to a web browser, processor memory or hard drive and stored there. They can be used for a range of different purposes, such as customizing a website for a particular user, helping a user navigate a website, improving that user’s website experience, and storing that user’s preferences and login information. Targeting or Advertising Purposes: </p></li>
                    <li><p>Using Cookies for targeting and advertising purpose allow us to display adverts on the website, and to collect information about your usage of the website in order to make advertisements more relevant and personalize to you and your interests. We may use third parties to display relevant and personalized advertisements to you through their networks. </p>
                        <ul>
                            <li style={{ listStyle: 'lower-alpha' }}>
                                ESSENTIAL AND NON-ESSENTIAL COOKIES:
                                <ul>
                                    <li>Cookies can be classified as either ‘essential’ or ‘non-essential’. </li>
                                    <li>Essential cookies:
                                        <ul>
                                            <li style={{ listStyle: 'circle' }}>Used solely to carry out or facilitate the transmission of communications over a network; or  </li>
                                            <li style={{ listStyle: 'circle' }}>Strictly necessary to provide an online service (e.g. our website or a service on our website) which you have requested. </li>
                                        </ul>
                                    </li>
                                    <li>Non-essential cookies:
                                        <ul>
                                            <li style={{ listStyle: 'circle' }}>These are many cookies that do not fall within the definition of essential cookies, such as cookies used to analyses your behaviour on a website (‘analytical’ cookies) or cookies used to display advertisements to you (‘advertising’ cookies). </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                SESSION AND PERSISTENT COOKIES:
                                <ul>
                                    <li>Cookies can be classified as either ‘session’ or ‘persistent’, depending on how long they last after they are placed on your browser.
                                        <ul>
                                            <li style={{ listStyle: 'circle' }}> <span style={{ fontWeight: '700' }}>Session Cookies:</span> Session cookies last for as long as you keep your browser open. They expire when you close your browser.  </li>
                                            <li style={{ listStyle: 'circle' }}> <span style={{ fontWeight: '700' }}>Persistent cookies:</span> Persistent cookies expire at a fixed point in time or if you manually delete them from your browser, whichever occurs first?   </li>
                                            <li style={{ listStyle: 'circle' }}> <span style={{ fontWeight: '700' }}>First and third party cookies: </span> Cookies can be classified as ‘first party’ or ‘third party’.    </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>

                <li><h2 className="heading-three">LIST OF COOKIES USED:  </h2></li>
                <ul className="mt-2">
                    <li><p>We use the following cookies on or in relation to our website: </p></li>
                </ul>

                <div className="table-container mt-1">
                    <table className="responsive-table text-center">
                        <thead className="text-center">
                            <tr className="text-center">
                                <th>Name of Cookie</th>
                                <th>Essential or Non-essential </th>
                                <th>Type of cookie </th>
                                <th>First or Third party? </th>
                                <th>Session or Persistent </th>
                                <th>Expiry Time </th>
                            </tr>
                        </thead>
                        <tbody className="text-center">
                            {data.map((row, index) => (
                                <tr key={index}>
                                    <td>{row.name}</td>
                                    <td>{row.isEssential}</td>
                                    <td>{row.type}</td>
                                    <td>{row.party}</td>
                                    <td>{row.session}</td>
                                    <td>{row.time}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <li><h2 className="heading-three">CHANGES TO OUR PRIVACY POLICY:</h2></li>
                <ul className="mt-2">
                    <li><p>We update and amend our Privacy Policy from time to time. </p></li>
                    <li><p>Minor changes to our Privacy Policy: </p>
                        <ul>
                            <li style={{ listStyle: 'circle' }}>Where we make minor changes to our Privacy Policy, we will update our Privacy Policy with a new effective date stated at the beginning of it.</li>
                            <li style={{ listStyle: 'circle' }}>Our processing of your information will be governed by the practices set out in that new version of the Privacy Policy from its effective date onwards. </li>
                            <li style={{ listStyle: 'circle' }}>Major changes to our Privacy Policy or the purposes for which we process your information: </li>
                            <li style={{ listStyle: 'circle' }}>Where we make major changes to our Privacy Policy or intend to use your information for a new purpose or a different purpose than the purposes for which we originally collected it, we will notify you by email (where possible) or by posting a notice on our website.  </li>
                            <li style={{ listStyle: 'circle' }}>We will provide you with the information about the change in question and the purpose and any other relevant information before we use your information for that new purpose. </li>
                            <li style={{ listStyle: 'circle' }}>Wherever required, we will obtain your prior consent before using your information for a purpose that is different from the purposes for which we originally collected it. </li>
                        </ul>
                    </li>
                </ul>

                <li><h2 className="heading-three">CHILDREN’S PRIVACY:  </h2></li>
                <ul className="mt-2">
                    <li><p>We do not knowingly contact or collect information from persons under the age of 16. The website is not intended to solicit information of any kind from persons under the age of 16. </p></li>
                    <li><p>It is possible that we could receive information pertaining to persons under the age of 16 by the fraud or deception of a third party. If we are notified of this, as soon as we verify the information, we will, where required by law to do so, immediately obtain the appropriate parental consent to use that information or, if we are unable to obtain such parental consent, we will delete the information from our servers.</p></li>
                    <li><p>If you would like to notify us of our receipt of information about persons under the age of 16, please do so by sending an email to <Link to="mailto:info@sprooze.com" className='link'>info@sprooze.com.</Link>  </p></li>
                </ul>

                <li><h2 className="heading-three">COPYRIGHT:  </h2></li>
                <ul className="mt-2">
                    <li><p>The copyright in this Privacy Policy is either owned by, or licensed to, us and is protected by copyright laws around the world and copyright protection software. All intellectual property rights in this document are reserved. </p></li>
                </ul>

                <li><h2 className="heading-three">CONTACT INFORMATION:  </h2></li>
                <ul className="mt-2">
                    <li><p>f you have any questions regarding these terms and conditions of this website, please contact us by any of the following means; </p></li>
                    <li><p><span style={{ fontWeight: 800 }}>Web:</span> <Link className='link' to='https://www.sprooze.com'>www.sprooze.com</Link> </p></li>
                    <li><p><span style={{ fontWeight: 700 }}>Email:</span> <Link to="mailto:info@sprooze.com" className='link'>info@sprooze.com</Link> </p></li>
                    <li><p><span style={{ fontWeight: 700 }}>Address:</span> Lavendelplein 133. 5643 EH Eindhoven, Netherlands. </p></li>
                </ul>


            </ol>

            <div className="col-12 pt-5 pb-5 d-flex align-items-center justify-content-between">
                <p>© All rights reserved from sprooze.</p>
                <p>October, 2024.</p>
            </div>
        </div>
    )
}

export default Provacy_Policy;