import React from "react";
import { getSocialMediaIcon } from "../utils/getSocialMediaIcons";

interface SocialMediaIconProps {
    names: string[];
}

const SocialMediaIcon: React.FC<SocialMediaIconProps> = ({ names }) => {
    return (
        <div className="d-flex align-items-center justify-content-start flex-wrap gap-4">
            {names.map((name, index) => (
                <span key={index}>
                    {getSocialMediaIcon(name) || <span>Unknown</span>}
                </span>
            ))}
        </div>
    )
}

export default SocialMediaIcon;