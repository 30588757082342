import React from "react";

interface IconGenerateProps {
    image: string;
    colour: string;
    tooltip?: string;
}

const generateSocialMediaIcon: React.FC<IconGenerateProps> = ({ image, colour, tooltip }) => {
    return (
        <div style={{
            // padding:'20px',
            height: '70px',
            backgroundColor: colour,
            borderRadius: '8.75px',
            width: '70px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <img src={image} alt="Social-Media-Icon" title={tooltip} />
        </div>
    )
}

export default generateSocialMediaIcon; 