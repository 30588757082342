import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Container,
  useMediaQuery,
  Box,
  Button,
  TextField,
  Link,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import "../../styles/inputBox.css";
import LinkedInIcon from "../../assets/images/SocialMedia/IinkedIn.svg";
import InstagramIcon from "../../assets/images/SocialMedia/Instagram.svg";
import FaceBookIcon from "../../assets/images/SocialMedia/Facebook.svg";
import GoogleIcon from "../../assets/images/SocialMedia/Google.svg";
import YoutubeIcon from "../../assets/images/SocialMedia/Youtube.svg";
import XIcon from "../../assets/images/SocialMedia/X.svg";
import { useNavigate, useParams } from "react-router-dom";
import { multiPartPUTRequest, multiPartRequest } from "../../api/apiServices";
import { createCompany, updateCompany } from "../../api/apiList";
import Loader from "../Loader";
import Toast from "../../utils/Toast";
import { Link as RouterLink } from "react-router-dom"; // Import Link from react-router-dom
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface ProfileFourthProps {
  onBack: () => void; // Function to navigate back to the previous component
  onNext: () => void; // Function to navigate to the next component
  setProfileData: (data: any) => void;
  initialData: any;
  mode: any;
}

const ProfileFourth: React.FC<ProfileFourthProps> = ({
  onBack,
  onNext,
  setProfileData,
  initialData,
  mode,
}: ProfileFourthProps) => {
  const isMobile = useMediaQuery("(max-width:578px)");

  const [showFileSelect, setShowFileSelect] = useState(false);
  const [linkedInKey, setLinkedInKey] = useState("");
  const [instagramKey, setInstagramKey] = useState("");
  const [facebookKey, setFacebookKey] = useState("");
  const [google, setGoogle] = useState<string | File>("");
  const [twitter, setTwitter] = useState({
    appKey: "",
    appSecret: "",
    accessToken: "",
    accessSecret: "",
  });

  const [hiringManager, setHiringManager] = useState<string | File>("");
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  

  const [errors, setErrors] = useState({
    linkedInKey: "",
    instagramKey: "",
    facebookKey: "",
    google: "",
    twitter_appKey: "",
    twitter_appSecret: "",
    twitter_accessToken: "",
    twitter_accessSecret: "",
    hiringManager: "",
  });

  const formIsValid = () => {
    const newErrors = {
      linkedInKey: !linkedInKey?.trim() ? "Linkdin is required" : "",
      instagramKey: !instagramKey?.trim() ? "Instagram is required" : "",
      facebookKey: !facebookKey?.trim() ? "FaceBook is required" : "",
      google: !google ? "Google is required" : "",
      twitter_appKey: !twitter?.appKey?.trim()
        ? "Twitter appKey is required"
        : "",
      twitter_appSecret: !twitter?.appSecret?.trim()
        ? "Twitter appSecret is required"
        : "",
      twitter_accessToken: !twitter?.accessToken?.trim()
        ? "Twitter accessToken is required"
        : "",
      twitter_accessSecret: !twitter?.accessSecret?.trim()
        ? "Twitter accessSecret is required"
        : "",

      hiringManager:
        !params.id && !showFileSelect && !hiringManager
          ? "Hiring Manager is required"
          : "",
    };
    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowFileSelect(event.target.checked); // Update state based on checkbox value
    setHiringManager("");
  };

  const handleGoogleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files[0]) {
      setGoogle(event.target.files[0]);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setHiringManager(event.target.files[0]);
    }
  };

  useEffect(() => {
    if (initialData) {
      setLinkedInKey(initialData?.linkedInKey);
      setInstagramKey(initialData?.instagramKey);
      setFacebookKey(initialData?.facebookKey);
      setGoogle(initialData?.googleKey);
      setTwitter(initialData?.twitter);
      setHiringManager(initialData?.hiringManager);
    }
  }, []);

  const params = useParams();

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const newData = {
      linkedInKey,
      instagramKey,
      facebookKey,
      google,
      twitter,
      hiringManager: showFileSelect ? hiringManager : hiringManager,
    };
    if (formIsValid()) {
      if (mode) {
        setLoading(true);
        const response = await multiPartPUTRequest(
          updateCompany(params?.id),
          newData
        );
        Toast(response?.data?.statusMessage);
        setProfileData(response?.data?.data);
        setLoading(false);
        navigate("/");
      } else {
        const allData = {
          ...initialData,
          ...newData,
          hiringManager: showFileSelect ? hiringManager : hiringManager,
        };
        setProfileData(allData);
        setLoader(true);
        const response = await multiPartRequest(createCompany, allData);
        setLoader(false);
        if (response) {
          Toast(response?.data?.statusMessage || "Compnay Create Successfully");
          navigate("/");
        }
      }
    }
  };

  const handleBack = (event: any) => {
    event.preventDefault();
    onBack();
  };

  // Determine if google is a string URL or a File
  const googleFileUrl =
    typeof google === "string" ? google : URL.createObjectURL(google);

  return (
    <div>
      <Container
        // maxWidth="md"
        style={{
          padding: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          spacing={2}
          bgcolor="#FFFFFF"
          width="784px"
          borderRadius="22px"
          border="1px solid #D6D6D7"
          margin={0}
        >
          <Grid
            item
            height={isMobile ? "auto" : "auto"}
            style={{ padding: 30 }}
          >
            <Grid
              container
              direction="row"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              gap="10px"
            >
              <Grid item display="flex" alignItems="center" gap="10px">
                <ArrowBackIcon
                  onClick={handleBack}
                  style={{ cursor: "pointer" }}
                />
                <Typography color="#273848" fontWeight={700} fontSize="20.94px">
                  Create Profile
                </Typography>
              </Grid>
              <Grid item gap={2} display="flex">
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#84E1CC",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#84E1CC",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#84E1CC",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#84E1CC",
                  }}
                />
              </Grid>
            </Grid>
            <Grid my={2}></Grid>
            <Grid item>
              <Grid
                direction="row"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid direction="row" display="flex" gap={1}>
                  <img src={LinkedInIcon} alt="linkedin" />
                  <Typography className="input-label-custom">
                    LinkedIn
                  </Typography>
                </Grid>
                <Grid>
                  <Link
                    // href="/signup"
                    color="#13213B"
                    style={{ textDecorationColor: "#13213B" }}
                  >
                    Connect
                  </Link>
                </Grid>
              </Grid>
              <Grid mb={1}></Grid>
              <TextField
                sx={{
                  // mt: 1,
                  "& .MuiOutlinedInput-input": {
                    padding: "10px 11px", // Add padding here
                  },
                }}
                placeholder="Insert LinkedIn API Key"
                fullWidth
                className="input-box-custom"
                rows={3}
                multiline
                error={!!errors.linkedInKey}
                helperText={errors.linkedInKey}
                value={linkedInKey}
                onChange={(e) => setLinkedInKey(e.target.value)}
              />
            </Grid>
            <Grid my={2}></Grid>
            <Grid item>
              <Grid
                direction="row"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid direction="row" display="flex" gap={1}>
                  <img src={InstagramIcon} alt="instagram-icon" />
                  <Typography className="input-label-custom">
                    Instagram
                  </Typography>
                </Grid>
                <Grid>
                  <Link
                    // href="/signup"
                    color="#13213B"
                    style={{ textDecorationColor: "#13213B" }}
                  >
                    Connect
                  </Link>
                </Grid>
              </Grid>
              <Grid mb={1}></Grid>
              <TextField
                sx={{
                  // mt: 1,
                  "& .MuiOutlinedInput-input": {
                    padding: "10px 11px", // Add padding here
                  },
                }}
                placeholder="Insert Instagram API Key"
                fullWidth
                className="input-box-custom"
                rows={3}
                multiline
                error={!!errors.instagramKey}
                helperText={errors.instagramKey}
                value={instagramKey}
                onChange={(e) => setInstagramKey(e.target.value)}
              />
            </Grid>
            <Grid my={2}></Grid>
            <Grid item>
              <Grid
                direction="row"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid direction="row" display="flex" gap={1}>
                  <img src={FaceBookIcon} alt="facebook-icon" />
                  <Typography className="input-label-custom">
                    Facebook
                  </Typography>
                </Grid>
                <Grid>
                  <Link
                    // href="/signup"
                    color="#13213B"
                    style={{ textDecorationColor: "#13213B" }}
                  >
                    Connect
                  </Link>
                </Grid>
              </Grid>
              <Grid mb={1}></Grid>
              <TextField
                sx={{
                  // mt: 1,
                  "& .MuiOutlinedInput-input": {
                    padding: "10px 11px", // Add padding here
                  },
                }}
                placeholder="Insert Facebook API Key"
                fullWidth
                className="input-box-custom"
                rows={3}
                multiline
                error={!!errors.facebookKey}
                helperText={errors.facebookKey}
                value={facebookKey}
                onChange={(e) => setFacebookKey(e.target.value)}
              />
            </Grid>
            <Grid my={2}></Grid>
            <Grid item>
              <Grid
                direction="row"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid direction="row" display="flex" gap={1}>
                  <img src={GoogleIcon} alt="google-icon" />
                  <Typography className="input-label-custom">Google</Typography>
                </Grid>
                <Grid>
                  <Link
                    // href="/signup"
                    color="#13213B"
                    style={{ textDecorationColor: "#13213B" }}
                  >
                    Connect
                  </Link>
                </Grid>
              </Grid>
              <Grid mb={1}></Grid>
              <TextField
                sx={{
                  // mt: 1,
                  "& .MuiOutlinedInput-input": {
                    padding: "10px 11px", // Add padding here
                  },
                }}
                type="file"
                inputProps={{ accept: "application/json" }}
                fullWidth
                className="input-box-custom"
                error={!!errors.google}
                helperText={errors.google}
                onChange={handleGoogleFileChange}
              />
              {google && (
                <Typography variant="body2" color="textSecondary">
                  Selected file:{" "}
                  <RouterLink
                    to={googleFileUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#13213B" }}
                  >
                    View file here
                  </RouterLink>
                </Typography>
              )}
            </Grid>
            <Grid my={2}></Grid>
            <Grid item>
              <Grid
                direction="row"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid direction="row" display="flex" gap={1}>
                  <img
                    src={XIcon}
                    alt="twitter-icon"
                    width="20px"
                    height="20px"
                  />
                  <Typography className="input-label-custom">X</Typography>
                </Grid>
                <Grid>
                  <Link
                    // href="/signup"
                    color="#13213B"
                    style={{ textDecorationColor: "#13213B" }}
                  >
                    Connect
                  </Link>
                </Grid>
              </Grid>
              <Grid mb={1}></Grid>
              <Grid item ml={2}>
                <Grid direction="row" display="flex" alignItems="center">
                  <Typography className="input-label-custom">
                    App Key
                  </Typography>
                </Grid>
                <Grid mb={1}></Grid>
                <TextField
                  sx={{
                    // mt: 1,
                    "& .MuiOutlinedInput-input": {
                      padding: "10px 11px", // Add padding here
                    },
                  }}
                  placeholder="Insert X App Key"
                  fullWidth
                  className="input-box-custom"
                  error={!!errors.twitter_appKey}
                  helperText={errors.twitter_appKey}
                  value={twitter?.appKey}
                  onChange={(e) =>
                    setTwitter({ ...twitter, appKey: e.target.value })
                  }
                />
              </Grid>
              <Grid mb={1}></Grid>
              <Grid item ml={2}>
                <Grid direction="row" display="flex" alignItems="center">
                  <Typography className="input-label-custom">
                    App Secret
                  </Typography>
                </Grid>
                <Grid mb={1}></Grid>
                <TextField
                  sx={{
                    // mt: 1,
                    "& .MuiOutlinedInput-input": {
                      padding: "10px 11px", // Add padding here
                    },
                  }}
                  placeholder="Insert X App Secret"
                  fullWidth
                  className="input-box-custom"
                  error={!!errors.twitter_appSecret}
                  helperText={errors.twitter_appSecret}
                  value={twitter?.appSecret}
                  onChange={(e) =>
                    setTwitter({ ...twitter, appSecret: e.target.value })
                  }
                />
              </Grid>
              <Grid mb={1}></Grid>
              <Grid item ml={2}>
                <Grid direction="row" display="flex" alignItems="center">
                  <Typography className="input-label-custom">
                    Access Token
                  </Typography>
                </Grid>
                <Grid mb={1}></Grid>
                <TextField
                  sx={{
                    // mt: 1,
                    "& .MuiOutlinedInput-input": {
                      padding: "10px 11px", // Add padding here
                    },
                  }}
                  placeholder="Insert X Access Token"
                  fullWidth
                  className="input-box-custom"
                  error={!!errors.twitter_accessToken}
                  helperText={errors.twitter_accessToken}
                  value={twitter?.accessToken}
                  onChange={(e) =>
                    setTwitter({ ...twitter, accessToken: e.target.value })
                  }
                />
              </Grid>
              <Grid mb={1}></Grid>
              <Grid item ml={2}>
                <Grid direction="row" display="flex" alignItems="center">
                  <Typography className="input-label-custom">
                    Access Secret
                  </Typography>
                </Grid>
                <Grid mb={1}></Grid>
                <TextField
                  sx={{
                    // mt: 1,
                    "& .MuiOutlinedInput-input": {
                      padding: "10px 11px", // Add padding here
                    },
                  }}
                  placeholder="Insert X Access Secret"
                  fullWidth
                  className="input-box-custom"
                  error={!!errors.twitter_accessSecret}
                  helperText={errors.twitter_accessSecret}
                  value={twitter?.accessSecret}
                  onChange={(e) =>
                    setTwitter({ ...twitter, accessSecret: e.target.value })
                  }
                />
              </Grid>
            </Grid>
            <Grid my={2}></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                Hiring Manager
              </Typography>
              <Grid mb="5px"></Grid>
              {showFileSelect ? (
                <TextField
                  sx={{
                    // mt: 1,
                    "& .MuiOutlinedInput-input": {
                      padding: "10px 11px", // Add padding here
                    },
                  }}
                  type="file"
                  fullWidth
                  className="input-box-custom"
                  error={!!errors.hiringManager}
                  helperText={errors.hiringManager}
                  onChange={handleFileChange}
                />
              ) : (
                <TextField
                  sx={{
                    // mt: 1,
                    "& .MuiOutlinedInput-input": {
                      padding: "10px 11px", // Add padding here
                    },
                  }}
                  placeholder="Enter Email"
                  fullWidth
                  className="input-box-custom"
                  error={!!errors.hiringManager}
                  helperText={errors.hiringManager}
                  value={hiringManager}
                  onChange={(e) => setHiringManager(e.target.value)}
                />
              )}
              <Grid my={2}></Grid>
              <Grid direction="row" display="flex" alignItems="center">
                <Checkbox
                  checked={showFileSelect}
                  onChange={handleCheckboxChange}
                  sx={{ borderRadius: 8, padding: 0 }}
                />
                <Typography className="input-label-custom">
                  Bulk Upload
                </Typography>
              </Grid>
            </Grid>
            <Grid mb="50px"></Grid>
            <Grid
              container
              direction="row"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid xs={2.5}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  style={{
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #000000",
                    color: "#273848",
                    boxShadow: "none",
                    borderRadius: "5px",
                    padding: "17px 17px",
                    lineHeight: "0px",
                    textTransform: "none",
                    letterSpacing: "0px",
                  }}
                  onClick={(event) => handleBack(event)}
                >
                  Back
                </Button>
              </Grid>
              <Grid
                item
                xs={isMobile ? 0 : 3}
                style={{ maxWidth: "fitContent" }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  style={{
                    backgroundColor: "#84E1CC",
                    boxShadow: "none",
                    borderRadius: "5px",
                    padding: "17px 17px",
                    textTransform: "none",
                    lineHeight: "0px",
                    letterSpacing: "0px",
                  }}
                  onClick={(event) => handleSubmit(event)}
                >
                  {loader ? (
                    <Loader />
                  ) : mode ? (
                    loading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      "Save & Next"
                    )
                  ) : (
                    "Continue"
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ProfileFourth;
