import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getRequest } from "../api/apiServices";
import { getHiringManagerList } from "../api/apiList";

interface HiringManager {
    id: number;
    name: string;
}

let initialState: HiringManager[] = [];

// Async thunk to fetch hiring managers from an API
export const fetchHiringManagers = createAsyncThunk('hiringManager/fetchHiringManagers', async ()=>{
    const response = await getRequest(getHiringManagerList)
    const data = response?.data?.data
    return data as HiringManager[]
})
  
const hirinManagerSlice = createSlice({
    name : "hiringManager",
    initialState ,
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(fetchHiringManagers.fulfilled,(state, action: PayloadAction<HiringManager[]>)=>{
            return action.payload;
        })
    }
})

export const {} =  hirinManagerSlice.actions

export default hirinManagerSlice.reducer