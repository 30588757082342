import React, { useEffect, useState } from 'react';
import Label from '../../components/Common/Label';
import FilterIcon from '../../assets/images/filtericon.svg';
import CallIcon from '../../assets/images/callicon.svg';
import MailIcon from '../../assets/images/mailIcon.svg';
import FileIcon from '../../assets/images/fileIcon.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  useMediaQuery,
} from '@mui/material';
import Loader from '../../utils/Loading';
import { postRequest } from '../../api/apiServices';
import { candidatesLeadsList } from '../../api/apiList';
import { CandidateLeadsDashboard } from '../../utils/CandidateDashboard';
import PaginationComponent from '../../utils/PaginationComponent';

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const matches = useMediaQuery('(min-width:780px)');
  const isTabs = useMediaQuery('(max-width:1200px)');
  const [loading, setLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState<CandidateLeadsDashboard>();
  const [filter, setFilter] = useState('');

  // Pagination state
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); // limit
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);

  const location = useLocation();
  const { campaignID } = location.state as { campaignID: any };

  // Pagination handlers
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const viewProfile = (id: number) =>
    navigate('/candidate-leads-dashboard/view', { state: { id, filter } });

  const handleFilterChange = async (event: SelectChangeEvent<string>) => {
    setLoading(true);
    setFilter(event.target.value as string);
    const response: any = await postRequest(candidatesLeadsList, {
      page: page + 1,
      limit: rowsPerPage,
      campaignId: campaignID,
      status: event.target.value,
    });
    if (response) {
      setDashboardData(response?.data);
      setCount(response?.data?.data?.count);
      setPage(response?.data?.data?.page - 1);
      setHasNextPage(response?.data?.data?.hasNextPage);
      setHasPreviousPage(response?.data?.data?.hasPreviousPage);
      setLoading(false);
    }
    setLoading(false);
  };

  const fetchUser = async () => {
    setLoading(true);
    const response: any = await postRequest(candidatesLeadsList, {
      page: page + 1,
      limit: rowsPerPage,
      campaignId: campaignID,
    });
    if (response) {
      setDashboardData(response?.data);
      setCount(response?.data?.data?.count);
      setPage(response?.data?.data?.page - 1);
      setHasNextPage(response?.data?.data?.hasNextPage);
      setHasPreviousPage(response?.data?.data?.hasPreviousPage);

      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUser();
  }, [page, rowsPerPage]);

  const inputStyle = {
    backgroundColor: '#ffffff',
    padding: '6px 12px',
    borderRadius: '8px',
    boxShadow: '0 1px 4px rgba(0,0,0,0.1)',
    height: '40px',
  };

  return (
    <>
      <div className="container-fluid" style={{ padding: '32px 0px' }}>
        <div className="row align-items-center justify-content-center">
          <Label
            children="Candidate List"
            fontWeight={700}
            fontSize="20.94px"
            color="#273848"
          />
          <Label
            children="Lorem ipsum dolor sit amet consectetur. Tortor odio."
            fontWeight={500}
            fontSize="12.94px"
            color="#273848"
          />
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ marginTop: '25px' }}
          >
            <Label
              children="Campaign Name"
              fontWeight={700}
              fontSize="17.94px"
              color="#273848"
            />
            <div className="d-flex align-items-center justify-content-center">
              <FormControl
                className="user"
                sx={{
                  minWidth: 120,
                  ...inputStyle,
                  boxShadow: 'none',
                  margin: { xs: '0', sm: '16px 0' },
                }}
              >
                <InputLabel sx={{ color: 'inherit', marginTop: '-6px' }}>
                  Filter
                </InputLabel>
                <Select
                  value={filter}
                  onChange={handleFilterChange}
                  label="Filter"
                  sx={{
                    height: '40px',
                    marginTop: '-4px',
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none', // Remove the outline
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      border: 'none', // Remove the outline on hover
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      border: 'none', // Remove the outline when focused
                    },
                    '& .MuiSelect-select': {
                      padding: '8px 12px', // Adjust padding if necessary
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="CONTACTED">Connected</MenuItem>
                  <MenuItem value="WAITING_ON_REPLY">
                    Waiting On Replay
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>

          <div
            className="d-flex align-items-center justify-content-center flex-column row"
            style={{ marginTop: '29px', gap: '26px' }}
          >
            {dashboardData && dashboardData?.data?.candidate?.length > 0 ? (
              dashboardData?.data?.candidate.map((data, index) => (
                <>
                  <div
                    key={index}
                    style={{
                      backgroundColor: 'white',
                      border: '1px solid #d6d6d7',
                      borderRadius: '27px',
                      padding: '30px 33px',
                    }}
                  >
                    <div
                      className="row"
                      style={{ gap: isTabs ? '10px' : '0px' }}
                    >
                      <div
                        className="col-md-3 d-flex align-items-center justify-content-start"
                        style={{
                          position: 'relative',
                          borderRight: matches ? '1px solid #DADaDA' : 'none',
                        }}
                      >
                        <div
                          className="d-flex align-items-center justify-content-start"
                          style={{ gap: '17px' }}
                        >
                          {/* <img src="https://randomuser.me/api/portraits/women/66.jpg" className="img-fluid" style={{ height: '66px', width: '66px', borderRadius: '50%', objectFit: 'cover' }} alt="USERIMAGE" /> */}
                          <div>
                            <Label
                              children={data?.name}
                              fontWeight={700}
                              fontSize={'17.48px'}
                              color={'#273848'}
                            />
                            <Label
                              children={data?.jobTitle}
                              fontWeight={500}
                              fontSize={'13.85px'}
                              color={'#273848'}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            position: 'absolute',
                            top: '-42.5px',
                            left: '8px',
                            backgroundColor: '#81e1cc',
                            color: 'white',
                            padding: '4px 30px',
                            borderRadius: '5px',
                            border: 'none',
                          }}
                        >
                          <Label
                            children={data?.status}
                            fontWeight={700}
                            fontSize="11.94px"
                            color="white"
                          />
                        </div>
                      </div>

                      <div className="col-md-6 d-flex align-items-center justify-content-center ">
                        <div
                          className="d-flex align-items-center justify-content-between w-100 flex-wrap"
                          style={{ padding: isTabs ? '0px' : '0px 30px' }}
                        >
                          <div className="d-flex align-items-center justify-content-center gap-1">
                            <img
                              src={CallIcon}
                              className="img-fluid"
                              alt="CallIcon"
                            />

                            <p
                              style={{
                                margin: '0px',
                                fontWeight: 500,
                                fontSize: '16.85px',
                                color: '#273848',
                              }}
                            >
                              +{data?.phone}
                            </p>
                          </div>

                          <div className="d-flex align-items-center justify-content-center gap-1">
                            <img
                              src={MailIcon}
                              className="img-fluid"
                              alt="MailIcon"
                            />
                            <Link
                              className="text-decoration-none"
                              to={`mailto:${data?.email}`}
                            >
                              <Label
                                children={data?.email}
                                fontWeight={500}
                                fontSize="16.85px"
                                color="#273848"
                              />
                            </Link>
                          </div>

                          <div className="d-flex align-items-center justify-content-center gap-1">
                            <img
                              src={FileIcon}
                              className="img-fluid"
                              alt="FileIcon"
                            />
                            <Link
                              to={data?.cv}
                              target="_blank"
                              style={{ color: '#273848' }}
                            >
                              <Label
                                children="View CV"
                                fontWeight={500}
                                fontSize="16.85px"
                                color="#273848"
                              />
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div
                        className="col-md-3 d-flex align-items-center justify-content-end"
                        style={{
                          borderLeft: matches ? '1px solid #DADaDA' : 'none',
                        }}
                      >
                        <button
                          className="user"
                          style={{
                            fontWeight: 500,
                            fontSize: '15.94px',
                            backgroundColor: '#81e1cc',
                            color: '#ffffff',
                            padding: '8px 20px',
                            borderRadius: '5px',
                            border: 'none',
                          }}
                          onClick={() => viewProfile(data?.id)}
                        >
                          Call to Action
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              ))
            ) : (
              <p>No Data</p>
            )}
          </div>

          <div className="col-12">
            <PaginationComponent
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              count={count}
              hasNextPage={hasNextPage}
              hasPreviousPage={hasPreviousPage}
            />
          </div>
        </div>
      </div>
      {loading && <Loader />}
    </>
  );
};

export default Dashboard;
