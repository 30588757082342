import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

interface PostingLoaderProps {
  loading: boolean;
  text: string;
}

const PostingLoader: React.FC<PostingLoaderProps> = ({ loading, text }) => {
  return (
    <>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            animation: "fade-in 0.5s ease-in-out",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "20px",
              backgroundColor: "#fff",
              borderRadius: "10px",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
              animation: "slide-up 0.8s ease-in-out",
            }}
          >
            <CircularProgress
              style={{
                width: "60px",
                height: "60px",
                color: "#85e2cc",
                animation: "spin 2s linear infinite",
              }}
            />
            <div
              style={{
                marginTop: "20px",
                textAlign: "center",
                animation: "fade-in 0.8s ease-in-out",
              }}
            >
              <span style={{ fontWeight: "bold" }}>{text}</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PostingLoader;
