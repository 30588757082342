import React, { useState } from "react";
import {
  Grid,
  Typography,
  Link,
  TextField,
  Button,
  Container,
  useMediaQuery,
  FormLabel,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Logo from "../../assets/images/Logo.svg";
import LogoName from "../../assets/images/LogoName.png";
import Image from "../../assets/images/Image.svg";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import "../../styles/inputBox.css";
import "../../assets/fonts/font.css";
import { handleAction } from "../../services/authService";
import { AxiosError } from "axios";
import Toast from "../../utils/Toast";
import Loader from "../../components/Loader";

const SignIn: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [emailError, setEmailError] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");
  const isMobile = useMediaQuery("(max-width:578px)");
  const navigate = useNavigate();
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [loading, setLoading] = useState(false)

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Prevent default form submission
    setEmailError("");
    setPasswordError("");

    if (!email || !password) {
      if (!email) setEmailError("Email is required");
      if (!password) setPasswordError("Password is required");
      return;
    }

    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError("Invalid email address");
      return;
    }

    if (password.length < 8) {
      setPasswordError("Password must be at least 8 characters long");
      return;
    }

    try {
      setLoading(true)
      await handleAction("login", { email, password });
      Toast("Login successful");
      setLoading(false)
      navigate("/"); 
    } catch (error) {
      setLoading(false)
      const axiosError = error as AxiosError;
      if (axiosError.response && axiosError.response.status === 401) {
        Toast("Invalid email or password", "error")
      } else {
        Toast("An error occurred.Please try again later.", "error")
      }
    }
  };

  return (
    <div style={{ backgroundColor: "#F4F4F4" }}>
      <Container
        maxWidth="md"
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#F4F4F4",
        }}
      >
        <Grid
          container
          spacing={2}
          justifyContent="center"
          bgcolor="#FFFFFF"
          borderRadius="22px"
          //  height={700}
          margin={0}
        >
          {/* Form Column */}
          <Grid
            item
            xs={12}
            md={6}
            //  height="100%"
            height={isMobile ? "fit-content" : "auto"}
            // bgcolor="green"
            justifyContent="center"
            style={{ padding: 30 }}
          >
            <Grid
              container
              spacing={2}
              height="100%"
              justifyContent="space-between"
              direction="column"
            >
              <Grid
                container
                spacing={2}
                width="100%"
                direction="column"
                margin={0}
                padding={0}
              >
                <Grid item>
                  {isMobile && (
                    <Grid
                      item
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <img
                        src={LogoName}
                        style={{
                          width: "70%",
                        }}
                      />
                    </Grid>
                  )}
                  <Typography
                    color="#273848"
                    fontWeight={700}
                    fontSize="29.94px"
                  >
                    Login
                  </Typography>
                </Grid>
                <Grid item></Grid>

                <Grid item>
                  <form onSubmit={handleSubmit}>
                    <Typography className="input-label-custom">
                      Email Address
                    </Typography>

                    <TextField
                      placeholder="Email Address"
                      fullWidth
                      className="input-box-custom"
                      sx={{
                        mt: 1,
                        "& .MuiOutlinedInput-input": {
                          padding: "10px 11px", // Add padding here
                        },
                      }}
                      style={{ font: "caption" }}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      error={!!emailError} // Apply error styling if emailError is true
                      InputProps={{
                        style: {
                          font: "caption",
                          fontWeight: 500,
                        },
                      }}
                    />
                    {emailError && (
                      <Typography color="error" fontSize={12}>
                        {emailError}
                      </Typography>
                    )}

                    <Typography className="input-label-custom" sx={{ mt: 2 }}>
                      Password
                    </Typography>
                    <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                      <OutlinedInput
                        sx={{ mt: 1 }}
                        id="outlined-adornment-password"
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                        placeholder="Password"
                        label=""
                        className="input-box-custom"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        error={!!passwordError} // Apply error styling if passwordError is true
                      />
                      {passwordError && (
                        <Typography color="error" fontSize={12}>
                          {passwordError}
                        </Typography>
                      )}
                    </FormControl>

                    <Link
                      href="/signup"
                      color="#6BCED7"
                      style={{ textDecorationColor: "#6BCED7" }}
                    >
                      Forgot Password
                    </Link>

                    <Button
                      variant="contained"
                      type="submit"
                      fullWidth
                      style={{
                        backgroundColor: "#84E1CC",
                        boxShadow: "none",
                        borderRadius: "5px",
                        padding: loading ? "7px" : "17px 17px",
                        lineHeight: "0px",
                        textTransform: "none",
                        letterSpacing: "0px",
                        marginTop: "25px", // Add some margin to separate the button from the link
                      }}
                    >
                      {loading ? <Loader /> : "Login"}
                    </Button>
                  </form>
                </Grid>
              </Grid>

              <Grid item>
                <Typography align="center" color="#273848">
                  Don't you have an account?{" "}
                  <Link
                    href="/signup"
                    color="#6BCED7"
                    style={{ textDecorationColor: "#6BCED7" }}
                  >
                    Sign Up
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {/* Image Column */}
          {!isMobile && (
            <Grid
              item
              md={6}
              //  height="100%"
              style={{ padding: 0, position: "relative" }}
            >
              {/* Replace the image source with your actual image */}
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  background: `url(${Logo})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderRadius: "0 22px 22px 0",
                  // opacity: 0.5,
                }}
              />
              <img
                src={Image}
                alt="Signup Image"
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "0 22px 22px 0",
                  objectFit: "cover",
                }}
              />
            </Grid>
          )}
        </Grid>
      </Container>
    </div >
  );
};

export default SignIn;
