import React, { useEffect, useRef, useState } from 'react';
import {
  Grid,
  Select,
  Typography,
  useMediaQuery,
  MenuItem,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/X';
import YouTubeIcon from '@mui/icons-material/YouTube';
import GoogleIcon from "@mui/icons-material/Google";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PostPublish from '../../components/PostingPublishing/PostPublish';

const PostingPublishing: React.FC = () => {
  const isMobile = useMediaQuery('(max-width:991px)');
  const [selectedPlatform, setSelectedPlatform] = useState('Instagram');


  const handlePlatformChange = (event: SelectChangeEvent<string>) => {
    setSelectedPlatform(event.target.value);
  };


  const getPlatformIcon = (platform: string) => {
    switch (platform) {
      case "Instagram":
        return <InstagramIcon sx={{ color: "#fff" }} />;
      case "Facebook":
        return <FacebookIcon sx={{ color: "#fff" }} />;
      case "LinkedIn":
        return <LinkedInIcon sx={{ color: "#fff" }} />;
      case "Twitter":
        return <TwitterIcon sx={{ color: "#fff" }} />;
      case "Google":
        return <GoogleIcon sx={{ color: "#fff" }} />;
      default:
        return null;
    }
  };

  return (
    <div style={{ height: "100%" }}>
      <Grid
        container
        spacing={1}
        height={isMobile ? "auto" : "100%"}
        margin={0}
        justifyContent="center"
        width="100%"
        style={{ padding: "10px 0px 10px 0px" }}
      >
        <Grid
          item
          xs={isMobile ? 12 : 2}
          bgcolor="#244564"
          display="flex"
          flexDirection={isMobile ? "row" : "column"}
          sx={{
            overflowX: isMobile ? "auto" : "hidden",
            whiteSpace: isMobile ? "nowrap" : "normal",
            "&::Webkit-scrollbar": {
              display: "none",
            },
            padding: "16px",
            alignItems: isMobile ? "baseline" : "center",
          }}
          style={{ padding: isMobile ? "20px" : "10px" }}
          borderRadius={isMobile ? "0px 0px 22px 22px" : "22px"}
        >
          <Grid
            container
            direction={isMobile ? "row" : "column"}
            alignItems="center"
            sx={{
              marginRight: isMobile ? "10px" : "0px",
            }}
          >
            <Grid item padding="17px 0px">
              <Typography fontSize="20.94px" fontWeight="bold" color="#fff">
                Select Social
              </Typography>
            </Grid>
            <Grid item width="100%">
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedPlatform}
                fullWidth
                onChange={handlePlatformChange}
                displayEmpty
                IconComponent={ArrowDropDownIcon}
                renderValue={(value) => (
                  <span
                    className={value === "" ? "placeholder" : ""}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "#fff",
                      backgroundColor: "#244564",
                    }}
                  >
                    {value === "" ? (
                      "Select"
                    ) : (
                      <>
                        {getPlatformIcon(value)}
                        <span style={{ marginLeft: "8px" }}>{value}</span>
                      </>
                    )}
                  </span>
                )}
                MenuProps={{
                  PaperProps: {
                    style: {
                      backgroundColor: "#244564",
                      color: "#fff",
                    },
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#fff",
                    },
                    "&:hover fieldset": {
                      borderColor: "#fff",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#fff",
                    },
                  },
                  "& .MuiSelect-icon": {
                    color: "#fff",
                  },
                  "& .MuiSelect-root": {
                    backgroundColor: "#244564",
                    color: "#fff",
                  },
                }}
              >
                <MenuItem value="" disabled>
                  Select
                </MenuItem>
                <MenuItem value={"Instagram"}>
                  <InstagramIcon sx={{ color: "#fff", marginRight: "8px" }} />{" "}
                  Instagram{" "}
                </MenuItem>
                <MenuItem value={"Facebook"}>
                  <FacebookIcon sx={{ color: "#fff", marginRight: "8px" }} />{" "}
                  Facebook
                </MenuItem>
                <MenuItem value={"LinkedIn"}>
                  <LinkedInIcon sx={{ color: "#fff", marginRight: "8px" }} />{" "}
                  LinkedIn
                </MenuItem>
                <MenuItem value={"Twitter"}>
                  <TwitterIcon sx={{ color: "#fff", marginRight: "8px" }} />{" "}
                  Twitter
                </MenuItem>
                <MenuItem value={"Google"}>
                  <GoogleIcon sx={{ color: "#fff", marginRight: "8px" }} />{" "}
                  Google
                </MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={8}
          style={{
            padding: "10px 0px 10px 0px",
            maxWidth: isMobile ? "100%" : "",
          }}
        >
          <PostPublish selectedPlatform={selectedPlatform} />
        </Grid>
      </Grid>
    </div>
  );
};

export default PostingPublishing;
