import React, { useEffect, useState } from "react";
import Label from "../../components/Common/Label";
import CallIcon from "../../assets/images/callicon.svg";
import MailIcon from "../../assets/images/mailIcon.svg";
import FileIcon from "../../assets/images/fileIcon.svg";
import MeetIcon from "../../assets/images/Dashboard/meet.svg";
import StarIcon from "../../assets/images/Dashboard/star.svg";
import UmbrellaIcon from "../../assets/images/Dashboard/umbrella.svg";
import InnfoIcon from "../../assets/images/Dashboard/info.svg";
import { useMediaQuery } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loader from "../../utils/Loading";
import { postRequest } from "../../api/apiServices";
import { getCandidateLeads } from "../../api/apiList";
import { CandidateLeadsDashboardView } from "../../utils/CandidateDashboard";
import Calendy from "../../components/Calendy";
import { HIRING_STATUS_CODE } from "../../utils/hiringStatusCode";
import NotNowPopup from "../../components/Popups/NotNowPopup";
import NeedInfo from "../../components/Popups/NeedInfo";

const DashboardView: React.FC = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<CandidateLeadsDashboardView>();
  const [open, setOpen] = useState(false);
  const [openNotNowPopus, setOpenNotNowPopus] = useState(false);
  const [openNeedInfoPopUp, setOpenNeedInfoPopUp] = useState(false);
  const navigate = useNavigate();
  const matches = useMediaQuery("(min-width:780px)");
  const isTabs = useMediaQuery("(max-width:1200px)");

  const { id, filter } = location.state as { id: number; filter: string };

  const fetUserData = async () => {
    setLoading(true);
    const response: any = await postRequest(getCandidateLeads, { id });
    if (response) {
      setData(response?.data);
    }
    setLoading(false);
  };

  const openCalendy = () => setOpen(true);

  const handleCalendyEvent = (eventData: {
    name: string;
    email: string;
    preparationDetails: string;
  }) => {
    console.log("Name:", eventData.name);
    console.log("Email:", eventData.email);
    console.log("Preparation Details:", eventData.preparationDetails);
  };

  const giveFeedback = () =>
    navigate("/feedback", { state: { id, mode: "create", status: "" } });
  const viewFeedback = () =>
    navigate("/feedback", {
      state: { id, mode: "view", status: data?.data?.status },
    });
  const updateCandidates = () =>
    navigate("/candidates/create-candidate", {
      state: {
        name: data?.data?.name,
        email: data?.data?.email,
        phone: data?.data?.phone,
        mode: "fetch",
      },
    });

  useEffect(() => {
    fetUserData();
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <>
      <div
        className="container-fluid"
        style={{ padding: isTabs ? "32px 20px" : "32px 0px" }}
      >
        <div
          className="d-flex align-items-center justify-content-center flex-column row"
          style={{ marginTop: "29px", gap: "26px" }}
        >
          <div
            style={{
              backgroundColor: "white",
              border: "1px solid #d6d6d7",
              borderRadius: "27px",
              padding: "30px 33px",
            }}
          >
            <div className="row" style={{ gap: isTabs ? "10px" : "0px" }}>
              <div
                className="col-md-3 d-flex align-items-center justify-content-start"
                style={{
                  position: "relative",
                  borderRight: matches ? "1px solid #DADaDA" : "none",
                }}
              >
                <div
                  className="d-flex align-items-center justify-content-start"
                  style={{ gap: "17px" }}
                >
                  {/* <img src="https://randomuser.me/api/portraits/women/66.jpg" className="img-fluid" style={{ height: '66px', width: '66px', borderRadius: '50%', objectFit: 'cover' }} alt="USERIMAGE" /> */}
                  <div>
                    <Label
                      children={data?.data?.name}
                      fontWeight={700}
                      fontSize={"17.48px"}
                      color={"#273848"}
                    />
                    <Label
                      children={data?.data?.jobTitle}
                      fontWeight={500}
                      fontSize={"13.85px"}
                      color={"#273848"}
                    />
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: "-42.5px",
                    left: "21px",
                    backgroundColor: "#81e1cc",
                    color: "white",
                    padding: "4px 30px",
                    borderRadius: "5px",
                    border: "none",
                  }}
                >
                  <Label
                    children={data?.data?.status}
                    fontWeight={700}
                    fontSize="11.94px"
                    color="white"
                  />
                </div>
              </div>

              <div className="col-md-6 d-flex align-items-center justify-content-center">
                <div
                  className="d-flex align-items-center justify-content-between w-100 flex-wrap"
                  style={{ padding: isTabs ? "0px" : "0px 30px" }}
                >
                  <div className="d-flex align-items-center justify-content-center gap-1">
                    <img src={CallIcon} className="img-fluid" alt="CallIcon" />
                    <p
                      style={{
                        margin: "0px",
                        fontWeight: 500,
                        fontSize: "16.85px",
                        color: "#273848",
                      }}
                    >
                      +{data?.data?.phone}
                    </p>
                  </div>

                  <div className="d-flex align-items-center justify-content-center gap-1">
                    <img src={MailIcon} className="img-fluid" alt="MailIcon" />
                    <Link
                      className="text-decoration-none"
                      to={`mailto:${data?.data?.email}`}
                    >
                      <Label
                        children={data?.data?.email}
                        fontWeight={500}
                        fontSize="16.85px"
                        color="#273848"
                      />
                    </Link>
                  </div>

                  <div className="d-flex align-items-center justify-content-center gap-1">
                    <img src={FileIcon} className="img-fluid" alt="FileIcon" />
                    <Link
                      to={data?.data?.cv || ""}
                      target="_blank"
                      style={{ color: "#273848" }}
                    >
                      <Label
                        children="View CV"
                        fontWeight={500}
                        fontSize="16.85px"
                        color="#273848"
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-3 d-flex align-items-center justify-content-end">
                {data?.data?.isGiveFeedback ? (
                  <button
                    className="user"
                    style={{
                      backgroundColor: "#84e1cc",
                      padding: "7px 25px",
                      textAlign: "center",
                      borderRadius: "8px",
                      border: "none",
                      color: "white",
                      fontWeight: 700,
                      fontSize: "16.24px",
                    }}
                    onClick={giveFeedback}
                  >
                    Give Feedback
                  </button>
                ) : (
                  <button
                    className="user"
                    style={{
                      backgroundColor: "#84e1cc",
                      padding: "7px 25px",
                      textAlign: "center",
                      borderRadius: "8px",
                      border: "none",
                      color: "white",
                      fontWeight: 700,
                      fontSize: "16.24px",
                    }}
                    onClick={viewFeedback}
                  >
                    View Feedback
                  </button>
                )}
              </div>
            </div>
          </div>

          <Label
            children="Select an Action"
            fontWeight={700}
            fontSize="20.48px"
          />

          <div
            className="row gap-5 justify-content-center"
            style={{
              backgroundColor: "white",
              border: "1px solid #d6d6d7",
              borderRadius: "27px",
              padding: "36px 23px",
            }}
          >
            <div
              className="col"
              style={{
                border: "1px solid #d6d6d7",
                borderRadius: "27px",
                padding: "27px",
                maxWidth: "300px",
              }}
            >
              <div
                className="d-flex align-items-center justify-content-between flex-column"
                style={{ gap: "50px", height: "100%" }}
              >
                <div
                  className="d-flex align-items-center justify-content-center flex-column"
                  style={{ gap: "20px" }}
                >
                  <div className="d-flex align-items-center justify-content-center">
                    <div
                      style={{
                        backgroundColor: "#EEF9FC",
                        padding: "20px",
                        borderRadius: "50%",
                      }}
                    >
                      <img src={MeetIcon} alt="MeetIcon" />
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-center flex-column">
                    <Label
                      children="Just meet"
                      fontWeight={700}
                      fontSize="16.48px"
                      color="#273848"
                    />
                    <p
                      className="m-0"
                      style={{
                        fontWeight: 500,
                        fontSize: "14.48px",
                        color: "#273848",
                        textAlign: "center",
                      }}
                    >
                      (Digital) Cup coffee (automated <br /> scheduling Calandy
                      like)
                    </p>
                  </div>
                </div>
                <button
                  className="user"
                  style={{
                    fontWeight: 500,
                    fontSize: "17.94px",
                    backgroundColor: "#84E1CC",
                    color: "white",
                    padding: "7px 60px",
                    borderRadius: "5px",
                    border: "none",
                  }}
                  onClick={openCalendy}
                >
                  Select
                </button>
              </div>
            </div>
            <div
              className="col"
              style={{
                border: "1px solid #d6d6d7",
                borderRadius: "27px",
                padding: "27px",
                maxWidth: "300px",
              }}
            >
              <div
                className="d-flex align-items-center justify-content-between flex-column"
                style={{ gap: "50px", height: "100%" }}
              >
                <div
                  className="d-flex align-items-center justify-content-center flex-column"
                  style={{ gap: "20px" }}
                >
                  <div className="d-flex align-items-center justify-content-center">
                    <div
                      style={{
                        backgroundColor: "#EEF9FC",
                        padding: "20px",
                        borderRadius: "50%",
                      }}
                    >
                      <img
                        style={{
                          height: "34px",
                          width: "34px",
                          objectFit: "contain",
                        }}
                        src={StarIcon}
                        alt="StarIcon"
                      />
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-center flex-column">
                    <Label
                      children="Not now"
                      fontWeight={700}
                      fontSize="16.48px"
                      color="#273848"
                    />
                    <p
                      className="m-0"
                      style={{
                        fontWeight: 500,
                        fontSize: "14.48px",
                        color: "#273848",
                        textAlign: "center",
                      }}
                    >
                      - When come back to you? <br />- In what circumstances
                      will you <br />
                      be interested?  <br />- Can we save your info?
                    </p>
                  </div>
                </div>
                <button
                  className="user"
                  style={{
                    fontWeight: 500,
                    fontSize: "17.94px",
                    backgroundColor: "#84E1CC",
                    color: "white",
                    padding: "7px 60px",
                    borderRadius: "5px",
                    border: "none",
                  }}
                  onClick={() => setOpenNotNowPopus(true)}
                >
                  Select
                </button>
              </div>
            </div>
            <div
              className="col"
              style={{
                border: "1px solid #d6d6d7",
                borderRadius: "27px",
                padding: "27px",
                maxWidth: "300px",
              }}
            >
              <div
                className="d-flex align-items-center justify-content-between flex-column"
                style={{ gap: "50px", height: "100%" }}
              >
                <div
                  className="d-flex align-items-center justify-content-center flex-column"
                  style={{ gap: "20px" }}
                >
                  <div className="d-flex align-items-center justify-content-center">
                    <div
                      style={{
                        backgroundColor: "#EEF9FC",
                        padding: "20px",
                        borderRadius: "50%",
                      }}
                    >
                      <img
                        style={{
                          height: "34px",
                          width: "34px",
                          objectFit: "contain",
                        }}
                        src={UmbrellaIcon}
                        alt="UmbrellaIcon"
                      />
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-center flex-column">
                    <Label
                      children="Interested in Vacancy"
                      fontWeight={700}
                      fontSize="16.48px"
                      color="#273848"
                    />
                    <p
                      className="m-0"
                      style={{
                        fontWeight: 500,
                        fontSize: "14.48px",
                        color: "#273848",
                        textAlign: "center",
                      }}
                    >
                      Interested in Vacancy
                    </p>
                  </div>
                </div>
                <button
                  className="user"
                  style={{
                    fontWeight: 500,
                    fontSize: "17.94px",
                    backgroundColor: "#84E1CC",
                    color: "white",
                    padding: "7px 60px",
                    borderRadius: "5px",
                    border: "none",
                  }}
                  onClick={updateCandidates}
                >
                  Select
                </button>
              </div>
            </div>
            <div
              className="col"
              style={{
                border: "1px solid #d6d6d7",
                borderRadius: "27px",
                padding: "27px",
                maxWidth: "300px",
              }}
            >
              <div
                className="d-flex align-items-center justify-content-between flex-column"
                style={{ gap: "50px", height: "100%" }}
              >
                <div
                  className="d-flex align-items-center justify-content-center flex-column"
                  style={{ gap: "20px" }}
                >
                  <div className="d-flex align-items-center justify-content-center">
                    <div
                      style={{
                        backgroundColor: "#EEF9FC",
                        padding: "20px",
                        borderRadius: "50%",
                      }}
                    >
                      <img
                        style={{
                          height: "34px",
                          width: "34px",
                          objectFit: "contain",
                        }}
                        src={InnfoIcon}
                        alt="InnfoIcon"
                      />
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-center flex-column">
                    <Label
                      children="Need more info"
                      fontWeight={700}
                      fontSize="16.48px"
                      color="#273848"
                    />
                    <p
                      className="m-0"
                      style={{
                        fontWeight: 500,
                        fontSize: "14.48px",
                        color: "#273848",
                        textAlign: "center",
                      }}
                    >
                      - Career website <br />- Video 
                      <br />- Full Vacancy <br />- (Digital) Cup coffee with{" "}
                      <br />
                      Corporate Recruiter (automated <br />
                      scheduling Calandy like) 
                    </p>
                  </div>
                </div>
                <button
                  className="user"
                  style={{
                    fontWeight: 500,
                    fontSize: "17.94px",
                    backgroundColor: "#84E1CC",
                    color: "white",
                    padding: "7px 60px",
                    borderRadius: "5px",
                    border: "none",
                  }}
                  onClick={() => setOpenNeedInfoPopUp(true)}
                >
                  Select
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {open && <Calendy onEventScheduled={handleCalendyEvent} userId={id} />}
      {openNotNowPopus && (
        <NotNowPopup
          open={openNotNowPopus}
          userID={id}
          onClose={() => setOpenNotNowPopus(false)}
        />
      )}
      {openNeedInfoPopUp && (
        <NeedInfo
          open={openNeedInfoPopUp}
          email={data?.data?.email || ""}
          onClose={() => setOpenNeedInfoPopUp(false)}
        />
      )}
    </>
  );
};

export default DashboardView;
