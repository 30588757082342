import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Container,
  useMediaQuery,
  Box,
  Button,
} from "@mui/material";
import "../../styles/inputBox.css";
import WorkIcon from "../../assets/images/JobIcon/work-icon.svg";
import OverviewIcon from "../../assets/images/JobIcon/Overview.svg";
import GoalIcon from "../../assets/images/JobIcon/goal.svg";
import CheckIcon from "../../assets/images/JobIcon/check-bold.svg";
import SymbolsIcon from "../../assets/images/JobIcon/symbols_step.svg";
import TeamsIcon from "../../assets/images/JobIcon/team-people.svg";
import { useNavigate, useParams } from "react-router-dom";
import { postRequest, putRequest } from "../../api/apiServices";
import { createJob, updateJob } from "../../api/apiList";
import { generateText } from "../../utils/AIGenerateText";
import Loader from "../Loader";
import Toast from "../../utils/Toast";

interface JobProfileSixthProps {
  onBack: () => void;
  onNext: () => void;
  initialData: any; // Function to navigate to the next component
  mode: any;
}

const JobProfileSixth: React.FC<JobProfileSixthProps> = ({
  onBack,
  onNext,
  initialData,
  mode,
}: JobProfileSixthProps) => {
  const isMobile = useMediaQuery("(max-width:578px)");
  const isTabs = useMediaQuery("(max-width:1200px)");
  const navigate = useNavigate();

  //params
  const params = useParams();

  const [overview, setOverview] = useState("");
  const [responsibilities, setResponsibilities] = useState("");
  const [challenges, setChallenges] = useState("");
  const [team, setTeam] = useState("");
  const [interview, setInterview] = useState("");

  const handleBack = (event: any) => {
    event.preventDefault();
    // Handle form submission logic here
    onBack(); // Call the onNext function to navigate to the next component
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (mode) {
      const response = await putRequest(updateJob(params?.id), initialData);
      if (response) {
        Toast(response?.data?.statusMessage || "Jobs Updated Successfully");
        navigate("/jobs");
      }
    } else {
      const response = await postRequest(createJob, initialData);
      if (response) {
        Toast(response?.data?.statusMessage || "Jobs Create Successfully");
        navigate("/jobs");
      }
    }
  };

  const handleOverviewResponse = async () => {
    const dataString = `This is ${initialData?.jobTitle} Job`;
    const reponse = await generateText(dataString);
    return reponse;
  };

  const handleReponsibilityResponse = async () => {
    const dataString = `Reponsibility ${initialData?.taskOfThisPosition},skills ${initialData?.technicalSkillsRequired}`;
    const reponse = await generateText(dataString);
    return reponse;
  };

  const handleChalengesResponse = async () => {
    const dataString = `Challenges ${initialData?.roleChallenges}`;
    const reponse = await generateText(dataString);
    return reponse;
  };

  const handleInterviewResponse = async () => {
    const dataString = `Interview Process ${initialData?.interviewProcess}`;
    const reponse = await generateText(dataString);
    return reponse;
  };

  const handleTeamResponse = async () => {
    const dataString = `Team ${initialData?.teamInteraction},Culture ${initialData?.cultureFit}`;
    const reponse = await generateText(dataString);
    return reponse;
  };

  //OnLoad Set the Data of Job Summery
  useEffect(() => {
    const fetchData = async () => {
      try {
        const overviewData = await handleOverviewResponse();
        const responsibilityResponse = await handleReponsibilityResponse();
        const challengesResponse = await handleChalengesResponse();
        const teamResponse = await handleTeamResponse();
        const interviewResponse = await handleInterviewResponse();
        setOverview(overviewData);
        setResponsibilities(responsibilityResponse);
        setChallenges(challengesResponse);
        setTeam(teamResponse);
        setInterview(interviewResponse);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <Container
        style={{
          display: "flex",
          padding: "25px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          spacing={2}
          bgcolor="#FFFFFF"
          width="884px"
          borderRadius="22px"
          border="1px solid #D6D6D7"
          margin={0}
        >
          <Grid
            item
            height={isMobile ? "auto" : "auto"}
            style={{ padding: "23px 16px" }}
          >
            <Grid
              container
              display="flex"
              bgcolor="#84E1CC"
              borderRadius="17px"
              padding="18px"
            >
              <Grid item display="flex" gap="20px">
                <img src={WorkIcon} alt="team-icon" />
                <Grid>
                  <Typography
                    color="#FFFFFF"
                    fontWeight={700}
                    fontSize="20.94px"
                  >
                    {initialData?.jobTitle}
                  </Typography>
                  <Grid mb="1px"></Grid>
                  <Typography
                    color="#FFFFFF"
                    fontWeight={500}
                    fontSize="12.94px"
                  ></Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid mt="35px"></Grid>
            <Grid
              display="flex"
              direction={isMobile ? "column" : "row"}
              alignItems="center"
              gap="10px"
            >
              <Grid>
                <Box
                  padding="17.50px"
                  borderRadius="7px"
                  width="fit-content"
                  bgcolor="#84E1CC"
                >
                  <img src={OverviewIcon} width="32px" height="32px" />
                </Box>
              </Grid>
              <Grid>
                <Typography fontWeight={700} fontSize="12.94px" color="#273848">
                  <ul>{overview ? <li> {overview} </li> : <Loader />}</ul>
                </Typography>
              </Grid>
            </Grid>
            <Grid mt="61px"></Grid>
            <Grid
              display="flex"
              alignItems="center"
              direction={isMobile ? "column" : "row"}
              gap="10px"
            >
              <Grid>
                <Box
                  padding="17.50px"
                  borderRadius="7px"
                  width="fit-content"
                  bgcolor="#84E1CC"
                >
                  <img src={CheckIcon} width="32px" height="32px" />
                </Box>
              </Grid>
              <Grid>
                <Typography fontWeight={700} fontSize="12.94px" color="#273848">
                  <ul>
                    {responsibilities ? (
                      <li> {responsibilities} </li>
                    ) : (
                      <Loader />
                    )}
                  </ul>
                </Typography>
              </Grid>
            </Grid>
            <Grid mt="61px"></Grid>
            <Grid
              display="flex"
              alignItems="center"
              direction={isMobile ? "column" : "row"}
              gap="10px"
            >
              <Grid>
                <Box
                  padding="17.50px"
                  borderRadius="7px"
                  width="fit-content"
                  bgcolor="#84E1CC"
                >
                  <img src={GoalIcon} width="32px" height="32px" />
                </Box>
              </Grid>
              <Grid>
                <Typography fontWeight={700} fontSize="12.94px" color="#273848">
                  <ul>{challenges ? <li> {challenges} </li> : <Loader />}</ul>
                </Typography>
              </Grid>
            </Grid>
            <Grid mt="61px"></Grid>
            <Grid
              display="flex"
              alignItems="center"
              direction={isMobile ? "column" : "row"}
              gap="10px"
            >
              <Grid>
                <Box
                  padding="17.50px"
                  borderRadius="7px"
                  width="fit-content"
                  bgcolor="#84E1CC"
                >
                  <img src={TeamsIcon} width="32px" height="32px" />
                </Box>
              </Grid>
              <Grid>
                <Typography fontWeight={700} fontSize="12.94px" color="#273848">
                  <ul>{team ? <li> {team} </li> : <Loader />}</ul>
                </Typography>
              </Grid>
            </Grid>
            <Grid mt="61px"></Grid>
            <Grid
              display="flex"
              alignItems="center"
              direction={isMobile ? "column" : "row"}
              gap="10px"
            >
              <Grid>
                <Box
                  padding="17.50px"
                  borderRadius="7px"
                  width="fit-content"
                  bgcolor="#84E1CC"
                >
                  <img src={SymbolsIcon} width="32px" height="32px" />
                </Box>
              </Grid>
              <Grid>
                <Typography fontWeight={700} fontSize="12.94px" color="#273848">
                  <ul>{interview ? <li> {interview} </li> : <Loader />}</ul>
                </Typography>
              </Grid>
            </Grid>
            <Grid mt="43px"></Grid>
            <Grid
              container
              direction={isMobile ? "column" : "row"}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              padding={isMobile ? "" : "0px 34px 0px 34px"}
              gap={isMobile ? "10px" : ""}
            >
              <Grid>
                <button
                  type="submit"
                  className="user-scale"
                  // variant="contained"
                  // fullWidth
                  style={{
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #000000",
                    color: "#273848",
                    boxShadow: "none",
                    borderRadius: "5px",
                    padding: "17px 17px",
                    lineHeight: "0px",
                    letterSpacing: "0px",
                    textTransform: "none",
                    width: "100%",
                    // textTransform:'lowercase',
                  }}
                  onClick={(event) => handleBack(event)}
                >
                  Add Informations
                </button>
              </Grid>
              <Grid item xs={isTabs ? 0 : 2} style={{ maxWidth: "fitContent" }}>
                <button
                  type="submit"
                  className="user-scale"
                  // variant="contained"
                  // fullWidth
                  style={{
                    backgroundColor: "#84E1CC",
                    boxShadow: "none",
                    borderRadius: "5px",
                    padding: isTabs ? "19px 55px" : "17px 17px",
                    lineHeight: "0px",
                    letterSpacing: "0px",
                    textTransform: "none",
                    border: "none",
                    color: "white",
                    width: " 100%",
                  }}
                  onClick={(event) => handleSubmit(event)}
                >
                  Accept
                </button>
              </Grid>
            </Grid>
            <Grid mb="10px"></Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default JobProfileSixth;
