import React, { useEffect, useState } from 'react';
import InterviewTip from '../../components/Interview/InterviewTip';
import InterviewReminder from '../../components/Interview/InterviewRemiender';
import Feedback from '../../components/Interview/Feedback';
import TickIcon from '../../assets/images/Interview/tick.svg';
import Label from '../../components/Common/Label';
import { useLocation } from 'react-router-dom';
import { HIRING_STATUS_CODE } from '../../utils/hiringStatusCode';
import { postRequest } from '../../api/apiServices';
import { updateCandidateStatus } from '../../api/apiList';
import Toast from '../../utils/Toast';
import Loader from '../../components/Loader';

const InterviewProcess: React.FC = () => {
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [campaginId, setCampaginId] = useState('');
  const [email, setEmail] = useState('');
  const location = useLocation();
  const { userId } = location.state as { userId: number };

  const handleNext = () => {
    if (step < 3) setStep(step + 1);
  };

  const handleBack = () => {
    if (step > 0) setStep(step - 1);
  };

  const updateUserStatus = async () => {
    setLoading(true);
    const payload = {
      id: userId,
      status: 'CONTACTED',
    };
    const response: any = await postRequest(updateCandidateStatus, payload);
    if (response) {
      setCampaginId(response?.data?.data?.campaignId);
      setEmail(response?.data?.data?.email);
      Toast(response?.data?.statusMessage || 'Candidate Updates successfully.');
    }
    setLoading(false);
  };

  useEffect(() => {
    updateUserStatus();
  }, []);

  // Function to render the current step
  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <>
            <div
              style={{
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  backgroundColor: 'white',
                  border: '1px solid #D6D6D7',
                  borderRadius: '27px',
                  padding: '45px 42px 40px 42px',
                  width: '650px',
                }}
              >
                <div
                  className="row d-flex align-items-center justify-content-center flex-column"
                  style={{ gap: '33px' }}
                >
                  <div className="d-flex align-items-center justify-content-center flex-column">
                    <img src={TickIcon} className="img-fluid" alt="TickIcon" />
                    <Label
                      children="Your interview is confirmed"
                      fontSize="20.94px"
                      fontWeight={700}
                      color="#273848"
                    />
                  </div>
                  <div
                    className="d-flex align-items-center justify-content-center flex-column"
                    style={{ gap: '80px' }}
                  >
                    <p
                      style={{
                        fontWeight: 500,
                        fontSize: '16.94px',
                        lineHeight: '24.73px',
                        textAlign: 'center',
                        color: '#273848',
                      }}
                    >
                      Lorem ipsum dolor sit amet consectetur. Penatibus sit
                      imperdiet posuere commodo adipiscing. Morbi massa volutpat
                      et aliquam ac. Massa magna augue quam sed. Et lectus sed
                      ullamcorper nulla mi sem sit dolor.Nisl in ut nibh et
                      scelerisque. Mi velit blandit turpis dictum auctor
                      tincidunt nisl.
                    </p>
                    <button
                      type="submit"
                      className="user-scale"
                      // variant="contained"
                      // fullWidth
                      style={{
                        backgroundColor: '#84E1CC',
                        boxShadow: 'none',
                        borderRadius: '5px',
                        padding: '20px 17px',
                        lineHeight: '0px',
                        letterSpacing: '0px',
                        textTransform: 'none',
                        color: 'white',
                        border: 'none',
                      }}
                      onClick={handleNext}
                    >
                      Call To Action
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {loading && <Loader />}
          </>
        );
      case 1:
        return (
          <InterviewTip
            handleNext={handleNext}
            handleBack={handleBack}
            campaignID={campaginId}
            email={email}
          />
        );
      case 2:
        return (
          <InterviewReminder handleNext={handleNext} handleBack={handleBack} />
        );
      case 3:
        return <Feedback handleNext={handleNext} handleBack={handleBack} />;
      default:
        return null;
    }
  };

  return <>{renderStepContent(step)}</>;
};

export default InterviewProcess;
