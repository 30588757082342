import React, { useState } from "react";
import {
  Grid,
  Typography,
  Link,
  TextField,
  Button,
  Container,
  useMediaQuery,
} from "@mui/material";
import Logo from "../../assets/images/Logo.svg";
import Image from "../../assets/images/Image.svg";
import LogoName from "../../assets/images/LogoName.png";
import Success from "../../assets/images/Success.svg";
// import EmailImage from "../../assets/images/EmailImage.svg";
import FormControl from "@mui/material/FormControl";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../services/authService";

const AccountSuccess: React.FC = () => {
  const isMobile = useMediaQuery("(max-width:578px)");
  const navigate = useNavigate();

  const handleSubmit = () => {
    const auth = getToken()
    if (auth?.status == 0) {
      navigate("/create-company-profile");
      return
    }
    navigate("/");
  };
  return (
    <div style={{ backgroundColor: "#F4F4F4" }}>
      <Container
        maxWidth="md"
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#F4F4F4",
        }}
      >
        <Grid
          container
          spacing={2}
          justifyContent="center"
          bgcolor="#FFFFFF"
          //   alignItems="center"
          borderRadius="22px"
          //   height={700}
          margin={0}
        >
          {/* Form Column */}
          <Grid
            item
            xs={12}
            md={6}
            height={isMobile ? "600px" : "auto"}
            // height="100%"
            justifyContent="center"
            style={{ padding: 30 }}
          >
            <Grid container spacing={2} height="100%" direction="column">
              <Grid item>
                {isMobile && (
                  <Grid
                    item
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <img
                      src={LogoName}
                      style={{
                        width: "70%",
                      }}
                    />
                  </Grid>
                )}
                <Typography
                  variant="subtitle1"
                  color="#273848"
                  fontWeight={700}
                  fontSize="24.94px"
                >
                  Account Succesfully Created
                </Typography>
                <Typography
                  variant="body2"
                  color="#273848"
                  fontWeight={500}
                  fontSize="16px"
                >
                  Lorem ipsum dolor sit amet consectetur. Tortor odio.
                </Typography>
              </Grid>
              <Grid item container spacing={2} justifyContent="center">
                <Grid
                  item
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <img src={Success} width="90%" />
                </Grid>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  style={{ backgroundColor: "#84E1CC", boxShadow: "none" }}
                  onClick={handleSubmit}
                >
                  Go to the Homepage
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {/* Image Column */}
          {!isMobile && (
            <Grid
              item
              md={6}
              // height="100%"
              style={{
                padding: 0,
                position: "relative",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  background: `url(${Logo})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderRadius: "0 22px 22px 0",
                }}
              />
              <img
                src={Image}
                alt="Email Verification Image"
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "0 22px 22px 0",
                  objectFit: "cover",
                }}
              />
            </Grid>
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default AccountSuccess;
