import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Container,
  useMediaQuery,
  Box,
  Button,
  TextareaAutosize,
  MenuItem,
  TextField,
  InputLabel,
  OutlinedInput,
  CircularProgress,
} from "@mui/material";
import "../../styles/inputBox.css";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import GoalIcon from "../../assets/images/JobIcon/goal-color.svg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import { putRequest } from "../../api/apiServices";
import { updateJob } from "../../api/apiList";

interface JobProfileThirdProps {
  onBack: () => void;
  onNext: () => void;
  setFormData: (data: any) => void;
  initialData: any;
  mode: any;
}

const JobProfileThird: React.FC<JobProfileThirdProps> = ({
  onBack,
  onNext,
  setFormData,
  initialData,
  mode,
}: JobProfileThirdProps) => {
  const isMobile = useMediaQuery("(max-width:578px)");
  const isTabs = useMediaQuery("(max-width:1200px)");

  //Filed Declaration
  const [roleChallenges, setRoleChallenges] = useState("");
  const [
    organizationalGrowthOpportunities,
    setOrganizationalGrowthOpportunities,
  ] = useState("");
  const [positionGoals, setPositionGoals] = useState("");
  const [loading, setLoading] = useState(false);

  const [successMetrics, setSuccessMetrics] = useState("");

  //params to get id
  const params = useParams();
  const navigate = useNavigate();

  const goBack = () => {
    navigate("/jobs");
  };

  const [errors, setErrors] = useState({
    roleChallenges: "",
    organizationalGrowthOpportunities: "",
    positionGoals: "",
    successMetrics: "",
  });

  useEffect(() => {
    if (initialData) {
      setRoleChallenges(initialData?.roleChallenges);
      setOrganizationalGrowthOpportunities(
        initialData?.organizationalGrowthOpportunities
      );
      setPositionGoals(initialData?.positionGoals);
      setSuccessMetrics(initialData?.successMetrics);
    }
  }, []);

  const handleBack = (event: any) => {
    event.preventDefault();
    onBack();
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const data = {
      roleChallenges,
      organizationalGrowthOpportunities,
      positionGoals,
      successMetrics,
    };
    if (formIsValid()) {
      if (mode) {
        setLoading(true);
        const response = await putRequest(updateJob(params?.id), data);
        setFormData(response?.data?.data);
        setLoading(false);
        onNext();
      } else {
        const allData = {
          ...initialData,
          ...data,
        };
        setFormData(allData);
        onNext();
      }
    }
  };

  const formIsValid = () => {
    const newErrors = {
      roleChallenges: !roleChallenges?.trim() ? "Challenges is required" : "",
      organizationalGrowthOpportunities:
        !organizationalGrowthOpportunities?.trim()
          ? "Opportunities is required"
          : "",
      positionGoals: !positionGoals?.trim()
        ? "Short & Long Term Goals is required"
        : "",
      successMetrics: !successMetrics?.trim() ? "Success is required" : "",
    };

    setErrors(newErrors);

    return Object.values(newErrors).every((error) => error === "");
  };

  return (
    <div>
      <Container
        style={{
          display: "flex",
          padding: "25px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          spacing={2}
          bgcolor="#FFFFFF"
          width="884px"
          borderRadius="22px"
          border="1px solid #D6D6D7"
          margin={0}
        >
          <Grid
            item
            height={isMobile ? "auto" : "auto"}
            style={{ padding: 30 }}
          >
            <Grid
              container
              direction="row"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              gap="10px"
            >
              <Grid
                item
                width="fit-content"
                display="flex"
                alignItems="center"
                gap="10px"
              >
                <ArrowBackIcon onClick={goBack} style={{ cursor: "pointer" }} />
                <Typography color="#273848" fontWeight={700} fontSize="20.94px">
                  Challenges & Goals
                </Typography>
              </Grid>
              <Grid item gap={2} display="flex">
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#84E1CC",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#84E1CC",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#84E1CC",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#DFF4EF",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#DFF4EF",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#DFF4EF",
                  }}
                />
              </Grid>
            </Grid>
            <Grid mt="26px"></Grid>
            {/* <Grid
              display="flex"
              alignItems="center"
              border="1px solid #c3c3c3"
              borderRadius="8px"
              padding="8px 0px 6px 15px"
            >
              <Grid direction="row" display="flex" gap={1} alignItems="center">
                <img src={GoalIcon} alt="overview-icon" />
                <Typography
                  //   className="input-label-custom"
                  fontWeight={700}
                  fontSize="13.94px"
                  lineHeight="13.94px"
                  color="#273848"
                >
                  Challenges & Goals
                </Typography>
              </Grid>
            </Grid> */}
            <Grid mt="20px"></Grid>
            <Grid item>
              <Typography
                //  color="#4E4E4E"
                //  fontWeight={500}
                //  fontSize="14px"
                className="input-label-custom"
              >
                What challenges might the candidate face in this role?{" "}
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <Grid mb={1}></Grid>
              <OutlinedInput
                placeholder="Type here..."
                multiline
                minRows={4}
                className="text-area-size"
                value={roleChallenges}
                onChange={(e) => setRoleChallenges(e.target.value)}
              />
              <InputLabel
                style={{
                  color: "#f44336",
                  paddingTop: "3px",
                  fontSize: "12px",
                  paddingLeft: "10px",
                }}
              >
                {errors.roleChallenges}
              </InputLabel>
            </Grid>
            <Grid mt="19px"></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                What growth opportunities are available within the organization?{" "}
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <Grid mb={1}></Grid>
              <TextField
                sx={{
                  // mt: 1,
                  "& .MuiOutlinedInput-input": {
                    padding: "10px 11px", // Add padding here
                  },
                }}
                placeholder="What growth opportunities are available within the organization?"
                fullWidth
                className="input-box-custom"
                error={!!errors.positionGoals}
                helperText={errors.positionGoals}
                value={positionGoals}
                onChange={(e) => setPositionGoals(e.target.value)}
              />
            </Grid>
            <Grid mt="23px"></Grid>
            <Typography
              color="#273848"
              fontWeight={700}
              lineHeight="14.94px"
              fontSize="15px"
              //   className="input-label-custom"
            >
              Goals and Objectives
            </Typography>
            <Grid mt="19px"></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                What are the short-term and long-term goals for this position?{" "}
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <Grid mb={1}></Grid>
              <TextField
                sx={{
                  // mt: 1,
                  "& .MuiOutlinedInput-input": {
                    padding: "10px 11px", // Add padding here
                  },
                }}
                placeholder="What are the short-term and long-term goals for this position?"
                fullWidth
                className="input-box-custom"
                error={!!errors.organizationalGrowthOpportunities}
                helperText={errors.organizationalGrowthOpportunities}
                value={organizationalGrowthOpportunities}
                onChange={(e) =>
                  setOrganizationalGrowthOpportunities(e.target.value)
                }
              />
            </Grid>
            <Grid mt="19px"></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                How will success be measured in this role?{" "}
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <Grid mb={1}></Grid>
              <OutlinedInput
                placeholder="Type here..."
                multiline
                minRows={4}
                className="text-area-size"
                value={successMetrics}
                onChange={(e) => setSuccessMetrics(e.target.value)}
              />
              <InputLabel
                style={{
                  color: "#f44336",
                  paddingTop: "3px",
                  fontSize: "12px",
                  paddingLeft: "10px",
                }}
              >
                {errors.successMetrics}
              </InputLabel>
            </Grid>
            <Grid mt="147px"></Grid>
            <Grid
              container
              direction="row"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid xs={isTabs ? 0 : 2}>
                <button
                  type="submit"
                  className="user-scale"
                  // variant="contained"
                  // fullWidth
                  style={{
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #000000",
                    color: "#273848",
                    boxShadow: "none",
                    borderRadius: "5px",
                    padding: "17px 17px",
                    lineHeight: "0px",
                    letterSpacing: "0px",
                    textTransform: "none",
                    width: " 100%",
                    // padding: "10px 50px",
                  }}
                  onClick={(event) => handleBack(event)}
                >
                  Back
                </button>
              </Grid>
              <Grid
                item
                xs={isTabs ? 0 : mode ? 3 : 2}
                style={{ maxWidth: "fitContent" }}
              >
                <button
                  type="submit"
                  className="user-scale"
                  // variant="contained"
                  // fullWidth
                  style={{
                    backgroundColor: "#84E1CC",
                    boxShadow: "none",
                    borderRadius: "5px",
                    padding: "17px 17px",
                    lineHeight: "0px",
                    letterSpacing: "0px",
                    textTransform: "none",
                    border: "none",
                    color: "white",
                    width: " 100%",
                  }}
                  onClick={(event) => handleSubmit(event)}
                >
                  {mode ? (
                    loading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      "Save & Next"
                    )
                  ) : (
                    "Continue"
                  )}
                </button>
              </Grid>
            </Grid>
            <Grid mb="20px"></Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default JobProfileThird;
