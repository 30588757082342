import React, { useEffect, useState } from "react";
import { Grid, Typography, useMediaQuery, TextField, Button, InputAdornment, IconButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit'; // Make sure to import the edit icon
import Logo from "../../assets/images/LogoNameBlack.png";
import "../../styles/JobOffer/jobOffer.css";
import Loader from "../../utils/Loading";
import { getRequest, postRequest } from "../../api/apiServices";
import { genratePDFOfferLetter, getUserProfile, sendOfferLetterEmail } from "../../api/apiList";
import { getToken } from "../../services/authService";
import { formatDate } from "../../utils/helper";
import {saveAs} from "file-saver"
import axios from "axios";
import Toast from "../../utils/Toast";

const drawerWidth = 100;

interface GenerateJobOfferPageTwo {
    candidateData : any,
    candidateOfferData : any
}

const GenerateJobOfferPageTwo: React.FC<GenerateJobOfferPageTwo> = ({ candidateData,candidateOfferData }) => {
   
    const isMobile = useMediaQuery("(max-width:767px)");
    const isTab = useMediaQuery("(max-width:900px)");
    const [loading, setLoading] = useState(false);
    const [editing, setEditing] = useState(true);
    const [inputsEnabled, setInputsEnabled] = useState(true);
    const [company,setCompany] = useState("")

    const [offerData,setOfferData] = useState({
        contract : "",
        manager : "",
        yearly_base_salary : "",
        month_salary : "",
        bonus : "",
        holiday_allowance : "",
        travel : "",
        total_income : "",
        holidays : ""
    })

    const [errors, setErrors] = useState({
        contract : "",
        manager : "",
        yearly_base_salary : "",
        month_salary : "",
        bonus : "",
        holiday_allowance : "",
        travel : "",
        total_income : "",
        holidays : ""
    });

    const formIsValid = () => {
        const newErrors = {
          contract: !offerData.contract?.trim() ? "Contract is required" : "",
          manager: !offerData.manager?.trim() ? "Manager is required" : "",
          yearly_base_salary: !offerData.yearly_base_salary?.trim() ? "Yearly Base Salary is required" : "",
          month_salary: !offerData.month_salary?.trim() ? "Monthly salary is required" : "",
          bonus: !offerData.bonus?.trim() ? "Bonus is required" : "",
          holiday_allowance: !offerData.holiday_allowance?.trim() ? "Holiday allowance is required" : "",
          travel: !offerData.travel?.trim() ? "Travel Allowance is required" : "",
          total_income: !offerData.total_income?.trim() ? "Total Yearly Income is required" : "",
          holidays: !offerData.holidays?.trim() ? "Holidays is required" : "",
      };
    
         setErrors(newErrors);
            return Object.values(newErrors).every((error) => error === "");
    };
    
    

    const handleSaveClick = () => {
        if(formIsValid()){
          setInputsEnabled(false);
          setEditing(false);
        }
    };

    const handleEditClick = () => {
        setInputsEnabled(true);
        setEditing(true);
    };

    const getUserData = async () => {
        const auth = getToken()
        const response = await getRequest(getUserProfile(auth?.uId))
        setCompany(response?.data?.data?.companyInfo?.name)
    }

    const handleInputChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        const {name,value} = e.target
        setOfferData({ ...offerData, [name]:value})
    }

    const handleDownloadPDF = async () => {
        setLoading(true)
        const data = { 
            name : candidateData?.name,
            jobTitle : candidateOfferData?.jobTitle,
            company : company,
            salary : candidateOfferData?.salary,
            startDate : candidateOfferData?.startDate,
            workHours : candidateOfferData?.workHours,
            location : candidateOfferData?.location,
            contract : offerData?.contract,
            manager : offerData?.manager,
            yearly_base_salary : offerData?.yearly_base_salary,
            month_salary : offerData?.month_salary,
            bonus : offerData?.bonus,
            holiday_allowance : offerData?.holiday_allowance,
            travel : offerData?.travel,
            total_income : offerData?.total_income,
            holidays : offerData?.holidays
        }
        const auth = getToken()
        const response = await axios.post(genratePDFOfferLetter,data,{
            responseType: 'blob' ,
            headers : {
                'Authorization' : `Bearer ${auth?.token}`
            }
          })
        const blob = new Blob([response.data], { type: 'application/pdf' });
        saveAs(blob, 'offer_letter.pdf');
        Toast("PDF Downloads Successfully")
        setLoading(false)
      };

    const handleSendEmail = async() => {
        setLoading(true)
        const data = { 
            name : candidateData?.name,
            jobTitle : candidateOfferData?.jobTitle,
            company : company,
            salary : candidateOfferData?.salary,
            startDate : candidateOfferData?.startDate,
            workHours : candidateOfferData?.workHours,
            location : candidateOfferData?.location,
            contract : offerData?.contract,
            manager : offerData?.manager,
            yearly_base_salary : offerData?.yearly_base_salary,
            month_salary : offerData?.month_salary,
            bonus : offerData?.bonus,
            holiday_allowance : offerData?.holiday_allowance,
            travel : offerData?.travel,
            total_income : offerData?.total_income,
            holidays : offerData?.holidays,
            email : candidateData?.email
        }
        const response = await postRequest(sendOfferLetterEmail,data)
        Toast(response?.data?.statusMessage)
        setLoading(false)
    }

    useEffect(() => {
        getUserData()
    }, [])

    return loading ? (
      <Loader />
    ) : (
      <div id="pdf-content">
        <Grid
          container
          spacing={1}
          paddingTop={isTab ? "0px" : "30px"}
          justifyContent="center"
          display={isTab ? "unset" : "flex"}
          // sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
        >
          {/* <Grid mt="35px"></Grid> */}
          <Grid item xs={8} style={{maxWidth: isTab ? "100%" : "auto", paddingLeft: isTab ? "0px" : ""}}>
            {/* <Grid mt="35px"></Grid> */}
            <Grid
              container
              direction="column"
              justifyContent="space-between"
              alignItems="center"
              bgcolor="#FFFFFF"
              borderRadius="14px"
              border="1px solid #D9D9D9"
              style={{ cursor: "pointer", padding: "20px" }}
            >
              <Grid mt="35px"></Grid>
              <Grid
                item
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <img
                  src={Logo}
                  alt=""
                  style={{ width: isMobile ? "40%" : "20%", height: "auto" }}
                />
              </Grid>
              <Grid mt="35px"></Grid>
              <Grid
                container
                direction="row"
                alignItems="center"
                spacing={2}
                mt="10px"
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Grid item style={{ width: "100%" }}>
                    <Typography className="top-1-Heading">
                      Dear {candidateData?.name},
                    </Typography>
                    <Grid mb={2}></Grid>
                    <Typography className="top-1-Text">
                      We are delighted to inform you that you have been selected
                      for the position of {candidateOfferData?.jobTitle} at{" "}
                      {company}. After a thorough evaluation of your application
                      and the successful completion of the interview process, we
                      are confident that your skills and experience align
                      excellently with what we are seeking.
                    </Typography>
                  </Grid>
                  <Grid mb={4}></Grid>
                  <Grid item style={{ width: "100%" }}>
                    <Typography className="top-2-Heading">Offer</Typography>
                    <Grid mb={2}></Grid>
                    <ul>
                      <li>
                        <Typography className="top-1-Text">
                          Position:{" "}
                          <strong>{candidateOfferData?.jobTitle}</strong>
                        </Typography>
                      </li>
                      <li>
                        <Typography className="top-1-Text">
                          Salary: Gross annual salary of{" "}
                          <strong>{candidateOfferData?.salary}</strong>
                        </Typography>
                      </li>
                      <li>
                        <Typography className="top-1-Text">
                          Start Date:{" "}
                          <strong>
                            {formatDate(candidateOfferData?.startDate)}
                          </strong>
                        </Typography>
                      </li>
                      <li>
                        <Typography className="top-1-Text">
                          Work Hours:{" "}
                          <strong>{candidateOfferData?.workHours}</strong>
                        </Typography>
                      </li>
                      <li>
                        <Typography className="top-1-Text">
                          Location:{" "}
                          <strong>{candidateOfferData?.location}</strong>
                        </Typography>
                      </li>
                    </ul>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                alignItems="center"
                spacing={2}
                mt="10px"
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    className="top-2-Heading"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    Employment Terms
                    <IconButton onClick={handleEditClick}>
                      <EditIcon />
                    </IconButton>
                  </Typography>
                  <Grid mb={2}></Grid>
                  <Grid item style={{ width: "100%", marginBottom: "5px" }}>
                    <TextField
                      placeholder="1 Year"
                      fullWidth
                      className="input-box-custom"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="end">
                            <Typography
                              style={{
                                minWidth: "100px",
                                color: "#273848",
                                fontSize: "12.94px",
                                fontWeight: "normal",
                              }}
                            >
                              Contract
                            </Typography>
                          </InputAdornment>
                        ),
                        disableUnderline: true,
                        style: {
                          backgroundColor: "#F5F5F5",
                          borderRadius: "4px",
                          padding: "10px 14px",
                        },
                        classes: {
                          notchedOutline: "no-border",
                        },
                        readOnly: !inputsEnabled,
                      }}
                      inputProps={{
                        style: {
                          padding: 0,
                          textAlign: "right",
                          color: "#273848",
                          fontSize: "12.94px",
                          fontWeight: "bold",
                        },
                      }}
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      name="contract"
                      error={!!errors.contract}
                      helperText={errors.contract}
                      value={offerData.contract}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item style={{ width: "100%", marginBottom: "5px" }}>
                    <TextField
                      placeholder="Thomas Janssen (ICT manager)"
                      fullWidth
                      className="input-box-custom"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="end">
                            <Typography
                              style={{
                                minWidth: "100px",
                                color: "#273848",
                                fontSize: "12.94px",
                                fontWeight: "normal",
                              }}
                            >
                              Manager
                            </Typography>
                          </InputAdornment>
                        ),
                        disableUnderline: true,
                        style: {
                          backgroundColor: "#fff",
                          borderRadius: "4px",
                          padding: "10px 14px",
                        },
                        classes: {
                          notchedOutline: "no-border",
                        },
                        readOnly: !inputsEnabled,
                      }}
                      inputProps={{
                        style: {
                          padding: 0,
                          textAlign: "right",
                          color: "#273848",
                          fontSize: "12.94px",
                          fontWeight: "bold",
                        },
                      }}
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      error={!!errors.manager}
                      helperText={errors.manager}
                      name="manager"
                      value={offerData.manager}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item style={{ width: "100%", marginBottom: "5px" }}>
                    <TextField
                      placeholder="€60.000"
                      fullWidth
                      className="input-box-custom"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="end">
                            <Typography
                              style={{
                                minWidth: "100px",
                                color: "#273848",
                                fontSize: "12.94px",
                                fontWeight: "normal",
                              }}
                            >
                              Yearly base salary
                            </Typography>
                          </InputAdornment>
                        ),
                        disableUnderline: true,
                        style: {
                          backgroundColor: "#F5F5F5",
                          borderRadius: "4px",
                          padding: "10px 14px",
                        },
                        classes: {
                          notchedOutline: "no-border",
                        },
                        readOnly: !inputsEnabled,
                      }}
                      inputProps={{
                        style: {
                          padding: 0,
                          textAlign: "right",
                          color: "#273848",
                          fontSize: "12.94px",
                          fontWeight: "bold",
                        },
                      }}
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      name="yearly_base_salary"
                      error={!!errors.yearly_base_salary}
                      helperText={errors.yearly_base_salary}
                      value={offerData.yearly_base_salary}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item style={{ width: "100%", marginBottom: "5px" }}>
                    <TextField
                      placeholder="€5.000"
                      fullWidth
                      className="input-box-custom"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="end">
                            <Typography
                              style={{
                                minWidth: "100px",
                                color: "#273848",
                                fontSize: "12.94px",
                                fontWeight: "normal",
                              }}
                            >
                              Monthly salary
                            </Typography>
                          </InputAdornment>
                        ),
                        disableUnderline: true,
                        style: {
                          backgroundColor: "#fff",
                          borderRadius: "4px",
                          padding: "10px 14px",
                        },
                        classes: {
                          notchedOutline: "no-border",
                        },
                        readOnly: !inputsEnabled,
                      }}
                      inputProps={{
                        style: {
                          padding: 0,
                          textAlign: "right",
                          color: "#273848",
                          fontSize: "12.94px",
                          fontWeight: "bold",
                        },
                      }}
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      name="month_salary"
                      error={!!errors.month_salary}
                      helperText={errors.month_salary}
                      value={offerData.month_salary}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item style={{ width: "100%", marginBottom: "5px" }}>
                    <TextField
                      placeholder="2% fixed of yearly salary"
                      fullWidth
                      className="input-box-custom"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="end">
                            <Typography
                              style={{
                                minWidth: "100px",
                                color: "#273848",
                                fontSize: "12.94px",
                                fontWeight: "normal",
                              }}
                            >
                              Bonus
                            </Typography>
                          </InputAdornment>
                        ),
                        disableUnderline: true,
                        style: {
                          backgroundColor: "#F5F5F5",
                          borderRadius: "4px",
                          padding: "10px 14px",
                        },
                        classes: {
                          notchedOutline: "no-border",
                        },
                        readOnly: !inputsEnabled,
                      }}
                      inputProps={{
                        style: {
                          padding: 0,
                          textAlign: "right",
                          color: "#273848",
                          fontSize: "12.94px",
                          fontWeight: "bold",
                        },
                      }}
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      name="bonus"
                      error={!!errors.bonus}
                      helperText={errors.bonus}
                      value={offerData.bonus}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item style={{ width: "100%", marginBottom: "5px" }}>
                    <TextField
                      placeholder="8%"
                      fullWidth
                      className="input-box-custom"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="end">
                            <Typography
                              style={{
                                minWidth: "100px",
                                color: "#273848",
                                fontSize: "12.94px",
                                fontWeight: "normal",
                              }}
                            >
                              Holiday allowance
                            </Typography>
                          </InputAdornment>
                        ),
                        disableUnderline: true,
                        style: {
                          backgroundColor: "#fff",
                          borderRadius: "4px",
                          padding: "10px 14px",
                        },
                        classes: {
                          notchedOutline: "no-border",
                        },
                        readOnly: !inputsEnabled,
                      }}
                      inputProps={{
                        style: {
                          padding: 0,
                          textAlign: "right",
                          color: "#273848",
                          fontSize: "12.94px",
                          fontWeight: "bold",
                        },
                      }}
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      name="holiday_allowance"
                      error={!!errors.holiday_allowance}
                      helperText={errors.holiday_allowance}
                      value={offerData.holiday_allowance}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item style={{ width: "100%", marginBottom: "5px" }}>
                    <TextField
                      placeholder="0,21 cents per kilometers "
                      fullWidth
                      className="input-box-custom"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="end">
                            <Typography
                              style={{
                                minWidth: "100px",
                                color: "#273848",
                                fontSize: "12.94px",
                                fontWeight: "normal",
                              }}
                            >
                              Travel allowance
                            </Typography>
                          </InputAdornment>
                        ),
                        disableUnderline: true,
                        style: {
                          backgroundColor: "#F5F5F5",
                          borderRadius: "4px",
                          padding: "10px 14px",
                        },
                        classes: {
                          notchedOutline: "no-border",
                        },
                        readOnly: !inputsEnabled,
                      }}
                      inputProps={{
                        style: {
                          padding: 0,
                          textAlign: "right",
                          color: "#273848",
                          fontSize: "12.94px",
                          fontWeight: "bold",
                        },
                      }}
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      name="travel"
                      error={!!errors.travel}
                      helperText={errors.travel}
                      value={offerData.travel}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item style={{ width: "100%", marginBottom: "5px" }}>
                    <TextField
                      placeholder="€66.000"
                      fullWidth
                      className="input-box-custom"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="end">
                            <Typography
                              style={{
                                minWidth: "100px",
                                color: "#273848",
                                fontSize: "12.94px",
                                fontWeight: "normal",
                              }}
                            >
                              Total yearly income
                            </Typography>
                          </InputAdornment>
                        ),
                        disableUnderline: true,
                        style: {
                          backgroundColor: "#fff",
                          borderRadius: "4px",
                          padding: "10px 14px",
                        },
                        classes: {
                          notchedOutline: "no-border",
                        },
                        readOnly: !inputsEnabled,
                      }}
                      inputProps={{
                        style: {
                          padding: 0,
                          textAlign: "right",
                          color: "#273848",
                          fontSize: "12.94px",
                          fontWeight: "bold",
                        },
                      }}
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      name="total_income"
                      error={!!errors.total_income}
                      helperText={errors.total_income}
                      value={offerData.total_income}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item style={{ width: "100%", marginBottom: "5px" }}>
                    <TextField
                      placeholder="25"
                      fullWidth
                      className="input-box-custom"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="end">
                            <Typography
                              style={{
                                minWidth: "100px",
                                color: "#273848",
                                fontSize: "12.94px",
                                fontWeight: "normal",
                              }}
                            >
                              Holidays
                            </Typography>
                          </InputAdornment>
                        ),
                        disableUnderline: true,
                        style: {
                          backgroundColor: "#F5F5F5",
                          borderRadius: "4px",
                          padding: "10px 14px",
                        },
                        classes: {
                          notchedOutline: "no-border",
                        },
                        readOnly: !inputsEnabled,
                      }}
                      inputProps={{
                        style: {
                          padding: 0,
                          textAlign: "right",
                          color: "#273848",
                          fontSize: "12.94px",
                          fontWeight: "bold",
                        },
                      }}
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      name="holidays"
                      error={!!errors.holidays}
                      helperText={errors.holidays}
                      value={offerData.holidays}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                alignItems="center"
                spacing={2}
                mt="10px"
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Grid item style={{ width: "100%" }}>
                    <Typography className="top-2-Heading">
                      Response Required
                    </Typography>
                    <Grid mb={2}></Grid>
                    <Typography className="top-1-Text">
                      Please respond by [Response Deadline] to confirm your
                      acceptance or address any questions. If we do not receive
                      a response by that time, we will assume that you have
                      accepted the offer.
                    </Typography>
                    <Grid mb={2}></Grid>
                    <Typography className="top-1-Text">
                      We look forward to welcoming you to {company} and are
                      excited about the contributions you will bring to our
                      team. If you have any questions please do not hesitate to
                      contact us!
                    </Typography>
                    <Grid mb={2}></Grid>
                    <Typography className="top-1-Text">
                      Best regards,
                    </Typography>
                    <Grid mb={2}></Grid>
                    <Typography className="top-1-Text">
                      [Signature HR / Recruitment user]
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid mt="30px"></Grid>
              <Grid
                id="removebtn"
                item
                container
                justifyContent="flex-end"
                style={{ maxWidth: "100%" }}
              >
                {editing ? (
                  <Button
                    type="submit"
                    variant="contained"
                    style={{
                      backgroundColor: "#84E1CC",
                      boxShadow: "none",
                      borderRadius: "5px",
                      padding: "17px 17px",
                      lineHeight: "0px",
                      textTransform: "none",
                      letterSpacing: "0px",
                    }}
                    onClick={handleSaveClick}
                  >
                    Save
                  </Button>
                ) : (
                  <>
                    <Button
                      type="submit"
                      variant="outlined" // Change variant to 'outlined' for outline button
                      style={{
                        backgroundColor: "transparent", // Set background color to transparent
                        color: "#273848", // Set text color to #273848
                        borderRadius: "5px",
                        padding: "17px 17px",
                        lineHeight: "0px",
                        textTransform: "none",
                        letterSpacing: "0px",
                        marginRight: "10px",
                        borderColor: "gray",
                      }}
                      onClick={() => {
                        handleDownloadPDF();
                      }}
                    >
                      Download PDF
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      style={{
                        backgroundColor: "#84E1CC",
                        boxShadow: "none",
                        borderRadius: "5px",
                        padding: "17px 17px",
                        lineHeight: "0px",
                        textTransform: "none",
                        letterSpacing: "0px",
                      }}
                      onClick={() => {
                        handleSendEmail();
                      }}
                    >
                      Send to Candidate
                    </Button>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid mt="26px"></Grid>
          </Grid>
        </Grid>
      </div>
    );
};

export default GenerateJobOfferPageTwo;
