import { configureStore } from "@reduxjs/toolkit";
import hirinManagerSlice from "./hiringManagerSlice";
import jobSlice from "./jobSlice";

const store = configureStore({
    reducer : {
        hiringManager : hirinManagerSlice,
        companyJob : jobSlice
    }
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store