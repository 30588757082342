import React, { useState } from 'react';
import Application from '../../components/Popups/ApplicationReceived';
import Logo from '../../assets/images/LogoName.png';
import { useMediaQuery } from '@mui/material';
import { useFormik } from 'formik';
import { ValidationSchema } from '../../utils/ApplicationFormValidationSchema';
import { multiPartRequest } from '../../api/apiServices';
import { createCandidateLeads } from '../../api/apiList';
import Toast from '../../utils/Toast';
import Loader from '../../utils/Loading';

interface FormInput {
  name: string;
  email: string;
  jobTitle: string;
  cv: FileList | null;
  phone: string;
}

const ApplicationForm: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const isMobile = useMediaQuery('(max-width:600px)');

  const formik = useFormik<FormInput>({
    initialValues: {
      name: '',
      email: '',
      jobTitle: '',
      cv: null,
      phone: '',
    },
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const formData = new FormData();
      formData.append('name', values.name);
      formData.append('email', values.email);
      formData.append('jobTitle', values.jobTitle);
      if (values.cv && values.cv.length > 0) {
        formData.append('cv', values.cv[0]);
      }
      formData.append('phone', values.phone);
      const response = await multiPartRequest(createCandidateLeads, formData);
      if (response) {
        Toast(
          response?.data?.statusMessage || 'Candidate Created Successfully'
        );
        handleOpen();
        setLoading(false);
        formik.resetForm();
      }
      setLoading(false);
      formik.resetForm();
    },
  });

  return (
    <>
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: '100vh' }}
      >
        <div
          className="container mt-3 bg-white"
          style={{
            borderRadius: '27px',
            border: '1px solid #D6D6D7',
            padding: isMobile ? '10px' : '20px 40px',
            maxWidth: isMobile ? '350px' : '600px',
            // overflowY: 'scroll',
            height: isMobile ? '630px' : '',
          }}
        >
          <div className="d-flex align-items-center justify-content-center">
            <img
              className="img-fluid"
              style={{ height: '150px', width: '100%', objectFit: 'contain' }}
              src={Logo}
              alt="Logo"
            />
          </div>
          <h2
            className="text-center mb-4"
            style={{
              color: '#6EC4B8',
              fontWeight: 800,
            }}
          >
            Join Our Team - Application Form
          </h2>
          <form onSubmit={formik.handleSubmit}>
            {/* Full Name Field */}
            <div className="mb-3">
              <label htmlFor="fullName" className="form-label">
                Full Name
              </label>
              <input
                className="form-control"
                id="name"
                name="name"
                type="text"
                placeholder="Please Enter Your Full Name"
                onChange={formik.handleChange}
                value={formik.values.name}
              />
              {formik.touched.name && formik.errors.name ? (
                <p className="m-0 text-danger">{formik.errors.name}</p>
              ) : null}
            </div>

            {/* Email Field */}
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                className="form-control"
                id="email"
                name="email"
                type="email"
                placeholder="Please Enter Your Email"
                onChange={formik.handleChange}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email ? (
                <p className="m-0 text-danger">{formik.errors.email}</p>
              ) : null}
            </div>

            {/* Job Title Field */}
            <div className="mb-3">
              <label htmlFor="jobTitle" className="form-label">
                Job Title
              </label>
              <input
                className="form-control"
                id="jobTitle"
                name="jobTitle"
                type="text"
                placeholder="Please Enter Your Job Title"
                onChange={formik.handleChange}
                value={formik.values.jobTitle}
              />
              {formik.touched.jobTitle && formik.errors.jobTitle ? (
                <p className="m-0 text-danger">{formik.errors.jobTitle}</p>
              ) : null}
            </div>

            {/* CV Upload Field */}
            <div className="mb-3">
              <label htmlFor="cvFile" className="form-label">
                Upload CV
              </label>
              <input
                className="form-control"
                id="cv"
                name="cv"
                type="file"
                onChange={(event) => {
                  const fileList = event.currentTarget.files;
                  formik.setFieldValue('cv', fileList);
                }}
              />
              {formik.touched.cv && formik.errors.cv ? (
                <p className="m-0 text-danger">{formik.errors.cv}</p>
              ) : null}
            </div>

            {/* Mobile Number Field */}
            <div className="mb-4">
              <label htmlFor="mobileNo" className="form-label">
                Mobile Number
              </label>
              <input
                className="form-control input-number"
                id="phone"
                name="phone"
                type="number"
                placeholder="Please Enter Your Mobile Number"
                onChange={formik.handleChange}
                value={formik.values.phone}
              />
              {formik.touched.phone && formik.errors.phone ? (
                <p className="m-0 text-danger">{formik.errors.phone}</p>
              ) : null}
            </div>

            {/* Submit Button */}
            <div
              className="d-grid float-end"
              style={{
                width: isMobile ? '50%' : '35%',
                paddingBottom: isMobile ? '20px' : '',
              }}
            >
              <button
                type="submit"
                className="btn btn-primary user-scale"
                style={{
                  backgroundColor: '#6EC4B8',
                  border: '1px solid #6EC4B8',
                }}
              >
                Submit Application
              </button>
            </div>
          </form>
        </div>
      </div>

      <Application open={open} onClose={handleClose} />
      {loading && <Loader />}
    </>
  );
};

export default ApplicationForm;
