import React, { useContext, useEffect, useState, useRef } from "react";
import Sidebar from "./Sidebar";
import { Container, useMediaQuery } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Loader from "../utils/Loading";
import { SidebarContext } from "../hooks/UseSidebar";
import Header from "./Header";
import '../App.css';
import HeaderName from "../utils/headerName.json";
import { HeaderNameType } from "../utils/HeaderRoutesType";

const MainLayout: React.FC = () => {
  const [sidebarExpanded, setSidebarExpanded] = React.useState(false);
  const { side, toggleSide, setSide } = useContext(SidebarContext);
  const boxRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navidate = useNavigate();
  const TypedHeaderName: HeaderNameType = HeaderName;
  const isMobile = useMediaQuery("(max-width:1200px)");

  const handleSidebarToggle = (expanded: boolean) => {
    setSidebarExpanded(expanded);
  };

    useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const handleOutsideClick = () => {
    if (side) {
      setSide(false);
    }
  };

  const getPageName = () => {
    const currentPath = location.pathname;
    console.log('currentPath: ', currentPath)
     for (const [key, value] of Object.entries(TypedHeaderName)) {
       const routePattern = new RegExp(`^${key.replace(/:\w+/g, "\\w+")}$`);
       if (routePattern.test(currentPath)) {
         return value;
       }
     }
    // return TypedHeaderName[currentPath] || "Dashboard";
     return "Dashboard"; 
  }

  return (
    <div className="main-layout">
      <Sidebar visible={sidebarExpanded} onToggle={handleSidebarToggle} />
      {side && <div className="overlay" onClick={handleOutsideClick}></div>}
      <div
        className="content-wrapper content"
        style={{
          flexGrow: 1,
          transition: "margin-left 1s ease",
          marginLeft: sidebarExpanded ? "200px" : isMobile ? "0px" : "110px",
          overflow: "auto",
          WebkitOverflowScrolling: "touch",
          scrollbarWidth: "none",
          msOverflowStyle: "none", 
          ...({ "&::-webkit-scrollbar": { display: "none" } } as any),
        }}
      >
        <Header headerRef={boxRef} pageName={getPageName()} isShow={true} />
        <div className="content-wrappers">
          {loading ? (
            <Loader />
          ) : (
            <Container
              style={{
                // padding: "20px",
                flexGrow: 1,
                boxSizing: "border-box",
                height: "100%",
                // overflow: "auto",
                maxWidth: "100%"
              }}
            >
              <Outlet />
            </Container>
          )}
        </div>
      </div>
    </div>
    // <div style={{ display: "flex", width: "100vw", height: "100vh" }}>
    //   <Sidebar visible={sidebarExpanded} onToggle={handleSidebarToggle} />
    //   {side && <div className="overlay" onClick={handleOutsideClick}></div>}
    //   <div
    // style={{
    //   flexGrow: 1,
    //   transition: "margin-left 1s ease",
    //   marginLeft: sidebarExpanded ? "200px" : "0px",
    //   overflow: "auto",
    //   WebkitOverflowScrolling: "touch",
    //   scrollbarWidth: "none",
    //   msOverflowStyle: "none",
    //   ...({ "&::-webkit-scrollbar": { display: "none" } } as any),
    // }}
    //   >
    //     <div className="content-wrapper-header" style={{ width: "100%" }}>
    //       <Header headerRef={boxRef} pageName="Dashboard" isShow={true} />
    // <div className="content-wrappers">
    //   {loading ? (
    //     <Loader />
    //   ) : (
    //     <Container
    //       style={{
    //         // padding: "20px",
    //         flexGrow: 1,
    //         boxSizing: "border-box",
    //         height: "100%",
    //         // overflow: "auto",
    //       }}
    //     >
    //       <Outlet />
    //     </Container>
    //   )}
    // </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default MainLayout;
