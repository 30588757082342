import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material';
import CurrentIcon from '../../assets/images/CandidateIcon/current-location.svg';
import WorkIcon from '../../assets/images/CandidateIcon/work-sharp.svg';
import SkillLavelIcon from '../../assets/images/CandidateIcon/skill-level.svg';
import ParkSolidIcon from '../../assets/images/CandidateIcon/park-solid.svg';
import CandidateSpeFirst from '../../components/CandidateSpecification/CandidateSpeFirst';
import CandidateSpeSecond from '../../components/CandidateSpecification/CandidateSpeSecond';
import CandidateSpeThird from '../../components/CandidateSpecification/CandidateSpeThird';
import CandidateSpeFourth from '../../components/CandidateSpecification/CandidateSpeFourth';
import CandidateSpeFifth from '../../components/CandidateSpecification/CandidateSpeFifth';
import PersonalIcon from '../../assets/images/CandidateIcon/PersonalIcon.svg';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getRequest } from '../../api/apiServices';
import { getCandidate } from '../../api/apiList';
import Loader from '../../utils/Loading';

const CondidateSpecification: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const isMobile = useMediaQuery('(max-width:635px)');
  const navigate = useNavigate();
  const { state } = useLocation();

  // To Handle the data
  const [candidateData, setCandidateData] = useState({});
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);

  // Get The Id
  const params = useParams();

  const goToNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const goToPreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handlePersonalInformationClick = () => {
    setCurrentPage(1);
  };

  const handleCurrentSituationClick = () => {
    setCurrentPage(2);
  };

  const handleExperienceClick = () => {
    setCurrentPage(3);
  };

  const handleSkillsClick = () => {
    setCurrentPage(4);
  };

  const handleIdealSituationClick = () => {
    setCurrentPage(5);
  };

  const handleOfferLetter = () => {
    navigate('/generat-job-offer', { state: { candidateData } });
  };

  // Get The Job Data
  const getCandidateData = async () => {
    const response = await getRequest(getCandidate(params?.id));
    return response?.data?.data;
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (params?.id) {
          setLoading(true);
          const data = await getCandidateData();
          setCandidateData(data);
          setUpdate(true);
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [params?.id]);

  useEffect(() => {
    console.log('IO');
    console.log(candidateData);
    setCandidateData({
      ...candidateData,
      name: state?.name,
      email: state?.email,
      phone: state?.phone,
    });
  }, [state]);

  return loading ? (
    <Loader />
  ) : (
    <div>
      <Grid
        container
        spacing={1}
        justifyContent={isMobile ? "center" : "center"}
      >
        <Grid xs={2} maxWidth={isMobile ? "none" : "auto"}>
          <Grid mt="33px"></Grid>
          <Grid
            className="user"
            direction="column"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            bgcolor="#FFFFFF"
            borderRadius="14px"
            border="1px solid #D9D9D9"
            onClick={handlePersonalInformationClick}
            style={{ cursor: "pointer" }}
          >
            <Grid padding="28px 90px 0px 98px">
              <Box padding="15px" borderRadius="7px" bgcolor="#84E1CC">
                <img src={PersonalIcon} />
              </Box>
            </Grid>
            <Grid padding="17px 35px 26px 35px">
              <Typography
                fontSize="13px"
                lineHeight="15.79px"
                fontWeight={500}
                color="#273848"
                textAlign="center"
              >
                Personal Information
              </Typography>
            </Grid>
          </Grid>
          <Grid mt="26px"></Grid>
          <Grid
            className="user"
            direction="column"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            bgcolor="#FFFFFF"
            borderRadius="14px"
            border="1px solid #D9D9D9"
            onClick={handleCurrentSituationClick}
            style={{ cursor: "pointer" }}
          >
            <Grid padding="28px 90px 0px 98px">
              <Box padding="15px" borderRadius="7px" bgcolor="#84E1CC">
                <img src={CurrentIcon} />
              </Box>
            </Grid>
            <Grid padding="17px 35px 26px 35px">
              <Typography
                fontSize="13px"
                fontWeight={500}
                color="#273848"
                textAlign="center"
              >
                Current Situation &amp;
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Practicalities
              </Typography>
            </Grid>
          </Grid>
          <Grid mt="26px"></Grid>
          <Grid
            className="user"
            direction="column"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            bgcolor="#FFFFFF"
            borderRadius="14px"
            border="1px solid #D9D9D9"
            onClick={handleExperienceClick}
            style={{ cursor: "pointer" }}
          >
            <Grid padding="28px 90px 0px 98px">
              <Box padding="15px" borderRadius="7px" bgcolor="#84E1CC">
                <img src={WorkIcon} />
              </Box>
            </Grid>
            <Grid padding="17px 35px 26px 35px">
              <Typography
                fontSize="13px"
                lineHeight="15.79px"
                fontWeight={500}
                color="#273848"
                textAlign="center"
              >
                Experience & Education
              </Typography>
            </Grid>
          </Grid>
          <Grid mt="26px"></Grid>
          <Grid
            className="user"
            direction="column"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            bgcolor="#FFFFFF"
            borderRadius="14px"
            border="1px solid #D9D9D9"
            onClick={handleSkillsClick}
            style={{ cursor: "pointer" }}
          >
            <Grid padding="28px 90px 0px 98px">
              <Box padding="15px" borderRadius="7px" bgcolor="#84E1CC">
                <img src={SkillLavelIcon} />
              </Box>
            </Grid>
            <Grid padding="17px 35px 26px 35px">
              <Typography
                fontSize="13px"
                fontWeight={500}
                color="#273848"
                textAlign="center"
              >
                Skills & Traits
              </Typography>
            </Grid>
          </Grid>
          <Grid mt="26px"></Grid>
          <Grid
            className="user"
            direction="column"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            bgcolor="#FFFFFF"
            borderRadius="14px"
            border="1px solid #D9D9D9"
            onClick={handleIdealSituationClick}
            style={{ cursor: "pointer" }}
          >
            <Grid padding="28px 90px 0px 98px">
              <Box padding="15px" borderRadius="7px" bgcolor="#84E1CC">
                <img src={ParkSolidIcon} />
              </Box>
            </Grid>
            <Grid padding="17px 35px 26px 35px">
              <Typography
                fontSize="13px"
                fontWeight={500}
                color="#273848"
                textAlign="center"
              >
                Ideal situation & Other
                <br />
                interviews
              </Typography>
            </Grid>
          </Grid>
          <Grid mt="36px"></Grid>
          {update && (
            <Grid
              direction="column"
              display="flex"
              justifyContent="center"
              alignItems="center"
              bgcolor="#244565"
              color="#ffffff"
              borderRadius="14px"
              border="1px solid #D9D9D9"
              onClick={handleOfferLetter}
              height={"55px"}
              style={{
                cursor: "pointer",
              }}
            >
                Genrate Offer
            </Grid>
          )}
        </Grid>
        <Grid item xs={8} maxWidth={isMobile ? "100%" : "100%"}>
          {currentPage === 1 && (
            <CandidateSpeFirst
              onNext={goToNextPage}
              setCandidateData={setCandidateData}
              initialData={candidateData}
              mode={update}
            />
          )}
          {currentPage === 2 && (
            <CandidateSpeSecond
              onBack={goToPreviousPage}
              onNext={goToNextPage}
              setCandidateData={setCandidateData}
              initialData={candidateData}
              mode={update}
            />
          )}
          {currentPage === 3 && (
            <CandidateSpeThird
              onBack={goToPreviousPage}
              onNext={goToNextPage}
              setCandidateData={setCandidateData}
              initialData={candidateData}
              mode={update}
            />
          )}
          {currentPage === 4 && (
            <CandidateSpeFourth
              onBack={goToPreviousPage}
              onNext={goToNextPage}
              setCandidateData={setCandidateData}
              initialData={candidateData}
              mode={update}
            />
          )}
          {currentPage === 5 && (
            <CandidateSpeFifth
              onBack={goToPreviousPage}
              onNext={goToNextPage}
              setCandidateData={setCandidateData}
              initialData={candidateData}
              mode={update}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default CondidateSpecification;
