import React from "react";
import Label from "../Common/Label";
import TimeIcon from '../../assets/images/Interview/time.svg';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface InterviewReminderProps {
  handleNext: () => void;
  handleBack: () => void;
}

const InterviewReminder: React.FC<InterviewReminderProps> = ({ handleNext, handleBack }) => {
  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          backgroundColor: 'white',
          border: '1px solid #D6D6D7',
          borderRadius: '27px',
          padding: '45px 42px 40px 42px',
          width: '650px'

        }}
      >
        <ArrowBackIcon style={{cursor:'pointer'}} onClick={handleBack} />
        <div className="row d-flex align-items-center justify-content-center flex-column" style={{ gap: '33px' }}>
          <div className="d-flex align-items-center justify-content-center flex-column" style={{gap:'16px'}}>
            <img src={TimeIcon} className="img-fluid" alt="TimeIcon" />
            <Label
              children="Your interview is in 2 days"
              fontSize="20.94px"
              fontWeight={700}
              color="#273848"
            />
          </div>
          <div className="d-flex align-items-center justify-content-center flex-column" style={{ gap: '73px' }}>
            <p style={{
              fontWeight: 500,
              fontSize: '16.94px',
              lineHeight: '24.73px',
              textAlign: 'center',
              color: '#273848'
            }}>Lorem ipsum dolor sit amet consectetur. Penatibus sit imperdiet posuere commodo adipiscing. Morbi massa volutpat et aliquam ac. Massa magna augue quam sed. Et lectus sed ullamcorper nulla mi sem sit dolor.Nisl in ut nibh et scelerisque. Mi velit blandit turpis dictum auctor tincidunt nisl.</p>
            <button
              type="submit"
              className="user-scale"
              // variant="contained"
              // fullWidth
              style={{
                backgroundColor: "#84E1CC",
                boxShadow: "none",
                borderRadius: "5px",
                padding: "20px 17px",
                lineHeight: "0px",
                letterSpacing: "0px",
                textTransform: "none",
                color: 'white',
                border: 'none'
              }}
              onClick={handleNext}
            >
              Call To Action
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterviewReminder;
