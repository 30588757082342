//EmailVarification.tsx
import React, { useState, useRef, useEffect } from "react";
import {
  Grid,
  Typography,
  Link,
  TextField,
  Button,
  Container,
  useMediaQuery,
} from "@mui/material";
import Logo from "../../assets/images/Logo.svg";
import Image from "../../assets/images/Image.svg";
import LogoName from "../../assets/images/LogoName.png";
import { useNavigate, useLocation } from "react-router-dom";
// import EmailImage from "../../assets/images/EmailImage.svg";
import FormControl from "@mui/material/FormControl";
import '../../styles/EmailOtpBox/inputOtpBox.css';
import authService from "../../services/authService";
import Toast from "../../utils/Toast";

interface CustomTextFieldClasses {
  className?: string;
  notchedOutline?: string;
}


const EmailVerification: React.FC<CustomTextFieldClasses> = () => {
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [otpError, setOtpError] = useState(false);
  const [timer, setTimer] = useState<number>(60);
  const navigate = useNavigate();
  const location = useLocation();

  const isMobile = useMediaQuery("(max-width:578px)");
  const refs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];

  const { email } = location.state || {};

  useEffect(() => {
    // Start the countdown timer
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 0) {
          clearInterval(interval); // Stop the timer when it reaches 0
        }
        return prevTimer > 0 ? prevTimer - 1 : 0;
      });
    }, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, []);


  const handleChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      // Check if the entered value is a number
      if (/^\d+$/.test(value) || value === "") {
        const updatedOtp = [...otp];
        updatedOtp[index] = value;
        setOtp(updatedOtp);
        // Redirect focus to the next input box
        if (index < refs.length - 1 && value !== "") {
          refs[index + 1].current?.focus();
        }
      }
    };

  const handleKeyDown =
    (index: number) => (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Backspace" && index > 0 && !otp[index]) {
        const updatedOtp = [...otp];
        updatedOtp[index - 1] = "";
        setOtp(updatedOtp);
        refs[index - 1].current?.focus();
      }
    };


  const handleSubmit = async () => {
    try {
      const otpPin = otp.join(""); // Concatenate the otp array into a string

      const isValid = await authService.verifyEmailOTP(email, otpPin); // Call verifyEmailOTP from authService
      if (isValid) {
        Toast("Email verified successfully");
        navigate("/accountsuccess");
      } else {
        setOtpError(true); // Set OTP error state to true
        Toast("Invalid OTP. Please try again.", "error");
      }
    } catch (error) {
      console.error("Error:", error);
      Toast("An error occurred while verifying email.", "error");
    }
  };

  const handleResendOTP = async () => {
    try {
      const isSuccess = await authService.resendOTP(email);
      if (isSuccess) {
        Toast("OTP resent successfully");
        setTimer(60); // Reset the timer
      } else {
        Toast("Failed to resend OTP", "error");
      }
    } catch (error) {
      console.error("Error resending OTP:", error);
      Toast("An error occurred while resending OTP", "error");
    }
  };

  return (
    <div style={{ backgroundColor: "#F4F4F4" }}>
      <Container
        maxWidth="md"
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#F4F4F4",
        }}
      >
        <Grid
          container
          spacing={2}
          justifyContent="center"
          bgcolor="#FFFFFF"
          //   alignItems="center"
          borderRadius="22px"
          //   height={700}
          margin={0}
        >
          <Grid
            item
            xs={12}
            md={6}
            height={isMobile ? "600px" : "auto"}
            // height="100%"
            justifyContent="center"
            style={{ padding: 30 }}
          >
            <Grid spacing={2} height="100%" direction="column">
              <Grid item>
                {isMobile && (
                  <Grid
                    item
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <img
                      src={LogoName}
                      style={{
                        width: "70%",
                      }}
                    />
                  </Grid>
                )}
                <Typography color="#273848" fontWeight={700} fontSize="29.94px">
                  Verify Your Email
                </Typography>
                <Typography color="#273848" fontWeight={500} fontSize="14px">
                  Lorem ipsum dolor sit amet consectetur. Tortor odio.
                </Typography>
              </Grid>
              <Grid
                item
                container
                // spacing={2}
                justifyContent="center"
                marginTop="20px"
                // margin="0px"
                gap="19px"
                padding={0}
                paddingLeft={0}
                marginBottom="20px"
              >
                {otp.map((digit, index) => (
                  <Grid key={index} padding={0}>
                    <TextField
                      // variant="outlined"
                      value={digit}
                      onChange={handleChange(index)}
                      onKeyDown={handleKeyDown(index)}
                      inputRef={refs[index]}
                      className="custom-notched-outline"
                      InputProps={{
                        classes: {
                          notchedOutline: "custom-notched-outline",
                        },
                      }}
                      inputProps={{
                        maxLength: 1,
                        style: {
                          height: "87px",
                          maxWidth: "52px",
                          textAlign: "center",
                          fontSize: 48,
                        },
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
              {otpError && (
                <Typography color="error">OTP does not match</Typography>
              )}

              <Grid>
                <Grid
                  direction="row"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid> </Grid>
                  <Grid>
                    {timer > 0 ? (
                      <Typography color="#273848">
                        Resend OTP in {timer} seconds
                      </Typography>
                    ) : (
                      <Link
                        onClick={handleResendOTP}
                        color="#13213B"
                        style={{
                          textDecorationColor: "#FFFFFF",
                          cursor: "pointer",
                        }}
                      >
                        Resend OTP
                      </Link>
                    )}
                  </Grid>
                </Grid>
                <Grid mb={2}></Grid>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  style={{
                    backgroundColor: "#84E1CC",
                    boxShadow: "none",
                    borderRadius: "5px",
                    padding: "17px 17px",
                    lineHeight: "0px",
                    textTransform: "none",
                    letterSpacing: "0px",
                  }}
                  onClick={handleSubmit}
                >
                  Confirm
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {/* Image Column */}
          {!isMobile && (
            <Grid
              item
              md={6}
              // height="100%"
              style={{
                padding: 0,
                position: "relative",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  background: `url(${Logo})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderRadius: "0 22px 22px 0",
                }}
              />
              <img
                src={Image}
                alt="Email Verification Image"
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "0 22px 22px 0",
                  objectFit: "cover",
                }}
              />
            </Grid>
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default EmailVerification;