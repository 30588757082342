import { Box } from '@mui/material';
import React from 'react';

const ShimmerEffect = () => {
    const width = '100px'; // Define the width internally
    const height = '16px'; // Define the height internally

    return (
        <div>
            <Box
                sx={{
                    width,
                    height,
                    position: 'relative',
                    overflow: 'hidden',
                }}
            >
                {/* Shimmer animation styles */}
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        height: '100%',
                        width: '100%',
                        background:
                            'linear-gradient(to right, #f0f0f0 4%, #e6e6e6 25%, #f0f0f0 36%)',
                        backgroundSize: '1000px 100%',
                        animation: 'shimmer 1.2s infinite linear',
                        borderRadius: '4px',
                    }}
                />
            </Box>

            {/* CSS keyframes for shimmer effect */}
            <style>
                {`
          @keyframes shimmer {
            0% {
              transform: translateX(-100%);
            }
            100% {
              transform: translateX(100%);
            }
          }
        `}
            </style>
        </div>
    );
};

export default ShimmerEffect;
