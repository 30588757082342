import React, { ReactElement, useEffect, useState } from "react";
import {
  Grid,
  Select,
  Typography,
  useMediaQuery,
  MenuItem,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material";
import InstagramPost from "../../components/ContentCreation/InstagramPost";
import FacebookPost from "../../components/ContentCreation/FacebookPost";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/X";
import GoogleIcon from "@mui/icons-material/Google";
import YouTubeIcon from "@mui/icons-material/YouTube";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LinkedInPost from "../../components/ContentCreation/LinkedInPost";
import TwitterPost from "../../components/ContentCreation/TwitterPost";
import YoutubePost from "../../components/ContentCreation/YoutubePost";
import GooglePost from "../../components/ContentCreation/GooglePost";
import { getRequest, postRequest } from "../../api/apiServices";
import apiList, { getContentById } from "../../api/apiList";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../utils/Loading";

type SocialFormat = {
  value: string;
  label: string;
  icon: ReactElement;
};

type Formats = {
  [key: string]: SocialFormat[];
};

const formats: Formats = {
  INSTAGRAM: [
    {
      value: "Instagram_Post",
      label: "Instagram Post (Square) - 1080 px 1080 px",
      icon: (
        <InstagramIcon
          sx={{
            color: "#fff",
            height: "40px",
            width: "40px",
          }}
        />
      ),
    },
    {
      value: "Instagram_Story",
      label: "Instagram Story - 1080 x 1920 px",
      icon: (
        <InstagramIcon
          sx={{
            color: "#fff",
            height: "40px",
            width: "40px",
          }}
        />
      ),
    },
    {
      value: "Instagram_Ad",
      label: "Instagram Ad (Square) - 1080 x 1080 px",
      icon: (
        <InstagramIcon
          sx={{
            color: "#fff",
            height: "40px",
            width: "40px",
          }}
        />
      ),
    },
    {
      value: "Instagram_Reel",
      label: "Instagram Reel - 1080 x 1920 px",
      icon: (
        <InstagramIcon
          sx={{
            color: "#fff",
            height: "40px",
            width: "40px",
          }}
        />
      ),
    },
  ],
  FACEBOOK: [
    {
      value: "Facebook_Post",
      label: "Facebook Post (Landscape) – 940 x 788 px",
      icon: (
        <FacebookIcon
          sx={{
            color: "#fff",
            height: "40px",
            width: "40px",
          }}
          style={{ boxSizing: "border-box" }}
        />
      ),
    },
    {
      value: "Facebook_Cover",
      label: "Facebook Cover – 1640 x 924 px",
      icon: (
        <FacebookIcon
          sx={{
            color: "#fff",
            height: "40px",
            width: "40px",
          }}
          style={{ boxSizing: "border-box" }}
        />
      ),
    },
    {
      value: "Facebook_Ad",
      label: "Facebook Ad – 1200 x 628 px",
      icon: (
        <FacebookIcon
          sx={{
            color: "#fff",
            height: "40px",
            width: "40px",
          }}
          style={{ boxSizing: "border-box" }}
        />
      ),
    },
    {
      value: "Facebook_Event_Cover",
      label: "Facebook Event Cover – 1920 x 1080 px",
      icon: (
        <FacebookIcon
          sx={{
            color: "#fff",
            height: "40px",
            width: "40px",
          }}
          style={{ boxSizing: "border-box" }}
        />
      ),
    },
  ],
  LINKEDIN: [
    {
      value: "LinkedIn_Post",
      label: "LinkedIn Post – 1200 x 1200 px",
      icon: (
        <LinkedInIcon
          sx={{
            color: "#fff",
            height: "40px",
            width: "40px",
          }}
          style={{ boxSizing: "border-box" }}
        />
      ),
    },
    {
      value: "LinkedIn_Single_Image_Ad",
      label: "LinkedIn Single Image Ad – 1200 x 627 px",
      icon: (
        <LinkedInIcon
          sx={{
            color: "#fff",
            height: "40px",
            width: "40px",
          }}
          style={{ boxSizing: "border-box" }}
        />
      ),
    },
    {
      value: "LinkedIn_Video_Ad",
      label: "LinkedIn Video Ad – 1920 x 1920 px",
      icon: (
        <LinkedInIcon
          sx={{
            color: "#fff",
            height: "40px",
            width: "40px",
          }}
          style={{ boxSizing: "border-box" }}
        />
      ),
    },
  ],
  TWITTER: [
    {
      value: "X_Text",
      label: "X_Text - 280 characters",
      icon: (
        <TwitterIcon
          sx={{
            color: "#fff",
            height: "40px",
            width: "40px",
          }}
          style={{ boxSizing: "border-box" }}
        />
      ),
    },
    {
      value: "X_Image",
      label: "X_Image – 800 x 418 px",
      icon: (
        <TwitterIcon
          sx={{
            color: "#fff",
            height: "40px",
            width: "40px",
          }}
          style={{ boxSizing: "border-box" }}
        />
      ),
    },
    {
      value: "X_Video",
      label: "X_Video – 1200 x 1200 px",
      icon: (
        <TwitterIcon
          sx={{
            color: "#fff",
            height: "40px",
            width: "40px",
          }}
          style={{ boxSizing: "border-box" }}
        />
      ),
    },
    {
      value: "X_Image_Ads",
      label: "X Image Ads – 800 x 418 px",
      icon: (
        <TwitterIcon
          sx={{
            color: "#fff",
            height: "40px",
            width: "40px",
          }}
          style={{ boxSizing: "border-box" }}
        />
      ),
    },
    {
      value: "X_Video_Ads",
      label: "X Video Ads – 1200 x 1200 px",
      icon: (
        <TwitterIcon
          sx={{
            color: "#fff",
            height: "40px",
            width: "40px",
          }}
          style={{ boxSizing: "border-box" }}
        />
      ),
    },
    {
      value: "X_Carousel_Ads",
      label: "X Carousel Ads Image 800 x 814 px | Video 800 x 450 px",
      icon: (
        <TwitterIcon
          sx={{
            color: "#fff",
            height: "40px",
            width: "40px",
          }}
          style={{ boxSizing: "border-box" }}
        />
      ),
    },
  ],
  GOOGLE: [
    {
      value: "Google_Talent",
      label: "Google_Talent - 230 characters",
      icon: (
        <GoogleIcon
          sx={{
            color: "#fff",
            height: "40px",
            width: "40px",
          }}
          style={{ boxSizing: "border-box" }}
        />
      ),
    },
    {
      value: "Google_Ad",
      label: "Google_Ad – 800 x 418 px",
      icon: (
        <GoogleIcon
          sx={{
            color: "#fff",
            height: "40px",
            width: "40px",
          }}
          style={{ boxSizing: "border-box" }}
        />
      ),
    },
  ],
};

const UpdateContentCreation: React.FC = () => {
  const isMobile = useMediaQuery("(max-width:991px)");
  const location = useLocation();
  const navigate = useNavigate();
  const { subscriptionPackage } = location.state || {};
  const [selectedPlatform, setSelectedPlatform] = useState("Instagram");
  const [selectedFormat, setSelectedFormat] = useState("");
  const [socialData, setSocialData] = useState({});
  const [activePlan, setActivePlan] = useState<any>();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState(false);

  const handlePlatformChange = (event: SelectChangeEvent<string>) => {
    setSelectedPlatform(event.target.value);
  };

  const platformMapping: { [key: string]: string } = {
    INSTAGRAM: "Instagram",
    FACEBOOK: "Facebook",
    LINKEDIN: "LinkedIn",
    TWITTER: "Twitter", // Assuming Twitter would be in your plan
    GOOGLE: "Google",
  };

  const getPlatformIcon = (platform: string) => {
    switch (platform) {
      case "Instagram":
        return <InstagramIcon sx={{ color: "#fff" }} />;
      case "Facebook":
        return <FacebookIcon sx={{ color: "#fff" }} />;
      case "LinkedIn":
        return <LinkedInIcon sx={{ color: "#fff" }} />;
      case "Twitter":
        return <TwitterIcon sx={{ color: "#fff" }} />;
      case "Google":
        return <GoogleIcon sx={{ color: "#fff" }} />;
      default:
        return null;
    }
  };

  const getDetails = async () => {
    setLoading(true);
    setMode(true);
    const response = await getRequest(getContentById(params?.id));
    setSocialData(response?.data?.data);
    setSelectedPlatform(response?.data?.data?.name);
    setSelectedFormat(response?.data?.data?.selectFormat);
    setLoading(false);
  };

  const getActivePlan = async () => {
    if (params?.id) return;
    setLoading(true);
    const response = await getRequest(apiList.activeSubscription);
    const activePlanData = response?.data?.data;
    setActivePlan(activePlanData);

    const firstAvailablePlatform = Object.keys(
      response?.data?.data?.subscription || {}
    ).find((platform) => {
      return Object.values(activePlanData.subscription[platform]).some(
        (isEnabled) => isEnabled
      );
    });

    if (!firstAvailablePlatform) {
      deActivePlan();

      return navigate("/subscriptionplan");
    } else {
      setSelectedPlatform(
        platformMapping[firstAvailablePlatform] || "Instagram"
      );
    }
    setLoading(false);
  };

  const deActivePlan = async () => {
    setLoading(true);
    await postRequest(apiList.deActiveSubscription);
    setLoading(false);
  };

  useEffect(() => {
    if (subscriptionPackage === "FREE") {
      // If the subscription package is FREE, display all platforms
      setActivePlan({ subscription: platformMapping });
    } else {
      // Fetch the active plan details if not FREE
      getActivePlan();
    }
    if (params?.id) getDetails();
  }, [subscriptionPackage]);

  const handleFormatChange = (event: any) => {
    setSelectedFormat(event.target.value);
  };

  const getAvailableFormats = () => {
    if (subscriptionPackage === "FREE") {
      // Define default formats for each platform when the package is "FREE"
      return (
        {
          Instagram: formats.INSTAGRAM.filter(
            (format) =>
              format.value === "Instagram_Post" ||
              format.value === "Instagram_Story" ||
              format.value === "Instagram_Reel"
          ),
          Facebook: formats.FACEBOOK.filter(
            (format) =>
              format.value === "Facebook_Post" ||
              format.value === "Facebook_Cover"
          ),
          LinkedIn: formats.LINKEDIN.filter(
            (format) => format.value === "LinkedIn_Post"
          ),
          Twitter: formats.TWITTER.filter(
            (format) =>
              format.value === "X_Text" ||
              format.value === "X_Image" ||
              format.value === "X_Video"
          ),
        }[selectedPlatform] || []
      );
    }

    const subscriptionFormats =
      activePlan?.subscription?.[selectedPlatform.toUpperCase()] || {};

    return (
      formats[selectedPlatform.toUpperCase()]?.filter(
        (format) => subscriptionFormats[format.value] === true
      ) || []
    );
  };

  const getSelectedFormatData = () => {
    // Get the formats for the selected platform
    const platformFormats = formats[selectedPlatform.toUpperCase()] || [];

    // Find the format that matches the selectedFormat
    const selectedFormatData = platformFormats.find(
      (format) => format.value === selectedFormat
    );

    // If found, return the data; otherwise, return a default/fallback object
    return (
      selectedFormatData || {
        value: selectedFormat,
        label: selectedFormat,
        icon: null,
      }
    );
  };

  const renderFormatOptions = () => {
    const availableFormats = getAvailableFormats();
    const selectedFormats = getSelectedFormatData();

    // Check if the selectedFormat exists in availableFormats
    const includesSelectedFormat = availableFormats.some(
      (format) => format.value === selectedFormat
    );

    // Add selected format to list if it's not already in availableFormats
    const formatsToRender = includesSelectedFormat
      ? availableFormats
      : [selectedFormats];

    return formatsToRender.map(({ value, label, icon }) => (
      <FormControlLabel
        key={value}
        value={value}
        control={<Radio sx={{ display: "none" }} />}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          marginRight: "0%",
        }}
        label={
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            gap="10px"
          >
            <Grid item>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "2px solid #fff",
                  borderRadius: "10.81px",
                  padding: "25px",
                }}
              >
                {icon}
              </div>
            </Grid>
            <Grid item>
              <Typography
                color="#fff"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                {label}
              </Typography>
            </Grid>
            <Grid item>
              <Radio
                sx={{ color: "#fff", "&.Mui-checked": { color: "#fff" } }}
                checked={selectedFormat === value}
                onChange={handleFormatChange}
                value={value}
              />
            </Grid>
          </Grid>
        }
        labelPlacement="start"
      />
    ));
  };

  const renderMenuItems = () => {
    const subscriptionPlatforms = activePlan?.subscription || platformMapping;
    const filteredPlatforms = Object.keys(subscriptionPlatforms).filter(
      (platform) => {
        if (subscriptionPackage === "FREE" && platform === "GOOGLE") {
          return false; // Exclude Google for free package
        }
        const formats = subscriptionPlatforms[platform];
        return Object.values(formats).some((isEnabled) => isEnabled);
      }
    );

    return filteredPlatforms
      .filter((key) => platformMapping[key] === selectedPlatform)
      .map((key) => (
        <MenuItem key={platformMapping[key]} value={platformMapping[key]}>
          {getPlatformIcon(platformMapping[key])}
          <span style={{ marginLeft: "8px" }}>{platformMapping[key]}</span>
        </MenuItem>
      ));
  };

  return loading ? (
    <Loader />
  ) : (
    <div
      style={{ backgroundColor: "#F4F4F4", height: "-webkit-fill-available" }}
    >
      <Grid
        container
        spacing={1}
        justifyContent="center"
        height="100%"
        marginTop="0px"
        width="100%"
        marginLeft={isMobile ? "0%" : ""}
      >
        <Grid
          item
          xs={isMobile ? 12 : 2}
          bgcolor="#244564"
          display="flex"
          flexDirection="column"
          borderRadius={isMobile ? "0px 0px 22px 22px" : "22px"}
          sx={{
            overflowX: isMobile ? "auto" : "hidden",
            whiteSpace: isMobile ? "nowrap" : "normal",
            "&::Webkit-scrollbar": {
              display: "none",
            },
            alignItems: isMobile ? "baseline" : "center",
          }}
          style={{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            padding: "10px",
          }}
        >
          <Grid
            container
            direction={isMobile ? "row" : "column"}
            alignItems="center"
            sx={{
              marginRight: isMobile ? "0px" : "0px",
            }}
          >
            <Grid item padding="17px 0px">
              <Typography fontSize="20.94px" fontWeight="bold" color="#fff">
                Select Social
              </Typography>
            </Grid>
            <Grid item width="100%">
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedPlatform}
                fullWidth
                onChange={handlePlatformChange}
                displayEmpty
                IconComponent={ArrowDropDownIcon}
                renderValue={(value) => (
                  <span
                    className={value === "" ? "placeholder" : ""}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "#fff",
                      backgroundColor: "#244564",
                    }}
                  >
                    {value === "" ? (
                      "Select"
                    ) : (
                      <>
                        {getPlatformIcon(value)}
                        <span style={{ marginLeft: "8px" }}>{value}</span>
                      </>
                    )}
                  </span>
                )}
                MenuProps={{
                  PaperProps: {
                    style: {
                      backgroundColor: "#244564",
                      color: "#fff",
                    },
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#fff",
                    },
                    "&:hover fieldset": {
                      borderColor: "#fff",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#fff",
                    },
                  },
                  "& .MuiSelect-icon": {
                    color: "#fff",
                  },
                  "& .MuiSelect-root": {
                    backgroundColor: "#244564",
                    color: "#fff",
                  },
                }}
              >
                <MenuItem value="" disabled>
                  Select
                </MenuItem>
                {renderMenuItems()}
              </Select>
            </Grid>
          </Grid>
          <Grid
            container
            mt={isMobile ? "0px" : "10px"}
            display="flex"
            flexDirection={isMobile ? "row" : "column"}
            justifyContent="center"
          >
            <Grid container item padding="17px 5px">
              <Typography fontSize="20.94px" fontWeight="bold" color="#fff">
                Select a Format
              </Typography>
            </Grid>
            <Grid item className="hidescroller" style={{ overflow: "auto" }}>
              <RadioGroup
                aria-label="format"
                name="format-group"
                value={selectedFormat}
                onChange={handleFormatChange}
                style={{
                  flexDirection: isMobile ? "row" : "column",
                  flexWrap: "unset",
                  gap: isMobile ? "20px" : "0px",
                }}
              >
                {renderFormatOptions()}
              </RadioGroup>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={8} maxWidth="100%" style={{ paddingLeft: isMobile ? '0%': ''}}>
          {selectedPlatform === "Instagram" && (
            <InstagramPost
              selectedFormat={selectedFormat}
              socialData={socialData}
              mode={mode}
            />
          )}
          {selectedPlatform === "Facebook" && (
            <FacebookPost
              selectedFormat={selectedFormat}
              socialData={socialData}
              mode={mode}
            />
          )}
          {selectedPlatform === "LinkedIn" && (
            <LinkedInPost
              selectedFormat={selectedFormat}
              socialData={socialData}
              mode={mode}
            />
          )}
          {selectedPlatform === "Twitter" && (
            <TwitterPost
              selectedFormat={selectedFormat}
              socialData={socialData}
              mode={mode}
            />
          )}
          {selectedPlatform === "Google" && (
            <GooglePost
              selectedFormat={selectedFormat}
              socialData={socialData}
              mode={mode}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default UpdateContentCreation;
