import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";
import { RegionalData } from "../../utils/AnalytivalDataType";
import { Grid, useMediaQuery } from "@mui/material";

// Register necessary Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend, Title);

interface RegionalChartProps {
  data: RegionalData[];
  jobTitle: string;
}

const RegionalChart: React.FC<RegionalChartProps> = ({ data, jobTitle }) => {
  const percentages = data.map(({ percentage }) =>
    parseFloat(percentage.replace("%", ""))
  );

  const labelsWithPercentages = data.map(
    ({ location }) => `${location.display_name}`
  );

  const isMobile = useMediaQuery("(max-width:1200px)");

  // Prepare chart data
  const chartData = {
    labels: labelsWithPercentages, // Use display_name as labels
    datasets: [
      {
        // label: "Regional Data",
        data: percentages,
        backgroundColor: [
          "#0088FE",
          "#00C49F",
          "#FFBB28",
          "#FF8042",
          "#FF4560",
          "#56C60A",
          "#7A4D96",
          "#FFC0CB",
          "#FFD700",
          "#4B0082",
          "#00FFFF",
          "#D2691E",
        ],
        hoverOffset: 4, // Optional: add a hover effect
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true, // Set to true to display the title
        text: `${jobTitle} Doughnut`, // Title text
        font: {
          size: 12, // Font size for the title
          weight: 400
        },
        padding: {
          top: 55, // Padding above the title
          bottom: isMobile ? 10 :  -40, // Padding below the title
        },
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem: any) => {
            const value = tooltipItem.raw;
            return ` ${value}%`;
          },
        },
      },
      legend: {
        position: isMobile ? "bottom"  as const : "right" as const, // Position the legend at the bottom
        labels: {
          boxWidth: 10, // Adjust the width of the legend boxes
          padding: 15, // Add padding between the boxes
          generateLabels: (chart: any) => {
            const labels = chart.data.labels || [];
            const dataset = chart.data.datasets[0];
            return labels.map((label: string, index: number) => {
              const value = dataset.data[index];
              return {
                text: `${value}% - ${label} `,
                fillStyle: dataset.backgroundColor[index],
                strokeStyle: dataset.backgroundColor[index],
                lineWidth: 1,
              };
            });
          },
        },
      },
    },
  };

  return (
    <Grid display="flex" alignItems="center" justifyContent="center" p={4}>
      <Doughnut
        data={chartData}
        options={options}
        width={isMobile ? 390 : 500}
        height={isMobile ? 300 : 400}
        // style={{
        //   width: isMobile ? "max-content" : 500,
        //   height: isMobile ? "max-content" : 400,
        // }}
      />
    </Grid>
  );
};

export default RegionalChart;
