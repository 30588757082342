import React from 'react';
import { Box, Modal, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import apiList from '../../api/apiList';
import { postRequest } from '../../api/apiServices';
import Toast from '../../utils/Toast';

interface NeedInfoProps {
  open: boolean;
  onClose: () => void;
  email: String;
}

const NeedInfo: React.FC<NeedInfoProps> = ({ open, onClose, email }) => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? 350 : 700,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    outline: 0,
    padding: isMobile ? '20px' : '40px 20px',
    borderRadius: '10px',
  };

  //Formik Setup
  const formik = useFormik({
    initialValues: {
      information: '',
    },
    validationSchema: Yup.object({
      information: Yup.string().required('Information Required'),
    }),
    onSubmit: async (value) => {
      const response = await postRequest(apiList.candidatesNeedMoreInfoEmail, {
        email: email,
        content: value.information,
      });
      Toast(response?.data?.statusMessage);
      onClose();
    },
  });

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="container">
            <div className="row">
              <div className="col-md-12 d-flex justify-content-end">
                <CloseIcon style={{ cursor: 'pointer' }} onClick={onClose} />
              </div>
              <form action="" onSubmit={formik.handleSubmit}>
                {/* Information */}
                <label
                  htmlFor="information"
                  className="form-label text-dark h4"
                >
                  More Information:{' '}
                </label>
                <textarea
                  name=""
                  className={`form-control ${
                    formik.touched.information && formik.errors.information
                      ? 'is-invalid'
                      : ''
                  }`}
                  id="information"
                  rows={isMobile ? 5 : 15}
                  value={formik.values.information}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="- Career website
                                                - Video 
                                                - Full Vacancy
                                                - (Digital) Cup coffee with
                                                Corporate Recruiter (automated
                                                scheduling Calandy like) "
                ></textarea>
                {formik.touched.information && formik.errors.information && (
                  <div className="text-danger">{formik.errors.information}</div>
                )}
                {/* submit button */}
                <div className="mt-3 float-end">
                  <button
                    type="submit"
                    className="user"
                    style={{
                      fontWeight: 500,
                      fontSize: '17.94px',
                      backgroundColor: '#84E1CC',
                      color: 'white',
                      padding: isMobile ? '7px 20px' : '7px 60px',
                      borderRadius: '5px',
                      border: 'none',
                    }}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default NeedInfo;
