import React, { useState } from "react";
import { Box, Grid, Typography, useMediaQuery, useTheme, Paper, CircularProgress, TextField, MenuItem } from "@mui/material";
import Header from "../../layout/Header";
import CandiProfilIcon from "../../assets/images/candiProfile.svg";
import CheckIcon from "../../assets/images/RightSign.svg";
import ChannelIcon from "../../assets/images/Channels.svg";
import MAnalysisIcon from "../../assets/images/MAnyasis.svg";
import FacebookIcon from '../../assets/images/SocialMedia/facebook_a.svg';
import InstagramIcon from '../../assets/images/SocialMedia/instagram_a.svg';
import LinkedInIcon from '../../assets/images/SocialMedia/linkedin_a.svg';
import YouTubeIcon from '../../assets/images/SocialMedia/youtube_a.svg';
import DiscordIcon from '../../assets/images/SocialMedia/discord_a.svg';
import TiktokIcon from '../../assets/images/SocialMedia/tiktok_a.svg';
import FacebookActiveIcon from '../../assets/images/SocialMedia/facebookActive_a.svg';
import InstagramActiveIcon from '../../assets/images/SocialMedia/instagramActive_a.svg';
import LinkedInActiveIcon from '../../assets/images/SocialMedia/linkedinActive_a.svg';
import YouTubeActiveIcon from '../../assets/images/SocialMedia/youTubeActive_a.svg';
import DiscordActiveIcon from '../../assets/images/SocialMedia/discordActive_a.svg';
import TiktokActiveIcon from '../../assets/images/SocialMedia/tiktokActive_a.svg';
import { styled } from '@mui/system';
import { Link } from "react-router-dom";

const drawerWidth = 100;
const CustomCircularProgress = styled(CircularProgress)(({ theme }) => ({
    '& .MuiCircularProgress-circle': {
        strokeLinecap: 'round',
        color: '#66CCE7',
        strokeWidth: 5
    },
    '& .MuiCircularProgress-circleStatic': {
        stroke: 'rgba(0, 0, 0, 0.26)'
    }
}));

const JobCandiAnalysis: React.FC = () => {
    const isMobile = useMediaQuery("(max-width:635px)");

    const [loading, setLoading] = useState(false)

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const activeSocials = {
        Facebook: true,
        Instagram: true,
        LinkedIn: false,
        Tiktok: true,
        YouTube: false,
        Discord: true,
    };

    const hourlyRate = 17.42;
    const lowRate = 11.43;
    const highRate = 20.43;
    const successRate = 76;

    const [timeUnit, setTimeUnit] = React.useState('hour');

    const handleChange = (event: any) => {
        setTimeUnit(event.target.value);
    };

    return loading ? <p>loading</p> : (
        <>
            <Header pageName="Analysis" isShow={true} />

            <div>
                <Grid
                    container
                    spacing={1}
                    paddingTop="30px"
                    justifyContent={isMobile ? "center" : "center"}
                    sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
                >
                    <Grid mt="35px"></Grid>
                    <Grid item xs={12} sm={12} bgcolor="#FFFFFF"
                        borderRadius="14px"
                        border="1px solid #D9D9D9"
                        padding="20px"
                    >
                        <Typography variant="h1" fontSize="20.94px" fontWeight="bold" style={{ color: '#273848' }}>
                            Job Title
                        </Typography>
                        <Typography style={{ fontWeight: 'medium', fontSize: '12.94px', color: '#273848', marginTop: '5px' }}>
                            Lorem ipsum dolor sit amet consectetur. Tortor odio.
                        </Typography>
                    </Grid>
                    <Grid item md={8} xs={12} maxWidth={isMobile ? "100%" : "100%"}>
                        {/* <Grid mt="35px"></Grid> */}
                        <Grid
                            direction="column"
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            bgcolor="#FFFFFF"
                            borderRadius="14px"
                            border="1px solid #D9D9D9"
                            style={{ cursor: "pointer", padding: "20px" }}
                        >
                            <Grid item container direction="row" alignItems="center">
                                <img src={MAnalysisIcon} alt="" />
                                <Typography variant="h6" style={{ marginLeft: '5px', fontWeight: 'bold', fontSize: '20.94px', color: '#273848' }}>Market Analysis</Typography>
                            </Grid>
                            <Grid item container direction="row" alignItems="center" spacing={2} mt="10px" >
                                <Grid item xs={12} sm={12} md={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', padding: '20px' }}>
                                    <Box style={{
                                        width: "100%", display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: window.innerWidth <= 400 ? 'column' : 'row',
                                        rowGap: window.innerWidth <= 400 ? '10px' : '0px'
                                    }}>
                                        <Typography
                                            variant="h4"
                                            sx={{ fontSize: '50px', fontWeight: 'bold', color: '#000' }} >
                                            ${hourlyRate}
                                        </Typography>
                                        <TextField
                                            select
                                            variant="outlined"
                                            value={timeUnit}
                                            onChange={handleChange}
                                            sx={{
                                                fontSize: '12.94px',
                                                fontWeight: 'bold',
                                                color: '#000',
                                                '.MuiOutlinedInput-input': {
                                                    padding: '5px 14px',
                                                },
                                                '.MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: '#000',
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: '#000',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: '#000',
                                                    },
                                                },
                                            }}
                                        >
                                            <MenuItem value="hour">Per hour</MenuItem>
                                            <MenuItem value="day">Per day</MenuItem>
                                            <MenuItem value="month">Per month</MenuItem>
                                        </TextField>
                                    </Box>
                                    <Box display="flex" justifyContent="space-between" width="100%" marginY="10px">
                                        <Typography variant="body2" sx={{ fontSize: '16.93px', fontWeight: 'bold', color: '#000' }}>
                                            Low:
                                        </Typography>
                                        <Typography variant="body2" sx={{ fontSize: '16.93px', fontWeight: 'bold', color: '#000' }}>
                                            ${lowRate}
                                        </Typography>
                                    </Box>
                                    <Box display="flex" justifyContent="space-between" width="100%" marginY="10px">
                                        <Typography variant="body2" sx={{ fontSize: '16.93px', fontWeight: 'bold', color: '#000' }}>
                                            High:
                                        </Typography>
                                        <Typography variant="body2" sx={{ fontSize: '16.93px', fontWeight: 'bold', color: '#000' }}>
                                            ${highRate}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Box position="relative" display="inline-flex" marginY="20px">
                                        <CustomCircularProgress variant="determinate" value={successRate} size={100} thickness={5} />
                                        <Box
                                            top={0}
                                            left={0}
                                            bottom={0}
                                            right={0}
                                            position="absolute"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Typography variant="h5" component="div" color="textSecondary">
                                                {`${successRate}%`}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Typography variant="body2" color="textSecondary" sx={{ fontSize: '13.94px', fontWeight: 'bold', color: '#273848' }}>
                                        % of success for finding a right candidate
                                    </Typography>
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid mt="26px"></Grid>
                        <Grid
                            container
                            direction="column"
                            justifyContent="space-between"
                            alignItems="center"
                            bgcolor="#FFFFFF"
                            borderRadius="14px"
                            border="1px solid #D9D9D9"
                            style={{ cursor: "pointer", padding: "20px" }}
                        >
                            <Grid item container direction="row" alignItems="center">
                                <img src={CandiProfilIcon} alt="" />
                                <Typography variant="h6" style={{ marginLeft: '5px', fontWeight: 'bold', fontSize: '20.94px', color: '#273848' }}>Approach Analysis</Typography>
                            </Grid>
                            <Grid mt="25px"></Grid>
                            <Grid item container direction="row" alignItems="center" spacing={2}>
                                <Grid item xs={12} sm={6} style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={CheckIcon} alt="" style={{ marginRight: '5px' }} />
                                    <Typography variant="body1" style={{ fontWeight: 'bold', fontSize: '14px', color: '#273848' }}>
                                        Lorem ipsum dolor sit amet consectetur. Platea aliquet leo lectus neque vulputate enim et. Mattis nunc
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={CheckIcon} alt="" style={{ marginRight: '5px' }} />
                                    <Typography variant="body1" style={{ fontWeight: 'bold', fontSize: '14px', color: '#273848' }}>
                                        Lorem ipsum dolor sit amet consectetur. Platea aliquet leo lectus neque vulputate enim et. Mattis nunc
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={CheckIcon} alt="" style={{ marginRight: '5px' }} />
                                    <Typography variant="body1" style={{ fontWeight: 'bold', fontSize: '14px', color: '#273848' }}>
                                        Lorem ipsum dolor sit amet consectetur. Platea aliquet leo lectus neque vulputate enim et. Mattis nunc
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={CheckIcon} alt="" style={{ marginRight: '5px' }} />
                                    <Typography variant="body1" style={{ fontWeight: 'bold', fontSize: '14px', color: '#273848' }}>
                                        Lorem ipsum dolor sit amet consectetur. Platea aliquet leo lectus neque vulputate enim et. Mattis nunc
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid mt="26px"></Grid>
                        <Grid
                            direction="column"
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            bgcolor="#FFFFFF"
                            borderRadius="14px"
                            border="1px solid #D9D9D9"
                            style={{ cursor: "pointer", padding: "20px" }}
                        >
                            <Grid item container direction="row" alignItems="center">
                                <img src={ChannelIcon} alt="" />
                                <Typography variant="h6" style={{ marginLeft: '5px', fontWeight: 'bold', fontSize: '20.94px', color: '#273848' }}>Channels Analysis</Typography>
                            </Grid>
                            <Grid mt="10px" container direction="row" justifyContent="start" spacing={2}>
                                <Grid item>
                                    <Box>
                                        <Link to={"/"} target="_blank" rel="noopener noreferrer">
                                            <img src={activeSocials.Facebook ? FacebookActiveIcon : FacebookIcon} alt="Facebook" />
                                        </Link>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Box>
                                        <Link to={"/"} target="_blank" rel="noopener noreferrer">
                                            <img src={activeSocials.Instagram ? InstagramActiveIcon : InstagramIcon} alt="Instagram" />
                                        </Link>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Box>
                                        <Link to="/" target="_blank" rel="noopener noreferrer">
                                            <img src={activeSocials.LinkedIn ? LinkedInActiveIcon : LinkedInIcon} alt="LinkedIn" />
                                        </Link>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Box>
                                        <Link to={"/"} rel="noopener noreferrer">
                                            <img src={activeSocials.Tiktok ? TiktokActiveIcon : TiktokIcon} alt="Tiktok" />
                                        </Link>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Box>
                                        <Link to={"/"} target="_blank" rel="noopener noreferrer">
                                            <img src={activeSocials.YouTube ? YouTubeActiveIcon : YouTubeIcon} alt="YouTube" />
                                        </Link>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Box>
                                        <Link to={"/"} target="_blank" rel="noopener noreferrer">
                                            <img src={activeSocials.Discord ? DiscordActiveIcon : DiscordIcon} alt="Discord" />
                                        </Link>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid mt="26px"></Grid>
                    </Grid>
                    <Grid item md={4} xs={12} maxWidth={isMobile ? "100%" : "100%"}>
                        <Grid bgcolor="#FFFFFF"
                            borderRadius="14px"
                            border="1px solid #D9D9D9"
                            height="100%"
                            display="flex"
                            flexDirection="column"
                            padding="20px">
                            <Grid item container direction="row" alignItems="center">
                                <img src={CandiProfilIcon} alt="CandidateImage" />
                                <Typography variant="h6" style={{ marginLeft: '5px', fontWeight: 'bold', fontSize: '20.94px', color: '#273848' }}>Candidate Profile</Typography>
                            </Grid>
                            <Grid mt="26px"></Grid>
                            <Grid>
                                <Typography variant="h6" style={{ fontWeight: 'bold', fontSize: '15.94px', color: '#273848' }}>Demographic</Typography>
                                <Typography style={{ fontWeight: 'medium', fontSize: '13.94px', color: '#273848' }}>Lorem ipsum dolor sit amet consectetur. Platea aliquet leo lectus neque vulputate enim et. Mattis nunc consequat etiam pharetra ut massa bibendum nulla. Non dictum lacus tellus interdum vulputate et ipsum ac ut. Nunc condimentum lectus.</Typography>
                            </Grid>
                            <Grid mt="26px"></Grid>
                            <Grid>
                                <Typography variant="h6" style={{ fontWeight: 'bold', fontSize: '15.94px', color: '#273848' }}>Geographic</Typography>
                                <Typography style={{ fontWeight: 'medium', fontSize: '13.94px', color: '#273848' }}>Lorem ipsum dolor sit amet consectetur. Platea aliquet leo lectus neque vulputate enim et. Mattis nunc consequat etiam pharetra ut massa bibendum nulla. Non dictum lacus tellus interdum vulputate et ipsum ac ut. Nunc condimentum lectus.</Typography>
                            </Grid>
                            <Grid mt="26px"> </Grid>
                            <Grid>
                                <Typography variant="h6" style={{ fontWeight: 'bold', fontSize: '15.94px', color: '#273848' }}>Psycographic</Typography>
                                <Typography style={{ fontWeight: 'medium', fontSize: '13.94px', color: '#273848' }}>Lorem ipsum dolor sit amet consectetur. Platea aliquet leo lectus neque vulputate enim et. Mattis nunc consequat etiam pharetra ut massa bibendum nulla. Non dictum lacus tellus interdum vulputate et ipsum ac ut. Nunc condimentum lectus.</Typography>
                            </Grid>
                            <Grid mt="26px"> </Grid>
                            <Grid>
                                <Typography variant="h6" style={{ fontWeight: 'bold', fontSize: '15.94px', color: '#273848' }}>Behavioral</Typography>
                                <Typography style={{ fontWeight: 'medium', fontSize: '13.94px', color: '#273848' }}>Lorem ipsum dolor sit amet consectetur. Platea aliquet leo lectus neque vulputate enim et. Mattis nunc consequat etiam pharetra ut massa bibendum nulla. Non dictum lacus tellus interdum vulputate et ipsum ac ut. Nunc condimentum lectus.</Typography>
                            </Grid>
                        </Grid>


                    </Grid>
                </Grid>
            </div>

        </>
    );
};

export default JobCandiAnalysis;
