import axios, { AxiosResponse } from 'axios';
import { getToken } from '../services/authService';
import Toast from '../utils/Toast';

async function makeRequest(method, url, data = {}, token = '') {
  try {
    const response = await axios({
      method,
      url,
      data,
      headers: token ? { Authorization: `Bearer ${token}` } : {},
    });
    return response;
  } catch (error) {
    Toast(
      error.response?.data?.statusMessage || 'Something Went Wrong..!',
      'error'
    );
  }
}

export async function getRequest(url) {
  const auth = getToken();
  return makeRequest('get', url, undefined, auth?.token);
}

export async function postRequest(url, data) {
  const auth = getToken();
  return makeRequest('post', url, data, auth?.token);
}

export async function putRequest(url, data) {
  const auth = getToken();
  return makeRequest('put', url, data, auth?.token);
}

export async function patchRequest(url, data) {
  const auth = getToken();
  return makeRequest('patch', url, data, auth?.token);
}

export async function deleteRequest(url, data) {
  const auth = getToken();
  return makeRequest('delete', url, data, auth?.token);
}

export async function multiPartRequest(url, data) {
  try {
    const auth = getToken();
    const respose = await axios.post(url, data, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    return respose;
  } catch (error) {
    Toast(
      error.response?.data?.statusMessage || 'Something Went Wrong..!',
      'error'
    );
  }
}

export async function multiPartPUTRequest(url, data) {
  const auth = getToken();
  const respose = await axios.put(url, data, {
    headers: {
      Authorization: `Bearer ${auth?.token}`,
      'Content-Type': 'multipart/form-data',
    },
  });
  return respose;
}
