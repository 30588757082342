import React, { useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Container,
  useMediaQuery,
} from "@mui/material";
import Success from "../../assets/images/PaymentSuccess.png";
import RightArrowIcon from "../../assets/images/RightArrow.png";
import { useNavigate } from "react-router-dom";

const PaymentSuccess: React.FC = () => {
  const navigate = useNavigate();

  const handleSubmit = () => {
      navigate("/content-creation");
  };
  return (
    <div>
      <Container
        maxWidth="sm"
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          container
          spacing={2}
          direction="column"
          alignItems="center"
          borderRadius="22px"
          bgcolor="#FFFFFF"
          margin={0}
          justifyContent="center"
          style={{ padding: 30 }}
        >
          <Grid>
            <Typography
              variant="subtitle1"
              color="#273848"
              fontWeight={700}
              fontSize="24.94px"
            >
              Payment Success!
            </Typography>
          </Grid>
          <Grid container spacing={2} justifyContent="center">
            <Grid
              style={{ padding: 30 }}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <img src={Success} width="40%" />
            </Grid>
          </Grid>
          <Grid display="flex" justifyContent="center">
            <Button
              type="submit"
              fullWidth
              style={{
                width: "auto",
                color: "#008E0D",
                fontSize: "18px",
                fontWeight: "500",
                letterSpacing: "0px",
                textTransform: "none",
              }}
              onClick={handleSubmit}
            >
              <span style={{ marginRight: "5px" }}>Continune</span>{" "}
              <img src={RightArrowIcon} width="20px" height="20px" />
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default PaymentSuccess;
