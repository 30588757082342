import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Container,
  useMediaQuery,
  IconButton,
} from '@mui/material';
import '../../styles/inputBox.css';
import CommonTable from '../Common/PostingTable';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
// import NoImageIcon from "../../assets/images/NoPhotoIcons.png";
import { postRequest } from '../../api/apiServices';
import { contentList } from '../../api/apiList';
import Loader from '../../utils/Loading';

interface selectedPlatformPost {
  selectedPlatform: string;
}

const PostPublish: React.FC<selectedPlatformPost> = ({ selectedPlatform }) => {
  const isMobile = useMediaQuery('(max-width:578px)');
  const isTabs = useMediaQuery("(max-width: 900px)");
  const [currentWeek, setCurrentWeek] = useState<Date>(new Date());
  const [loading, setLoading] = useState(false);
  const headings: string[] = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  // API Data Set
  const [data, setData] = useState([]);

  const getCurrentWeekDates = (date: Date): Date[] => {
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1); // Adjust when day is Sunday (0)
    const startOfWeek = new Date(date.setDate(diff));
    const endOfWeek = new Date(date.setDate(date.getDate() + 6));
    return [startOfWeek, endOfWeek];
  };

  // Get current week start and end dates
  const [weekStartDate, weekEndDate] = getCurrentWeekDates(currentWeek);

  // Function to navigate to the previous week
  const handlePreviousWeek = () => {
    setCurrentWeek(
      (prevWeek) => new Date(prevWeek.setDate(prevWeek.getDate() - 7))
    );
  };

  // Function to navigate to the next week
  const handleNextWeek = async () => {
    setCurrentWeek(
      (prevWeek) => new Date(prevWeek.setDate(prevWeek.getDate() + 7))
    );
  };

  // Format the current week dates to display
  const formattedStartDate = `${weekStartDate.getDate()} ${weekStartDate.toLocaleString(
    'default',
    { month: 'short' }
  )}`;
  const formattedEndDate = `${weekEndDate.getDate()} ${weekEndDate.toLocaleString(
    'default',
    { month: 'short' }
  )}`;

  const getContentData = async () => {
    const payload = {
      selectedPlatform,
      weekStartDate,
      weekEndDate,
    };
    setLoading(true);
    const response = await postRequest(contentList, payload);
    setData(response?.data?.data);
    setLoading(false);
  };

  useEffect(() => {
    getContentData();
  }, [selectedPlatform, formattedEndDate]);

  return loading ? (
    <Loader />
  ) : (
    <div style={{ display: "flex", height: "100%" }}>
      <Container style={{ padding: isTabs ? 0 : "" }}>
        <Grid
          container
          spacing={2}
          bgcolor="#FFFFFF"
          borderRadius="22px"
          height="100%"
          border="1px solid #D6D6D7"
          width="100%"
          margin={0}
        >
          <Grid
            item
            style={{
              width: "inherit",
              padding: 30,
              justifyContent: isTabs ? "unset" : "",
            }}
          >
            <Grid
              container
              direction="row"
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              gap={isMobile ? "10px" : ""}
            >
              <Grid item>
                <Typography
                  color="#273848"
                  fontWeight={700}
                  fontSize="16.94px"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <IconButton aria-label="filter">
                    <FilterAltIcon sx={{ color: "#273848" }} />
                  </IconButton>
                  Filter By
                </Typography>
              </Grid>
              <Grid item width="fit-content">
                <Typography
                  color="#273848"
                  fontWeight={700}
                  fontSize="16.94px"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <IconButton
                    onClick={handlePreviousWeek}
                    aria-label="previous week"
                  >
                    <SkipPreviousIcon />
                  </IconButton>
                  <IconButton onClick={handleNextWeek} aria-label="next week">
                    <SkipNextIcon />
                  </IconButton>
                  <Typography style={{ width: "max-content" }}>
                    {formattedStartDate} - {formattedEndDate}
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
            <Grid my={4}></Grid>
            <CommonTable
              headings={headings}
              data={data}
              label={selectedPlatform}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default PostPublish;
