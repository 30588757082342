import React, { useContext, useEffect, useRef, useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  Popover,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import "../styles/Header/header.css";
import User from "../assets/images/User.svg";
import { clearSessionStorage, getToken } from "../services/authService";
import MenuIcon from "../assets/images/Sidebar/menuIcon.svg";

import { useNavigate } from "react-router-dom";
import { getRequest } from "../api/apiServices";
import { getUserProfile } from "../api/apiList";
import Loader from "../components/Loader";
import { MutableRefObject } from "react";
import Toast from "../utils/Toast";
import { SidebarContext } from "../hooks/UseSidebar";
import ConfirmationDialog from "../components/DailogBox";

const drawerWidth = 100;

interface HeaderProps {
  pageName?: string;
  isShow?: boolean; // Assuming userRole is a string
  className?: string;
  headerRef?: MutableRefObject<null>;
}

interface UserData {
  profile: string;
  name: string;
  status: string;
}

const Header: React.FC<HeaderProps> = ({
  isShow,
  pageName,
  className,
  headerRef,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null); // explicitly define anchorEl type
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    // explicitly define event type
    setAnchorEl(event.currentTarget);
  };
  const { toggleSide } = useContext(SidebarContext);
  const [openDialog, setOpenDialog] = useState(false);

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleEditUserProfile = () => {
    const auth = getToken();
    navigate(`/update-user-profile/${auth?.uId}`);
  };

  const handleEditProfile = () => {
    const auth = getToken();
    navigate(`/update-company-profile/${auth?.uId}`);
  };

  const handleOpenConfirmationDialog =()=>{
    setOpenDialog(true);
  }; 

  const handleLogout = () => {
    clearSessionStorage();
    Toast("Logout Successfully.");
    navigate("/login");
  };

  const handleClose = () => {
    setOpenDialog(false);
  }

  const auth = getToken();

  const isMobile = useMediaQuery("(max-width:500px)");
  const isTabs = useMediaQuery("(max-width:1200px)");

  const toggleSidebar = () => {
    toggleSide();
  };

  return isShow ? (
    <>
      <Box
        position="static"
        className={className}
        ref={headerRef}
        sx={{
          // width: `calc(100% - ${drawerWidth}px)`,
          // ml: `${drawerWidth}px`,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 1,
          flexDirection: "row",
          marginTop: isTabs ? "0px" : "20px",
        }}
      >
        {/* Page Neme */}
        <Container className="header-main">
          <Box className="menu-button">
            <img
              src={MenuIcon}
              width={24}
              height={24}
              onClick={toggleSidebar}
            />
          </Box>
          <Typography
            className="text-shadow"
            variant={isTabs ? "h5" : "h3"}
            component="div"
          >
            {pageName}
          </Typography>
        </Container>

        {/* User Informations */}
        <Container
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "auto",
          }}
          className="header-main header-box"
        >
          {isTabs ? (
            <>
              <Grid
                direction="row"
                display="flex"
                alignItems="center"
                bgcolor="#FFFFFF"
                borderRadius="32px"
                border="1px solid #D6D6D7"
                padding="2px"
                position="relative"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                // width={"200px"}
              >
                <div
                  className="d d-flex align-items-center justify-content-center p-1"
                  style={{ margin: "0px auto" }}
                >
                  <Box
                    borderRadius="50%"
                    display="flex"
                    overflow="hidden"
                    width="50px"
                    height="50px"
                  >
                    <img
                      src={auth?.profile || User}
                      alt="User"
                      style={{
                        width: "50px",
                        height: "50px",
                        objectFit: "cover",
                      }}
                    />
                  </Box>
                </div>

                {/* Dropdoen start */}
                <Popover
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handlePopoverClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  PaperProps={{
                    sx: {
                      borderRadius: "10px",
                      width: "180px",
                      marginLeft: "-5px",
                      top: 0,
                      position: "fixed",
                    },
                  }}
                >
                  <List>
                    <ListItem
                      button
                      onClick={handleEditUserProfile}
                      sx={{
                        "&:hover": {
                          backgroundColor: "rgb(110, 196, 184,0.5)",
                        },
                      }}
                    >
                      <ListItemText primary="Profile" />
                    </ListItem>
                    {auth?.status == "0" ? (
                      <ListItem
                        button
                        onClick={handleEditProfile}
                        sx={{
                          "&:hover": {
                            backgroundColor: "rgb(110, 196, 184,0.5)",
                          },
                        }}
                      >
                        <ListItemText primary="Compnay Profile" />
                      </ListItem>
                    ) : (
                      ""
                    )}

                    <ListItem
                      button
                      onClick={handleLogout}
                      sx={{
                        "&:hover": {
                          backgroundColor: "rgb(110, 196, 184,0.5)",
                        },
                      }}
                    >
                      <ListItemText primary="Logout" />
                    </ListItem>
                  </List>
                </Popover>
              </Grid>
            </>
          ) : (
            <>
              <Grid
                direction="row"
                display="flex"
                alignItems="center"
                bgcolor="#FFFFFF"
                borderRadius="32px"
                border="1px solid #D6D6D7"
                padding="4px 4px 4px 19px"
                position="relative"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                width={"auto"}
                minWidth={"200px"}
              >
                <div
                  className="d d-flex align-items-center justify-content-center p-1"
                  style={{ margin: "0px auto" }}
                >
                  {/* User Personal Information */}
                  <Grid>
                    <Typography component="div">{auth?.name}</Typography>
                    <Typography
                      display="flex"
                      justifyContent="flex-end"
                      variant="body2"
                    >
                      {auth?.status == "0" ? "Recruiter" : "Hiring Manager"}
                    </Typography>
                  </Grid>
                  <Grid mx="15px"></Grid>
                  {/* User Image */}
                  <Grid>
                    <Box
                      borderRadius="50%"
                      display="flex"
                      overflow="hidden"
                      width="50px"
                      height="50px"
                    >
                      <img
                        src={auth?.profile || User}
                        alt="User"
                        style={{
                          width: "50px",
                          height: "50px",
                          objectFit: "cover",
                        }}
                      />
                    </Box>
                  </Grid>
                </div>

                {/* Dropdoen start */}
                <Popover
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handlePopoverClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  PaperProps={{
                    sx: {
                      borderRadius: "10px",
                      width: "180px",
                      marginLeft: "-5px",
                      top: 0,
                      position: "fixed",
                    },
                  }}
                >
                  <List>
                    <ListItem
                      button
                      onClick={handleEditUserProfile}
                      sx={{
                        "&:hover": {
                          backgroundColor: "rgb(110, 196, 184,0.5)",
                        },
                      }}
                    >
                      <ListItemText primary="Profile" />
                    </ListItem>
                    {auth?.status == "0" ? (
                      <ListItem
                        button
                        onClick={handleEditProfile}
                        sx={{
                          "&:hover": {
                            backgroundColor: "rgb(110, 196, 184,0.5)",
                          },
                        }}
                      >
                        <ListItemText primary="Compnay Profile" />
                      </ListItem>
                    ) : (
                      ""
                    )}

                    <ListItem
                      button
                      onClick={handleOpenConfirmationDialog}
                      sx={{
                        "&:hover": {
                          backgroundColor: "rgb(110, 196, 184,0.5)",
                        },
                      }}
                    >
                      <ListItemText primary="Logout" />
                    </ListItem>
                  </List>
                </Popover>
              </Grid>
            </>
          )}
        </Container>
      </Box>
      <ConfirmationDialog
        open={openDialog}
        onConfirm={handleLogout}
        onCancel={handleClose}
        title="Confirm Logout"
        message="Are you sure you want to log out?"
      />
    </>
  ) : (
    <span></span>
  );
};

export default Header;
