import React, { useState } from 'react';
import Label from '../Common/Label';
import LightBulg from '../../assets/images/Interview/lightBulb.svg';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { postRequest } from '../../api/apiServices';
import apiList from '../../api/apiList';

interface InterviewTipProps {
  handleNext: () => void;
  handleBack: () => void;
  campaignID: String;
  email: String;
}

const InterviewTip: React.FC<InterviewTipProps> = ({
  handleNext,
  handleBack,
  campaignID,
  email,
}) => {
  const navigate = useNavigate();

  const [text, setText] = useState('');

  const goToDashboard = async () => {
    if (text) {
      const response = await postRequest(apiList.sendInterViewTipsToCandidate, {
        email: email,
        content: text,
      });
    }
    navigate('/candidate-leads-dashboard', { state: { campaignID } });
  };

  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          backgroundColor: 'white',
          border: '1px solid #D6D6D7',
          borderRadius: '27px',
          padding: '45px 42px 40px 42px',
          width: '650px',
        }}
      >
        <ArrowBackIcon style={{ cursor: 'pointer' }} onClick={handleBack} />
        <div
          className="row d-flex align-items-center justify-content-center flex-column"
          style={{ gap: '33px' }}
        >
          <div
            className="d-flex align-items-center justify-content-center flex-column"
            style={{ gap: '24px' }}
          >
            <img src={LightBulg} className="img-fluid" alt="LightBulg" />
            <Label
              children="Interview Tips"
              fontSize="20.94px"
              fontWeight={700}
              color="#273848"
            />
          </div>
          <div
            className="d-flex align-items-center justify-content-center flex-column"
            style={{ gap: '30px' }}
          >
            <textarea
              className="form-control"
              placeholder="Type here..."
              id="floatingTextarea2"
              style={{ height: '177px' }}
              name="text"
              value={text}
              onChange={(e) => setText(e.target.value)}
            ></textarea>
            <button
              type="submit"
              className="user-scale"
              // variant="contained"
              // fullWidth
              style={{
                backgroundColor: '#84E1CC',
                boxShadow: 'none',
                borderRadius: '5px',
                padding: '20px 17px',
                lineHeight: '0px',
                letterSpacing: '0px',
                textTransform: 'none',
                color: 'white',
                border: 'none',
              }}
              // onClick={handleNext}
              onClick={goToDashboard}
            >
              Call To Action
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterviewTip;

{
  /* <button onClick={handleBack}>Back</button>
      <button onClick={handleNext}>Next</button> */
}
