import React, { useCallback, useEffect, useState } from 'react';
import Label from '../Common/Label';
import { useLocation, useNavigate } from 'react-router-dom';
import { CandidateLeadsDashboardView } from '../../utils/CandidateDashboard';
import { postRequest } from '../../api/apiServices';
import {
  getCandidateFeedback,
  getCandidateLeads,
  giveCandidateFeedback,
  updateCandidateStatus,
} from '../../api/apiList';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Toast from '../../utils/Toast';
import Loader from '../../utils/Loading';

interface FeedbackProps {
  handleNext?: () => void;
  handleBack?: () => void;
}

// Define Schema
const schema = yup.object().shape({
  summary: yup.string().required('Summary is required'),
  teamFit: yup.string().required('Personal / team fit is required'),
  skills: yup.string().required('Skills are required'),
  positives: yup.string().required('Positives are required'),
  status: yup.string().required('Status is required'),
  improvement: yup.string().required('Areas for improvement are required'),
});

const Feedback: React.FC<FeedbackProps> = ({ handleNext, handleBack }) => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [readOnly, setReadOnly] = useState(false);

  const [data, setData] = useState<CandidateLeadsDashboardView>();
  const [feedbackData, setFeedbackData] = useState({
    id: null,
    candidateLeadsId: '',
    summary: '',
    teamFit: '',
    skills: '',
    positives: '',
    improvement: '',
  });
  const { id, mode, status } = location.state as {
    id: number;
    mode: 'create' | 'view';
    status: string;
  };
  const navigate = useNavigate();
  const candidateStatus = status || '';

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      summary: '',
      teamFit: '',
      skills: '',
      positives: '',
      status: status || '',
      improvement: '',
    },
  });

  //Fetching User information
  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await postRequest(getCandidateLeads, { id });
      if (response) setData(response.data);
    } finally {
      setLoading(false);
    }
  }, [id]);

  //Fetching Feedback Details
  const fetchFeedbackDetails = useCallback(async () => {
    setLoading(true);
    const response = await postRequest(getCandidateFeedback, {
      candidateLeadsId: id,
    });
    if (response) {
      const feedback = response?.data?.data;
      setFeedbackData(feedback);
      setReadOnly(true);

      // Set default values in the form
      reset({
        summary: feedback.summary || '',
        teamFit: feedback.teamFit || '',
        skills: feedback.skills || '',
        positives: feedback.positives || '',
        status: feedback.status || status || '',
        improvement: feedback.improvement || '',
      });
    }
    setLoading(false);
  }, [id, mode]);

  //Trigger on form submit
  const onSubmit = async (formData: any) => {
    setLoading(true);
    const payload = { candidateLeadsId: id, ...formData };

    try {
      const feedbackResponse = await postRequest(
        giveCandidateFeedback,
        payload
      );
      const statusResponse = await postRequest(updateCandidateStatus, {
        id,
        status: formData.status,
      });

      if (feedbackResponse && statusResponse) {
        Toast(
          statusResponse?.data?.statusMessage ||
          'Candidate updated successfully'
        );
        reset();
        navigate('/candidate-leads-dashboard/view', { state: { id } });
      }
    } catch (error) {
      Toast('Failed to submit feedback');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    if (mode === 'view') fetchFeedbackDetails();
  }, [fetchData, fetchFeedbackDetails, mode]);

  return loading ? (
    <Loader />
  ) : (
    <>
      <div
        style={{
          paddingTop: '32px',
        }}
      >
        <div className="container" style={{ paddingBottom: '34px' }}>
          <div className="row g-4">
            <div className="col-md-4">
              <div
                style={{
                  backgroundColor: 'white',
                  border: '1px solid #D6D6D7',
                  borderRadius: '27px',
                  padding: '27px',
                  height: 'auto',
                }}
              >
                <div
                  className="d-flex align-items-center justify-content-start"
                  style={{ gap: '27px' }}
                >
                  {/* <img src="https://randomuser.me/api/portraits/women/66.jpg" className="img-fluid" style={{ height: '86px', width: '86px', borderRadius: '50%', objectFit: 'cover' }} alt="USERIMAGE" /> */}
                  <div>
                    <Label
                      children={data?.data?.name}
                      fontWeight={700}
                      fontSize={'20.48px'}
                      color={'#273848'}
                    />
                    <Label
                      children={data?.data?.jobTitle}
                      fontWeight={500}
                      fontSize={'16.85px'}
                      color={'#273848'}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div
                style={{
                  backgroundColor: 'white',
                  border: '1px solid #D6D6D7',
                  borderRadius: '27px',
                  padding: '27px 27px 27px 27px',
                  height: '100%',
                }}
              >
                <div className="row">
                  <div className="col-12">
                    <Label
                      children="Give a Feedback"
                      fontWeight={700}
                      fontSize="20.94px"
                      color="#273848"
                    />
                    <Label
                      children="Lorem ipsum dolor sit amet consectetur. Tortor odio."
                      fontWeight={500}
                      fontSize="12.94px"
                      color="#273848"
                    />
                  </div>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div
                      className="row d-flex align-items-start justify-content-center flex-column"
                      style={{ marginTop: '23px', gap: '26px' }}
                    >
                      {/* Short Summary */}
                      <div className="d-flex align-items-start justify-content-center flex-column gap-1">
                        <Label
                          children="Short summary"
                          fontWeight={500}
                          fontSize="14px"
                          color="#4e4e4e"
                        />
                        <textarea
                          {...register('summary')}
                          className="form-control"
                          placeholder="Type here..."
                          readOnly={readOnly}
                          style={{
                            height: '142px',
                            border: '1px solid #d9d9d9',
                          }}
                        ></textarea>
                        {errors.summary && (
                          <span style={{ fontSize: '12px', color: 'red' }}>
                            {errors.summary.message}
                          </span>
                        )}
                      </div>

                      {/* Personal / Team Fit */}
                      <div className="d-flex align-items-start justify-content-center flex-column gap-1">
                        <Label
                          children="Personal / team fit"
                          fontWeight={500}
                          fontSize="14px"
                          color="#4e4e4e"
                        />
                        <input
                          type="text"
                          {...register('teamFit')}
                          className="form-control"
                          readOnly={readOnly}
                          placeholder="Personal / team fit"
                          style={{ border: '1px solid #d9d9d9' }}
                        />
                        {errors.teamFit && (
                          <span style={{ fontSize: '12px', color: 'red' }}>
                            {errors.teamFit.message}
                          </span>
                        )}
                      </div>

                      {/* Skills  */}
                      <div className="d-flex align-items-start justify-content-center flex-column gap-1">
                        <Label
                          children="Skills"
                          fontWeight={500}
                          fontSize="14px"
                          color="#4e4e4e"
                        />
                        <input
                          type="text"
                          {...register('skills')}
                          className="form-control"
                          readOnly={readOnly}
                          placeholder="Skills"
                          style={{ border: '1px solid #d9d9d9' }}
                        />
                        {errors.skills && (
                          <span style={{ fontSize: '12px', color: 'red' }}>
                            {errors.skills.message}
                          </span>
                        )}
                      </div>

                      {/* Positives  */}
                      <div className="d-flex align-items-start justify-content-center flex-column gap-1">
                        <Label
                          children="Positives"
                          fontWeight={500}
                          fontSize="14px"
                          color="#4e4e4e"
                        />
                        <input
                          type="text"
                          {...register('positives')}
                          className="form-control"
                          readOnly={readOnly}
                          placeholder="Positives"
                          style={{ border: '1px solid #d9d9d9' }}
                        />
                        {errors.positives && (
                          <span style={{ fontSize: '12px', color: 'red' }}>
                            {errors.positives.message}
                          </span>
                        )}
                      </div>

                      {/* Status */}
                      <div className="d-flex align-items-start justify-content-center flex-column gap-1">
                        <Label
                          children="Status"
                          fontWeight={500}
                          fontSize="14px"
                          color="#4e4e4e"
                        />
                        <select
                          {...register('status')}
                          className="form-control"
                          id="status"
                          style={{ cursor: 'pointer' }}
                        >
                          <option className="form-control" value="" disabled>
                            Please Select Status
                          </option>
                          <option className="form-control" value="CONTACTED">
                            Connected
                          </option>
                          <option
                            className="form-control"
                            value="WAITING_ON_REPLY"
                          >
                            Waiting For Replay
                          </option>
                        </select>
                      </div>

                      {/* Areas for improvement  */}
                      <div className="d-flex align-items-start justify-content-center flex-column gap-1">
                        <Label
                          children="Areas for improvement"
                          fontWeight={500}
                          fontSize="14px"
                          color="#4e4e4e"
                        />
                        <textarea
                          {...register('improvement')}
                          className="form-control"
                          readOnly={readOnly}
                          placeholder="Type here..."
                          style={{
                            height: '142px',
                            border: '1px solid #d9d9d9',
                          }}
                        ></textarea>
                        {errors.improvement && (
                          <span style={{ fontSize: '12px', color: 'red' }}>
                            {errors.improvement.message}
                          </span>
                        )}
                      </div>

                      {/* Submit Button */}
                      <div
                        className="d-flex align-items-end justify-content-end"
                        style={{ gap: '10px' }}
                      >
                        <button
                          type="submit"
                          className="user-scale"
                          style={{
                            borderRadius: '5px',
                            backgroundColor: '#84e1cc',
                            border: 'none',
                            padding: '7px 30px',
                            color: 'white',
                            fontWeight: 500,
                            fontSize: '17.94px',
                          }}
                        >
                          Call To Action
                        </button>

                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Feedback;
