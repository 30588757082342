import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { useMediaQuery } from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { postRequest } from "../../api/apiServices";
import { updateCandidateDescription } from "../../api/apiList";
import Toast from "../../utils/Toast";
import Loader from "../../utils/Loading";

interface NotNowPopupProps {
    open: boolean;
    userID: number;
    onClose: () => void;
}

const NotNowPopup: React.FC<NotNowPopupProps> = ({ open, userID, onClose }) => {
    const [loading, setLoading] = useState(false);
    const isMobile = useMediaQuery("(max-width: 600px)");

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: isMobile ? 350 : 700,
        bgcolor: "background.paper",
        boxShadow: 24,
        padding: isMobile ? "20px" : "40px 20px",
        borderRadius: "10px",
        outline: "none"
    };

    const buttonStyles = {
        fontWeight: 500,
        fontSize: "17.94px",
        backgroundColor: "#84E1CC",
        color: "white",
        padding: isMobile ? "7px 20px" : "7px 60px",
        borderRadius: "5px",
        border: "none",
    };

    const {
        values,
        handleChange,
        handleBlur,
        touched,
        errors,
        handleSubmit,
    } = useFormik({
        initialValues: { description: "" },
        validationSchema: Yup.object({
            description: Yup.string().required("Description is required."),
        }),
        onSubmit: async (value) => {
            setLoading(true);
            const response = await postRequest(updateCandidateDescription, {
                id: userID,
                notNowInfo: value.description,
            });
            Toast(response?.data?.statusMessage || "Description Updated Successfully");
            setLoading(false);
            onClose();
        },
    });

    if (loading) return <Loader />;

    return (
        <Modal open={open} onClose={onClose} aria-labelledby="not-now-popup-title">
            <Box sx={style}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-end">
                            <CloseIcon style={{ cursor: "pointer" }} onClick={onClose} />
                        </div>
                        <form onSubmit={handleSubmit}>
                            <label htmlFor="description" className="form-label text-dark h4">
                                Description:
                            </label>
                            <textarea
                                id="description"
                                name="description"
                                rows={isMobile ? 5 : 15}
                                className={`form-control ${touched.description && errors.description ? "is-invalid" : ""}`}
                                value={values.description}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="- When can we come back to you? 
                                - Under what circumstances will you be interested? 
                                - Can we save your info?"
                            />
                            {touched.description && errors.description && (
                                <div className="text-danger">{errors.description}</div>
                            )}
                            <div className="mt-3 float-end">
                                <button type="submit" className="user" style={buttonStyles}>
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </Box>
        </Modal>
    );
};

export default NotNowPopup;
