import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { useMediaQuery } from "@mui/material";
import "../../styles/common.css";

interface ChartDescriptionsProps {
  open: boolean;
  description: string;
  onClose: () => void;
}

const ChartDescriptions: React.FC<ChartDescriptionsProps> = ({
  open,
  description,
  onClose,
}) => {
  const isMobile = useMediaQuery("(max-width: 600px)");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? 350 : 700,
    bgcolor: "background.paper",
    boxShadow: 24,
    padding: isMobile ? "0px 0px 10px 0px" : "0px 0px 10px 0px",
    borderRadius: "10px 10px 10px 10px",
    outline: "none",
    zIndex: 10,
  };



  return (
    <Modal open={open} onClose={onClose} aria-labelledby="not-now-popup-title">
      <Box sx={style}>
        <div className="row-no-gutters" style={{ padding: 0 }}>
          <div className="header-popup">
            <div className="d-flex justify-content-between align-items-center p-0">
              <div className="d-flex justify-content-start align-items-center">
                <label htmlFor="description" className="h4 p-0 m-0">
                  Job description
                </label>
              </div>
              <div className="d-flex justify-content-end align-items-center">
                <CloseIcon style={{ cursor: "pointer" }} onClick={onClose} />
              </div>
            </div>
          </div>
          <label className="p-4">{description}</label>
        </div>
      </Box>
    </Modal>
  );
};

export default ChartDescriptions;
