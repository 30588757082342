import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Container,
  useMediaQuery,
  Box,
  Button,
  TextField,
  OutlinedInput,
  InputAdornment,
  Link,
  useTheme,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import "../../styles/inputBox.css";
import AiIcon from "../../assets/images/AiIconDark.svg";
import uploadFileIcon from "../../assets/images/uploadFileIcon.svg";
import { useNavigate, useParams } from "react-router-dom";
import { multiPartPUTRequest, multiPartRequest } from "../../api/apiServices";
import { createContent, updateContent } from "../../api/apiList";
import Toast from "../../utils/Toast";
import { getAIImages } from "../../utils/AIGenerateImage";
import CloseIcon from "@mui/icons-material/Close";
import Loader from "../Loader";
import dayjs, { Dayjs } from "dayjs";
import { generateText } from "../../utils/AIGenerateText";
import { getMediaType } from "../../utils/helper";
import {
  LocalizationProvider,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import PostingLoader from "../../utils/PostingLoader";

// Format Define
const Format = {
  X_TEXT: "X_Text",
  X_IMAGE: "X_Image",
  X_VIDEO: "X_Video",
  X_IMAGE_AD: "X_Image_Ads",
  X_VIDEO_AD: "X_Video_Ads",
  X_CAROUSEL_AD: "X_Carousel_Ads",
};

// Defeine Format validation
const sizeLimits = {
  [Format.X_TEXT]: {
    types: ["text"],
    maxWidth: 1080,
    maxHeight: 1080,
  },
  [Format.X_IMAGE]: {
    types: ["image"],
    maxWidth: 800,
    maxHeight: 418,
  },
  [Format.X_VIDEO]: {
    types: ["video"],
    maxWidth: 1200,
    maxHeight: 1200,
  },
  [Format.X_IMAGE_AD]: {
    types: ["image"],
    maxWidth: 800,
    maxHeight: 418,
  },
  [Format.X_VIDEO_AD]: {
    types: ["video"],
    maxWidth: 1200,
    maxHeight: 1200,
  },
  [Format.X_CAROUSEL_AD]: {
    types: ["video", "image"],
    maxWidth: 800,
    maxHeight: 450,
  },
};

const TwitterPost: React.FC<{
  selectedFormat: string;
  socialData: any;
  mode: boolean;
}> = ({ selectedFormat, socialData, mode }) => {
  const isMobile = useMediaQuery("(max-width:578px)");
  const isTabs = useMediaQuery("(max-width:1200px)");
  const theme = useTheme();
  const params = useParams();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [format, setFormat] = useState<string>(selectedFormat);
  const [profile, setProfile] = useState<File | string | null>(null);
  const [postText, setPostText] = useState("");
  const [aiImages, setAIImages] = useState<string[]>([]);
  const [prompt, setPrompt] = useState("");
  const [aiDialogOpen, setAIDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("postNow");
  const [scheduledDateTime, setScheduledDateTime] = useState<Dayjs | null>(
    null
  );
  const [aiLoading, setAiLoading] = useState(false);

  const navigate = useNavigate();

  // Validation of Form
  const [errors, setErrors] = useState({
    format: "",
    profile: "",
    postText: "",
  });

  // Define Validation function
  const formIsValid = () => {
    const newErrors = {
      format: !format?.trim() ? "Please Select Format is required" : "",
      profile:
        format === Format.X_TEXT
          ? ""
          : !profile
          ? "Please Upload Format Regarding File."
          : "",
      postText: !postText ? "Post Text Is Required" : "",
    };
    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };

  useEffect(() => {
    setFormat(selectedFormat);
    setProfile(socialData?.content?.photo);
    setPostText(socialData?.content?.postText);
    setScheduledDateTime(dayjs(socialData?.content?.scheduleDateAndTime));
  }, [selectedFormat]);

  const handleGridClick = () => document.getElementById("file-upload")?.click();

  // Function to handle opening confirmation dialog
  const handleOpenConfirmation = () => setConfirmationOpen(true);

  // Function to handle closing confirmation dialog
  const handleCloseConfirmation = () => setConfirmationOpen(false);

  // select radiobutton
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSelectedOption(event.target.value);

  // Function to handle scheduling post
  const handleSchedulePost = async () => {
    if (!scheduledDateTime || !scheduledDateTime.isAfter(dayjs())) {
      Toast("Please select a future date and time.", "error");
      return;
    }
    handleCloseConfirmation();
    let formattedDate;
    if (scheduledDateTime) {
      formattedDate = dayjs(scheduledDateTime).format("YYYY-MM-DD HH:mm:ss");
    }
    const data = {
      name: "Twitter",
      selectFormat: format,
      photo: profile,
      postText,
      scheduleDateAndTime: formattedDate,
      isSchedule: true,
    };
    if (mode) {
      setSubmitLoading(true);
      const response = await multiPartPUTRequest(
        updateContent(params?.id),
        data
      );
      if (response) {
        Toast(response?.data?.statusMessage || "Content Created Successfully");
        navigate("/posting-publishing");
      }
      setSubmitLoading(false);
    } else {
      setSubmitLoading(true);
      const response = await multiPartRequest(createContent, data);
      if (response) {
        Toast(response?.data?.statusMessage || "Content Update Successfully");
        navigate("/posting-publishing");
      }
      setSubmitLoading(false);
    }
  };

  // Function to handle immediate post
  const handleImmediatePost = async () => {
    handleCloseConfirmation();
    const data = {
      name: "Twitter",
      selectFormat: format,
      photo: profile,
      postText,
    };
    setSubmitLoading(true);
    // multiPartRequest(createContent, data)
    //   .then((response) => {})
    //   .catch((error) => {})
    //   .finally(() => {
    //     setSubmitLoading(false);
    //   });
    // setTimeout(() => {
    //   setSubmitLoading(false);
    //   navigate('/posting-publishing');
    // }, 10000);
    const response = await multiPartRequest(createContent, data);
    if (response) {
      Toast(response?.data?.statusMessage || "Content Created Successfully");
      navigate("/posting-publishing");
    }
    setSubmitLoading(false);
  };

  // Submit Function And Call The API
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (formIsValid()) {
      handleOpenConfirmation();
    }
  };

  // Upload Image Function
  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      // call the validation function
      const isValid = await imageAndTypeValidation(file, selectedFormat);
      if (isValid) {
        setProfile(file);
      } else {
        Toast("Invalid media type or size for the selected format.", "error");
        event.target.value = "";
      }
    }
  };

  // Define The Size and Type Validation
  const imageAndTypeValidation = (file: File, fromat: string) => {
    const formatRules = sizeLimits[fromat];
    if (!formatRules) return false;

    if (formatRules.types) {
      if (!formatRules.types.some((type) => file?.type.startsWith(type))) {
        return false;
      }
    }

    if (file?.type.startsWith("image")) {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      return new Promise<boolean>((resolve) => {
        img.onload = () => {
          if (
            img.width > formatRules.maxWidth ||
            img.height > formatRules.maxHeight
          ) {
            resolve(false);
          } else {
            resolve(true);
          }
        };
      });
    }

    if (file?.type.startsWith("video")) {
      const video = document.createElement("video");
      video.preload = "metadata";
      return new Promise<boolean>((resolve) => {
        video.onloadedmetadata = () => {
          if (
            video.videoWidth > formatRules.maxWidth ||
            video.videoHeight > formatRules.maxHeight
          ) {
            resolve(false);
          } else {
            resolve(true);
          }
        };
        video.src = URL.createObjectURL(file);
      });
    }
    return false;
  };

  const handleClearImage = () => setProfile(null);

  // Open Dialog
  const handleGenerateImageClick = () => setAIDialogOpen(true);

  const handlePromptChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setPrompt(event.target.value);
  };

  // Genrating Image With AI
  const handleGenerateImages = async () => {
    try {
      setAIImages([]);
      setAIDialogOpen(false);
      setLoading(true);
      const generatedImages = await getAIImages(prompt);
      setAIImages(generatedImages);
    } catch (error) {
      console.error("Error generating images:", error);
    } finally {
      setLoading(false);
      setAIDialogOpen(false);
    }
  };

  // Select Of Genration AI Image
  const handleSelectAIImage = (image: string) => setProfile(image);

  // genrate Text With AI
  const generateTextWithAI = async () => {
    setAiLoading(true);
    const data = await generateText(postText);
    setPostText(data);
    setAiLoading(false);
  };

  return (
    <div>
      <Grid mt="28px"></Grid>
      <Container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: isTabs ? "0px 0px 0px 0px" : " 0px 16px 0px 16px",
        }}
      >
        <Grid
          spacing={2}
          bgcolor="#FFFFFF"
          width="884px"
          borderRadius="22px"
          border="1px solid #D6D6D7"
          margin={0}
        >
          <Grid
            item
            height={isMobile ? "auto" : "auto"}
            style={{ padding: 30 }}
          >
            <Grid
              container
              direction="row"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              gap={isMobile ? "10px" : ""}
            >
              <Grid item width="fit-content">
                <Typography color="#273848" fontWeight={700} fontSize="20.94px">
                  X Post
                </Typography>
                <Typography color="#273848" fontWeight={500} fontSize="12.94px">
                  Lorem ipsum dolor sit amet consectetur. Tortor odio.
                </Typography>
              </Grid>
            </Grid>
            <Grid my={4}></Grid>

            <Grid item>
              <Typography className="input-label-custom">Pos Text</Typography>
              <Grid mb={1}></Grid>

              <OutlinedInput
                placeholder="Type here..."
                multiline
                minRows={4}
                style={{ display: "inline-block", padding: "25px 11px" }}
                className="text-area-size"
                value={postText}
                onChange={(e) => setPostText(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="flex-end"
                      width="100%"
                      marginBottom="2%"
                      onClick={generateTextWithAI}
                    >
                      <Grid
                        className="user-scale"
                        item
                        bgcolor="transparent"
                        padding="5px 22px 5px 16px"
                        width="fit-content"
                        borderRadius="6px"
                        border="1px solid #273848"
                        sx={{ cursor: "pointer" }}
                      >
                        <Grid
                          container
                          direction="row"
                          display="flex"
                          alignItems="center"
                          gap="7px"
                        >
                          <img src={AiIcon} alt="instagram-icon" />
                          <Typography
                            fontWeight={500}
                            fontSize="12.55px"
                            color="#273848"
                          >
                            Generate Vacancy Text with AI
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </InputAdornment>
                }
              />
              <InputLabel
                style={{
                  color: "#f44336",
                  paddingTop: "3px",
                  fontSize: "12px",
                  paddingLeft: "10px",
                }}
              >
                {errors.postText}
              </InputLabel>
            </Grid>
            <Grid my={2}></Grid>
            {format !== Format.X_TEXT && (
              <>
                <Grid item>
                  <Typography className="input-label-custom">Image</Typography>
                  <Grid mb={1}></Grid>
                  <Grid
                    item
                    border="1px solid #D5D5D5"
                    direction="column"
                    justifyContent="space-between"
                    alignItems="center"
                    display="flex"
                    borderRadius="20px"
                    // padding={isMobile ? "30px" : "18px 230px 48px 230px"}
                    onClick={handleGridClick}
                    style={{
                      cursor: "pointer",
                      position: "relative",
                      width: "100%",
                    }}
                    width="100%"
                  >
                    {profile ? (
                      <>
                        {typeof profile === "string" ? (
                          <>
                            {getMediaType(profile) === "video" ? (
                              <video
                                controls
                                src={profile}
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "100%",
                                  height: "200px",
                                  width: "150px",
                                  objectFit: "contain",
                                }}
                              />
                            ) : (
                              <img
                                src={profile}
                                alt="selected-image"
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "100%",
                                  height: "200px",
                                  width: "150px",
                                  objectFit: "contain",
                                }}
                              />
                            )}
                            <CloseIcon
                              onClick={handleClearImage}
                              style={{
                                position: "absolute",
                                top: "8px",
                                right: "8px",
                                cursor: "pointer",
                                color: "#4E4E4E",
                              }}
                            />
                          </>
                        ) : (
                          <>
                            {profile.type.startsWith("image") ? (
                              <img
                                src={URL.createObjectURL(profile)}
                                alt="selected-image"
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "100%",
                                  height: "200px",
                                  width: "150px",
                                  objectFit: "contain",
                                }}
                              />
                            ) : profile.type.startsWith("video") ? (
                              <video
                                controls
                                src={URL.createObjectURL(profile)}
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "100%",
                                  height: "200px",
                                  width: "150px",
                                  objectFit: "contain",
                                }}
                              />
                            ) : null}
                            <CloseIcon
                              onClick={handleClearImage}
                              style={{
                                position: "absolute",
                                top: "8px",
                                right: "8px",
                                cursor: "pointer",
                                color: "#4E4E4E",
                              }}
                            />
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <img
                          src={uploadFileIcon}
                          alt="uploadFile-Icon"
                          style={{ marginTop: "2%" }}
                        />
                        <Grid my={1}></Grid>
                        <Typography align="center" color="#4E4E4E">
                          Drag a file or{" "}
                          <Link
                            // href="/signin"
                            color="#4E4E4E"
                            style={{ textDecorationColor: "#4E4E4E" }}
                          >
                            select
                          </Link>
                        </Typography>
                      </>
                    )}
                    <input
                      id="file-upload"
                      type="file"
                      style={{ display: "none" }}
                      accept="image/*, video/*"
                      onChange={handleImageChange}
                    />
                  </Grid>
                  <InputLabel
                    style={{
                      color: "#f44336",
                      paddingTop: "3px",
                      fontSize: "12px",
                      paddingLeft: "10px",
                    }}
                  >
                    {errors.profile}
                  </InputLabel>
                  <>
                    <Grid my={1}></Grid>
                    <Grid
                      className="user-scale"
                      item
                      bgcolor="transparent"
                      padding="9px 22px 9px 16px"
                      width="fit-content"
                      borderRadius="6px"
                      border="1px solid #273848"
                      marginBottom="2%"
                      marginTop={"13px"}
                      marginLeft={"5px"}
                      sx={{ cursor: "pointer" }}
                    >
                      <Grid
                        container
                        direction="row"
                        display="flex"
                        alignItems="center"
                        gap="7px"
                        onClick={handleGenerateImageClick}
                      >
                        <img src={AiIcon} alt="instagram-icon" />
                        <Typography
                          fontWeight={500}
                          fontSize="12.55px"
                          color="#273848"
                        >
                          Generate Image with AI
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                  <Dialog
                    open={aiDialogOpen}
                    onClose={() => setAIDialogOpen(false)}
                    fullWidth
                    maxWidth="md"
                  >
                    <DialogTitle>Generate Image with AI</DialogTitle>
                    <DialogContent>
                      <OutlinedInput
                        placeholder="Type here..."
                        multiline
                        minRows={4}
                        className="text-area-size"
                        value={prompt}
                        onChange={handlePromptChange}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() => setAIDialogOpen(false)}
                        color="primary"
                      >
                        Cancel
                      </Button>
                      <Button onClick={handleGenerateImages} color="primary">
                        Generate
                      </Button>
                    </DialogActions>
                  </Dialog>

                  {loading && (
                    <Grid container justifyContent="center">
                      <CircularProgress style={{ marginTop: "20px" }} />
                    </Grid>
                  )}

                  {aiImages.length > 0 && (
                    <Grid container spacing={2}>
                      {aiImages.map((image, index) => (
                        <Grid
                          item
                          key={index}
                          xs={6}
                          sm={3}
                          onClick={() => handleSelectAIImage(image)}
                        >
                          <img
                            src={image}
                            alt={`AI-generated-${index}`}
                            style={{
                              marginTop: "15px",
                              width: "100%",
                              cursor: "pointer",
                              border: "1px solid #CCCCCC",
                              borderRadius: "7px",
                            }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </Grid>
                <Grid my={2}></Grid>
                <Grid item width="fit-content">
                  <Typography color="#4E4E4E" fontWeight={600} fontSize="16px">
                    Additional Informations
                  </Typography>
                  <Grid container direction="column" gap={1} mt={2}>
                    <Typography
                      color="#4E4E4E"
                      fontWeight={500}
                      fontSize="16px"
                    >
                      <strong>Image:</strong>
                    </Typography>
                    <Typography
                      color="#4E4E4E"
                      fontWeight={500}
                      fontSize="15px"
                    >
                      - Supports the following file extensions:
                      <strong> .jpg, .png, .gif, .bmp, .tiff</strong>
                    </Typography>

                    <Typography
                      color="#4E4E4E"
                      fontWeight={500}
                      fontSize="16px"
                      mt={2}
                    >
                      <strong>Video:</strong>
                    </Typography>
                    <Typography
                      color="#4E4E4E"
                      fontWeight={500}
                      fontSize="15px"
                    >
                      - Supports the following file extensions:{" "}
                      <strong>.mp4, .mov, .avi, .mkv, .webm</strong>
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
            <Grid my={4}></Grid>
            <Grid
              container
              direction="row"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid xs={2}></Grid>
              <Grid item style={{ maxWidth: "fitContent" }}>
                <button
                  type="submit"
                  className="user-scale"
                  // variant="contained"
                  // fullWidth
                  style={{
                    backgroundColor: "#84E1CC",
                    boxShadow: "none",
                    borderRadius: "5px",
                    padding: "17px 17px",
                    lineHeight: "0px",
                    textTransform: "none",
                    letterSpacing: "0px",
                    width: "100%",
                    border: "none",
                    color: "white",
                  }}
                  onClick={(event) => handleSubmit(event)}
                >
                  {submitLoading ? <Loader /> : "Generate Proposals"}
                </button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Dialog
          open={confirmationOpen}
          onClose={handleCloseConfirmation}
          fullWidth
          style={{
            borderRadius: "22px",
            border: "1px solid #D6D6D7",
          }}
        >
          <DialogTitle>Confirm and Schedule Post</DialogTitle>
          <DialogContent>
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="post-options"
                name="post-options"
                value={selectedOption}
                onChange={handleRadioChange}
              >
                {mode ? (
                  <FormControlLabel
                    value="schedulePost"
                    control={<Radio />}
                    label="Schedule Post"
                  />
                ) : (
                  <>
                    <FormControlLabel
                      value="postNow"
                      control={<Radio />}
                      label="Post Now"
                    />
                    <FormControlLabel
                      value="schedulePost"
                      control={<Radio />}
                      label="Schedule Post"
                    />
                  </>
                )}
              </RadioGroup>
            </FormControl>

            {selectedOption === "schedulePost" && (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem label="Select Date and Time:">
                  <MobileDateTimePicker
                    value={scheduledDateTime}
                    onChange={(date) => setScheduledDateTime(dayjs(date))}
                  />
                </DemoItem>
              </LocalizationProvider>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseConfirmation}
              style={{
                color: "#244564",
                textTransform: "none",
                letterSpacing: "0px",
              }}
            >
              Cancel
            </Button>
            {selectedOption === "schedulePost" ? (
              <Button
                type="submit"
                variant="contained"
                style={{
                  backgroundColor: "#84E1CC",
                  boxShadow: "none",
                  borderRadius: "5px",
                  padding: "17px 17px",
                  lineHeight: "0px",
                  textTransform: "none",
                  letterSpacing: "0px",
                }}
                onClick={handleSchedulePost}
              >
                {submitLoading ? "Posting..." : "Schedule Post"}
              </Button>
            ) : (
              <Button
                type="submit"
                variant="contained"
                style={{
                  backgroundColor: "#84E1CC",
                  boxShadow: "none",
                  borderRadius: "5px",
                  padding: "17px 17px",
                  lineHeight: "0px",
                  textTransform: "none",
                  letterSpacing: "0px",
                }}
                onClick={handleImmediatePost}
              >
                {submitLoading ? "Posting..." : "Post Now"}
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </Container>
      <PostingLoader
        loading={submitLoading}
        text={
          selectedOption === "schedulePost"
            ? "Wait... we are schedule your content...!"
            : "Wait... we are publishing your content...!"
        }
      />
      <Grid mt="28px"></Grid>
    </div>
  );
};

export default TwitterPost;
