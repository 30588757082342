import { useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import Loader from "../../utils/Loading";
import Logo from '../../assets/images/LogoName.png';
import { useFormik } from "formik";
import { ValidationSchema } from "../../utils/CompaignFormValidationSchema";
import Toast from "../../utils/Toast";
import { getRequest, postRequest, putRequest } from "../../api/apiServices";
import { createCompaignForm, getCompaignForm, updateCompaignForm } from "../../api/apiList";
import { useNavigate, useParams } from "react-router-dom";

interface FormInput {
    type: string;
    name: string;
    id: string;
}

const CompaignForm: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');
    const { id } = useParams();
    const navigate = useNavigate();

    const [initialData, setInitialData] = useState<FormInput>({
        type: '',
        name: '',
        id: ''
    });

    // Fetch the campaign data when editing
    const fetchCampaignData = async () => {
        if (id) {
            try {
                const response = await getRequest(getCompaignForm(id));
                if (response?.data?.data) {
                    setInitialData({
                        type: response.data.data.campaignType,
                        name: response.data.data.name,
                        id: response.data.data.campaignId
                    });
                }
            } catch (error) {
                Toast("Failed to fetch campaign data.");
            }
        }
    };

    // Formik setup for form handling
    const formik = useFormik<FormInput>({
        initialValues: initialData,
        enableReinitialize: true,
        validationSchema: ValidationSchema,
        onSubmit: async (values) => {
            setLoading(true);
            try {
                const payload = { name: values.name, campaignId: values.id, campaignType: values.type };
                const response = id ? await putRequest(updateCompaignForm(id), payload) : await postRequest(createCompaignForm, payload);

                if (response) {
                    Toast(response?.data?.statusMessage || (id ? 'Campaign Updated Successfully' : 'Campaign Created Successfully'));
                    if (id) fetchCampaignData(); 
                    else formik.resetForm();
                }
                navigate(-1)
            } catch (error) {
                Toast("Failed to submit form.");
            } finally {
                setLoading(false);
            }
        }
    });

    useEffect(() => {
        fetchCampaignData();
    }, [id]);

    return (
        <>
            <div className="d d-flex align-items-center justify-content-center" style={{ height: '100%' }}>
                <div className="container" style={{
                    borderRadius: '27px',
                    border: '1px solid #D6D6D7',
                    padding: isMobile ? '10px' : '20px 40px',
                    maxWidth: isMobile ? '350px' : '600px',
                    height: isMobile ? '630px' : '',
                    backgroundColor: 'white'
                }}>

                    <div className="d-flex align-items-center justify-content-center">
                        <img className="img-fluid" style={{ height: '150px', width: '100%', objectFit: 'contain' }} src={Logo} alt="Logo" />
                    </div>

                    <h2 className="text-center mb-4" style={{
                        color: '#6EC4B8',
                        fontWeight: 800
                    }}>Campaign Form</h2>

                    <form onSubmit={formik.handleSubmit}>
                        {/* Campaign Type */}
                        <div className="col mb-3">
                            <label htmlFor="type" className="form-label">Campaign Type</label>
                            <select
                                name="type"
                                className="p-2 rounded-3 form-control"
                                id="type"
                                onChange={formik.handleChange}
                                value={formik.values.type}
                            >
                                <option value="" disabled>Select campaign type</option>
                                {['Instagram', 'Facebook', 'Linkedin', 'Twitter', 'Google'].map((platform) => (
                                    <option key={platform} value={platform}>{platform.toUpperCase()}</option>
                                ))}
                            </select>
                            {formik.touched.type && formik.errors.type && (
                                <p className="m-0 text-danger">{formik.errors.type}</p>
                            )}
                        </div>

                        {/* Campaign Name */}
                        <div className="col mb-3">
                            <label htmlFor="name" className="form-label">Campaign Name</label>
                            <input
                                type="text"
                                className="p-2 rounded-3 form-control"
                                id="name"
                                name="name"
                                onChange={formik.handleChange}
                                value={formik.values.name}
                            />
                            {formik.touched.name && formik.errors.name && (
                                <p className="m-0 text-danger">{formik.errors.name}</p>
                            )}
                        </div>

                        {/* Campaign ID */}
                        <div className="col mb-3">
                            <label htmlFor="id" className="form-label">Campaign ID</label>
                            <input
                                type="text"
                                className="p-2 rounded-3 form-control"
                                id="id"
                                name="id"
                                inputMode="numeric"
                                onChange={formik.handleChange}
                                value={formik.values.id}
                            />
                            {formik.touched.id && formik.errors.id && (
                                <p className="m-0 text-danger">{formik.errors.id}</p>
                            )}
                        </div>

                        {/* Submit Button */}
                        <div className="d-flex align-items-end justify-content-end w-100" style={{ width: isMobile ? '50%' : '35%', paddingBottom: isMobile ? '20px' : '' }}>
                            <button type="submit" className="btn btn-primary user-scale" style={{ backgroundColor: '#6EC4B8', border: '1px solid #6EC4B8' }}>
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            {loading && <Loader />}
        </>
    );
}

export default CompaignForm;
