// Error.tsx
import React, { useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Container,
  useMediaQuery,
} from "@mui/material";
import Logo from "../../assets/images/Logo.svg";
import LogoName from "../../assets/images/LogoName.png";
import Image from "../../assets/images/Image.svg";
import { useNavigate } from "react-router-dom";
import "../../styles/inputBox.css";
import "../../assets/fonts/font.css";

const Error: React.FC = () => {
  const isMobile = useMediaQuery("(max-width:578px)");
  const navigate = useNavigate();

  const handleSubmit = async () => {
    navigate("/"); 
  };
  
  return (
    <div style={{ backgroundColor: "#F4F4F4" }}>
      <Container
        maxWidth="md"
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#F4F4F4",
        }}
      >
        <Grid
          container
          spacing={2}
          justifyContent="center"
          bgcolor="#FFFFFF"
          borderRadius="22px"
          //  height={700}
          margin={0}
        >
          {/* Form Column */}
          <Grid
            item
            xs={12}
            md={6}
            //  height="100%"
            height={isMobile ? "fit-content" : "auto"}
            // bgcolor="green"
            justifyContent="center"
            style={{ padding: 30 }}
          >
            <Grid
              container
              spacing={2}
              height="100%"
              justifyContent="space-between"
              direction="column"
            >
              <Grid
                container
                spacing={2}
                width="100%"
                height="100%"
                direction="column"
                margin={0}
                padding={0}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item>
                  {isMobile && (
                    <Grid
                      item
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <img
                        src={LogoName}
                        style={{
                          width: "70%",
                        }}
                      />
                    </Grid>
                  )}
                  <Typography
                    color="#273848"
                    fontWeight={700}
                    fontSize="29.94px"
                  >
                    404
                  </Typography>
                  <Typography color="#273848" fontWeight={500} fontSize="14px">
                    There's no one here
                  </Typography>
                  <Typography color="#273848" fontWeight={500} fontSize="14px">
                    If you follow the direction you can survive.
                  </Typography>
                </Grid>
                <Grid item></Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    // color="primary"
                    fullWidth
                    style={{
                      backgroundColor: "#84E1CC",
                      boxShadow: "none",
                      borderRadius: "5px",
                      padding: "17px 17px",
                      lineHeight: "0px",
                      textTransform: "none",
                      letterSpacing: "0px",
                    }}
                    onClick={handleSubmit}
                  >
                    Go Home
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* Image Column */}
          {!isMobile && (
            <Grid
              item
              md={6}
              //  height="100%"
              style={{ padding: 0, position: "relative" }}
            >
              {/* Replace the image source with your actual image */}
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  background: `url(${Logo})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderRadius: "0 22px 22px 0",
                  // opacity: 0.5,
                }}
              />
              <img
                src={Image}
                alt="Signup Image"
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "0 22px 22px 0",
                  objectFit: "cover",
                }}
              />
            </Grid>
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default Error;
