import axios from "axios";
import Toast from "./Toast";
export const generateText = async (keywords) => {
  // Handling Gaurd's
  if (
    !keywords ||
    typeof keywords !== "string" ||
    keywords.trim().length === 0
  ) {
    Toast("Please enter some details...!", "error");
    return;
  }

  const apiKey = `sk-proj-UKfq2kxHgAqegNCJb1tMT3BlbkFJ5KY1O4Ut58XGLhztMKYl`;
  // const apiKey = `sk-proj-UKfq2kxHgAqegNCJb1tMT3BlbkFJ5KY1O4Ut58XGLhztMK`;
  const apiUrl = `https://api.openai.com/v1/chat/completions`;

  const headers = {
    Authorization: `Bearer ${apiKey}`,
    "Content-Type": "application/json",
  };

  const data = {
    model: "gpt-4",
    messages: [
      {
        role: "user",
        content: keywords,
      },
    ],
    max_tokens: 45,
  };
  try {
    const response = await axios.post(apiUrl, data, { headers });
    return response.data.choices[0].message.content.trim();
  } catch (error) {
    console.error("Error calling OpenAI API:", error);
    throw new Error("Failed to generate text");
  }
};

export const generateTextObject = async (questionsAndKeywords) => {
  // Handling Guard Clauses
  if (!questionsAndKeywords || typeof questionsAndKeywords !== "object") {
    Toast("Invalid input. Please provide an object.", "warning");
    return;
  }

  const apiKey = `sk-proj-UKfq2kxHgAqegNCJb1tMT3BlbkFJ5KY1O4Ut58XGLhztMKYl`;
  // const apiKey = `sk-proj-UKfq2kxHgAqegNCJb1tMT3BlbkFJ5KY1O4Ut58XGLhztMK`;
  const apiUrl = `https://api.openai.com/v1/chat/completions`;

  const headers = {
    Authorization: `Bearer ${apiKey}`,
    "Content-Type": "application/json",
  };

  const responses = {};

  for (const [question, keywords] of Object.entries(questionsAndKeywords)) {
    const data = {
      model: "gpt-4",
      messages: [
        {
          role: "user",
          content: `${keywords} Please provide a Important Details, concise Important Details without listing points.`,
        },
      ],
      max_tokens: 50,
    };

    try {
      const response = await axios.post(apiUrl, data, { headers });
      responses[question] = response.data.choices[0].message.content.trim();
    } catch (error) {
      console.error("Error calling OpenAI API:", error);
      throw new Error("Failed to generate text");
    }
  }

  return responses;
};
