import React from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/X";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GoogleIcon from "@mui/icons-material/Google";
import { getMediaType } from "../../utils/helper";
import NoImageIcon from "../../assets/images/NoPhotoIcons.png";
import { useNavigate } from "react-router-dom";

interface DataItem {
  id: number;
  photo: string;
}
interface CommonTableProps {
  headings: string[];
  data: DataItem[][];
  label: string | string[]; // Allow label to be either a string or an array of strings
}

const CommonTable: React.FC<CommonTableProps> = ({ headings, data, label }) => {
  // Ensure label is always treated as an array, even if it's a single string
  const labels = Array.isArray(label) ? label : [label];
  const navigate = useNavigate();
  
  // Mapping label names to respective icons
  const iconMap: { [key: string]: React.ReactNode } = {
    Instagram: <InstagramIcon />,
    Facebook: <FacebookIcon />,
    Twitter: <TwitterIcon />,
    Youtube: <YouTubeIcon />,
    LinkedIn: <LinkedInIcon />,
    Google: <GoogleIcon />,
  };

  const getDataById = async (id: number) => {
    navigate(`/content-update/${id}`);
  };

  return (
    <div style={{ overflowX: "auto", scrollbarWidth: "thin" }}>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr style={{ backgroundColor: "#FBFBFB" }}>
            {headings.map((heading, index) => (
              <th
                key={index}
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                  textAlign: "center",
                  color: "#273848",
                  fontWeight: "bold",
                  fontSize: "16.94px",
                }}
              >
                {heading}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {data.map((dayData, dayIndex) => (
              <td
                key={dayIndex}
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                  textAlign: "left",
                  verticalAlign: "top",
                  position: "relative", // Ensure position is relative for absolute positioning of icons
                }}
              >
                {dayData.length > 0 ? (
                  dayData.map((data, imgIndex) => (
                    <div
                      key={imgIndex}
                      style={{
                        position: "relative",
                        marginTop: "25px",
                        cursor: "pointer",
                      }}
                      onClick={() => getDataById(data?.id)}
                    >
                      <div
                        style={{
                          position: "absolute",
                          top: "-20px",
                          left: "50%",
                          transform: "translateX(-50%)",
                          zIndex: 1,
                        }}
                      >
                        {labels.map((socialLabel, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              marginRight: "4px",
                              position: "relative",
                              zIndex: 1,
                              backgroundColor: "#244564",
                              padding: "4px",
                              borderRadius: "50%",
                            }}
                          >
                            {React.cloneElement(
                              iconMap[socialLabel] as React.ReactElement,
                              {
                                style: { color: "#ffffff" },
                              }
                            )}
                          </div>
                        ))}
                      </div>
                      {data.photo ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {getMediaType(data.photo) === "image" ? (
                            <img
                              src={data.photo}
                              alt={`Post`}
                              style={{
                                width: "100px",
                                height: "100px",
                                borderRadius: "8px",
                                objectFit: "cover",
                              }}
                            />
                          ) : (
                            <video
                              src={data.photo}
                              controls
                              style={{
                                width: "100px",
                                height: "100px",
                                borderRadius: "8px",
                                objectFit: "cover",
                              }}
                            />
                          )}
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={NoImageIcon}
                            alt="No Image Available"
                            style={{
                              width: "60px",
                              height: "60px",
                              marginTop: "10px",
                              borderRadius: "8px",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <td
                    colSpan={headings.length}
                    style={{ textAlign: "center", padding: "20px" }}
                  >
                    <img
                      style={{
                        height: "30px",
                        width: "30px",
                      }}
                      src="https://img.icons8.com/?size=100&id=lK3bQU1otwUc&format=png&color=000000"
                    />
                  </td>
                )}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CommonTable;
