import { Box, Container, Grid, Typography } from "@mui/material";
import Header from "../../layout/Header";
import { getToken } from "../../services/authService";
import { useEffect, useRef, useState } from "react";
import { gsap } from 'gsap';
import { getRequest } from "../../api/apiServices";
import { getDashboardData } from "../../api/apiList";
import Loader from "../../utils/Loading";
import { DashboardCount } from "../../utils/DashboardDataType";
import { useNavigate } from "react-router-dom";

const drawerWidth = 100;

const Dashboard = () => {
  const [count, setCount]=useState<DashboardCount>();
  const [isLoading, setIsLoading]=useState(true);
  const user = getToken();
  const boxRef = useRef(null);
  const navigate = useNavigate();
  const gridRefs = useRef<(HTMLDivElement | null)[]>([]);

  const getDashboardCount= async()=>{
    setIsLoading(true);
    const response = await getRequest(getDashboardData);
    if(response?.data?.success){
      setCount(response?.data.data);
        setIsLoading(false);
    }
    setIsLoading(false);
  }
  
   useEffect(()=>{
       getDashboardCount();
   },[])

  useEffect(() => {
    const timeLine = gsap.timeline();

    // Animation for the boxRef element
    timeLine.fromTo(
      boxRef.current,
      { scale: 1 },
      { scale: 1, duration: 1, ease: "back.out(1.7)" }
    );

    timeLine.fromTo(
      gridRefs.current,
      { scale: 1 },
      { scale: 1, duration: 0.6, stagger: 0.3, ease: "back.out(1.7)" }
    );
  }, []);

  const handleNavigation = (path?: any)=>{
    navigate(path); 
  }

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <Box>
        <Box
          display="flex"
          flexDirection="column"
          padding="0px"
          paddingTop="10px"
        >
          <Grid container spacing={2}>
            {[
              {
                title: "Hiring Manager",
                count: count?.hirringManagerCount || 0,
                bgcolor: "#273848",
                color: "#84E1CC",
                border: "#D9D9D9",
                // path: "/hiring-manager",
              },
              {
                title: "Jobs",
                count: count?.jobCount || 0,
                bgcolor: "#FFFFFF",
                color: "#273848",
                border: "#D6D6D7",
                path: "/jobs",
              },
              {
                title: "Candidates",
                count: count?.candidateCount || 0,
                bgcolor: "#FFFFFF",
                color: "#273848",
                border: "#D6D6D7",
                path: "/candidates",
              },
            ].map((item, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                key={index}
                ref={(el) => (gridRefs.current[index] = el)}
              >
                <Box
                  bgcolor={item.bgcolor}
                  borderRadius="27px"
                  padding="20px"
                  border={`1px solid ${item.border}`}
                  className="user-box dashboard-box"
                  sx={{
                    transition: "transform 0.3s",
                    "&:hover": {
                      transform: "scale(1.05)",
                      boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
                    },
                  }}
                  onClick={() => handleNavigation(item?.path)}
                  
                >
                  <Box
                    padding="10px"
                    bgcolor={`${item.color}1A`}
                    width="fit-content"
                    borderRadius="10px"
                  >
                    <Typography
                      fontSize="20px"
                      lineHeight={1}
                      fontWeight={700}
                      color={item.color}
                    >
                      {item.title}
                    </Typography>
                  </Box>
                  <Typography
                    lineHeight={1}
                    fontSize="75px"
                    fontWeight={700}
                    color={item.color}
                  >
                    {item.count}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Dashboard;
