import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { HistogramData } from "../../utils/AnalytivalDataType";
import { useMediaQuery } from "@mui/material";

// Register necessary Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface HistogramChartProps {
  data: HistogramData;
  jobTitle?: string;
}

const HistogramChart: React.FC<HistogramChartProps> = ({ data, jobTitle }) => {

  const isMobile = useMediaQuery("(max-width:1200px)");

  // Parse percentage data
  const parsedData = Object.values(data).map((percentage) =>
    parseFloat(percentage.replace("%", ""))
  );

  // Prepare chart data
  const chartData = {
    labels: Object.keys(data), // Use the range as labels
    datasets: [
      {
        label: `${jobTitle} Jobs in Netherlands`,
        data: parsedData, 
        backgroundColor: "#82ca9d", // Bar color
        borderColor: "#82ca9d", // Border color
        borderWidth: 1, // Border width
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        mode: "index" as const,
        intersect: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
        },
      },
      y: {
        title: {
          display: true,
        },
        beginAtZero: true,
        ticks: {
          callback: function (value: any) {
            return `${value}%`;
          },
        },
      },
    },
  };

  return <Bar data={chartData} options={options} width={  isMobile ? 300 : 600} height={  isMobile ? 200 : 300} />;
};

export default HistogramChart;
