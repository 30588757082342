import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Container,
  useMediaQuery,
  Box,
  Button,
  TextareaAutosize,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  OutlinedInput,
  CircularProgress,
} from "@mui/material";
import "../../styles/inputBox.css";
import InfoIcon from "../../assets/images/JobIcon/info.svg";
import {
  LocalizationProvider,
  DateTimePicker,
  DatePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import BlockhiringIcon from "../../assets/images/JobIcon/Block-hiring.svg";
import { useParams } from "react-router-dom";
import { putRequest } from "../../api/apiServices";
import { updateJob } from "../../api/apiList";
import DeleteIcon from "../../assets/images/deleteIcon.svg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

interface JobProfilefifthProps {
  onBack: () => void;
  onNext: () => void; // Function to navigate to the next component
  setFormData: (data: any) => void;
  initialData: any;
  mode: any;
}

interface Step {
  stepNumber: number;
  content: string;
  customContent?: string;
}

const JobProfilefifth: React.FC<JobProfilefifthProps> = ({
  onBack,
  onNext,
  setFormData,
  initialData,
  mode,
}: JobProfilefifthProps) => {
  const isMobile = useMediaQuery("(max-width:578px)");
  const isTabs = useMediaQuery("(max-width:1200px)");

  const [positionTimeline, setPositionTimeline] = useState<Dayjs | null>(null);
  const [urgentNeeds, setUrgentNeeds] = useState("");
  const [additionalRequirements, setAdditionalRequirements] = useState("");
  const [loading, setLoading] = useState(false);

  // Add Step
  const [interviewProcess, setInterviewProcess] = useState<Step[]>([]);

  const handleAddStep = (): void => {
    const newStep: Step = {
      stepNumber: interviewProcess?.length + 1,
      content: "",
    };
    setInterviewProcess([...interviewProcess, newStep]);
  };

  const handleDropdownChange = (index: number, content: string): void => {
    const updatedSteps = [...interviewProcess];
    updatedSteps[index].content = content;
    if (content !== "Other") {
      updatedSteps[index].customContent = "";
    }
    setInterviewProcess(updatedSteps);
  };

  const handleDeleteStep = (index: number): void => {
    const updatedSteps = interviewProcess.filter((_, i) => i !== index);
    // Reorder step numbers
    updatedSteps.forEach((step, i) => (step.stepNumber = i + 1));
    setInterviewProcess(updatedSteps);
  };

  const handleCustomContentChange = (
    index: number,
    customContent: string
  ): void => {
    const updatedSteps = [...interviewProcess];
    updatedSteps[index].customContent = customContent;
    setInterviewProcess(updatedSteps);
  };

  const params = useParams();

  const [errors, setErrors] = useState({
    positionTimeline: "",
    urgentNeeds: "",
    interviewProcess: "",
    additionalRequirements: "",
  });

  useEffect(() => {
    if (initialData) {
      if (initialData.positionTimeline) {
        setPositionTimeline(dayjs(initialData.positionTimeline));
      }
      setUrgentNeeds(initialData?.urgentNeeds);
      setInterviewProcess(initialData?.interviewProcess || []);
      setAdditionalRequirements(initialData?.additionalRequirements);
    }
  }, []);

  const formIsValid = () => {
    const newErrors = {
      positionTimeline: !positionTimeline ? "Timeline is required" : "",
      urgentNeeds: !urgentNeeds?.trim() ? "Urgent Hiring is required" : "",
      interviewProcess:
        interviewProcess.length === 0 ? "Interview Process is required" : "",
      additionalRequirements: !additionalRequirements?.trim()
        ? "Any Additional Information is required"
        : "",
    };
    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };

  const handleBack = (event: any) => {
    event.preventDefault();
    onBack(); // Call the onNext function to navigate to the next component
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const data = {
      positionTimeline: positionTimeline?.toISOString() || "",
      urgentNeeds,
      interviewProcess: interviewProcess,
      additionalRequirements,
    };
    if (formIsValid()) {
      if (mode) {
        setLoading(true);
        const response = await putRequest(updateJob(params?.id), data);
        setFormData(response?.data?.data);
        setLoading(false);
        onNext();
      } else {
        const allData = {
          ...initialData,
          ...data,
        };
        setFormData(allData);
        onNext();
      }
    }
  };

  const disablePastDates = (date: Dayjs) => {
    return date.isBefore(dayjs(), "day");
  };

  return (
    <div>
      <Container
        style={{
          display: "flex",
          padding: "25px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          spacing={2}
          bgcolor="#FFFFFF"
          width="884px"
          borderRadius="22px"
          border="1px solid #D6D6D7"
          margin={0}
        >
          <Grid
            item
            height={isMobile ? "auto" : "auto"}
            style={{ padding: 30 }}
          >
            <Grid
              container
              direction="row"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              gap="10px"
            >
              <Grid
                item
                width="fit-content"
                display="flex"
                alignItems="center"
                gap="10px"
              >
                <ArrowBackIcon
                  onClick={(event) => handleBack(event)}
                  style={{ cursor: "pointer" }}
                />
                <Typography color="#273848" fontWeight={700} fontSize="20.94px">
                  Interview Process
                </Typography>
              </Grid>
              <Grid item gap={2} display="flex">
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#84E1CC",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#84E1CC",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#84E1CC",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#84E1CC",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#84E1CC",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#DFF4EF",
                  }}
                />
              </Grid>
            </Grid>
            <Grid mt="16px"></Grid>

            {/* <Grid
              display="flex"
              alignItems="center"
              border="1px solid #c3c3c3"
              borderRadius="8px"
              padding="8px 0px 6px 15px"
            >
              <Grid direction="row" display="flex" gap={1} alignItems="center">
                <img src={InfoIcon} alt="team-icon" />
                <Typography
                  //   className="input-label-custom"
                  fontWeight={700}
                  fontSize="13.94px"
                  lineHeight="13.94px"
                  color="#273848"
                >
                  Interview process & Additional information
                </Typography>
              </Grid>
            </Grid> */}

            <Grid mt="15px"></Grid>
            <Typography
              color="#273848"
              fontWeight={700}
              lineHeight="14.94px"
              fontSize="15px"
              //   className="input-label-custom"
            >
              Timeline
            </Typography>
            <Grid mt="19px"></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                What is the expected timeline for filling this position?{" "}
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <Grid mb={1}></Grid>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Select Date"
                  value={positionTimeline}
                  onChange={(date: Dayjs | null) => setPositionTimeline(date)}
                  shouldDisableDate={disablePastDates}
                  slotProps={{ textField: { fullWidth: true } }}
                />
              </LocalizationProvider>
              <InputLabel
                style={{
                  color: "#f44336",
                  paddingTop: "3px",
                  fontSize: "12px",
                  paddingLeft: "10px",
                }}
              >
                {errors.positionTimeline}
              </InputLabel>
            </Grid>
            <Grid mt="19px"></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                Are there any urgent hiring needs?{" "}
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <Grid mb={1}></Grid>
              <TextField
                sx={{
                  // mt: 1,
                  "& .MuiOutlinedInput-input": {
                    padding: "10px 11px", // Add padding here
                  },
                }}
                placeholder="Are there any urgent hiring needs?"
                fullWidth
                className="input-box-custom"
                error={!!errors.urgentNeeds}
                helperText={errors.urgentNeeds}
                value={urgentNeeds}
                onChange={(e) => setUrgentNeeds(e.target.value)}
              />
            </Grid>
            <Grid mt="19px"></Grid>
            <Typography
              color="#273848"
              fontWeight={700}
              lineHeight="14.94px"
              fontSize="15px"
              //   className="input-label-custom"
            >
              Interview Process
            </Typography>
            <Grid mt="19px"></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                Can you outline the interview process, including the number of
                rounds and key decision-makers?{" "}
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <Grid mb={3}></Grid>
              <Button
                type="submit"
                variant="contained"
                style={{
                  backgroundColor: "#84E1CC",
                  boxShadow: "none",
                  borderRadius: "5px",
                  padding: "17px 17px",
                  lineHeight: "0px",
                  letterSpacing: "0px",
                  textTransform: "none",
                  marginBottom: "5px",
                }}
                onClick={handleAddStep}
              >
                Add Step
              </Button>
              {interviewProcess?.map((step, index) => (
                <Box key={index} sx={{ mb: 2 }}>
                  <Grid
                    container
                    direction="row"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    gap={isMobile ? "10px" : ""}
                  >
                    <Typography>Step {step.stepNumber}</Typography>
                  </Grid>
                  <Select
                    value={step.content}
                    onChange={(e) =>
                      handleDropdownChange(index, e.target.value as string)
                    }
                    displayEmpty
                    size="small"
                    sx={{ mb: step.content === "Other" ? 2 : 0, width: "92%" }}
                  >
                    <MenuItem value="">
                      <em>Select Step Content</em>
                    </MenuItem>
                    <MenuItem value="First Interview">First Interview</MenuItem>
                    <MenuItem value="Second Interview">
                      Second Interview
                    </MenuItem>
                    <MenuItem value="Third Interview">Third Interview</MenuItem>
                    <MenuItem value="Final Interview">Final Interview</MenuItem>
                    <MenuItem value="Assessment">Assessment</MenuItem>
                    <MenuItem value="Trial Day">Trial Day</MenuItem>
                    <MenuItem value="Employment Conditions">
                      Employment Conditions
                    </MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                  <IconButton
                    sx={{ color: "red", ml: 2 }}
                    onClick={() => handleDeleteStep(index)}
                  >
                    <img
                      src={DeleteIcon}
                      alt="Delete"
                      style={{ width: "20px", height: "26px", color: "red" }}
                    />
                  </IconButton>

                  {step.content === "Other" && (
                    <TextField
                      sx={{
                        // mt: 1,
                        "& .MuiOutlinedInput-input": {
                          padding: "10px 11px", // Add padding here
                        },
                      }}
                      variant="outlined"
                      placeholder="Enter custom step content"
                      fullWidth
                      onChange={(e) =>
                        handleCustomContentChange(index, e.target.value)
                      }
                      size="small"
                      value={step.customContent || ""} // Bind the value to customContent
                    />
                  )}
                </Box>
              ))}
              <InputLabel
                style={{
                  color: "#f44336",
                  paddingTop: "3px",
                  fontSize: "12px",
                  paddingLeft: "10px",
                }}
              >
                {errors.interviewProcess}
              </InputLabel>
            </Grid>
            <Grid mt="19px"></Grid>
            <Grid
              item
              bgcolor="#84E1CC"
              padding="9px 32px 9px 16px"
              width="fit-Content"
              borderRadius="4px"
            >
              <Grid
                direction="row"
                display="flex"
                alignItems="center"
                gap="2.5px"
              >
                <img src={BlockhiringIcon} alt="instagram-icon" />
                <Typography fontWeight={500} fontSize="12.55px" color="#FFFFFF">
                  Block time slots in agenda hiring manager
                </Typography>
              </Grid>
            </Grid>
            <Grid mb="17px"></Grid>
            <Typography
              color="#273848"
              fontWeight={700}
              lineHeight="14.94px"
              fontSize="15px"
              //   className="input-label-custom"
            >
              Any Additional Information
            </Typography>
            <Grid mt="19px"></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                Is there any other information or specific requirements that
                should be considered? <span style={{ color: "red" }}>*</span>
              </Typography>
              <Grid mb={1}></Grid>
              <OutlinedInput
                placeholder="Type here..."
                multiline
                minRows={4}
                className="text-area-size"
                value={additionalRequirements}
                onChange={(e) => setAdditionalRequirements(e.target.value)}
              />
            </Grid>
            <InputLabel
              style={{
                color: "#f44336",
                paddingTop: "3px",
                fontSize: "12px",
                paddingLeft: "10px",
              }}
            >
              {errors.additionalRequirements}
            </InputLabel>
            <Grid mt="30px"></Grid>
            <Grid
              container
              direction="row"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid xs={isTabs ? 0 : 2}>
                <button
                  type="submit"
                  className="user-scale"
                  // variant="contained"
                  // fullWidth
                  style={{
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #000000",
                    color: "#273848",
                    boxShadow: "none",
                    borderRadius: "5px",
                    padding: "17px 17px",
                    lineHeight: "0px",
                    letterSpacing: "0px",
                    textTransform: "none",
                    width: "100%",
                    // padding: "10px 50px",
                  }}
                  onClick={(event) => handleBack(event)}
                >
                  Back
                </button>
              </Grid>
              <Grid
                item
                xs={isTabs ? 0 : mode ? 3 : 2}
                style={{ maxWidth: "fitContent" }}
              >
                <button
                  type="submit"
                  className="user-scale"
                  // variant="contained"
                  // fullWidth
                  style={{
                    backgroundColor: "#84E1CC",
                    boxShadow: "none",
                    borderRadius: "5px",
                    padding: "17px 17px",
                    lineHeight: "0px",
                    letterSpacing: "0px",
                    textTransform: "none",
                    border: "none",
                    color: "white",
                    width: " 100%",
                  }}
                  onClick={(event) => handleSubmit(event)}
                >
                  {mode ? (
                    loading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      "Save & Next"
                    )
                  ) : (
                    "Continue"
                  )}
                </button>
              </Grid>
            </Grid>
            <Grid mb="10px"></Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default JobProfilefifth;
