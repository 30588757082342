// Sidebar.tsx
import React, { useContext, useEffect, useRef } from "react";
import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import Logo_Icon from "../assets/images/Logo_Icon.svg";
import Logo from "../assets/images/Logo_Color.svg";
import HomeIcon from "../assets/images/Sidebar/HomeIcon.svg";
import JobIcon from "../assets/images/Sidebar/JobIcon.svg";
import CandidateIcon from "../assets/images/Sidebar/CandidateIcon.svg";
import ContentIcon from "../assets/images/Sidebar/ContentIcon.svg";
import PostingIcon from "../assets/images/Sidebar/PostingIcon.svg";
import CampaignIcon from "../assets/images/Sidebar/CompaignIcon.svg";
import CandidateMenuIcon from "../assets/images/Sidebar/CandidateMeniUcon.svg";
import "../styles/Sidebar/sidebar.css";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SidebarContext } from "../hooks/UseSidebar";

const Sidebar: React.FC<{
  onToggle: (expanded: boolean) => void;
  visible: boolean;
}> = ({ onToggle, visible }) => {
  const location = useLocation();
  const [expanded, setExpanded] = React.useState(false);
  const [showIcons, setShowIcons] = useState(false);
  const { side, toggleSide } = useContext(SidebarContext);
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:1200px)");
  const isTab = useMediaQuery(
    " (min-device-width: 900px) and (max-device-width: 1024px)"
  );

  const sidebarRef = useRef<HTMLDivElement>(null);

  const MainRoute = () => {
    navigate("/");
  };

  const isActive = (path: string) => {
    return location.pathname === path;
  };

  // Function to check if the current path is a child of the provided parent path
  const isChildActive = (parentPath: string) => {
    return location.pathname.startsWith(parentPath);
  };

  const handleMenuItemClick = (url: string) => {
    window.location.href = url;
  };

  const handleMouseEnter = () => {
    if (!isMobile) setExpanded(true);
    if (!isMobile) onToggle(true);
    if (!isMobile) setShowIcons(true);
  };

  const handleMouseLeave = () => {
    setExpanded(false);
    onToggle(false);
    setShowIcons(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      sidebarRef.current &&
      !sidebarRef.current.contains(event.target as Node) &&
      side // Ensure sidebar is open
    ) {
      toggleSide(); // Close the sidebar
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [side]);

  return (
    <>
      <div
        ref={sidebarRef}
        className={`sidebar-item ${side ? "visible" : "hidden"}`}
        style={{
          width: expanded ? "200px" : "",
          transition: "width 0.5s ease-in",
          position: isTab ? "unset" : "fixed",
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          style={{ marginBottom: 20 }}
          onClick={MainRoute}
        >
          <Box style={{ display: showIcons ? "none" : "block" }}>
            <img src={Logo_Icon} alt="logo-icon" />
          </Box>
          <Box style={{ display: showIcons ? "block" : "none" }}>
            <img src={Logo} alt="logo" />
          </Box>
        </Grid>

        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className="Sidebar-Menu"
        >
          <Container
            className="menu-item"
            style={{
              background: isActive("/") ? "#6ec4b8" : "",
            }}
            onClick={() => handleMenuItemClick("/")}
          >
            <Box
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img src={HomeIcon} alt="HomeIcon" className="sidebar-icon" />
              <Box style={{ display: showIcons ? "block" : "none" }}>
                <Typography
                  className="sidebar-lebel"
                  style={{
                    marginLeft: 10,
                    color: isActive("/") ? "#273848" : "",
                  }}
                >
                  Dashboard
                </Typography>
              </Box>
            </Box>
          </Container>
          <Container
            className="menu-item"
            style={{
              background:
                isActive("/jobs") || isChildActive("/jobs") ? "#6ec4b8" : "",
            }}
            onClick={() => handleMenuItemClick("/jobs")}
          >
            <Box
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img src={JobIcon} alt="JobIcon" className="sidebar-icon" />
              <Box style={{ display: showIcons ? "block" : "none" }}>
                <Typography
                  className="sidebar-lebel"
                  style={{
                    marginLeft: 10,
                    color:
                      isActive("/job-listing") || isChildActive("/job-listing")
                        ? "#273848"
                        : "",
                  }}
                >
                  Jobs
                </Typography>
              </Box>
            </Box>
          </Container>
          <Container
            className="menu-item"
            style={{
              background:
                isActive("/candidates") || isChildActive("/candidates")
                  ? "#6ec4b8"
                  : "",
            }}
            onClick={() => handleMenuItemClick("/candidates")}
          >
            <Box
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img src={CandidateIcon} alt="CandidateIcon" />
              <Box style={{ display: showIcons ? "block" : "none" }}>
                <Typography
                  className="sidebar-lebel"
                  style={{
                    marginLeft: 10,
                    color:
                      isActive("/candidates") || isChildActive("/candidates")
                        ? "#273848"
                        : "",
                  }}
                >
                  Candidates
                </Typography>
              </Box>
            </Box>
          </Container>
          <Container
            className="menu-item"
            style={{
              background:
                isActive("/content-creation") ||
                isChildActive("/content-creation")
                  ? "#6ec4b8"
                  : "",
            }}
            onClick={() => handleMenuItemClick("/subscriptionplan")}
          >
            <Box
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={ContentIcon}
                alt="ContentIcon"
                className="sidebar-icon"
              />
              <Box style={{ display: showIcons ? "block" : "none" }}>
                <Typography
                  className="sidebar-lebel"
                  style={{
                    marginLeft: 10,
                    color:
                      isActive("/content-creation") ||
                      isChildActive("/content-creation")
                        ? "#273848"
                        : "",
                  }}
                >
                  Content Creation
                </Typography>
              </Box>
            </Box>
          </Container>
          <Container
            className="menu-item"
            style={{
              backgroundColor:
                isActive("/posting-publishing") ||
                isChildActive("/posting-publishing")
                  ? "#6ec4b8"
                  : "",
            }}
            onClick={() => handleMenuItemClick("/posting-publishing")}
          >
            <Box
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={PostingIcon}
                alt="PostingIcon"
                className="sidebar-icon"
              />
              <Box style={{ display: showIcons ? "block" : "none" }}>
                <Typography
                  className="sidebar-lebel"
                  style={{
                    marginLeft: 10,
                    color:
                      isActive("/posting-publishing") ||
                      isChildActive("/posting-publishing")
                        ? "#273848"
                        : "",
                  }}
                >
                  Posting / Publishing
                </Typography>
              </Box>
            </Box>
          </Container>
          <Container
            className="menu-item"
            style={{
              backgroundColor:
                isActive("/campaigns") || isChildActive("/campaigns")
                  ? "#6ec4b8"
                  : "",
            }}
            onClick={() => handleMenuItemClick("/campaigns")}
          >
            <Box
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={CampaignIcon}
                alt="CampaignIcon"
                className="sidebar-icon"
              />
              <Box style={{ display: showIcons ? "block" : "none" }}>
                <Typography
                  className="sidebar-lebel"
                  style={{
                    marginLeft: 10,
                    color:
                      isActive("/campaigns") || isChildActive("/campaigns")
                        ? "#273848"
                        : "",
                  }}
                >
                  Compaign
                </Typography>
              </Box>
            </Box>
          </Container>

          <Container
            className="menu-item"
            style={{
              backgroundColor:
                isActive("/candidate-menu") || isChildActive("/candidate-menu")
                  ? "#6ec4b8"
                  : "",
            }}
            onClick={() => handleMenuItemClick("/candidate-menu")}
          >
            <Box
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={CandidateMenuIcon}
                alt="CampaignIcon"
                className="sidebar-icon"
              />
              <Box style={{ display: showIcons ? "block" : "none" }}>
                <Typography
                  className="sidebar-lebel"
                  style={{
                    marginLeft: 10,
                    color:
                      isActive("/candidate-menu") ||
                      isChildActive("/candidate-menu")
                        ? "#273848"
                        : "",
                  }}
                >
                  Candidate Menu
                </Typography>
              </Box>
            </Box>
          </Container>
        </Grid>
      </div>
    </>
  );
};

export default Sidebar;
