import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  useMediaQuery,
  TextField,
  Button,
  InputLabel,
} from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as CalendarIcon } from "../../assets/images/calendar.svg"; // Assuming you have a calendar icon
import { styled } from "@mui/system";
import Logo from "../../assets/images/LogoNameBlack.png";
import DatePicker from "react-datepicker";
import Loader from "../../utils/Loading";
import { AppDispatch, RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { companyJobList } from "../../store/jobSlice";

const drawerWidth = 100;

const CustomDatePicker = styled(DatePicker)({
  width: "100%", // Ensure the width is 100%
  "& .react-datepicker-wrapper": {
    width: "100%",
  },
  "& .react-datepicker__input-container": {
    width: "100%",
  },
  "& input": {
    height: 43, // Adjust the height to match other TextFields
    width: "100%", // Ensure the input itself is 100% width
    paddingRight: "30px", // Ensure space for the calendar icon
    boxSizing: "border-box", // Ensure padding is included in width
  },
});

const CalendarIconContainer = styled("span")({
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  marginRight: "10px",
});

interface GenerateJobOfferPageOneProps {
    onNext: () => void; // Function to navigate to the next component
    setCandidateOfferData: (data: any) => void;
    initialData: any;
}

const GenerateJobOfferPageOne: React.FC<GenerateJobOfferPageOneProps> = ({ onNext, initialData, setCandidateOfferData }) => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTab = useMediaQuery("(max-width:900px)");

  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const [offerData, setOfferData] = useState({
    jobTitle: "",
    salary: "",
    startDate: "",
    workHours: "",
    location: "",
  });

  const [errors, setErrors] = useState({
    jobTitle: "",
    salary : "",
    startDate: "",
    workHours: "",
    location: "",
  });

  const formIsValid = () => {
    const newErrors = {
      jobTitle: !offerData.jobTitle?.trim() ? "Job Title is required" : "",
      salary: !offerData.salary?.trim() ? "Salary is required" : "",
      startDate: !offerData.startDate?.trim() ? "StartDate is required" : "",
      workHours: !offerData.workHours?.trim() ? "Work  Hours is required" : "",
      location: !offerData.location?.trim() ? "Location is required" : "",
  };

    setErrors(newErrors);

    return Object.values(newErrors).every((error) => error === "");
  };



  const dispatch: AppDispatch = useDispatch<AppDispatch>();

  useEffect(()=>{
    dispatch(companyJobList())
  },[])

  const getCompanyJob = useSelector((state: RootState) => state.companyJob);
  useEffect(() => {
    if (getCompanyJob.length > 0 && initialData.jobPosition) {
        const selectedJob = getCompanyJob.find((job) => job.id.toString() === initialData.jobPosition.toString());
        if (selectedJob) {
            setOfferData({ ...offerData,  jobTitle : selectedJob.jobTitle});
        }
    }   
}, [getCompanyJob]);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    setOfferData({ ...offerData, startDate: date ? date.toISOString() : "" });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const { name, value } = e.target;
    setOfferData({ ...offerData, [name]: value });
  };

  const handleSubmit = async (event: any) => {
    if(formIsValid()){
      setCandidateOfferData(offerData)
      onNext()
    }
  }

  return loading ? (
    <Loader />
  ) : (
    <div>
      <Grid
        container
        spacing={1}
        paddingTop={isMobile ? "0px" : "30px"}
        justifyContent="center"
        display={isTab ? "unset" : "flex"}
        // sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
      >
        {/* <Grid mt="35px"></Grid> */}
        <Grid
          item
          xs={8}
          // maxWidth={isMobile ? "none" : "auto"}
          // style={{
          //   paddingLeft: isTab ? "0px" : "",
          // }}
          style={{
            maxWidth: isTab ? "100%" : "auto",
            paddingLeft: isTab ? "0px" : "",
          }}
        >
          {/* <Grid mt="35px"></Grid> */}
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            bgcolor="#FFFFFF"
            borderRadius="14px"
            border="1px solid #D9D9D9"
            style={{ cursor: "pointer", padding: "20px" }}
          >
            {/* <Grid mt="35px"></Grid> */}
            <Grid
              item
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <img
                src={Logo}
                alt=""
                style={{
                  width: isMobile ? "50%" : "20%",
                  height: "auto",
                  objectFit: "contain",
                }}
              />
            </Grid>
            <Grid mt="35px"></Grid>
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={2}
              mt="10px"
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Grid item style={{ width: "100%" }}>
                  <Typography className="input-label-custom">
                    Job Title
                  </Typography>
                  <Grid mb={1}></Grid>
                  <TextField
                    sx={{
                      // mt: 1,
                      "& .MuiOutlinedInput-input": {
                        padding: "10px 11px", // Add padding here
                      },
                    }}
                    name="jobTitle"
                    placeholder="Enter job title"
                    fullWidth
                    className="input-box-custom"
                    value={offerData.jobTitle}
                    onChange={handleInputChange}
                    error={!!errors.jobTitle}
                    helperText={errors.jobTitle}
                  />
                </Grid>
                <Grid mb={1}></Grid>
                <Grid item style={{ width: "100%" }}>
                  <Typography className="input-label-custom">Salary</Typography>
                  <Grid mb={1}></Grid>
                  <TextField
                    sx={{
                      // mt: 1,
                      "& .MuiOutlinedInput-input": {
                        padding: "10px 11px", // Add padding here
                      },
                    }}
                    name="salary"
                    placeholder="Enter salary"
                    fullWidth
                    className="input-box-custom"
                    value={offerData.salary}
                    onChange={handleInputChange}
                    error={!!errors.salary}
                    helperText={errors.salary}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Grid
                  item
                  style={{
                    width: "100%",
                    marginRight: isMobile ? "0" : "5px",
                    marginBottom: isMobile ? "8px" : 0,
                  }}
                >
                  <Typography className="input-label-custom">
                    Start Date
                  </Typography>
                  <Grid mb={1}></Grid>
                  <CustomDatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    placeholderText="MM/DD/YYYY"
                    dateFormat="MM/dd/yyyy"
                    minDate={new Date()}
                    customInput={
                      <TextField
                        sx={{
                          // mt: 1,
                          "& .MuiOutlinedInput-input": {
                            padding: "10px 11px", // Add padding here
                          },
                        }}
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <CalendarIconContainer>
                              <CalendarIcon />
                            </CalendarIconContainer>
                          ),
                        }}
                      />
                    }
                  />
                  <InputLabel
                    style={{
                      color: "#f44336",
                      paddingTop: "3px",
                      fontSize: "12px",
                      paddingLeft: "10px",
                    }}
                  >
                    {errors.startDate}
                  </InputLabel>
                </Grid>
                <Grid item style={{ width: "100%", marginLeft: "5px" }}>
                  <Typography className="input-label-custom">
                    Work Hours per Week
                  </Typography>
                  <Grid mb={1}></Grid>
                  <TextField
                    sx={{
                      // mt: 1,
                      "& .MuiOutlinedInput-input": {
                        padding: "10px 11px", // Add padding here
                      },
                    }}
                    name="workHours"
                    placeholder="Enter total work hours per week"
                    fullWidth
                    className="input-box-custom"
                    value={offerData.workHours}
                    onChange={handleInputChange}
                    error={!!errors.workHours}
                    helperText={errors.workHours}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Grid item style={{ width: "100%" }}>
                  <Typography className="input-label-custom">
                    Office Location/Remote
                  </Typography>
                  <Grid mb={1}></Grid>
                  <TextField
                    sx={{
                      // mt: 1,
                      "& .MuiOutlinedInput-input": {
                        padding: "10px 11px", // Add padding here
                      },
                    }}
                    name="location"
                    placeholder="Office Location/Remote"
                    fullWidth
                    className="input-box-custom"
                    value={offerData.location}
                    onChange={handleInputChange}
                    error={!!errors.location}
                    helperText={errors.location}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid mt="15px"></Grid>
            <Grid
              item
              container
              justifyContent="flex-end"
              style={{ maxWidth: "100%" }}
            >
              <Button
                type="submit"
                variant="contained"
                style={{
                  backgroundColor: "#84E1CC",
                  boxShadow: "none",
                  borderRadius: "5px",
                  padding: "17px 17px",
                  lineHeight: "0px",
                  textTransform: "none",
                  letterSpacing: "0px",
                }}
                onClick={(event) => handleSubmit(event)}
              >
                Go to offer
              </Button>
            </Grid>
          </Grid>
          <Grid mt="26px"></Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default GenerateJobOfferPageOne;
