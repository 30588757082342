import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCompanyJob } from "../api/apiList";
import { getRequest } from "../api/apiServices";

interface Job {
    id: number;
    jobTitle: string;
}

let initialState: Job[] = [];

// Create Thnuk For jobListByCompany
export const companyJobList = createAsyncThunk('job', async ()=>{
    const response = await getRequest(getCompanyJob)
    const data = response?.data?.data
    return data as Job[]
})

const jobReducers = createSlice({
    name : "job",
    initialState,
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(companyJobList.fulfilled,(state, action: PayloadAction<Job[]>)=>{
            return action.payload;
        })
    }
})

export const {} = jobReducers.actions

export default jobReducers.reducer