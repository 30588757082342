import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Label from "../../components/Common/Label";
import AnalysisIcon from "../../assets/images/analysis.svg";
import TrueIcon from '../../assets/images/trueAnalysis.svg';
import CompanyLogo from '../../assets/images/Logo_Icon.svg';
import ChannelAnalysisIcon from '../../assets/images/channelAnalysisIcon.svg';
import { Grid, useMediaQuery } from "@mui/material";
import SocialMediaIcon from "../../components/SocialMediaIcon";
import Loader from "../../utils/Loading";
import { getRequest, postRequest } from "../../api/apiServices";
import apiList from "../../api/apiList";
import { Analytical } from '../../utils/AnalytivalDataType';
import HistoricalChart from "../../components/AnalyticsChart/HistoricalChart";
import HistogramChart from "../../components/AnalyticsChart/HistogramChart";
import RegionalChart from "../../components/AnalyticsChart/RegionalChart";
import TopCompaniesChart from "../../components/AnalyticsChart/TopCompaniesChart";
import ChartDescriptions from "../../components/Popups/ChartDescription";


const Analytics: React.FC = () => {
    const [formData, setFormData] = useState<Analytical>();
    const [historicalData, setHistoricalData] = useState(null);
    const [histogramData, setHistogramData] = useState(null);
    const [regionalData, setRegionalData] = useState(null);
    const [topCompaniesData, setTopCompaniesData] = useState(null);
    const [openDescriptionPopup, setOpenDescriptionPopup] = useState(false);
    const [description, setDescription] = useState('');
    const location = useLocation();
    const jobTitle = location.state?.jobTitle || '';
    const navigate = useNavigate();
    const [loaing, setLoading] = useState(false);
    const [isLoading,setIsLoading] = useState(false);
    const isMobile = useMediaQuery("(max-width:1200px)");


    //Get Data
    const getAnalyticsData = async () => {
        setLoading(true);
        const response = await postRequest(apiList.getAnalytics, { name: jobTitle })
        if (response) {
            setFormData(response.data);
            setLoading(false);
        }
        setLoading(false);
    }

    const fetchHistoricalData = async () => {
      const response = await getRequest(apiList.getHistoricalChart);
      if (response?.data.success) {
        setHistoricalData(response?.data?.data);
      }
    };

    const fetchHistogramData = async () => {
      const response = await postRequest(apiList.getHistogramChart, {
        name: jobTitle,
      });
      if (response?.data.success) {
        setHistogramData(response?.data.data);
      }
    };

    const fetchRegionalData = async () => {
      const response = await getRequest(apiList.getRegionalChart);
      if (response?.data.success) {
        setRegionalData(response?.data.data);
      }
    };

    const fetchTopCompaniesData = async () => {
      const response = await postRequest(apiList.getTopCompaniesChart, {
        name: jobTitle,
      });
      if (response?.data.success) {
        setTopCompaniesData(response?.data.data);
      }
    };

    useEffect(() => {
        const fetchAllData = async () => {
          await Promise.all([
            fetchHistoricalData(),
            fetchHistogramData(),
            fetchRegionalData(),
            fetchTopCompaniesData(),
          ]);
        };

        fetchAllData();
        getAnalyticsData();
    }, []);

    //Navigate Charts
    const viewCharts = (description: string) => {
        setDescription(description);
        setOpenDescriptionPopup(true);
    };


    return (
      <>
        <div className="container-fluid" style={{ paddingTop: "50px" }}>
          <div className="row d-flex align-items-center justify-content-start flex-column gap-4">
            {/* Job title header */}
            <Grid
              spacing={2}
              bgcolor="#FFFFFF"
              width="auto"
              borderRadius="22px"
              border="1px solid #D6D6D7"
              // margin={0}
              flexWrap="wrap"
              style={{ overflowY: isMobile ? "hidden" : "hidden" }}
            >
              <Grid item style={{ padding: 30 }}>
                <Label
                  children={jobTitle}
                  fontSize="20.94px"
                  fontWeight={700}
                  color="#273848"
                />
                <Grid my={2}></Grid>
                <Grid
                  direction="row"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  flexWrap="wrap"
                  //   gap={isMobile ? "10px" : ""}
                >
                  <Grid
                    width="100%"
                    direction="row"
                    display="flex"
                    justifyContent={isMobile ? "center" : "space-between"}
                    alignItems="center"
                    flexWrap={isMobile ? "wrap" : "nowrap"}
                  >
                    <Grid item>
                      {/* {data?.data?.historicalData && ( */}
                      <HistoricalChart data={historicalData || {}} />
                      {/* )} */}
                    </Grid>
                    <Grid item>
                      {/* {data?.data?.historicalData && ( */}
                      <HistogramChart
                        data={histogramData || {}}
                        jobTitle={jobTitle}
                      />
                      {/* )} */}
                    </Grid>
                  </Grid>
                  <Grid
                    width="100%"
                    direction="row"
                    display="flex"
                    justifyContent={isMobile ? "center" : "space-between"}
                    alignItems="center"
                    flexWrap={isMobile ? "wrap" : "nowrap"}
                  >
                    <Grid item>
                      {/* {data?.data?.regionalData && ( */}
                      <RegionalChart
                        data={regionalData || []}
                        jobTitle={jobTitle}
                      />
                      {/* )} */}
                    </Grid>
                    <Grid item>
                      {/* {data?.data?.topCompanies && ( */}
                      <TopCompaniesChart data={topCompaniesData || []} />
                      {/* )} */}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {loaing ? (
              <Loader />
            ) : (
              <>
                {/* Top Jobs Cards */}
                <div
                  className="row"
                  style={{
                    border: "1px solid #d6d6d7",
                    borderRadius: "27px",
                    padding: "20px",
                    backgroundColor: "white",
                  }}
                >
                  {/* Header */}
                  <div>
                    <Label
                      children="Top 4 jobs"
                      fontWeight={700}
                      fontSize="20.94px"
                      color="#273848"
                    />
                  </div>

                  {/* New Code */}
                  <div className="container py-5">
                    <div className="row gy-4">
                      {formData && formData?.data?.jobDetails ? (
                        formData?.data?.jobDetails
                          ?.filter((data) => data !== null)
                          .map((data, index) => (
                            <div
                              key={index}
                              className="col-lg-3 col-md-4 col-sm-6"
                            >
                              <div
                                className="custom-card"
                                style={{ cursor: "pointer" }}
                                onClick={() => viewCharts(data?.description)}
                              >
                                <div className="card-content">
                                  <h4 className="company-name">
                                    {data?.company}
                                  </h4>
                                  <h5 className="job-title">{data?.title}</h5>
                                  <p className="job-location">
                                    {data?.location}
                                  </p>
                                  <p className="job-salary">{data?.salary}</p>
                                </div>

                                <div className="card-footer">
                                  <span className="footer-text">
                                    Click for more details
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))
                      ) : (
                        <div className="col-12 text-center">
                          <p className="text-muted">No Jobs Found</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div
                  className="row"
                  style={{
                    border: "1px solid #d6d6d7",
                    borderRadius: "30px 20px",
                    padding: "20px",
                    backgroundColor: "white",
                  }}
                >
                  <div className="col-12 d-flex align-items-center justify-content-start gap-3 ">
                    <img src={AnalysisIcon} alt="Analysis-Icon" />
                    <Label
                      children="Approach Analysis"
                      fontWeight={700}
                      fontSize="20.94px"
                      color="#273848"
                    />
                  </div>

                  <div className="row mt-3 d-flex align-items-start justify-content-start flex-column gap-3">
                    {formData?.data &&
                    formData?.data?.approchAnalysisData?.length > 0 ? (
                      <div className="row">
                        {formData.data.approchAnalysisData
                          .slice(0, 4)
                          .map((data, index) => (
                            <div
                              className={`col-md-6 d-flex align-items-start justify-content-start gap-2 ${
                                index >= 2 ? "mt-3" : ""
                              }`} // Add margin-top for rows after the first two
                              key={index}
                            >
                              <img src={TrueIcon} alt="True-Icon" />
                              <Label
                                fontWeight={500}
                                fontSize={"13.59px"}
                                color="#273848"
                              >
                                {data}
                              </Label>
                            </div>
                          ))}
                      </div>
                    ) : (
                      <p>No data found</p>
                    )}
                  </div>
                </div>

                {/* Channel Analysis */}
                <div
                  className="row mb-4"
                  style={{
                    border: "1px solid #d6d6d7",
                    borderRadius: "27px",
                    padding: "20px",
                    backgroundColor: "white",
                  }}
                >
                  {/* header */}
                  <div className="d-flex align-items-start justify-content-start gap-2">
                    <img src={ChannelAnalysisIcon} alt="ChannelAnalysis-Icon" />
                    <Label
                      children="Channels Analysis"
                      fontWeight={700}
                      fontSize="20.94px"
                      color="#273848"
                    />
                  </div>

                  {/* Social Media */}
                  <div className="row d-flex align-items-center justify-content-start flex-wrap mt-3">
                    <SocialMediaIcon
                      names={formData?.data?.channelAnalysisData || []}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        {openDescriptionPopup && (
          <ChartDescriptions
            open={openDescriptionPopup}
            description={description}
            onClose={() => setOpenDescriptionPopup(false)}
          />
        )}
      </>
    );
}

export default Analytics;