import React, { useState } from "react";
import { Grid, useMediaQuery} from "@mui/material";
import GenerateJobOfferPageOne from "./GenerateJobOfferPageOne";
import GenerateJobOfferPageTwo from "./GenerateJobOfferPageTwo";
import { useLocation } from "react-router-dom";

const GenerateJobOffer: React.FC = () => {
  const isMobile = useMediaQuery("(max-width:991px)");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [candidateOfferData, setCandidateOfferData] = useState({});
  const location = useLocation()
  const candidateData = location.state?.candidateData;

  const goToNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const goToPreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  return (
    <div>
      <Grid
        container
        spacing={1}
        justifyContent="center"
      >
        <Grid item xs={12} maxWidth="100%">
          {currentPage === 1 && <GenerateJobOfferPageOne onNext={goToNextPage}  setCandidateOfferData={setCandidateOfferData} initialData={candidateData} />}
          {currentPage === 2 && <GenerateJobOfferPageTwo candidateData={candidateData} candidateOfferData={candidateOfferData}  />}
        </Grid> 
      </Grid>
    </div>
  );
};

export default GenerateJobOffer;