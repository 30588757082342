//ForgotPassword.tsx
import React, { useState } from "react";
import {
  Grid,
  Typography,
  Link,
  TextField,
  Button,
  Container,
  useMediaQuery,
  FormLabel,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Logo from "../../assets/images/Logo.svg";
import LogoName from "../../assets/images/LogoName.png";
import Image from "../../assets/images/Image.svg";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import "../../styles/inputBox.css";
import { handleAction } from "../../services/authService";
import { AxiosError } from "axios";

const ForgotPassword: React.FC = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordError, setPasswordError] = useState<string>("");
  const [confirmPasswordError, setConfirmPasswordError] = useState<string>("");
  const [passwordMismatch, setPasswordMismatch] = useState<string>("");
  const isMobile = useMediaQuery("(max-width:578px)");
  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleSubmit = async () => {
    navigate("/login");
    // try {
    //   setPasswordError("");
    //   setConfirmPasswordError("");
    //   setPasswordMismatch("");

    //   // Form validation
    //   if (!password || !confirmPassword) {
    //     if (!password) setPasswordError("Password is required");
    //     if (!confirmPassword)
    //       setConfirmPasswordError("Password confirmation is required");
    //     return;
    //   }

    //   if (password.length < 8) {
    //     setPasswordError("Password must be at least 8 characters long");
    //     return;
    //   }

    //   if (password !== confirmPassword) {
    //     setPasswordMismatch("Passwords do not match");
    //     return;
    //   }

    //   // setPasswordMismatch(false);

    //   // Call the register API function
    //   await handleAction("forgot", { password });

    //   toast.success("Change Password successful");
    //   navigate("/login");
    // } catch (error) {
    //   // Handle API call errors
    //   console.error("Error:", error);

    //   // Check if it's an AxiosError
    //   if ((error as AxiosError).isAxiosError) {
    //     const axiosError = error as AxiosError;

    //     // Check for specific status codes
    //     if (axiosError.response?.status === 400) {
    //       // Bad request - Invalid data
    //       toast.error("Invalid email or password format");
    //     } else if (axiosError.response?.status === 409) {
    //       // Conflict - Email already registered
    //       toast.error("Email is already registered");
    //     } else {
    //       // Other server errors
    //       toast.error("An error occurred while registering");
    //     }
    //   } else {
    //     // Non-Axios errors
    //     toast.error("An error occurred while registering");
    //   }
    // }
  };

  return (
    <div style={{ backgroundColor: "#F4F4F4" }}>
      <Container
        maxWidth="md"
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#F4F4F4",
        }}
      >
        <Grid
          container
          spacing={2}
          justifyContent="center"
          bgcolor="#FFFFFF"
          // alignItems="center"
          borderRadius="22px"
          // height={700}
          margin={0}
        // width="max-content"
        >
          {/* Form Column */}
          <Grid
            item
            xs={12}
            md={6}
            height={isMobile ? "fit-content" : "auto"}
            // bgcolor="green"
            justifyContent="center"
            style={{ padding: 30 }}
          >
            <Grid
              container
              spacing={2}
              height="100%"
              justifyContent="space-between"
              direction="column"
            >
              <Grid
                container
                spacing={2}
                width="100%"
                direction="column"
                margin={0}
                padding={0}
              >
                <Grid item>
                  {isMobile && (
                    <Grid
                      item
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <img
                        src={LogoName}
                        style={{
                          width: "70%",
                        }}
                      />
                    </Grid>
                  )}
                  <Typography
                    color="#273848"
                    fontWeight={700}
                    fontSize="29.94px"
                  >
                    Forgot Password
                  </Typography>
                  <Typography color="#273848" fontWeight={500} fontSize="14px">
                    Lorem ipsum dolor sit amet consectetur. Tortor odio.
                  </Typography>
                </Grid>
                <Grid item></Grid>
                <Grid item>
                  <Typography className="input-label-custom">
                    New Password
                  </Typography>
                  <FormControl fullWidth variant="outlined">
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      placeholder="Password"
                      label=""
                      className="input-box-custom"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      error={!!passwordError}
                    />
                    {passwordError && (
                      // Display error message here conditionally
                      <Typography color="error" fontSize={12}>
                        {passwordError}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                <Grid item>
                  <Typography className="input-label-custom">
                    Confirm Password
                  </Typography>
                  {/* <TextField label="Password" type="password" fullWidth /> */}
                  <FormControl fullWidth variant="outlined">
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showConfirmPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleShowConfirmPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showConfirmPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      placeholder="Confirm Password"
                      label=""
                      className="input-box-custom"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      error={!!confirmPasswordError}
                    />
                    {confirmPasswordError && (
                      <Typography color="error" fontSize={12}>
                        {confirmPasswordError}
                      </Typography>
                    )}
                    {passwordMismatch && (
                      <Typography color="error" fontSize={12}>
                        {passwordMismatch}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>

                <Grid item>
                  <Button
                    variant="contained"
                    // color="primary"
                    fullWidth
                    style={{
                      backgroundColor: "#84E1CC",
                      boxShadow: "none",
                      borderRadius: "5px",
                      padding: "17px 17px",
                      lineHeight: "0px",
                      textTransform: "none",
                      letterSpacing: "0px",
                    }}
                    onClick={handleSubmit}
                  >
                    Change Password
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* Image Column */}
          {!isMobile && (
            <Grid
              item
              xs={12}
              md={6}
              style={{
                padding: 0,
                position: "relative",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  background: `url(${Logo})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderRadius: "0 22px 22px 0",
                  // opacity: 0.5,
                }}
              />
              <img
                src={Image}
                alt="ForgotPassword Image"
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "0 22px 22px 0",
                  objectFit: "cover",
                }}
              />
            </Grid>
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default ForgotPassword;
