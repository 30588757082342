import React from "react";
import GenerateIcon from './generateSocialMediaIcon';
import FacebookIcon from '../assets/images/Channel Analysis/Facebook.svg';
import DiscordIcon from '../assets/images/Channel Analysis/Discord.svg';
import InstagramIcon from '../assets/images/Channel Analysis/Instagram.svg';
import LinkedInIcon from '../assets/images/Channel Analysis/LinnkedIn.svg';
import YoutubeIcon from '../assets/images/Channel Analysis/Youtube.svg';

import AppleIcon from '../assets/images/Channel Analysis/Apple.svg';
import ClubhouseIcon from '../assets/images/Channel Analysis/Clubhouse.svg';
import DribbleIcon from '../assets/images/Channel Analysis/Dribbble.svg';
import FigmaIcon from '../assets/images/Channel Analysis/Figma.svg';
import GithubIcon from '../assets/images/Channel Analysis/Github.svg';
import GoogleIcon from '../assets/images/Channel Analysis/Google.svg';
import MediumIcon from '../assets/images/Channel Analysis/Medium.svg';
import PinterestIcon from '../assets/images/Channel Analysis/Pinterest.svg';
import RedditIcon from '../assets/images/Channel Analysis/Reddit.svg';
import SignalIcon from '../assets/images/Channel Analysis/Signal.svg';
import SnapchatIcon from '../assets/images/Channel Analysis/Snapchat.svg';
import SpotifyIcon from '../assets/images/Channel Analysis/Spotify.svg';
import TelegramIcon from '../assets/images/Channel Analysis/Telegram.svg';
import ThreadIcon from '../assets/images/Channel Analysis/Thread.svg';
import TiktokIcon from '../assets/images/Channel Analysis/Tiktok.svg';
import TumbirIcon from '../assets/images/Channel Analysis/Tumbir.svg';
import TwitchIcon from '../assets/images/Channel Analysis/Twitch.svg';
import TwitterIcon from '../assets/images/Channel Analysis/Twitter.svg';
import VkIcon from '../assets/images/Channel Analysis/Vk.svg';
import StackOverFlowicon from '../assets/images/Channel Analysis/StackOverflow.svg';
import DefaultIcon from '../assets/images/Channel Analysis/Default.svg';

export const getSocialMediaIcon = (name: string): JSX.Element | null => {
    switch (name.toLowerCase()) {
        case 'facebook':
            return <GenerateIcon colour="#66CCE7" image={FacebookIcon} tooltip="Facebook" />;
        case 'discord':
            return <GenerateIcon colour="#66CCE7" image={DiscordIcon} tooltip="Discord" />;
        case 'instagram':
            return <GenerateIcon colour="#66CCE7" image={InstagramIcon} tooltip="Instagram" />;
        case 'linkedin':
            return <GenerateIcon colour="#BEC3C7" image={LinkedInIcon} tooltip="LinkedIn" />;
        case 'youtube':
            return <GenerateIcon colour="#66CCE7" image={YoutubeIcon} tooltip="Youtube" />;
        case 'apple':
            return <GenerateIcon colour="#BEC3C7" image={AppleIcon} tooltip="Apple" />;
        case 'clubhouse':
            return <GenerateIcon colour="#66CCE7" image={ClubhouseIcon} tooltip="Clubhouse" />;
        case 'dribbble':
            return <GenerateIcon colour="#BEC3C7" image={DribbleIcon} tooltip="Dribble" />;
        case 'figma':
            return <GenerateIcon colour="#66CCE7" image={FigmaIcon} tooltip="Figma" />;
        case 'github':
            return <GenerateIcon colour="#BEC3C7" image={GithubIcon} tooltip="Github" />;
        case 'google':
            return <GenerateIcon colour="#66CCE7" image={GoogleIcon} tooltip="Google" />;
        case 'medium':
            return <GenerateIcon colour="#BEC3C7" image={MediumIcon} tooltip="Medium" />;
        case 'pinterest':
            return <GenerateIcon colour="#66CCE7" image={PinterestIcon} tooltip="Pinterest" />;
        case 'reddit':
            return <GenerateIcon colour="#BEC3C7" image={RedditIcon} tooltip="Reddit" />;
        case 'signal':
            return <GenerateIcon colour="#66CCE7" image={SignalIcon} tooltip="Signal" />;
        case 'snapchat':
            return <GenerateIcon colour="#BEC3C7" image={SnapchatIcon} tooltip="Snapchat" />;
        case 'spotify':
            return <GenerateIcon colour="#66CCE7" image={SpotifyIcon} tooltip="Spotify" />;
        case 'telegram':
            return <GenerateIcon colour="#BEC3C7" image={TelegramIcon} tooltip="Telegram" />;
        case 'thread':
            return <GenerateIcon colour="#66CCE7" image={ThreadIcon} tooltip="Thread" />;
        case 'tiktok':
            return <GenerateIcon colour="#BEC3C7" image={TiktokIcon} tooltip="Tiktok" />;
        case 'tumblr':
            return <GenerateIcon colour="#66CCE7" image={TumbirIcon} tooltip="Tumbir" />;
        case 'twitch':
            return <GenerateIcon colour="#BEC3C7" image={TwitchIcon} tooltip="Twitch" />;
        case 'twitter':
            return <GenerateIcon colour="#66CCE7" image={TwitterIcon} tooltip="Twitter" />;
        case 'vk':
            return <GenerateIcon colour="#BEC3C7" image={VkIcon} tooltip="Vk" />;
        case 'stack overflow':
            return <GenerateIcon colour="#66CCE7" image={StackOverFlowicon} tooltip="StackOverFlow" />;
        default:
            return <GenerateIcon colour="#66CCE7" image={DefaultIcon} tooltip="Empty" />;
    }
    };
