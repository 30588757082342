import { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Typography,
  IconButton,
  InputBase,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Skeleton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import plusIcon from "../../assets/images/plus.svg";
import EditIcon from "../../assets/images/EditIcon.svg";
import DeleteIcon from "../../assets/images/deleteIcon.svg";
import "../../styles/dashboard.css";
import Header from "../../layout/Header";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import { deleteRequest, postRequest } from "../../api/apiServices";
import { compaignFormList, deleteCompaignForm } from "../../api/apiList";
import moment from "moment";
import Loader from "../../utils/Loading";
import Toast from "../../utils/Toast";
import PaginationComponent from "../../utils/PaginationComponent";

const drawerWidth = 100;

interface Campaign {
  id: number;
  name: string;
  createdAt: string;
  campaignId: string;
}

const CandidateMenu = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Adjust breakpoint if necessary
  const isTabs = useMediaQuery("(max-width:1200px)");
  const navigate = useNavigate();

  const [campaign, setCampaign] = useState<Campaign[]>([]);
  const [selectedCampaign, setSelectedCampaign] = useState<number | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const tableRef = useRef(null);

  // Pagination state
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5); // limit
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);

  // Pagination handlers
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Debounce Timer
  const debounceTimer = useRef<NodeJS.Timeout | null>(null);

  const handleSelect = (id: number) => {
    setSelectedCampaign(id);
  };

  const viewCandidateLeadsDashboard = (campaignID: any) =>
    navigate("/candidate-leads-dashboard", { state: { campaignID } });

  //Searching campaign
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);

    // Clear the previous debounce timer
    if (debounceTimer.current) clearTimeout(debounceTimer.current);

    // Set a new debounce timer
    debounceTimer.current = setTimeout(() => {
      fetchCompaignData(page, rowsPerPage, value); // Fetch campaigns with the updated search term
    }, 300);
  };

  // Fetch Campaign Data
  const fetchCompaignData = async (
    page: number,
    limit: number,
    search: string
  ) => {
    // if (searchTerm) setLoader(true);
    setLoader(true);
    const response = await postRequest(compaignFormList, {
      page,
      limit,
      search,
      searchColumns: ["name"],
    });
    if (response) {
      setCampaign(response?.data?.data?.campaigns || []);
      setCount(response?.data?.data?.count);
      setPage(response?.data?.data?.page - 1);
      setHasNextPage(response?.data?.data?.hasNextPage);
      setHasPreviousPage(response?.data?.data?.hasPreviousPage);
      setLoader(false);
    }
    setLoader(false);
  };

  useEffect(() => {
    if (!searchLoading) {
      fetchCompaignData(page + 1, rowsPerPage, searchTerm);
    }
  }, [page, rowsPerPage, searchTerm]);

  const inputStyle = {
    backgroundColor: "#ffffff",
    padding: "6px 12px",
    borderRadius: "8px",
    boxShadow: "0 1px 4px rgba(0,0,0,0.1)",
    height: "40px",
  };

  return loader ? (
    <Loader />
  ) : (
    <>
      {/* <Header pageName="Candidate Menu" isShow={true} /> */}

      <Box
        sx={{
          // width: `calc(100% - ${drawerWidth}px)`,
          // ml: `${drawerWidth}px`,
          mt: isMobile ? "" : "50px",
          backgroundColor: "#f0f0f0",
          // padding: "20px",
          borderRadius: "8px",
        }}
      >
        <Box
          display="flex"
          alignItems={"end"}
          justifyContent={isTabs ? "start" : "end"}
          mb={2}
        >
          <Box
            display="flex"
            flexDirection={{ xs: "column", md: "row" }}
            alignItems={{ xs: "flex-start", md: "center" }}
            mt={{ xs: 2, md: 0 }}
          >
            <Box
              className="user-box"
              sx={{
                display: "flex",
                alignItems: "center",
                width: isMobile ? "auto" : "300px", // Set width dynamically based on screen size
                ...inputStyle,
                marginRight: "16px",
              }}
            >
              <SearchIcon />
              <InputBase
                placeholder="Search here..."
                value={searchTerm}
                onChange={handleSearchChange}
                sx={{ ml: 1, flex: 1, fontSize: "16px" }}
              />
            </Box>
          </Box>
        </Box>

        <Divider />

        <div ref={tableRef}>
          <Box mt={3} className="table-container">
            <Box className="table-wrapper" sx={{ backgroundColor: "#f0f0f0" }}>
              <Box display="flex" padding="16px 0" textAlign="center">
                <Box width="10%" display="flex" justifyContent="center">
                  <Typography
                    fontSize="16px"
                    fontWeight={500}
                    color="#8C8C8C"
                    letterSpacing="normal"
                  >
                    Sr.
                  </Typography>
                </Box>
                <Box width="30%" display="flex" justifyContent="center">
                  <Typography
                    fontSize="16px"
                    fontWeight={500}
                    color="#8C8C8C"
                    letterSpacing="normal"
                  >
                    Campaign Name
                  </Typography>
                </Box>
                <Box width="50%" display="flex" justifyContent="center">
                  <Typography
                    fontSize="16px"
                    fontWeight={500}
                    color="#8C8C8C"
                    letterSpacing="normal"
                  >
                    ID
                  </Typography>
                </Box>
                <Box width="20%" display="flex" justifyContent="center">
                  <Typography
                    fontSize="16px"
                    fontWeight={500}
                    color="#8C8C8C"
                    letterSpacing="normal"
                  >
                    Publish Date
                  </Typography>
                </Box>
              </Box>

              {loader ? (
                Array.from({ length: 4 }).map((_, index) => (
                  <Box
                    key={index}
                    display="flex"
                    alignItems="center"
                    textAlign="center"
                    bgcolor="#ffffff"
                    borderRadius="4px"
                    my={1}
                    py={1}
                    className="shimmer-effect"
                    sx={{
                      "& > div": {
                        width: "100%",
                        height: "20px",
                        borderRadius: "4px",
                      },
                    }}
                  >
                    <Box width="10%" display="flex" justifyContent="center">
                      <div></div>
                    </Box>
                    <Box width="30%" display="flex" justifyContent="center">
                      <div></div>
                    </Box>
                    <Box width="50%" display="flex" justifyContent="center">
                      <div></div>
                    </Box>
                    <Box width="20%" display="flex" justifyContent="center">
                      <div></div>
                    </Box>
                  </Box>
                ))
              ) : campaign.length === 0 ? (
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Typography fontSize="18px" fontWeight={500} color="#8C8C8C">
                    {/* No records yet. */}
                    {loader ? <Loader /> : <p>No records yet. </p>}
                  </Typography>
                </Box>
              ) : (
                campaign.map((jobItem, index) => (
                  <Box
                    className="row-shadow"
                    display="flex"
                    key={jobItem.id}
                    alignItems="center"
                    textAlign="center"
                    bgcolor="#ffffff"
                    borderRadius="4px"
                    sx={{ cursor: "pointer" }}
                    my={1}
                    py={1}
                    onClick={() =>
                      viewCandidateLeadsDashboard(jobItem?.campaignId)
                    }
                  >
                    <Box width="10%" display="flex" justifyContent="center">
                      <Typography
                        fontSize="16px"
                        fontWeight={500}
                        color="#273848"
                      >
                        {index + 1}
                      </Typography>
                    </Box>
                    <Box width="30%" display="flex" justifyContent="center">
                      <Typography
                        fontSize="16px"
                        fontWeight={500}
                        color="#273848"
                      >
                        {jobItem.name}
                      </Typography>
                    </Box>
                    <Box width="50%" display="flex" justifyContent="center">
                      <Typography
                        fontSize="16px"
                        fontWeight={500}
                        color="#273848"
                      >
                        {jobItem.campaignId}
                      </Typography>
                    </Box>
                    <Box width="20%" display="flex" justifyContent="center">
                      <Typography
                        fontSize="16px"
                        fontWeight={500}
                        color="#273848"
                      >
                        {moment(jobItem.createdAt)
                          .subtract(10, "days")
                          .calendar()}
                      </Typography>
                    </Box>
                  </Box>
                ))
              )}
            </Box>
          </Box>

          <PaginationComponent
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            count={count}
            hasNextPage={hasNextPage}
            hasPreviousPage={hasPreviousPage}
          />
        </div>
      </Box>
    </>
  );
};

export default CandidateMenu;
