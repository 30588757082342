import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useMediaQuery } from "@mui/material";

// Register necessary Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface HistoricalChartProps {
  data: { [key: string]: number };
}

const HistoricalChart: React.FC<HistoricalChartProps> = ({ data }) => {


  const isMobile = useMediaQuery("(max-width:1200px)");

  // Prepare chart data
  const chartData = {
    labels: Object.keys(data), // Use dates as labels
    datasets: [
      {
        label: "IT Jobs in Netherlands",
        data: Object.values(data), // Use the values as data
        borderColor: "#8884d8",
        backgroundColor: "rgba(136, 132, 216, 0.2)",
        fill: true, // Optional: make the area under the line filled
        pointRadius: 5,
        pointHoverRadius: 8,
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        mode: "index" as const, // This ensures the value is treated as a valid enum-like type
        intersect: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
        },
      },
      y: { 
        title: {
          display: true,
        },
      },
    },
  };

  return <Line data={chartData} options={options} width={ isMobile ? 350 : 600} height={ isMobile ? 200 : 300}/>;
};

export default HistoricalChart;