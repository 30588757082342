import axios from 'axios';

// const BASE_URL = "http://192.168.1.8:3000/api/";
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const handleAction = async (action: string, data: any) => {
  switch (action) {
    case 'register':
      return register(data);
    case 'verifyEmailOTP':
      return verifyEmailOTP(data.email, data.otp);
    case 'login':
      return login(data.email, data.password);
    case 'create-company':
      return saveProfileData(data);
    default:
      throw new Error(`Invalid action: ${action}`);
  }
};

export const register = async (userData: any) => {
  try {
    const response = await axios.post(`${BASE_URL}register`, userData);
    const {
      user: { email, uId, status, name },
      token,
    } = response.data.data;
    saveToken(token, email, uId, status, name);
    return { email, token };
  } catch (error) {
    throw error;
  }
};

export const verifyEmailOTP = async (email: string, otpPin: string) => {
  try {
    const response = await axios.post(`${BASE_URL}otp-verify`, {
      email,
      otpPin,
    });
    const { token, user } = response.data.data; // Assuming the API returns a token
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const resendOTP = async (email: string) => {
  try {
    await axios.post(`${BASE_URL}resend-otp`, { email });
    return true; // Return true if the request is successful
  } catch (error) {
    console.error('Error resending OTP:', error);
    return false; // Return false if there's an error
  }
};

export const login = async (email: string, password: string) => {
  try {
    const response = await axios.post(`${BASE_URL}login`, { email, password });
    const { token, user } = response.data.data; // Assuming the API returns a token
    saveToken(
      token,
      user?.email,
      user?.uId,
      user?.status,
      user?.name,
      user?.profile
    ); // Save the token
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const saveProfileData = async (profileData: any) => {
  try {
    const token = getToken();
    if (!token) {
      throw new Error('Token not found');
    }
    const response = await axios.post(
      `${BASE_URL}create-company`,
      profileData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// UserInfo Store In Session Storage
export const saveToken = (
  token: string,
  email: string,
  uId: string,
  status: string,
  name?: string,
  profile?: string
) => {
  const expiresIn = 24 * 60 * 60;
  const expirationTime = Date.now() + expiresIn * 1000;
  const userDataWithExpiration = {
    token,
    email,
    uId,
    expirationTime,
    status,
    name,
    profile,
  };
  sessionStorage.setItem('userData', JSON.stringify(userDataWithExpiration));
};

// update Session Stoarge data
export const updateUserData = (updatedData: Partial<any>) => {
  const userDataString = sessionStorage.getItem('userData');
  if (!userDataString) {
    return;
  }
  const userData = JSON.parse(userDataString);
  const updatedUserData = {
    ...userData,
    ...updatedData,
  };
  sessionStorage.setItem('userData', JSON.stringify(updatedUserData));
};

export const getToken = () => {
  const userDataString = sessionStorage.getItem('userData');
  if (!userDataString) {
    return null;
  }
  const userData = JSON.parse(userDataString);
  if (!userData.expirationTime || Date.now() > userData.expirationTime) {
    clearSessionStorage();
    return null;
  }
  return userData;
};

export const clearSessionStorage = () => {
  sessionStorage.removeItem('userData');
};

export default {
  handleAction,
  register,
  verifyEmailOTP,
  resendOTP,
  saveProfileData,
  login,
  saveToken,
  getToken,
};
