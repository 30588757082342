import React from 'react';
import '../../styles/privacypolicy.css';
import { Link } from 'react-router-dom';

const TermsAndConditions: React.FC = () => {
  return (
    <div className='privacy-policy pt-2'>
      <h1 className="centered-heading">TERMS AND CONDITIONS</h1>
      <p className='mt-5'>
        The following terms and conditions, govern your access to and use of the <span><Link to="https://www.sprooze.com/" className='link'>www.sprooze.com</Link></span>&nbsp;website, including any content, products and services offered on or through www.sprooze.com (the "website"), (collectively, "we" or "us").
      </p>
      <p id='second-header' className='pt-2'>
        PLEASE READ THE PRIVACY POLICY AND ALL OF THE FOLLOWING TERMS AND CONDITIONS BEFORE USING THIS SITE. BY CONTINUING TO ACCESS OR USE THIS SITE OR ANY SERVICE ON THIS SITE, YOU SIGNIFY YOUR ACCEPTANCE OF THESE TERMS AND CONDITIONS.
      </p>
      <p className='pt-2' style={{ fontWeight: 600 }}>These Terms and Conditions are effective from 9th October 2024.</p>

      <ol>
        <li><h2 className="heading-three">OUR DETAILS:</h2></li>
        <ul className='mt-2'>
          <li><p>sprooze, (we, our and us) operates the website <span><Link to="https://www.costaxis.com/" className='link'>https://www.costaxis.com/.</Link></span></p></li>
          <li><p>sprooze, is a software recruitment marketing solution based in Netherland. Our mailing Address is Lavendelplein 133, 5643 EH Eindhoven, Netherlands.
            our email address is <Link to="mailto:info@sprooze.com" className='link'>info@sprooze.com</Link></p>
            <li><p>www.sprooze.com is compliant with the 2018 E.U. GDPR.</p></li>
          </li>
        </ul>

        <li><h2 className="heading-three">SERVICE:</h2></li>
        <ul className='mt-2'>
          <li><p> Your use of the Service is at your sole risk. The Services are provided on an “as is” and “as available” basis.</p></li>
          <li><p>You understand and agree that we shall not be liable for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to any loss of profit, loss of goodwill, loss of business reputation, loss of data, cost of procurement of substitute goods or services, or other intangible loss, resulting from:
            <ul>
              <li style={{ listStyle: 'lower-roman' }}> <p>the service </p> </li>
              <li style={{ listStyle: 'lower-roman' }}> <p>the results of the service</p> </li>
              <li style={{ listStyle: 'lower-roman' }}> <p>the use or the inability to use the service;</p> </li>
              <li style={{ listStyle: 'lower-roman' }}> <p>any changes which we may make to the service, or any permanent or temporary termination of the service;</p> </li>
              <li style={{ listStyle: 'lower-roman' }}> <p>or any other matter relating to the service.</p> </li>
            </ul>
          </p>
          </li>
        </ul>

        <li><h2 className="heading-three">SUBSCRIPTION:</h2></li>
        <ul className='mt-2'>
          <li><p><Link className='link' to='https://www.sprooze.com'>www.sprooze.com</Link> offers monthly subscriptions and shall charge the subscription fee from the users.  </p></li>
          <li><p>We offer memberships and paid subscriptions of our online services. You can purchase a plan, Advertised prices and features may change. </p></li>
        </ul>


        <li><h2 className="heading-three">SUBSCRIPTION FEE:</h2></li>
        <ul className='mt-2'>
          <li><p>You must pay all fees including taxes during your subscription period and any renewal periods. Our fees includes a monthly or annual fee. </p></li>
        </ul>

        <li><h2 className="heading-three">ACCEPTANCE:</h2></li>
        <ul className='mt-2'>
          <li>
            <p>Please read these Terms and Conditions and Privacy Policy very carefully. If you do not agree to any of the provisions set out in those documents, you must not use the website services. By accessing or using the Platform, registering an account, or by viewing, accessing, uploading or downloading any content or information from or to the website, you represent and warrant that you have read and understood these Terms and Conditions, and that you are either 16 years of age or more, or the applicable age of majority in your jurisdiction. </p>
          </li>
        </ul>

        <li><h2 className="heading-three">ACCOUNT DETAILS:</h2></li>
        <ul className='mt-2'>
          <li><p>If we provide you with account information such as a user name, identification number, account code, and/or password, you must keep such information confidential and secret and not disclose it to anyone.</p></li>
          <li><p>All account information is provided for use of the named account holder only, and not for any other person. You are responsible for any consequences of unauthorized access to your account due to any disclosure of your account information to any third party. </p></li>
          <li><p>We reserve the right to withdraw access to your account without notice for any actual or suspected breach of these Terms and Conditions or any other documentation referred to in them, including, without limitation, where we suspect that there has been unauthorized access to your account, or any unauthorized disclosure of your login information. </p></li>
          <li><p>If you know or suspect that the confidentiality of your login information has been compromised, for example, by the disclosure of such information to any third party, you must immediately change your password. If you are unable to change your password, you must immediately notify us by email, at <Link to="mailto:info@sprooze.com" className='link'>info@sprooze.com</Link> </p></li>
        </ul>

        <li><h2 className="heading-three">MODIFICATIONS TO THESE TERMS AND CONDITIONS:</h2></li>
        <ul className='mt-2'>
          <li>
            <p>We reserve the right to update these Terms and Conditions and any other documentation referred to in any of these documents from time to time. We may change our Terms and Conditions and other documentation for any reason, including: </p>
            <ul>
              <li style={{ listStyle: 'lower-roman' }}><p>To reflect any changes in the way we carry out our business; </p></li>
              <li style={{ listStyle: 'lower-roman' }}><p> To account for any changes we make to our website, including, without limitation, any new features or functionality we provide, any adjustments to the means by which we provide notices to you, or any changes in the content, purpose or availability of the website;  </p></li>
              <li style={{ listStyle: 'lower-roman' }}><p> To accurately describe our current data-processing activities so that you are kept up to date with our latest practices; </p></li>
              <li style={{ listStyle: 'lower-roman' }}><p>To ensure, that our documentation complies and remains compliant with any and all current and future applicable laws, regulations and official guidance.  </p></li>
            </ul>
            <li><p>If required by law, we will provide you with notice of any changes in these Terms and Conditions or the other documentation referred to in them by posting a notice on the website and/or by posting an updated version of these Terms and Conditions or other such documentation on our website with a new effective date stated at the beginning of them. </p></li>
          </li>
        </ul>

        <li><h2 className="heading-three">INFORMATION AND CONTENT ON OUR WEBSITE PROVIDED ON NON-RELIANCE BASIS:</h2></li>
        <ul className='mt-2'>
          <li><p>Our website is made available to you in order to provide you with general information about us, our business, and any services that we offer from time to time. We do not make our website available for any other purposes, except as expressly provided in these Terms and Conditions.</p></li>
          <li><p>We make no representations and provide no warranties whatsoever, whether express or implied, that any of the content or materials available on our website from time to time are accurate, up to date or complete.</p></li>
        </ul>

        <li><h2 className="heading-three">OTHER DOCUMENTS GOVERNING YOUR USE OF OUR WEBSITE:</h2></li>
        <ul className='mt-2'>
          <li><p>In addition to these Terms and Conditions, your use of our website is also governed by the following document: Our privacy policy is available at <Link className='link' to='https://www.distressedunrest.com/mailto:fabylisschool@icloud.com'>https://www.distressedunrest.com</Link> </p></li>
          <li><p>Our privacy policy governs our use of your information. </p></li>
          <li><p>It sets out the types of information we collect, the reasons we collect it, how we use it, where we may pass it on to any third parties, in what circumstances and for what reasons, and any other relevant information relating to our use and/or processing of your information and your rights in relation to your information.</p></li>
        </ul>

        <li><h2 className="heading-three">LINKS TO OTHER WEBSITES:</h2></li>
        <ul className='mt-2'>
          <li><p>Links to third-party content or websites may appear on our website from time to time.
            We are not responsible for the content of any websites accessible via any link(s) on our website.
            All content on third-party websites is outside of our control, and we do not represent or warrant that such content is related to us or our website,
            suitable or appropriate for use or viewing, lawful or accurate.</p></li>
          <li><p>You should check the terms & conditions and privacy policy of any such third party to establish how they may use your information
            before you decide to use their website and its features.</p></li>
        </ul>

        <li><h2 className="heading-three">AVAILABILITY OF OUR WEBSITE:</h2></li>
        <ul className='mt-2'>
          <li><p>We make no representations and provide no warranties that:</p>
            <ul>
              <li style={{ listStyle: 'lower-roman' }}>The website will be made available at any specific time or from any specific geographical location;</li>
              <li style={{ listStyle: 'lower-roman' }}>Your access to the website will be continuous or uninterrupted; or</li>
              <li style={{ listStyle: 'lower-roman' }}>The website will be accessible or optimized on all browsers, computers, tablets, phones, or viewing platforms.</li>
              <li style={{ listStyle: 'lower-roman' }}>We reserve the right to suspend access to all or part of the website for any reason, including for business or operational reasons, such as improving the appearance or functionality of the website, content updates, periodic maintenance, or to resolve any issues that we become aware of. Wherever we anticipate that we need to suspend access to the website for a considerable period of time, we will try to provide you with prior notice where reasonably practicable. </li>
            </ul>
          </li>
        </ul>


        <li><h2 className="heading-three">PERMITTED USE OF MATERIALS ON OUR WEBSITE: </h2></li>
        <ul className='mt-2'>
          <li><p>The content on our website is provided for your personal, private, and non-commercial use only. You may print or share the content from our website for lawful personal, private, and non-commercial purposes, and you may also make others within your organization aware of the content on our website. You may not otherwise extract, reproduce or distribute the content of our website without our prior written consent. </p></li>
          <li><p>Whenever you pass on any content or materials from our website to anyone, you must acknowledge us as the authors of such content or materials (or any other authors wherever credited by us) at the time when you pass on such content or materials.</p></li>
        </ul>

        <li><h2 className="heading-three">PROHIBITED USES OF OUR WEBSITE: </h2></li>
        <ul className='mt-2'>
          <li><p>You must not reproduce duplicate, copy or resell any part of our website or any content from our website, save and except to the extent expressly permitted in these Terms and Conditions.</p></li>
          <li><p>You must not, without our prior written consent, access, interfere with, damage or disrupt in any way our website or any part of it, our systems, any of our hardware or equipment or any networks on which our website is hosted, any software that we use to create or modify the website or to make the website available to you, or any hardware, equipment, network, server, software or technology owned or operated by us or any third party. </p></li>
          <li><p>You must use our website for lawful purposes only and in accordance with these Terms and Conditions.</p></li>
          <li><p>You must not use our website: </p></li>
          <li><p>For any purpose that is unlawful or that in any way breaches any applicable laws or regulations, whether local, national or international; </p></li>
          <li><p>For any fraudulent purposes whatsoever; </p></li>
          <li><p>To conduct any unsolicited or unauthorized advertising or direct or indirect marketing to anyone by any means, or to otherwise spam, communicate with or market to anyone any goods, services or business not authorized by us; </p></li>
          <li><p>To upload, host or transmit any viruses, malware, adware, spyware, worms, Trojan horses, keystroke loggers, spyware, logic bombs, time bombs or any other harmful programs or code which could adversely affect the use or operation of the website, our hardware or systems, or the computers, tablets, phones or other devices of any users or other third parties, or to upload any content or materials containing any such content;</p></li>
          <li><p>You will not partake in any behaviour that victimizes, harasses, degrades, or intimidates an individual or group of individuals on the basis of religion, gender, sexual orientation, race, ethnicity, age, or disability. To communicate with, harm or attempt to harm in any way; </p></li>
        </ul>

        <li><h2 className="heading-three">LIMITATIONS OF LIABILITY: </h2></li>
        <ul className='mt-2'>
          <li><p>OUR ENTIRE LIABILITY, AND YOUR EXCLUSIVE REMEDY, IN LAW, IN EQUITY, OR OTHERWISE, WITH RESPECT TO THE WEBSITE CONTENT, PRODUCTS AND SERVICES AND/OR FOR ANY BREACH OF THESE TERMS AND CONDITIONS IS SOLELY LIMITED TO THE AMOUNT YOU PAID, FOR PRODCUTS AND SERVICES PURCHASED VIA THE WEBSITE. </p></li>
          <li><p>WE WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES IN CONNECTION WITH THESE TERMS AND CONDITIONS OR THE SERVICES IN ANY MANNER, INCLUDING LIABILITIES RESULTING FROM </p>
            <ul>
              <li style={{ listStyle: 'lower-alpha' }}>YOUR USE OF OUR WEBSITE; </li>
              <li style={{ listStyle: 'lower-alpha' }}>ANY CORRUPTION OR LOSS OF DATA;</li>
              <li style={{ listStyle: 'lower-alpha' }}>ANY INABILITY TO ACCESS OUR WEBSITE, INCLUDING, WITHOUT LIMITATION, ANY INTERRUPTIONS, SUSPENSION OR WITHDRAWAL OF OUR WEBSITE (FOR ANY REASON WHATSOEVER); </li>
              <li style={{ listStyle: 'lower-alpha' }}>ANY USE YOU MAKE OF ANY CONTENT OR MATERIALS ON OUR WEBSITE, INCLUDING ANY RELIANCE YOU MAKE ON SUCH CONTENT OR MATERIAL;</li>
              <li style={{ listStyle: 'lower-alpha' }}>ANY LOSS OF SAVINGS; </li>
              <li style={{ listStyle: 'lower-alpha' }}>ANY OTHER SECONDARY, CONSEQUENTIAL OR INDIRECT LOSSES, </li>
            </ul>
          </li>
          <li><p>AND EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE, WITHOUT LIMITATION, YOU ASSUME AND SHALL BE LIABLE FOR THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR OR CORRECTION IN THE EVENT OF ANY SUCH LOSS, DAMAGE, COSTS, EXPENSES, LIABILITIES OR PENALTIES ARISING. </p></li>
          <li><p>WE SHALL NOT BE LIABLE FOR ANY DAMAGE THAT YOU COULD HAVE AVOIDED BY FOLLOWING OUR ADVICE TO APPLY AN UPDATE OFFERED TO YOU FREE OF CHARGE OR FOR DAMAGE THAT WAS CAUSED BY YOU FAILING TO CORRECTLY FOLLOW INSTALLATION INSTRUCTIONS OR TO HAVE IN PLACE THE MINIMUM SYSTEM REQUIREMENTS ADVISED BY US. </p></li>
          <li><p>YOU AGREE THAT IN THE EVENT THAT YOU INCUR ANY DAMAGES, LOSSES OR INJURIES ARISING OUT OF, OR IN CONNECTION WITH, OUR ACTS OR OMISSIONS, THE DAMAGES, IF ANY, CAUSED TO YOU ARE NOT IRREPARABLE OR SUFFICIENT TO ENTITLE YOU TO AN INJUNCTION PREVENTING ANY EXPLOITATION OF ANY WEBSITE, SERVICE, PROPERTY, OTHER CONTENT OWNED OR CONTROLLED BY US, AND YOU WILL HAVE NO RIGHTS TO ENJOIN OR RESTRAIN THE DEVELOPMENT, PRODUCTION, DISTRIBUTION, ADVERTISING, EXHIBITION OR EXPLOITATION OF ANY WEBSITE, PROPERTY, SERVICE, OR OTHER CONTENT OWNED OR CONTROLLED BY US. </p></li>
          <li><p>To the extent that any of the provisions of this clause are unenforceable as outright exclusions of liability, they shall be construed as limitations on liability, limiting our liability to you to the maximum extent permitted by law. </p></li>
        </ul>

        <li><h2 className="heading-three">NO WARRANTY; DISCLAIMERS: </h2></li>
        <ul className='mt-2'>
          <li><p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, DOCUMENTATION, AND SERVICES ARE PROVIDED SOLELY ON AN “AS IS,” AND “AS AVAILABLE BASIS, WITHOUT WARRANTY OF ANY KIND, AND WE MAKES NO (AND HEREBY DISCLAIMS ALL) WARRANTIES, REPRESENTATIONS, OR CONDITIONS, WHETHER WRITTEN, ORAL, EXPRESS, IMPLIED OR STATUTORY, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF SATISFACTORY QUALITY, COURSE OF DEALING, TRADE USAGE OR PRACTICE, MERCHANTABILITY, TITLE, NONINFRINGEMENT, OR FITNESS FOR A PARTICULAR PURPOSE, WITH RESPECT TO THE USE, MISUSE, OR INABILITY TO USE THE WEBSITE, DOCUMENTATION, OR SERVICES (IN WHOLE OR IN PART) OR ANY OTHER PRODUCTS OR SERVICES PROVIDED TO USER BY US. WE DOES NOT WARRANT THAT ANY OR ALL ERRORS CAN BE CORRECTED, OR THAT OPERATION OF THE WEBSITE AND SERVICES SHALL BE UNINTERRUPTED OR ERROR-FREE. SOME STATES AND JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR CONDITIONS OR LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO USER IN THEIR ENTIRETY.</p></li>
        </ul>

        <li><h2 className="heading-three">DISCLAIMERS: </h2></li>
        <ul className='mt-2'>
          <li><p>THE WEBSITE IS PROVIDED ON AN “AS IS”, “AS AVAILABLE” AND “WITH ALL FAULTS” BASIS. TO THE FULLEST EXTENT PERMISSIBLE BY LAW, WE DO NOT MAKE ANY REPRESENTATIONS OR WARRANTIES OR ENDORSEMENTS OF ANY KIND WHATSOEVER, EXPRESS OR IMPLIED, AS TO: </p>
            <ul>
              <li style={{ listStyle: 'disc' }}>THE SERVICE; </li>
              <li style={{ listStyle: 'disc' }}>THE WEBSITE CONTENT;  </li>
              <li style={{ listStyle: 'disc' }}>USER CONTENT; OR  </li>
              <li style={{ listStyle: 'disc' }}>SECURITY ASSOCIATED WITH THE TRANSMISSION OF INFORMATION TO THE WEBSITE.  </li>
            </ul>
          </li>
          <li><p>IN ADDITION, WE HEREBY DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, TITLE, CUSTOM, TRADE, QUIET ENJOYMENT, SYSTEM INTEGRATION AND FREEDOM FROM COMPUTER VIRUS.</p></li>
          <li><p>WE DO NOT REPRESENT OR WARRANT THAT THE SERVICE WILL BE ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED OR THAT THE SERVICE OR THE SERVER THAT MAKES THE SERVICE AVAILABLE IS FREE FROM ANY HARMFUL COMPONENTS, INCLUDING, WITHOUT LIMITATION, VIRUSES. WE DO NOT MAKE ANY REPRESENTATIONS OR WARRANTIES THAT THE INFORMATION (INCLUDING ANY INSTRUCTIONS) ON THE SERVICE IS ACCURATE, COMPLETE, OR USEFUL. YOU ACKNOWLEDGE THAT YOUR USE OF THE WEBSITE IS AT YOUR SOLE RISK. WE DO NOT WARRANT THAT YOUR USE OF THE WEBSITE IS LAWFUL IN ANY PARTICULAR JURISDICTION, AND WE SPECIFICALLY DISCLAIM SUCH WARRANTIES. SOME JURISDICTIONS LIMIT OR DO NOT ALLOW THE DISCLAIMER OF IMPLIED OR OTHER WARRANTIES SO THE ABOVE DISCLAIMER MAY NOT APPLY TO YOU TO THE EXTENT SUCH JURISDICTION'S LAW IS APPLICABLE TO YOU AND THESE TERMS AND CONDITIONS. </p></li>
          <li><p>BY ACCESSING OR USING THE WEBSITE YOU REPRESENT AND WARRANT THAT YOUR ACTIVITIES ARE LAWFUL IN EVERY JURISDICTION WHERE YOU ACCESS OR USE THE SERVICE. WE DO NOT ENDORSE CONTENT AND SPECIFICALLY DISCLAIM ANY RESPONSIBILITY OR LIABILITY TO ANY PERSON OR ENTITY FOR ANY LOSS, DAMAGE (WHETHER ACTUAL, CONSEQUENTIAL, PUNITIVE OR OTHERWISE), INJURY, CLAIM, LIABILITY OR OTHER CAUSE OF ACTION OF ANY KIND OR CHARACTER BASED UPON OR RESULTING FROM ANY CONTENT. </p></li>
        </ul>

        <li><h2 className="heading-three">INDEMNIFICATION: </h2></li>
        <ul className='mt-2'>
          <li><p>You and also any third party for or on behalf of whom you operate an account or activity on the website, agree to defend, indemnify and hold us harmless from and against any claims, liabilities, damages, losses and expenses, including, without limitation, reasonable legal and attorneys’ fees and costs, arising out of or in any way connected with any of the following: </p>
            <ul>
              <li style={{ listStyle: 'disc' }}>Your uploads, access to or use of the website; </li>
              <li style={{ listStyle: 'disc' }}>Your breach or alleged breach of these Terms and Conditions;</li>
              <li style={{ listStyle: 'disc' }}>Your violation of any third-party right, including, without limitation, any intellectual property right, publicity, confidentiality, property, or privacy right; </li>
              <li style={{ listStyle: 'disc' }}>Your violation of any laws, rules, regulations, codes, statutes, ordinances or orders of any governmental and quasi-governmental authorities, including, without limitation, all regulatory, administrative and legislative authorities; or </li>
              <li style={{ listStyle: 'disc' }}>Any misrepresentation made by you. </li>
            </ul>
          </li>
          <li><p>You will cooperate as fully required by us in the defence of any claim. We reserve the right to assume the exclusive defence and control of any matter subject to indemnification by you, and you will not, in any event, settle any claim without our prior written consent. </p></li>
        </ul>

        <li><h2 className="heading-three">NO WAIVER: </h2></li>
        <ul className='mt-2'>
          <li><p>No party shall be deemed to have waived any provision of these Terms and Conditions or the exercise of any rights held under these Terms and Conditions unless such waiver is made expressly and in writing. Waiver by any party of a breach or violation of any provision of these Terms and Conditions shall not constitute a waiver of any other subsequent breach or violation.</p></li>
        </ul>

        <li><h2 className="heading-three">SEVERABILITY: </h2></li>
        <ul className='mt-2'>
          <li><p>In the event any provision of these Terms is deemed to be void, invalid, or unenforceable, that provision shall be severed from the remainder of these Terms and Conditions so as not to cause the invalidity or unenforceability of the remainder of these Terms. All remaining provisions of these terms shall then continue in full force and effect. If any provision shall be deemed invalid due to its scope or breadth, such provision shall be deemed valid to the extent of the scope and breadth permitted by law.  </p></li>
        </ul>

        <li><h2 className="heading-three">GOVERNING LAW: </h2></li>
        <ul className='mt-2'>
          <li><p>These Terms and Conditions, any documents they refer to, and any disputes arising from or in relation to them or any documents they refer to, whether contractual or non-contractual, shall be governed by and construed in accordance with the law of the Netherlands. </p></li>
        </ul>

        <li><h2 className="heading-three">COPYRIGHT: </h2></li>
        <ul className='mt-2'>
          <li><p>The copyright in these Terms and Conditions is either owned by or licensed to, us and is protected by copyright laws around the world and copyright protection software. Unless expressly indicated otherwise, all intellectual property rights in this document and elsewhere on our website, including any content on our website, are reserved.  </p></li>
        </ul>

        <li><h2 className="heading-three">CONTACT INFORMATION:   </h2></li>
        <ul className='mt-2'>
          <li><p>If you have any questions regarding these terms and conditions of this website, please contact us by any of the following means; </p></li>
          <li><p><span style={{ fontWeight: 800 }}>Web:</span> <Link className='link' to='https://www.sprooze.com'>www.sprooze.com</Link> </p></li>
          <li><p><span style={{ fontWeight: 800 }}>Email:</span> <Link to="mailto:info@sprooze.com" className='link'>info@sprooze.com</Link> </p></li>
          <li><p><span style={{ fontWeight: 800 }}>Address:</span> Lavendelplein 133. 5643 EH Eindhoven, Netherlands. </p></li>
        </ul>
      </ol>

      <div className="col-12 pt-5 pb-5 d-flex align-items-center justify-content-between">
        <p>© All rights reserved from sprooze.</p>
        <p>October, 2024.</p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
