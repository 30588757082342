import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Container,
  useMediaQuery,
  Button,
} from "@mui/material";
import "../../styles/inputBox.css";
import FacebookIcon from "../../assets/images/Subscription/FaceBokkIcon.svg";
import GoogleIcon from "../../assets/images/Subscription/GoogleIcon.svg";
import InstagramIcon from "../../assets/images/Subscription/InstagramIcon.svg";
import LinkedInIcon from "../../assets/images/Subscription/LinkedInIcon.svg";
import XIcon from "../../assets/images/Subscription/XIcon.svg";
import { useNavigate } from "react-router-dom";
import { PlanType } from "../../helper/planConstant";
import { getRequest, postRequest } from "../../api/apiServices";
import apiList from "../../api/apiList";
import Loader from "../../utils/Loading";

const socialMediaIcons: any = {
  FACEBOOK: FacebookIcon,
  LINKEDIN: LinkedInIcon,
  INSTAGRAM: InstagramIcon,
  GOOGLE: GoogleIcon,
  TWITTER: XIcon,
};

const SubscriptionPlan = () => {
  const isMobile = useMediaQuery("(max-width:578px)");
  const isTabs = useMediaQuery("(max-width:1200px)");
  const navigate = useNavigate();
  const [plans, setPlans] = useState([]);
  const [activePlan, setActivePlan] = useState();
  const [loading, setLoading] = useState(false);

  const handlePlanClick = (planType: PlanType) => {
    navigate(`/payment-gateway/${planType}`);
  };

  const handleFreePlanClick = (planType: PlanType) => {
    navigate("/content-creation", {
      state: { subscriptionPackage: planType },
    });
  };

  const getPlanList = async () => {
    setLoading(true);
    const response = await postRequest(apiList.subscriptionList);
    setPlans(response?.data?.data || []);
    setLoading(false);
  };

  const getActivePlan = async () => {
    setLoading(true);
    const response = await getRequest(apiList.activeSubscription);
    setActivePlan(response?.data?.data);
    setLoading(false);
  };

  useEffect(() => {
    getActivePlan();
    if (!activePlan) getPlanList();
  }, []);

  if (activePlan && Object.keys(activePlan).length > 0)
    navigate("/content-creation");

  return loading ? (
    <>
      <Loader />
    </>
  ) : (
    <Container
      style={{
        height: isMobile ? "auto" : "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // marginLeft: isTabs ? "0px" : "50px",
        margin: "0px auto"
        // padding: isTabs ? "20px" : "0px",
      }}
    >
      <Grid
        spacing={2}
        bgcolor="#FFFFFF"
        width="1484px"
        borderRadius="22px"
        border="1px solid #D6D6D7"
        margin={isTabs ? "20px" : 0}
      >
        <Grid item height={isMobile ? "auto" : "auto"} style={{ padding: 30 }}>
          <Grid
            container
            direction="row"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={isMobile ? "10px" : ""}
          >
            <Grid item width="fit-content">
              <Typography color="#273848" fontWeight={700} fontSize="20.94px">
                Choose your plan
              </Typography>
              <Typography color="#273848" fontWeight={500} fontSize="12.94px">
                Lorem ipsum dolor sit amet consectetur. Tortor odio.
              </Typography>
            </Grid>
            <Grid item style={{ maxWidth: "fitContent" }}>
              <Button
                type="submit"
                variant="contained"
                fullWidth
                className="free-plan-button"
                style={{
                  backgroundColor: "#84E1CC",
                  // color: "#273848",
                  boxShadow: "none",
                  width: "fitContent",
                  fontWeight: 700,
                  fontSize: "15px",
                  borderRadius: "5px",
                  padding: "17px 17px",
                  lineHeight: "0px",
                  textTransform: "none",
                  letterSpacing: "0px",
                }}
                onClick={() => handleFreePlanClick(PlanType.FREE)}
              >
                <span className="free-plan-button-content">
                  Continue with free content
                </span>
              </Button>
            </Grid>
          </Grid>
          <Grid my={5}></Grid>
          <Grid
            container
            direction="row"
            display="flex"
            gap={isMobile ? "10px" : "30px"}
            justifyContent="center"
            alignItems="center"
          >
            {plans.map((plan: any, index) => {
              return (
                <Grid
                  spacing={2}
                  bgcolor="#FFFFFF"
                  borderRadius="22px"
                  className="main-plan-div"
                  width="320px"
                  border="1px solid #D6D6D7"
                  margin={0}
                  style={{ padding: 20 }}
                  onClick={() => handlePlanClick(plan.code)}
                  key={index + 1}
                >
                  <Grid item width="fit-content">
                    <Typography
                      color="#273848"
                      fontWeight={600}
                      fontSize="30px"
                      className="plan-title"
                    >
                      {plan?.name || ""}
                    </Typography>
                    <Typography
                      color="#273848"
                      fontWeight={400}
                      fontSize="20.94px"
                    >
                      {plan.discount_price > 0 ? (
                        <>
                          <span
                            style={{
                              textDecoration: "line-through",
                              marginRight: "8px",
                            }}
                          >
                            € {plan.discount_price}
                          </span>
                          <span>€ {plan.price}</span>
                        </>
                      ) : (
                        `€ ${plan.price}`
                      )}
                    </Typography>
                  </Grid>
                  <Grid my={4}></Grid>
                  <Grid item width="230px">
                    <Typography
                      color="#273848"
                      fontWeight={400}
                      fontSize="16px"
                    >
                      On average, about
                      <Typography
                        component="span"
                        fontWeight={700}
                        fontSize="16px"
                      >
                        {" "}
                        {plan.description}{" "}
                      </Typography>
                      apply for this package.
                    </Typography>
                  </Grid>
                  <Grid my={4}></Grid>
                  <Grid item width="fit-content" gap="10px" display="flex">
                    {plan.socialMedia.map((platform: string) => (
                      <img
                        key={platform}
                        src={socialMediaIcons[platform]}
                        alt={`${platform} Icon`}
                      />
                    ))}
                  </Grid>
                  <Grid my={6}></Grid>
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    className="request-button"
                    style={{
                      backgroundColor: "#FFFFFF",
                      color: "#273848",
                      border: "1px solid #D6D6D7",
                      boxShadow: "none",
                      width: "fitContent",
                      fontWeight: 700,
                      fontSize: "15px",
                      borderRadius: "5px",
                      padding: "17px 17px",
                      lineHeight: "0px",
                      textTransform: "none",
                      letterSpacing: "0px",
                    }}
                  >
                    <span className="request-button-content">
                      Choose package
                    </span>
                  </Button>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SubscriptionPlan;
