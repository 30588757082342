import toast from "react-hot-toast";

const Toast = (message, type = "success") => {
  toast[type](message, {
    duration: 2000,
    position: "top-right",
    style: { borderRadius: "8px", minWidth: "250px" },
    className: "",
  });
};

export default Toast;
