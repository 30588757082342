import React, { useEffect, useState } from "react";
import ProfileFirst from "../../components/Profile/ProfileFirst"; // Import your Profile component
import ProfileSecond from "../../components/Profile/ProfileSecond";
import ProfileThird from "../../components/Profile/ProfileThird";
import ProfileFourth from "../../components/Profile/Profilefourth";
import authService from "../../services/authService";
import { useParams } from "react-router-dom";
import { getRequest } from "../../api/apiServices";
import { getCompany } from "../../api/apiList";
import Loading from "../../utils/Loading"


const Profile: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);

  // handle Form Data
  const [profileData, setProfileData] = useState({});
  const [loading, setLoading] = useState(false)
  const [update, setUpdate] = useState(false)

  // Get The Id
  const params = useParams()
  // console.log('params: ', params);

  const goToNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const goToPreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const getCompanyData = async () => {
    const response = await getRequest(getCompany(params?.id))
    return response?.data?.data
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (params?.id) {
          setLoading(true);
          const data = await getCompanyData()
          setProfileData(data)
          setUpdate(true)
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData()
  }, [params?.id])

  return loading ? <Loading/> : (
    <>
      {currentPage === 1 && <ProfileFirst onNext={goToNextPage} setProfileData={setProfileData} initialData={profileData} mode={update} />}
      {currentPage === 2 && (
        <ProfileSecond
          onBack={goToPreviousPage}
          onNext={goToNextPage}
          setProfileData={setProfileData} initialData={profileData}
          mode={update}
        />
      )}
      {currentPage === 3 && (
        <ProfileThird
          onBack={goToPreviousPage}
          onNext={goToNextPage}
          setProfileData={setProfileData} initialData={profileData}
          mode={update}
        />
      )}
      {currentPage === 4 && (
        <ProfileFourth
          onBack={goToPreviousPage}
          onNext={goToNextPage}
          setProfileData={setProfileData} initialData={profileData}
          mode={update}
        />
      )}
    </>
  );
};

export default Profile;
