// const baseUrl = 'http://192.168.1.3:3000/api/';
// const baseUrl = "http://54.88.107.63:3000/api/";
const baseUrl = process.env.REACT_APP_BASE_URL;

module.exports = {
  //dashboard
  getDashboardData: `${baseUrl}dashboard/count`,

  //job
  createJob: `${baseUrl}create-job`,
  getJob: (id) => `${baseUrl}get-job/${id}`,
  updateJob: (id) => `${baseUrl}update-job/${id}`,
  jobsList: `${baseUrl}jobs`,
  deleteJob: (id) => `${baseUrl}delete-job/${id}`,
  getCompanyJob: `${baseUrl}company-jobs`,
  getJobByName: `${baseUrl}get-job`,

  //candidate
  createCandidate: `${baseUrl}create-candidate`,
  getCandidate: (id) => `${baseUrl}get-candidate/${id}`,
  updateCandidate: (id) => `${baseUrl}update-candidate/${id}`,
  candidatesList: `${baseUrl}candidates`,
  deleteCandidate: (id) => `${baseUrl}delete-candidate/${id}`,
  getCandidateByJob: `${baseUrl}get-candidate`,
  updateCandidateDescription: `${baseUrl}candidate-leads/view/update`,

  //company
  createCompany: `${baseUrl}create-company`,
  getCompany: (id) => `${baseUrl}get-company/${id}`,
  updateCompany: (id) => `${baseUrl}update-company/${id}`,

  //User
  getUserProfile: (id) => `${baseUrl}user/${id}`,
  updateUserProfile: (id) => `${baseUrl}update-user-profile/${id}`,
  updateUserPassword: (id) => `${baseUrl}update-user-password/${id}`,

  //Hirinn Manager
  getHiringManagerList: `${baseUrl}hiring-manager`,

  // Offer Letter
  genratePDFOfferLetter: `${baseUrl}offer-send-candidate`,
  sendOfferLetterEmail: `${baseUrl}send-email-candidate`,

  //Content Creations
  createContent: `${baseUrl}content/create`,
  getContentById: (id) => `${baseUrl}content/get/${id}`,
  contentList: `${baseUrl}content/list`,
  updateContent: (id) => `${baseUrl}content/update/${id}`,

  //Payment API
  initializePayment: `${baseUrl}payment/create-payment-intent`,
  confirmPayment: `${baseUrl}payment/confirm-payment`,

  // subscription list
  subscriptionList: `${baseUrl}subscription/list`,
  subscriptionGet: `${baseUrl}subscription/get`,
  activeSubscription: `${baseUrl}subscription/active`,
  deActiveSubscription: `${baseUrl}subscription/deactive`,

  // CandidatesLeads API
  createCandidateLeads: `${baseUrl}candidate-leads/create`,
  getCandidateLeads: `${baseUrl}candidate-leads/view`,
  candidatesLeadsList: `${baseUrl}candidate-leads/list`,
  updateCandidateStatus: `${baseUrl}candidate-leads/updates/status`,
  giveCandidateFeedback: `${baseUrl}candidate-leads/feedback`,
  getCandidateFeedback: `${baseUrl}candidate-leads/feedback/view`,
  sendInterViewTipsToCandidate: `${baseUrl}candidate-leads/send-interview-tips`,

  // Compaign Form
  createCompaignForm: `${baseUrl}campaign/create`,
  getCompaignForm: (id) => `${baseUrl}campaign/get/${id}`,
  compaignFormList: `${baseUrl}campaign/list`,
  updateCompaignForm: (id) => `${baseUrl}campaign/update/${id}`,
  deleteCompaignForm: (id) => `${baseUrl}delete-campaign/${id}`,
  candidatesNeedMoreInfoEmail: `${baseUrl}candidate-leads/view/info-email`,

  // Analytics
  getAnalytics: `${baseUrl}adzuna-data-analysis`,
  getCharts: `${baseUrl}adzuna-chart-data`,
  getHistoricalChart: `${baseUrl}adzuna/historical-data`,
  getHistogramChart: `${baseUrl}adzuna/histogram-data`,
  getTopCompaniesChart: `${baseUrl}adzuna/top-companies-data`,
  getRegionalChart: `${baseUrl}adzuna/regional-data`,
};
