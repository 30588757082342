import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Container,
  useMediaQuery,
  Box,
  Button,
  TextareaAutosize,
  MenuItem,
  TextField,
  InputLabel,
  OutlinedInput,
  CircularProgress,
} from "@mui/material";
import "../../styles/inputBox.css";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import CurrentLocationIcon from "../../assets/images/CandidateIcon/current-location-color.svg";
import { getRequest, postRequest, putRequest } from "../../api/apiServices";
import {
  getCandidateByJob,
  getCompanyJob,
  updateCandidate,
} from "../../api/apiList";
import { useParams } from "react-router-dom";
import ConfirmationDialog from "../DailogBox";
import { generateTextObject } from "../../utils/AIGenerateText";
import LoadingOverlay from "../../utils/LoadingOverlay";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { companyJobList } from "../../store/jobSlice";
import {
  LocalizationProvider,
  DateTimePicker,
  DatePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";

interface CandidateSpeFirstProps {
  onNext: () => void; // Function to navigate to the next component
  setCandidateData: (data: any) => void;
  initialData: any;
  mode: any;
}

interface GeneratedTextResponse {
  [key: string]: string;
}

const CandidateSpeFirst: React.FC<CandidateSpeFirstProps> = ({
  onNext,
  setCandidateData,
  initialData,
  mode,
}: CandidateSpeFirstProps) => {
  const isMobile = useMediaQuery("(max-width:578px)");
  const [position, setPosition] = useState("");
  const isTabs = useMediaQuery("(max-width:1200px)");

  // Use Store
  const dispatch: AppDispatch = useDispatch<AppDispatch>();

  // declare form fields
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [jobPosition, setJobPosition] = useState("");
  const [rolesResponsibilities, setRolesResponsibilities] = useState("");
  const [opportunities, setOpportunities] = useState("");
  const [salaryExpectation, setSalaryExpectation] = useState("");
  const [salaryRange, setSalaryRange] = useState("");
  const [jobBenifits, setJobBenifits] = useState("");
  const [startExtended, setStartExtended] = useState<Dayjs | null>(null);
  const [commitments, setCommitments] = useState("");
  const [loading, setLoading] = useState(false);

  //params
  const params = useParams();

  const [errors, setErrors] = useState({
    name: "",
    phone: "",
    email: "",
    jobPosition: "",
    rolesResponsibilities: "",
    opportunities: "",
    salaryExpectation: "",
    salaryRange: "",
    jobBenifits: "",
    commitments: "",
  });

  const formIsValid = () => {
    const newErrors = {
      name: !name?.trim() ? "Name is required" : "",
      phone: !phone?.trim() ? "Phone Number is required" : "",
      email: !email?.trim() ? "Email is required" : "",
      jobPosition: !jobPosition ? "Job Position is required" : "",
      rolesResponsibilities: !rolesResponsibilities?.trim()
        ? "Current Role and Responsibilities is required"
        : "",
      opportunities: !opportunities?.trim() ? "Opportunities is required" : "",
      salaryExpectation: !salaryExpectation?.trim()
        ? "Salary Expectation is required"
        : "",
      salaryRange: !salaryRange?.trim() ? "Salary Range is required" : "",
      jobBenifits: !jobBenifits?.trim()
        ? "Benefits Are Important To You In A Job is required"
        : "",
      commitments: !commitments?.trim()
        ? "Obstacles Or Commitments is required"
        : "",
    };

    setErrors(newErrors);

    return Object.values(newErrors).every((error) => error === "");
  };

  useEffect(() => {
    dispatch(companyJobList());
    if (initialData) {
      setName(initialData?.name);
      setPhone(initialData?.phone);
      setEmail(initialData?.email);
      setJobPosition(initialData?.jobPosition);
      setRolesResponsibilities(initialData?.rolesResponsibilities);
      setOpportunities(initialData?.opportunities);
      setSalaryExpectation(initialData?.salaryExpectation);
      setSalaryRange(initialData?.salaryRange);
      setJobBenifits(initialData?.jobBenifits);
      setStartExtended(dayjs(initialData?.startExtended));
      setCommitments(initialData?.commitments);
    }
  }, []);

  const getCompanyJob = useSelector((state: RootState) => state.companyJob);
  useEffect(() => {
    if (getCompanyJob.length > 0 && initialData.jobPosition) {
      const selectedJob = getCompanyJob.find(
        (job) => job.id.toString() === initialData.jobPosition.toString()
      );
      if (selectedJob) {
        setJobPositionName(selectedJob.jobTitle);
      }
    }
  }, [getCompanyJob]);

  const handlePosition = (event: SelectChangeEvent) => {
    setPosition(event.target.value as string);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    let isoStartExtended = "";

    if (startExtended && dayjs(startExtended).isValid()) {
      isoStartExtended = dayjs(startExtended).toISOString();
    }

    const data = {
      name,
      phone,
      email,
      jobPosition,
      rolesResponsibilities,
      opportunities,
      salaryExpectation,
      salaryRange,
      jobBenifits,
      startExtended: isoStartExtended,
      commitments,
    };
    if (formIsValid()) {
      if (mode) {
        setLoading(true);
        const response = await putRequest(updateCandidate(params?.id), data);
        setCandidateData(response?.data?.data);
        setLoading(false);
        onNext();
      } else {
        const allData = {
          ...initialData,
          ...data,
        };
        setCandidateData(allData);
        onNext();
      }
    }
  };

  // For Timing
  const [titleTimer, setTitleTimer] = useState<NodeJS.Timeout | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [foundCandidateData, setFoundCandidateData] = useState<any>(null);
  const [aiLoading, setAiLoading] = useState(false);

  // Creae A Method to handle The Data
  const handleCandidateJob = (e: any) => {
    e.preventDefault();
    const selectedJob = e.target.value;
    const selectedCompanyJob = getCompanyJob.find(
      (job) => job.id === selectedJob
    );
    if (selectedCompanyJob) {
      setJobPositionName(selectedCompanyJob?.jobTitle);
      setJobPosition(selectedCompanyJob.id.toString());
    }
    const findJob = getCompanyJob.filter((job) => job.id === selectedJob);
    const jobTitle = findJob[0]?.jobTitle;
    if (!mode) {
      if (titleTimer) {
        clearTimeout(titleTimer);
      }

      setTitleTimer(
        setTimeout(async () => {
          try {
            const response = await postRequest(getCandidateByJob, {
              id: selectedJob,
            });
            if (response?.status === 200 && response?.data) {
              setFoundCandidateData(response?.data?.data);
              setOpenDialog(true);
            } else {
              setAiLoading(true);
              const object = {
                workingHours: [
                  `Title : ${jobTitle} Question : What are your expectations regarding working hours and flexibility in this role?`,
                ],
                technicalSkills: [
                  `Title : ${jobTitle} Question : What technical skills or expertise do you bring to this role?`,
                ],
                deadlines: [
                  `Title : ${jobTitle} Question : How do you handle stress and tight deadlines?`,
                ],
                commonGoal: [
                  `Title : ${jobTitle} Question : Describe a situation where you had to collaborate with a diverse team to achieve a common goal.`,
                ],
                workplace: [
                  `Title : ${jobTitle} Question : Can you share an example of when you had to navigate a difficult conversation or conflict in the workplace?`,
                ],
                teamMembersColleague: [
                  `Title : ${jobTitle} Question : How would you describe yourself as a team member and a colleague?`,
                ],
                motivates: [
                  `Title : ${jobTitle} Question : What motivates you to excel in your work?`,
                ],
                responsibilitiesChallenges: [
                  `Title : ${jobTitle} Question : Can you describe your ideal job in terms of responsibilities and challenges?`,
                ],
                projects: [
                  `Title : ${jobTitle} Question : What kind of projects or tasks bring out the best in you professionally?`,
                ],
                newOrganization: [
                  `Title : ${jobTitle} Question : What factors are most important to you when considering a new organization?`,
                ],
                companyMission: [
                  `Title : ${jobTitle} Question : How do you align with our company's values and mission?`,
                ],
                jobFactores: [
                  `Titile : ${jobTitle} Question : What factors are crucial for you when choosing a new job?`,
                ],
                preferenceCultureGrowthCompensation: [
                  `Titile : ${jobTitle} Question : How do you prioritize between company culture, career growth, and compensation?`,
                ],
              };
              const data = (await generateTextObject(
                object
              )) as GeneratedTextResponse;
              initialData.workingHours = data?.workingHours;
              initialData.technicalSkills = data?.technicalSkills;
              initialData.deadlines = data?.deadlines;
              initialData.workplace = data?.workplace;
              initialData.commonGoal = data?.commonGoal;
              initialData.teamMembersColleague = data?.teamMembersColleague;
              initialData.motivates = data?.motivates;
              initialData.responsibilitiesChallenges =
                data?.responsibilitiesChallenges;
              initialData.projects = data?.projects;
              initialData.newOrganization = data?.newOrganization;
              initialData.companyMission = data?.companyMission;
              initialData.jobFactores = data?.jobFactores;
              initialData.preferenceCultureGrowthCompensation =
                data?.preferenceCultureGrowthCompensation;
              setAiLoading(false);
            }
          } catch (error) {
            console.error("Error in handleCandidateJob:", error);
          }
        }, 3000)
      );
    }
  };

  const handleDialogConfirm = () => {
    if (foundCandidateData) {
      setRolesResponsibilities(foundCandidateData?.rolesResponsibilities);
      setOpportunities(foundCandidateData?.opportunities);
      setSalaryExpectation(foundCandidateData?.salaryExpectation);
      setSalaryRange(foundCandidateData?.salaryRange);
      setJobBenifits(foundCandidateData?.jobBenifits);
      setStartExtended(dayjs(foundCandidateData?.startExtended));
      setCommitments(foundCandidateData?.commitments);
      initialData.workingHours = foundCandidateData?.workingHours;
      initialData.project = foundCandidateData?.project;
      initialData.specificChallenges = foundCandidateData?.specificChallenges;
      initialData.currentResponsibilities =
        foundCandidateData?.currentResponsibilities;
      initialData.educationalBackground =
        foundCandidateData?.educationalBackground;
      initialData.courceCertificates = foundCandidateData?.courceCertificates;
      initialData.technicalSkills = foundCandidateData?.technicalSkills;
      initialData.previousPositionTechnicalSkills =
        foundCandidateData?.previousPositionTechnicalSkills;
      initialData.deadlines = foundCandidateData?.deadlines;
      initialData.commonGoal = foundCandidateData?.commonGoal;
      initialData.workplace = foundCandidateData?.workplace;
      initialData.teamMembersColleague =
        foundCandidateData?.teamMembersColleague;
      initialData.motivates = foundCandidateData?.motivates;
      initialData.responsibilitiesChallenges =
        foundCandidateData?.responsibilitiesChallenges;
      initialData.ambitionsGrowthPath = foundCandidateData?.ambitionsGrowthPath;
      initialData.projects = foundCandidateData?.projects;
      initialData.newOrganization = foundCandidateData?.newOrganization;
      initialData.companyMission = foundCandidateData?.companyMission;
      initialData.jobFactores = foundCandidateData?.jobFactores;
      initialData.preferenceCultureGrowthCompensation =
        foundCandidateData?.preferenceCultureGrowthCompensation;
      initialData.interviewedOtherCompanies =
        foundCandidateData?.interviewedOtherCompanies;
    }
    setOpenDialog(false);
  };

  const [jobPositionName, setJobPositionName] = useState("");
  const handleDialogCancel = async () => {
    setOpenDialog(false);
    const findJob = getCompanyJob.filter(
      (job) => job.id.toString() === jobPosition.toString()
    );
    const jobTitle = findJob[0]?.jobTitle;
    if (!mode) {
      setAiLoading(true);
      const object = {
        workingHours: [
          `Title : ${jobTitle} Question : What are your expectations regarding working hours and flexibility in this role?`,
        ],
        technicalSkills: [
          `Title : ${jobTitle} Question : What technical skills or expertise do you bring to this role?`,
        ],
        deadlines: [
          `Title : ${jobTitle} Question : How do you handle stress and tight deadlines?`,
        ],
        commonGoal: [
          `Title : ${jobTitle} Question : Describe a situation where you had to collaborate with a diverse team to achieve a common goal.`,
        ],
        workplace: [
          `Title : ${jobTitle} Question : Can you share an example of when you had to navigate a difficult conversation or conflict in the workplace?`,
        ],
        teamMembersColleague: [
          `Title : ${jobTitle} Question : How would you describe yourself as a team member and a colleague?`,
        ],
        motivates: [
          `Title : ${jobTitle} Question : What motivates you to excel in your work?`,
        ],
        responsibilitiesChallenges: [
          `Title : ${jobTitle} Question : Can you describe your ideal job in terms of responsibilities and challenges?`,
        ],
        projects: [
          `Title : ${jobTitle} Question : What kind of projects or tasks bring out the best in you professionally?`,
        ],
        newOrganization: [
          `Title : ${jobTitle} Question : What factors are most important to you when considering a new organization?`,
        ],
        companyMission: [
          `Title : ${jobTitle} Question : How do you align with our company's values and mission?`,
        ],
        jobFactores: [
          `Titile : ${jobTitle} Question : What factors are crucial for you when choosing a new job?`,
        ],
        preferenceCultureGrowthCompensation: [
          `Titile : ${jobTitle} Question : How do you prioritize between company culture, career growth, and compensation?`,
        ],
      };
      const data = (await generateTextObject(object)) as GeneratedTextResponse;
      initialData.workingHours = data?.workingHours;
      initialData.technicalSkills = data?.technicalSkills;
      initialData.deadlines = data?.deadlines;
      initialData.workplace = data?.workplace;
      initialData.commonGoal = data?.commonGoal;
      initialData.teamMembersColleague = data?.teamMembersColleague;
      initialData.motivates = data?.motivates;
      initialData.responsibilitiesChallenges = data?.responsibilitiesChallenges;
      initialData.projects = data?.projects;
      initialData.newOrganization = data?.newOrganization;
      initialData.companyMission = data?.companyMission;
      initialData.jobFactores = data?.jobFactores;
      initialData.preferenceCultureGrowthCompensation =
        data?.preferenceCultureGrowthCompensation;
      setAiLoading(false);
    }
  };

  const disablePastDates = (date: Dayjs) => {
    return date.isBefore(dayjs(), "day");
  };

  return (
    <div>
      <Container
        style={{
          display: "flex",
          padding: "25px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          spacing={2}
          bgcolor="#FFFFFF"
          width="884px"
          borderRadius="22px"
          border="1px solid #D6D6D7"
          margin={0}
        >
          <Grid
            item
            height={isMobile ? "auto" : "auto"}
            style={{ padding: 30 }}
          >
            <Grid
              container
              direction="row"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              gap={isMobile ? "10px" : ""}
            >
              <Grid item width="fit-content">
                <Typography color="#273848" fontWeight={700} fontSize="20.94px">
                  Personal Information
                </Typography>
              </Grid>
              <Grid item gap={2} display="flex">
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#84E1CC",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#DFF4EF",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#DFF4EF",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#DFF4EF",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#DFF4EF",
                  }}
                />
              </Grid>
            </Grid>
            <Grid mt="26px"></Grid>
            <Typography
              color="#273848"
              fontWeight={700}
              lineHeight="14.94px"
              fontSize="15px"
              //   className="input-label-custom"
            >
              Personal Information
            </Typography>
            <Grid mt="15px"></Grid>
            <Grid mt="20px"></Grid>
            <Grid item>
              <Typography className="input-label-custom">Name</Typography>
              <Grid mb="5px"></Grid>
              <TextField
                placeholder="Enter your name"
                fullWidth
                sx={{
                  // mt: 1,
                  "& .MuiOutlinedInput-input": {
                    padding: "10px 11px", // Add padding here
                  },
                }}
                className="input-box-custom"
                error={!!errors.name}
                helperText={errors.name}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid mt="20px"></Grid>
            <Grid item>
              <Typography className="input-label-custom">Email</Typography>
              <Grid mb="5px"></Grid>
              <TextField
                sx={{
                  // mt: 1,
                  "& .MuiOutlinedInput-input": {
                    padding: "10px 11px", // Add padding here
                  },
                }}
                placeholder="Enter your email"
                fullWidth
                className="input-box-custom"
                error={!!errors.email}
                helperText={errors.email}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid mt="20px"></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                Mobile Number
              </Typography>
              <Grid mb="5px"></Grid>
              <TextField
                sx={{
                  // mt: 1,
                  "& .MuiOutlinedInput-input": {
                    padding: "10px 11px", // Add padding here
                  },
                }}
                placeholder="Enter your mobile number"
                fullWidth
                className="input-box-custom"
                error={!!errors.phone}
                helperText={errors.phone}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Grid>
            <Grid mt="20px"></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                Job Position
              </Typography>
              <Grid mb={1}></Grid>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={jobPosition}
                fullWidth
                displayEmpty
                onChange={(e) => handleCandidateJob(e)}
                renderValue={(value) => (
                  <span className={value === "" ? "placeholder" : ""}>
                    {value === "" ? "Select" : jobPositionName}
                  </span>
                )}
              >
                <MenuItem value="" disabled>
                  Select
                </MenuItem>
                {getCompanyJob.map((job) => (
                  <MenuItem key={job.id} value={job.id}>
                    {job.jobTitle}
                  </MenuItem>
                ))}
              </Select>
              <InputLabel
                style={{
                  color: "#f44336",
                  paddingTop: "3px",
                  fontSize: "12px",
                  paddingLeft: "10px",
                }}
              >
                {errors.jobPosition}
              </InputLabel>
            </Grid>
            <Grid mt="30px"></Grid>
            <Grid
              display="flex"
              alignItems="center"
              border="1px solid #c3c3c3"
              borderRadius="8px"
              padding="8px 0px 6px 15px"
            >
              <Grid direction="row" display="flex" gap={1} alignItems="center">
                <img src={CurrentLocationIcon} alt="overview-icon" />
                <Typography
                  //   className="input-label-custom"
                  fontWeight={700}
                  fontSize="13.94px"
                  lineHeight="13.94px"
                  color="#273848"
                >
                  Current Situation & Practicalities
                </Typography>
              </Grid>
            </Grid>
            <Grid mt="30px"></Grid>
            <Typography
              color="#273848"
              fontWeight={700}
              lineHeight="14.94px"
              fontSize="15px"
              //   className="input-label-custom"
            >
              Current Situation
            </Typography>
            <Grid mt="15px"></Grid>
            <Grid item>
              <Typography
                //  color="#4E4E4E"
                //  fontWeight={500}
                //  fontSize="14px"
                className="input-label-custom"
              >
                Can you briefly describe your current role and responsibilities?
              </Typography>
              <Grid mb="5px"></Grid>
              <OutlinedInput
                placeholder="Type here..."
                multiline
                minRows={4}
                className="text-area-size"
                value={rolesResponsibilities}
                onChange={(e) => setRolesResponsibilities(e.target.value)}
              />
            </Grid>
            <InputLabel
              style={{
                color: "#f44336",
                paddingTop: "3px",
                fontSize: "12px",
                paddingLeft: "10px",
              }}
            >
              {errors.rolesResponsibilities}
            </InputLabel>
            <Grid mt="25px"></Grid>
            <Grid item>
              <Typography
                //  color="#4E4E4E"
                //  fontWeight={500}
                //  fontSize="14px"
                className="input-label-custom"
              >
                What prompted you to start exploring new opportunities?
              </Typography>
              <Grid mb="5px"></Grid>
              <OutlinedInput
                placeholder="Type here..."
                multiline
                minRows={4}
                className="text-area-size"
                value={opportunities}
                onChange={(e) => setOpportunities(e.target.value)}
              />
            </Grid>
            <InputLabel
              style={{
                color: "#f44336",
                paddingTop: "3px",
                fontSize: "12px",
                paddingLeft: "10px",
              }}
            >
              {errors.opportunities}
            </InputLabel>
            <Grid mt="30px"></Grid>
            <Typography
              color="#273848"
              fontWeight={700}
              lineHeight="14.94px"
              fontSize="15px"
              //   className="input-label-custom"
            >
              Salary Indication & Benefits
            </Typography>
            <Grid mt="15px"></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                What is your salary expectation for this position?
              </Typography>
              <Grid mb="5px"></Grid>
              <TextField
                sx={{
                  // mt: 1,
                  "& .MuiOutlinedInput-input": {
                    padding: "10px 11px", // Add padding here
                  },
                }}
                placeholder="What is your salary expectation for this position?"
                fullWidth
                className="input-box-custom"
                error={!!errors.salaryExpectation}
                helperText={errors.salaryExpectation}
                value={salaryExpectation}
                onChange={(e) => setSalaryExpectation(e.target.value)}
              />
            </Grid>
            <Grid mt="19px"></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                Can you discuss your understanding of the salary range for this
                role?
              </Typography>
              <Grid mb="5px"></Grid>
              <TextField
                sx={{
                  // mt: 1,
                  "& .MuiOutlinedInput-input": {
                    padding: "10px 11px", // Add padding here
                  },
                }}
                placeholder="Can you discuss your understanding of the salary range for this role?"
                fullWidth
                className="input-box-custom"
                error={!!errors.salaryRange}
                helperText={errors.salaryRange}
                value={salaryRange}
                onChange={(e) => setSalaryRange(e.target.value)}
              />
            </Grid>
            <Grid mt="19px"></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                Beyond salary, what other benefits are important to you in a job
                offer?
              </Typography>
              <Grid mb="5px"></Grid>
              <TextField
                sx={{
                  // mt: 1,
                  "& .MuiOutlinedInput-input": {
                    padding: "10px 11px", // Add padding here
                  },
                }}
                placeholder="Beyond salary, what other benefits are important to you in a job
                offer?"
                fullWidth
                className="input-box-custom"
                error={!!errors.jobBenifits}
                helperText={errors.jobBenifits}
                value={jobBenifits}
                onChange={(e) => setJobBenifits(e.target.value)}
              />
            </Grid>
            <Grid mt="30px"></Grid>
            <Typography
              color="#273848"
              fontWeight={700}
              lineHeight="14.94px"
              fontSize="15px"
              //   className="input-label-custom"
            >
              Salary Indication & Benefits
            </Typography>
            <Grid mt="18px"></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                When would you be available to start if an offer is extended?
              </Typography>
              <Grid mb="5px"></Grid>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Select Date"
                  sx={{ mt: 2 }}
                  value={startExtended}
                  onChange={(date: Dayjs | null) => setStartExtended(date)}
                  shouldDisableDate={disablePastDates}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid mt="18px"></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                Are there any potential obstacles or commitments that might
                affect your start date?
              </Typography>
              <Grid mb="5px"></Grid>
              <TextField
                sx={{
                  // mt: 1,
                  "& .MuiOutlinedInput-input": {
                    padding: "10px 11px", // Add padding here
                  },
                }}
                placeholder="Are there any potential obstacles or commitments that might affect your start date?"
                fullWidth
                className="input-box-custom"
                error={!!errors.commitments}
                helperText={errors.commitments}
                value={commitments}
                onChange={(e) => setCommitments(e.target.value)}
              />
            </Grid>
            <Grid mt="42px"></Grid>
            <Grid
              container
              direction="row"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid xs={2}></Grid>
              <Grid
                item
                xs={isTabs ? 0 : mode ? 3 : 2}
                style={{ maxWidth: "fitContent" }}
              >
                <button
                  type="submit"
                  className="user-scale"
                  // variant="contained"
                  // fullWidth
                  style={{
                    backgroundColor: "#84E1CC",
                    boxShadow: "none",
                    borderRadius: "5px",
                    padding: "17px 17px",
                    lineHeight: "0px",
                    letterSpacing: "0px",
                    textTransform: "none",
                    color: "white",
                    width: "100%",
                    border: "none",
                  }}
                  onClick={(event) => handleSubmit(event)}
                >
                  {mode ? (
                    loading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      "Save & Next"
                    )
                  ) : (
                    "Continue"
                  )}
                </button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ConfirmationDialog
          open={openDialog}
          onConfirm={handleDialogConfirm}
          onCancel={handleDialogCancel}
          title="Confirmation"
          message="A similar Candidate Job Posotion Already Exists. Do you want to use the details of the existing candidate?"
        />
      </Container>
      <LoadingOverlay loading={aiLoading} />
    </div>
  );
};

export default CandidateSpeFirst;
