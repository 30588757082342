export const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
};


export const getMediaType = (url) => {
    const imageExtensions = [
      'jpg',
      'jpeg',
      'png',
      'gif',
      'bmp',
      'tiff',
      'tif',
      'ico',
      'webp',
      'svg',
    ];
    const videoExtensions = [
      'mp4',
      'mov',
      'wmv',
      'avi',
      'flv',
      'mkv',
      'webm',
      'mpeg',
      'mpg',
      'm4v',
      '3gp',
      '3g2',
      'f4v',
      'rm',
      'rmvb',
      'vob',
      'ogv',
    ];
    const extension = url.split('.').pop()?.split("?")[0].toLowerCase();
  
    if (extension && imageExtensions.includes(extension)) {
      return 'image';
    } else if (extension && videoExtensions.includes(extension)) {
      return 'video';
    } else {
      throw new Error('Unsupported media type');
    }
  };