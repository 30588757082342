import React, { useEffect, useState } from "react";
import {
    Grid,
    Typography,
    Container,
    useMediaQuery,
    Box,
    Button,
    TextField,
    InputLabel,
} from "@mui/material";
import "../../styles/inputBox.css";
import CloseIcon from "@mui/icons-material/Close";
import uploadFileIcon from "../../assets/images/uploadFileIcon.svg";
import { useNavigate, useParams } from "react-router-dom";
import { putRequest } from "../../api/apiServices";
import { updateUserPassword } from "../../api/apiList";
import Toast from "../../utils/Toast";

interface ProfileProps {
}

const UserPassword: React.FC<ProfileProps> = ({ }: ProfileProps) => {
    const isMobile = useMediaQuery("(max-width:578px)");

    const navigate = useNavigate()

    // handle Form field
    const [password, setPassword] = useState("")
    const [newPass, setNewPass] = useState("")
    const [confirmPass, setConfirmPass] = useState("")
    const [match, setMatch] = useState("")

    const [errors, setErrors] = useState({
        password: "",
        newPass: "",
        confirmPass: "",
    });

    const formIsValid = () => {
        const newErrors = {
            password: !password ? "Password is required" : "",
            newPass: !newPass?.trim() ? "New Password required" : "",
            confirmPass: !confirmPass?.trim() ? "Confirm New Password is required" : "",
        };
        setErrors(newErrors);
        return Object.values(newErrors).every((error) => error === "");
    };


    //params 
    const params = useParams()

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        if (formIsValid()) {
            if (newPass !== confirmPass) {
                return setMatch("New Password and Confirm Password are Not Same.")
            }
            const response = await putRequest(updateUserPassword(params?.id), { newPass: newPass })
            if (response?.data?.success) {
                Toast(response?.data?.statusMessage)
                navigate("/")
            }
        }
    };

    useEffect(() => {
    }, [])

    return (
        <div style={{ backgroundColor: "#F4F4F4" }}>
            <Container
                maxWidth="md"
                style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#F4F4F4",
                }}
            >
                <Grid
                    spacing={2}
                    bgcolor="#FFFFFF"
                    width="784px"
                    borderRadius="22px"
                    border="1px solid #D6D6D7"
                    margin={0}
                >
                    <Grid
                        item
                        height={isMobile ? "auto" : "auto"}
                        style={{ padding: 30 }}
                    >
                        <Grid
                            container
                            direction="row"
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            gap={isMobile ? "10px" : ""}
                        >
                            <Grid item xs={8}>
                                <Typography
                                    className="profile-title"
                                    color="#273848"
                                    fontWeight={700}
                                    fontSize="20.94px"
                                >
                                    Update Your Password
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid my={2}></Grid>
                        <Grid my={2}></Grid>
                        <Grid item>
                            <Typography className="input-label-custom">
                                Password<span style={{ color: "red" }}>*</span>
                            </Typography>
                            <Grid mb={1}></Grid>
                            <TextField
                                sx={{
                                    // mt: 1,
                                    "& .MuiOutlinedInput-input": {
                                      padding: "10px 11px", // Add padding here
                                    },
                                  }}
                                placeholder="Name"
                                fullWidth
                                className="input-box-custom"
                                error={!!errors.password}
                                helperText={errors.password}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Grid>
                        <Grid my={2}></Grid>
                        <Grid item>
                            <Typography className="input-label-custom">
                                New Password<span style={{ color: "red" }}>*</span>
                            </Typography>
                            <Grid mb={1}></Grid>
                            <TextField
                                sx={{
                                    // mt: 1,
                                    "& .MuiOutlinedInput-input": {
                                      padding: "10px 11px", // Add padding here
                                    },
                                  }}
                                placeholder="New Password"
                                fullWidth
                                className="input-box-custom"
                                error={!!errors.newPass}
                                helperText={errors.newPass}
                                value={newPass}
                                onChange={(e) => setNewPass(e.target.value)}
                            />
                        </Grid>
                        <Grid my={2}></Grid>
                        <Grid item>
                            <Typography className="input-label-custom">
                                Confirm Password<span style={{ color: "red" }}>*</span>
                            </Typography>
                            <Grid mb={1}></Grid>
                            <TextField
                                sx={{
                                    // mt: 1,
                                    "& .MuiOutlinedInput-input": {
                                      padding: "10px 11px", // Add padding here
                                    },
                                  }}
                                placeholder="Confirm Password"
                                fullWidth
                                className="input-box-custom"
                                error={!!errors.confirmPass}
                                helperText={errors.confirmPass}
                                value={confirmPass}
                                onChange={(e) => setConfirmPass(e.target.value)}
                            />
                        </Grid>
                        <InputLabel style={{ color: '#f44336', paddingTop: "3px", fontSize: "12px", paddingLeft: "10px" }}>{match}</InputLabel>
                        <Grid my={2}></Grid>
                        <Grid
                            container
                            direction="row"
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Grid item xs={3} style={{ maxWidth: "fitContent" }}>
                            </Grid>
                            <Grid item xs={3} style={{ maxWidth: "fitContent" }}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    fullWidth
                                    style={{
                                        backgroundColor: "#84E1CC",
                                        boxShadow: "none",
                                        borderRadius: "5px",
                                        padding: "17px 17px",
                                        lineHeight: "0px",
                                        textTransform: "none",
                                        letterSpacing: "0px",
                                    }}
                                    onClick={(event) => handleSubmit(event)}
                                >
                                    Update Password
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default UserPassword;
