import React from "react";
import Typography from "@mui/material/Typography";

interface LabelProps {
  children: React.ReactNode;
  // Static styles
  fontSize?: string | number;
  fontWeight?: string | number;
  color?: string;
  // Dynamic styles
  sx?: object;
}

const Label: React.FC<LabelProps> = ({
  children,
  fontSize = "inherit",
  fontWeight = "inherit",
  color = "inherit",
  sx = {},
}) => {
  return (
    <Typography
      variant="body1"
      sx={{
        // Static styles
        fontSize,
        fontWeight,
        color,
        // Dynamic styles
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

export default Label;
