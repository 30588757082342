import axios from 'axios';
import Toast from './Toast';

export const getAIImages = async (keywords) => {
  if (
    !keywords ||
    typeof keywords !== 'string' ||
    keywords.trim().length === 0
  ) {
    Toast('Please Enter Some Data...!', 'warning');
    return;
  }

  const apiKey = `sk-proj-UKfq2kxHgAqegNCJb1tMT3BlbkFJ5KY1O4Ut58XGLhztMKYl`;
  // const apiKey = `sk-proj-UKfq2kxHgAqegNCJb1tMT3BlbkFJ5KY1O4Ut58XGLhztMK`;
  const apiUrl = `https://api.openai.com/v1/images/generations`;

  const headers = {
    Authorization: `Bearer ${apiKey}`,
    'Content-Type': 'application/json',
  };

  const data = {
    prompt: keywords,
    n: 4,
    size: '1024x1024',
    quality: 'standard',
  };

  try {
    const response = await axios.post(apiUrl, data, { headers });
    return response.data.data.map((image) => image.url);
  } catch (error) {
    console.error('Error calling OpenAI API:', error);
    throw new Error('Failed to generate text');
  }
};
