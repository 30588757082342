import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import SendIcon from '@mui/icons-material/Send';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { Grid, Typography, Avatar, IconButton } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import '../../styles/postPreview.css';

const PostPreview: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const postText = location.state?.postText || '';
    const { profile } = location.state || { profile: null };

    console.log('Post Text:', postText); // Debugging line
    console.log('Profile:', profile); // Debugging line

    const goBack = () => {
        window.history.back();
    };

    return (
        <>
            <Grid container alignItems="center" justifyContent="flex-start" style={{ padding: '20px' }}>
                <ArrowBackIcon style={{ cursor: 'pointer' }} onClick={goBack} />
                <Typography style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={goBack}>Back</Typography>
            </Grid>
            <Grid container alignItems="center" justifyContent="center" style={{ minHeight: '80vh' }}>
                <div className="card">
                    <div className="header">
                        <Avatar src="https://randomuser.me/api/portraits/men/32.jpg" className="avatar" />
                        <Typography className="username">b_parker024</Typography>
                        <MoreHorizIcon className="more-icon" />
                    </div>
                    <div className="content" style={{ overflow: 'hidden' }}>
                        {profile && profile.type.startsWith('image') ? (
                            <img
                                src={URL.createObjectURL(profile)}
                                alt="selected-image"
                                className='post-image'
                                style={{
                                    height: '87%',
                                    width: '100%',
                                    objectFit: 'cover',
                                }}
                            />
                        ) : profile && profile.type.startsWith('video') ? (
                            <video
                                controls
                                src={URL.createObjectURL(profile)}
                                style={{
                                    maxWidth: '100%',
                                    maxHeight: '100%',
                                    height: '200px',
                                    width: '250px',
                                    objectFit: 'cover',
                                }}
                            />
                        ) : null}
                    </div>
                    <div className="footer">
                        <div className="footer-left">
                            <IconButton>
                                <FavoriteBorderIcon />
                            </IconButton>
                            <IconButton>
                                <ChatBubbleOutlineIcon />
                            </IconButton>
                            <IconButton>
                                <SendIcon />
                            </IconButton>
                        </div>
                        <div className="dots"></div>
                        <div className="footer-right">
                            <IconButton>
                                <BookmarkBorderIcon />
                            </IconButton>
                        </div>
                    </div>
                    <div className="post-info" style={{ paddingBottom: '20px', borderTop: 'none' }}>
                        <Typography variant="body1">
                            <strong>b_parker024</strong> {postText}
                        </Typography>
                    </div>
                </div>
            </Grid>
        </>
    );
};

export default PostPreview;
