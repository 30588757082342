import * as Yup from 'yup';

export const ValidationSchema = Yup.object({
    name: Yup.string()
        .required('Name is required')
        .min(3, 'Name must be at least 3 characters'),

    email: Yup.string()
        .required('Email is required')
        .email('Invalid email format'),

    jobTitle: Yup.string()
        .required('Job title is required'),

    cv: Yup.mixed()
        .required('CV is required')
        .test('fileSize', 'File size too large', (value) => {
            // Cast value to FileList
            if (value instanceof FileList && value.length > 0) {
                return value[0].size <= 2 * 1024 * 1024; // Less than 2MB
            }
            return false;
        })
        .test('fileType', 'Unsupported file format', (value) => {
            // Cast value to FileList
            if (value instanceof FileList && value.length > 0) {
                return ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(value[0].type);
            }
            return false;
        }),

    phone: Yup.string()
        .required('Phone is required')
        .matches(/^\d{10}$/, 'Phone must be exactly 10 digits'),
});