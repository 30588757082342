//SignUp.tsx
import React, { useEffect, useState } from "react";
import {
    Grid,
    Typography,
    Link,
    TextField,
    Button,
    Container,
    useMediaQuery,
    FormLabel,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Logo from '../../assets/images/Logo.svg';
import LogoName from '../../assets/images/LogoName.png';
import Image from '../../assets/images/Image.svg';
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate, useParams } from "react-router-dom";
import "../../styles/inputBox.css";
import { handleAction } from "../../services/authService";
import { AxiosError } from "axios";
import Toast from "../../utils/Toast";

interface ErrorResponse {
    statusMessage?: string;
}

const Invitation: React.FC = () => {
    const [name, setUserName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [userNameError, setUserNameError] = useState<string>("");
    const [emailError, setEmailError] = useState<string>("");
    const [passwordError, setPasswordError] = useState<string>("");
    const [confirmPasswordError, setConfirmPasswordError] = useState<string>("");
    const [passwordMismatch, setPasswordMismatch] = useState<string>("");
    const isMobile = useMediaQuery("(max-width:578px)");
    const navigate = useNavigate();

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleShowConfirmPassword = () =>
        setShowConfirmPassword((show) => !show);

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };

    const params = useParams()

    const handleSubmit = async () => {
        try {
            setUserNameError("");
            setEmailError("");
            setPasswordError("");
            setConfirmPasswordError("");
            setPasswordMismatch("");

            if (!name || !email || !password || !confirmPassword) {
                if (!name) setUserNameError("UserName is required");
                if (!email) setEmailError("Email is required");
                if (!password) setPasswordError("Password is required");
                if (!confirmPassword)
                    setConfirmPasswordError("Password confirmation is required");
                return;
            }

            // Validate email format
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(email)) {
                setEmailError("Invalid email address");
                return;
            }
            if (password.length < 8) {
                setPasswordError("Password must be at least 8 characters long");
                return;
            }
            if (password !== confirmPassword) {
                setPasswordMismatch("Passwords do not match");
                return;
            }
            const registerData = {
                name, email, password, status: 1, companyId: params?.id
            }
            await handleAction("register", registerData);
            Toast("Please Check Your Email");
            navigate("/emailverify", { state: { email: email } });
        } catch (error) {
            if ((error as AxiosError<ErrorResponse>).isAxiosError) {
                const axiosError = error as AxiosError<ErrorResponse>;
                Toast(
                    axiosError.response?.data?.statusMessage || "Something Went Wrong..!", "error"
                );
            } else {
                Toast("An error occurred while registering", "error");
            }
        }
    };

    useEffect(() => {
        const { email, id } = params
        if (email) {
            setEmail(email)
        }
    }, [params])

    return (
        <div style={{ backgroundColor: "#F4F4F4" }}>
            <Container
                maxWidth="md"
                style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#F4F4F4",
                }}
            >
                <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    bgcolor="#FFFFFF"
                    // alignItems="center"
                    borderRadius="22px"
                    // height={700}
                    margin={0}
                // width="max-content"
                >
                    {/* Form Column */}
                    <Grid
                        item
                        xs={12}
                        md={6}
                        height={isMobile ? "fit-content" : "auto"}
                        // bgcolor="green"
                        justifyContent="center"
                        style={{ padding: 30 }}
                    >
                        <Grid
                            container
                            spacing={2}
                            height="100%"
                            justifyContent="space-between"
                            direction="column"
                        >
                            <Grid
                                container
                                spacing={2}
                                width="100%"
                                direction="column"
                                margin={0}
                                padding={0}
                            >
                                <Grid item>
                                    {isMobile && (
                                        <Grid
                                            item
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <img
                                                src={LogoName}
                                                style={{
                                                    width: "70%",
                                                }}
                                            />
                                        </Grid>
                                    )}
                                    <Typography
                                        color="#273848"
                                        fontWeight={700}
                                        fontSize="29.94px"
                                    >
                                        Create an Account
                                    </Typography>
                                    <Typography color="#273848" fontWeight={500} fontSize="14px">
                                        Lorem ipsum dolor sit amet consectetur. Tortor odio.
                                    </Typography>
                                </Grid>
                                <Grid item></Grid>
                                <Grid item>
                                    <Typography className="input-label-custom">
                                        User Name
                                    </Typography>
                                    <TextField
                                        sx={{
                                            // mt: 1,
                                            "& .MuiOutlinedInput-input": {
                                              padding: "10px 11px", // Add padding here
                                            },
                                          }}
                                        placeholder="Enter Your Name"
                                        fullWidth
                                        className="input-box-custom"
                                        value={name}
                                        onChange={(e) => setUserName(e.target.value)}
                                        error={!!userNameError} // Apply error styling if emailError is true
                                        InputProps={{
                                            style: {
                                                font: "caption",
                                                fontWeight: 500,
                                            },
                                        }}
                                    />
                                    {userNameError && (
                                        <Typography color="error" fontSize={12}>
                                            {userNameError}
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid item>
                                    <Typography className="input-label-custom">
                                        Email Address
                                    </Typography>
                                    <TextField
                                        sx={{
                                            // mt: 1,
                                            "& .MuiOutlinedInput-input": {
                                              padding: "10px 11px", // Add padding here
                                            },
                                          }}
                                        placeholder="Email Address"
                                        fullWidth
                                        className="input-box-custom"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        error={!!emailError} // Apply error styling if emailError is true
                                        InputProps={{
                                            style: {
                                                font: "caption",
                                                fontWeight: 500,
                                            },
                                        }}
                                    />
                                    {emailError && (
                                        <Typography color="error" fontSize={12}>
                                            {emailError}
                                        </Typography>
                                    )}
                                </Grid>

                                <Grid item>
                                    <Typography className="input-label-custom">
                                        Password
                                    </Typography>
                                    {/* <TextField label="Password" type="password" fullWidth /> */}
                                    <FormControl fullWidth variant="outlined">
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            type={showPassword ? "text" : "password"}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        // aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            placeholder="Password"
                                            label=""
                                            className="input-box-custom"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            error={!!passwordError}
                                        />
                                        {passwordError && (
                                            // Display error message here conditionally
                                            <Typography color="error" fontSize={12}>
                                                {passwordError}
                                            </Typography>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <Typography className="input-label-custom">
                                        Confirm Password
                                    </Typography>
                                    {/* <TextField label="Password" type="password" fullWidth /> */}
                                    <FormControl fullWidth variant="outlined">
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            type={showConfirmPassword ? "text" : "password"}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        // aria-label="toggle password visibility"
                                                        onClick={handleShowConfirmPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showConfirmPassword ? (
                                                            <VisibilityOff />
                                                        ) : (
                                                            <Visibility />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            placeholder="Confirm Password"
                                            label=""
                                            className="input-box-custom"
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            error={!!confirmPasswordError}
                                        />
                                        {confirmPasswordError && (
                                            <Typography color="error" fontSize={12}>
                                                {confirmPasswordError}
                                            </Typography>
                                        )}
                                        {passwordMismatch && (
                                            <Typography color="error" fontSize={12}>
                                                {passwordMismatch}
                                            </Typography>
                                        )}
                                    </FormControl>
                                </Grid>

                                <Grid item>
                                    <Button
                                        variant="contained"
                                        // color="primary"
                                        fullWidth
                                        style={{
                                            backgroundColor: "#84E1CC",
                                            boxShadow: "none",
                                            borderRadius: "5px",
                                            padding: "17px 17px",
                                            lineHeight: "0px",
                                            textTransform: "none",
                                            letterSpacing: "0px",
                                        }}
                                        onClick={handleSubmit}
                                    >
                                        Create Account
                                    </Button>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Typography align="center" color="#273848">
                                    Do you already have an account?{" "}
                                    <Link
                                        href="/login"
                                        color="#6BCED7"
                                        style={{ textDecorationColor: "#6BCED7" }}
                                    >
                                        Sign In
                                    </Link>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* Image Column */}
                    {!isMobile && (
                        <Grid
                            item
                            xs={12}
                            md={6}
                            style={{
                                padding: 0,
                                position: "relative",
                            }}
                        >
                            <div
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    background: `url(${Logo})`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    borderRadius: "0 22px 22px 0",
                                    // opacity: 0.5,
                                }}
                            />
                            <img
                                src={Image}
                                alt="Signup Image"
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "0 22px 22px 0",
                                    objectFit: "cover",
                                }}
                            />
                        </Grid>
                    )}
                </Grid>
            </Container>
        </div>
    );
};

export default Invitation;
