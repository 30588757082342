import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Container,
  useMediaQuery,
  Box,
  Button,
  TextareaAutosize,
  InputLabel,
  OutlinedInput,
  CircularProgress,
} from "@mui/material";
import "../../styles/inputBox.css";
import { multiPartPUTRequest } from "../../api/apiServices";
import { updateCompany } from "../../api/apiList";
import { useParams } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface ProfileThirdProps {
  onBack: () => void;
  onNext: () => void;
  setProfileData: (data: any) => void;
  initialData: any;
  mode: any
}

const ProfileThird: React.FC<ProfileThirdProps> = ({ onBack, onNext, setProfileData, initialData, mode }: ProfileThirdProps) => {
  const isMobile = useMediaQuery("(max-width:578px)");
  const [employerValueProposition, setEmployerValueProposition] = useState("");
  const [companyMileStones, setCompanyMilestones] = useState("");
  const [companyCulture, setCompanyCulture] = useState("");
  const [secondaryBenefits, setSecondaryBenefits] = useState("");
  const [loading , setLoading] = useState(false)


  const [errors, setErrors] = useState({
    employerValueProposition: "",
    companyMileStones: "",
    companyCulture: "",
    secondaryBenefits: "",
  });

  const formIsValid = () => {
    const newErrors = {
      employerValueProposition: !employerValueProposition?.trim() ? "Employer Value Proposition is required" : "",
      companyMileStones: !companyMileStones?.trim() ? "Company Milestones is required" : "",
      companyCulture: !companyCulture?.trim() ? "Company Culture is required" : "",
      secondaryBenefits: !secondaryBenefits?.trim() ? "Secondary Benefits is required" : "",
    };
    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };

  useEffect(() => {
    if (initialData) {
      setEmployerValueProposition(initialData?.employerValueProposition)
      setCompanyMilestones(initialData?.companyMileStones)
      setCompanyCulture(initialData?.companyCulture)
      setSecondaryBenefits(initialData?.secondaryBenefits)
    }
  }, [])

  const handleBack = (event: any) => {
    event.preventDefault();
    onBack();
  };
  const params = useParams()
  

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const data = {
      employerValueProposition,
      companyMileStones,
      companyCulture,
      secondaryBenefits
    }
    if (mode) {
      setLoading(true)
      const response = await multiPartPUTRequest(updateCompany(params?.id), data)
      setProfileData(response?.data?.data)
      setLoading(false)
      onNext()
    } else {
      if (formIsValid()) {
        const allData = {
          ...initialData,
          ...data,
        };
        setProfileData(allData)
        onNext(); // Call the onNext function to navigate to the next component
      }
    }

  };

  return (
    <div>
      <Container
        maxWidth="md"
        style={{
          padding: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          spacing={2}
          bgcolor="#FFFFFF"
          width="784px"
          borderRadius="22px"
          border="1px solid #D6D6D7"
          margin={0}
        >
          <Grid
            item
            height={isMobile ? "auto" : "auto"}
            style={{ padding: 30 }}
          >
            <Grid
              container
              direction="row"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              gap="10px"
            >
              <Grid item display="flex" alignItems="center" gap="10px">
                <ArrowBackIcon
                  onClick={handleBack}
                  style={{ cursor: "pointer" }}
                />
                <Typography color="#273848" fontWeight={700} fontSize="20.94px">
                  Create Profile
                </Typography>
              </Grid>
              <Grid item gap={2} display="flex">
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#84E1CC",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#84E1CC",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#84E1CC",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#DFF4EF",
                  }}
                />
              </Grid>
            </Grid>
            <Grid my={2}></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                Employer Value Proposition
              </Typography>
              <Grid mb={1}></Grid>
              <OutlinedInput
                placeholder="Why do employees want to work at your company?..."
                multiline
                minRows={4}
                className="text-area-size"
                value={employerValueProposition}
                onChange={(e) => setEmployerValueProposition(e.target.value)}
              />
            </Grid>
            <InputLabel
              style={{
                color: "#f44336",
                paddingTop: "3px",
                fontSize: "12px",
                paddingLeft: "10px",
              }}
            >
              {errors.employerValueProposition}
            </InputLabel>
            <Grid my={2}></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                Company Milestones
              </Typography>
              <Grid mb={1}></Grid>
              <OutlinedInput
                placeholder="What is the company proud of?..."
                multiline
                minRows={4}
                className="text-area-size"
                value={companyMileStones}
                onChange={(e) => setCompanyMilestones(e.target.value)}
              />
            </Grid>
            <InputLabel
              style={{
                color: "#f44336",
                paddingTop: "3px",
                fontSize: "12px",
                paddingLeft: "10px",
              }}
            >
              {errors.companyMileStones}
            </InputLabel>
            <Grid my={2}></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                Company Culture
              </Typography>
              <Grid mb={1}></Grid>
              <OutlinedInput
                placeholder="Company Culture..."
                multiline
                minRows={4}
                className="text-area-size"
                value={companyCulture}
                onChange={(e) => setCompanyCulture(e.target.value)}
              />
            </Grid>
            <InputLabel
              style={{
                color: "#f44336",
                paddingTop: "3px",
                fontSize: "12px",
                paddingLeft: "10px",
              }}
            >
              {errors.companyCulture}
            </InputLabel>
            <Grid my={2}></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                Secondary Benefits
              </Typography>
              <Grid mb={1}></Grid>
              <OutlinedInput
                placeholder="Secondary Benefits..."
                multiline
                minRows={4}
                className="text-area-size"
                value={secondaryBenefits}
                onChange={(e) => setSecondaryBenefits(e.target.value)}
              />
            </Grid>
            <InputLabel
              style={{
                color: "#f44336",
                paddingTop: "3px",
                fontSize: "12px",
                paddingLeft: "10px",
              }}
            >
              {errors.secondaryBenefits}
            </InputLabel>
            <Grid mb="10px"></Grid>
            <Grid
              container
              direction="row"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid xs={2.5}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  style={{
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #000000",
                    color: "#273848",
                    boxShadow: "none",
                    borderRadius: "5px",
                    padding: "17px 17px",
                    lineHeight: "0px",
                    textTransform: "none",
                    letterSpacing: "0px",
                  }}
                  onClick={(event) => handleBack(event)}
                >
                  Back
                </Button>
              </Grid>
              <Grid
                item
                xs={isMobile ? 0 : 3}
                style={{ maxWidth: "fitContent" }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  style={{
                    backgroundColor: "#84E1CC",
                    boxShadow: "none",
                    borderRadius: "5px",
                    padding: "17px 17px",
                    lineHeight: "0px",
                    textTransform: "none",
                    letterSpacing: "0px",
                  }}
                  onClick={(event) => handleSubmit(event)}
                >
                  {mode ? (
                    loading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      "Save & Next"
                    )
                  ) : (
                    "Continue"
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ProfileThird;
